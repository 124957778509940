import EarnBackgroundWebp from 'assets/images/earn_bg.webp';
import EarnBackgroundPng from 'assets/images/earn_bg.png';
import * as S from './Statistics.styles';
import GeneralStatistics from './GeneralStatistics/GeneralStatistics';
import { Spacer } from 'components';

const Statistics: React.FC = () => {
  return (
    <S.StatisticsWrapper>
      <S.BackgroundImage
        src={EarnBackgroundWebp}
        fallback={EarnBackgroundPng}
        alt="statistics background image"
      />
      <S.StatisticsCard>
        <S.Title>Statistics</S.Title>
        <S.SubTitle>Flurry Analytics accross all networks</S.SubTitle>
        {/* <S.StatisticsTypeWrapper>
          <S.StatisticsTypeSelector>
            <S.StatisticsTypeBtn isActive>Overview</S.StatisticsTypeBtn>
            <S.StatisticsTypeBtn>Tokens</S.StatisticsTypeBtn>
          </S.StatisticsTypeSelector>
          <S.TokenSearchBar
            placeholder="Search for a token..."
            onChange={function (): void {
              throw new Error('Function not implemented.');
            }}
          />
        </S.StatisticsTypeWrapper> */}
        <GeneralStatistics />
      </S.StatisticsCard>
      <Spacer axis="vertical" size={50} />
    </S.StatisticsWrapper>
  );
};

export default Statistics;
