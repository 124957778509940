import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import React, { useRef } from 'react';
import * as S from './UserModal.styles';
import { showUserModal } from 'core/store/ui/ui';
import UserDetails from './UserDetails';
import {
  DisconnectButton,
  useOnClickOutside,
  UserNotifications,
} from 'components';
import { useMediaQuery } from 'react-responsive';
import { sendGAEvent } from 'utils';

const UserModal: React.FC = () => {
  const dispatch = useAppDispatch();
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1200px)' });

  const mobileMenuRef = useRef(null);
  useOnClickOutside(mobileMenuRef, () => {
    if (isTabletOrMobile) {
      sendGAEvent('Navbar', 'Hide User Modal');
      dispatch(showUserModal(false));
    }
  });

  const notificationsModalUI = useAppSelector((state) => state.ui.userModal);

  if (!notificationsModalUI.show) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Modal ref={mobileMenuRef}>
        <S.ModalCloseBtn
          onClick={() => {
            sendGAEvent('Navbar', 'Hide User Modal');
            dispatch(showUserModal(false));
          }}
        />
        <UserNotifications />
        <S.DesktopInfos>
          <UserDetails />
          <DisconnectButton />
        </S.DesktopInfos>
      </S.Modal>
    </S.Wrapper>
  );
};

export default UserModal;
