import React from 'react';
import * as S from './Navbar.styles';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { shortenStringInMiddle } from '../../utils/strings';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { showUserModal } from 'core/store/ui/ui';
import styled, { keyframes } from 'styled-components/macro';
import { selectPendingTransactions } from 'core/store/transactions/selectors';
import { sendGAEvent } from 'utils';

const glowing = (color: string) => keyframes`
  0% {
    box-shadow: ${color} 0px 0px 1px;
  }
  50% {
    box-shadow: ${color} 0px 0px 6px;
  }
  100% {
    box-shadow: ${color} 0px 0px 1px;
  }
`;

const Circle = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#5dff72' : '#ffffff')};
  border-radius: 1.5rem;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 0.4rem;
  animation: ${(props) => glowing(props.active ? '#5dff72' : '#ffffff')} 1s
    infinite;
  transform: scale(0.8);
`;

const AuthButton: React.FC = () => {
  const userModalShowing = useAppSelector((state) => state.ui.userModal.show);

  const user = useAppSelector((state) => state.auth.user);
  const pendingTransactions = useAppSelector((state) =>
    selectPendingTransactions(state)
  );

  const dispatch = useAppDispatch();

  return (
    <S.NavBtn>
      <S.Btn
        onClick={() => {
          if (user !== undefined && user.address !== undefined) {
            sendGAEvent(
              'Navbar',
              !userModalShowing ? 'Show User Modal' : 'Hide User Modal'
            );
            dispatch(showUserModal(!userModalShowing));
          } else {
            sendGAEvent('Navbar', 'Connect');
            dispatch(connectToProvider());
          }
        }}
      >
        {user && user.address ? (
          <S.BtnUserAddress>
            <Circle active={pendingTransactions.length > 0} />
            {shortenStringInMiddle(user.address)}
          </S.BtnUserAddress>
        ) : (
          'Connect Wallet'
        )}
      </S.Btn>
    </S.NavBtn>
  );
};

export default AuthButton;
