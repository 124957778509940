import React from 'react';
import * as S from './SearchBar.styles';

export interface PriceInputProps {
  onChange: (v: string) => void;
  placeholder?: string;
  className?: string;
}

const SearchBar: React.FC<PriceInputProps> = ({
  onChange,
  placeholder,
  className,
}) => {
  return (
    <S.Wrapper className={className}>
      <S.Input
        type="text"
        placeholder={placeholder ?? 'Search...'}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          onChange(e.target.value)
        }
      />
      <S.SearchIcon />
    </S.Wrapper>
  );
};

export default SearchBar;
