import BigNumber from 'bignumber.js';
import {
  Contract,
  FarmApr,
  FarmContract,
  NetworkInfo,
  PriceOracle,
} from 'core/types';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { ethers, BigNumber as EthersBN } from 'ethers';
import { BN, findUsdPriceOracle, formatToString, getValueOfRef } from 'utils';

export const formatAPR = (
  apr: number | undefined,
  decimals: number
): string | undefined => {
  if (apr !== undefined) {
    if (
      apr >= parseFloat(ethers.utils.formatEther(ethers.constants.MaxUint256))
    ) {
      return '∞ ';
    }

    return formatToString(apr * 100, decimals, true, ',');
  }

  return undefined;
};

export const aprToNumber = (apr: EthersBN | undefined): number | undefined => {
  return apr ? parseFloat(ethers.utils.formatEther(apr)) : undefined;
};

export const apyNormalized = (
  strategieKey: string,
  ratePerBlock: number,
  blocksPerDay: number
): number | undefined => {
  if (!ratePerBlock) {
    return undefined;
  }

  if (
    strategieKey.toLowerCase().includes('compound') ||
    strategieKey.toLowerCase().includes('venus') ||
    strategieKey.toLowerCase().includes('cream')
  ) {
    return (
      (parseFloat(ethers.utils.formatEther(ratePerBlock)) * blocksPerDay + 1) **
        365 -
      1
    );
  }
  return undefined;
};

export const calculateFarmsApr = async (payload: {
  farms: FarmContract[];
  lpStaking: Contract | null;
  flurryToken: Contract | null;
  priceOracles: PriceOracle[];
  multicall: Multicall;
  network: NetworkInfo | undefined;
  flurryUsdPrice: BigNumber | undefined;
}): Promise<FarmApr[]> => {
  const result: FarmApr[] = [];
  const {
    farms,
    lpStaking,
    flurryToken,
    priceOracles,
    network,
    multicall,
    flurryUsdPrice,
  } = payload;

  if (!network) {
    throw Error('No Network');
  }
  if (!lpStaking) {
    throw Error('No LP staking contracts');
  }
  if (!flurryToken) {
    throw Error('No Flurry staking contracts');
  }
  const flurryPriceOracle = findUsdPriceOracle(
    priceOracles,
    flurryToken.address
  );

  // Building contract multicall
  const multicallContext: ContractCallContext[] = [];
  if (flurryPriceOracle) {
    multicallContext.push({
      reference: 'flurryPrice',
      contractAddress: flurryPriceOracle.address,
      abi: flurryPriceOracle.abi ?? [],
      calls: [
        {
          reference: 'flurryPrice',
          methodName: 'price',
          methodParameters: [flurryToken.address],
        },
      ],
      context: {
        decimals: flurryPriceOracle.decimals,
      },
    });
  }

  for (const f of farms) {
    if (f.unreleased) {
      continue;
    }

    multicallContext.push({
      reference: f.address,
      contractAddress: lpStaking.address,
      abi: lpStaking.abi ?? [],
      calls: [
        {
          reference: 'rewardRatePerTokenStaked',
          methodName: 'rewardRatePerTokenStaked',
          methodParameters: [f.address],
        },
      ],
    });
  }

  // Executing contract multicall
  const callResults = (await multicall.call(multicallContext)).results;

  // Formating all contract result into usable value
  for (const f of farms) {
    if (f.unreleased) {
      continue;
    }
    const rewardRatePerTokenStaked = getValueOfRef(
      callResults[f.address]?.callsReturnContext,
      'rewardRatePerTokenStaked'
    );

    if (
      !f.totalSupply ||
      f.totalSupply.isZero() ||
      !f.liquidity ||
      f.liquidity.isZero() ||
      rewardRatePerTokenStaked.gte(ethers.constants.MaxUint256)
    ) {
      result.push({
        farmKey: f.key,
        apr: BN(ethers.constants.MaxUint256).toNumber(),
      });
      continue;
    }

    const farmRewardPerToken = parseFloat(
      ethers.utils.formatUnits(rewardRatePerTokenStaked)
    );

    const flurryPrice = getValueOfRef(
      callResults.flurryPrice?.callsReturnContext ?? [],
      'flurryPrice'
    );
    const flurryPriceDecimals =
      callResults.flurryPrice?.originalContractCallContext.context?.decimals;

    const price = flurryPrice
      ? BN(flurryPrice, flurryPriceDecimals)
      : flurryUsdPrice;

    if (price) {
      const flurryApr =
        farmRewardPerToken * price.toNumber() * network.blocksPerYear;

      const lpTokenInUsd = new BigNumber(1)
        .div(f.totalSupply)
        .times(f.liquidity);

      result.push({
        farmKey: f.key,
        apr: flurryApr / lpTokenInUsd.toNumber(),
      });
    }
  }
  return result;
};
