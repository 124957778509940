import BigNumber from 'bignumber.js';
import { BigNumber as EthersBN, ethers } from 'ethers';

export const BN = (
  balance: EthersBN,
  decimals?: EthersBN | number
): BigNumber => {
  let clonedBN = BigNumber.clone();
  if (decimals) {
    if (typeof decimals === 'number') {
      clonedBN = BigNumber.clone({ DECIMAL_PLACES: decimals });
    } else {
      clonedBN = BigNumber.clone({ DECIMAL_PLACES: decimals.toNumber() });
    }
  }

  return new clonedBN(
    decimals
      ? ethers.utils.formatUnits(balance, EthersBN.from(decimals))
      : ethers.utils.formatEther(balance)
  );
};
