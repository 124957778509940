import React, { useState, useRef } from 'react';

import { CommonBases } from './CommonBases';
import TokenOptionsList from './TokenOptionsList';
import * as S from './SelectTokenPopup.styles';

import { SearchBar, Spacer, useOnClickOutside } from 'components';
import { CurrencyOption } from 'core/types';

interface SelectTokenPopupProps {
  tokens: CurrencyOption[];
  mostUsedTokens: CurrencyOption[];
  currentToken: string;
  onSelect: (v: CurrencyOption) => void;
  onClose: () => void;
  isOpen: boolean;
}

export const SelectTokenPopup: React.FC<SelectTokenPopupProps> = ({
  tokens,
  mostUsedTokens,
  currentToken,
  onSelect,
  onClose,
  isOpen,
}: SelectTokenPopupProps) => {
  const [searchFilter, setSearchFilter] = useState<string | undefined>(
    undefined
  );

  const ref = useRef(null);
  useOnClickOutside(ref, () => onClose());

  if (!isOpen) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Popup ref={ref}>
        <S.CloseBtn onClick={onClose} />
        <S.Header>Select a token</S.Header>
        <S.SearchBarWrapper>
          <SearchBar
            onChange={(v: string) => {
              setSearchFilter(v);
            }}
            placeholder="Search name or paste address..."
          />
        </S.SearchBarWrapper>
        <Spacer axis="vertical" size={10} />
        <CommonBases
          tokens={mostUsedTokens}
          onSelected={onSelect}
          currentToken={currentToken}
        />
        <Spacer axis="vertical" size={15} />
        <TokenOptionsList
          options={tokens}
          currentOption={currentToken}
          onSelected={onSelect}
          filter={searchFilter}
        />
      </S.Popup>
    </S.Wrapper>
  );
};
