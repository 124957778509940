import styled, { css } from 'styled-components/macro';
import { down } from 'styled-breakpoints';
import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

export const Wrapper = styled.div`
  padding: 2rem 2rem;
  ${down('md')} {
    padding: 2rem 1rem;
  }
`;

export const SubTitle = styled.h4`
  font-size: 0.7rem;
  color: #5e5e5e;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
`;

export const FarmColumnLayout = css`
  grid-template-columns: 0.5fr 1.5fr 2fr 2fr;
`;

export const FarmTableWrapper = styled.div`
  position: relative;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0.04em 0.1em,
    rgba(0, 0, 0, 0.1) 0px 0.1em 0.2em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 7px;
`;

export const Header = styled.div`
  display: grid;
  ${FarmColumnLayout}
  padding: 1.5rem 1.5rem;
  box-shadow: rgba(33, 35, 38, 0.384) 0px 10px 10px -10px;
  ${down('md')} {
    padding: 0.8rem 1.5rem;
  }
`;

export const HeaderItem = styled.div<{
  isSorted?: boolean;
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${(props) => (props.isSorted ? '#0000FF' : 'inherit')};
  ${down('md')} {
    font-size: 0.7rem;
  }
  ${down('sm')} {
    display: ${(props) => (props.hideOnTablet ? 'none' : 'flex')};
  }
  ${down('xs')} {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  }
`;

export const ArrowUp = styled(FiArrowUp)`
  padding-left: 0.2rem;
`;
export const ArrowDown = styled(FiArrowDown)`
  padding-left: 0.2rem;
`;

export const BodyItem = styled.div``;

export const EmptyFarms = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-size: 0.8rem;
`;
