import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useAppDispatch, useAppSelector, useBlockNow } from 'core/store/hooks';
import { updateFarmSupplies } from 'core/store/farms/thunks/updateFarmSupplies';

/**
 * Updater for tracking farm supplies, performing fetches on each new
 * block
 */
const FarmLiquiditiesUpdater = (): null => {
  const dispatch = useAppDispatch();
  const { currentProvider, user, network } = useAppSelector(
    (state) => state.auth
  );
  const farms = useAppSelector((state) => state.farms.list);
  const prevUser = usePrevious(user);
  const prevNetwork = usePrevious(network);

  const blockNow = useBlockNow();
  const prevBlock = usePrevious(blockNow);

  // TODO: Clear all contracts and tokens if the account/chain changes.
  useEffect(() => {
    // if (prevUser !== user || prevNetwork !== network) {
    //   dispatch(reset());
    // }
  }, [network, prevNetwork, prevUser, user]);

  useEffect(() => {
    if (!user?.address || !network || !currentProvider?.web3provider) return;

    if (blockNow !== prevBlock) {
      dispatch(
        updateFarmSupplies({
          farms,
          multicall: currentProvider.multicall,
        })
      );
    }
  }, [currentProvider, user, dispatch, blockNow, prevBlock, network, farms]);

  return null;
};

export default FarmLiquiditiesUpdater;
