import { FaChevronUp } from 'react-icons/fa';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import { FarmColumnLayout } from './CompensationTable.styles';

export const Wrapper = styled.div<{ isOpened?: boolean }>`
  display: grid;
  align-items: center;
  ${FarmColumnLayout}
  padding: 1rem 1.5rem;
  border-top: 1px solid #0000001a;
  background-color: ${(props) => (props.isOpened ? '#f8feff' : 'inherit')};
  ${down('xs')} {
    display: flex;
    flex-direction: column;
  }
`;

export const FarmTitle = styled.h2`
  a {
    margin-left: 5px;
    color: black;
  }
  margin: 0;
  font-size: 1rem;
  ${down('md')} {
    font-size: 0.8rem;
  }
  ${down('sm')} {
    white-space: nowrap;
  }
  ${down('xs')} {
    font-size: 1rem;
    margin: 0.5rem 0 1rem 0;
  }
`;
export const Value = styled.div`
  font-size: 0.95rem;
  ${down('md')} {
    font-size: 0.9rem;
  }
  ${down('sm')} {
    font-size: 0.8rem;
  }
  ${down('xs')} {
    font-size: 1rem;
    width: 100%;
    margin: 0.3rem 0;
    padding: 0.8rem 0;
    border-radius: 1rem;
    text-align: center;
    background-color: #00000008;
    button {
      margin: 0 auto;
    }
  }
`;

export const ValueTitle = styled.div`
  display: none;
  font-size: 0.8rem;
  padding-bottom: 0.4rem;
  ${down('xs')} {
    display: block;
  }
`;

export const StakedTokenValue = styled(Value)`
  ${down('xs')} {
    margin: 0;
    background-color: transparent;
    padding: 0;
  }
`;
export const StakedUsdValue = styled(Value)`
  font-weight: bold;
  color: #00000071;
  font-size: 0.7rem;
`;

export const Chevron = styled(FaChevronUp)<{ $isup: boolean }>`
  margin-left: auto;
  transform: ${(props) => (!props.$isup ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
`;

export const BodyContent = styled.div<{ show?: boolean }>`
  width: 100%;
  max-height: ${(props) => (props.show ? '700px' : '0')};
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
  background-color: ${(props) => (props.show ? '#f8feff' : 'inherit')};
`;

export const BodyWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0 0 1rem 0;
  ${down('sm')} {
    flex-direction: column;
  }
`;

export const Forms = styled.div`
  width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  grid-template-columns: 1fr 0.2fr;
  padding: 0 1rem 0 1rem;
  align-items: center;
`;

// ----------

export const UsdValue = styled.div`
  font-size: 0.7rem;
  font-style: italic;
  padding-right: 0.2rem;
`;

export const BalanceLabel = styled.div`
  font-size: 0.8rem;
  margin-left: 1.2rem;
`;

export const UnstakeBtn = styled.button`
  align-self: flex-end;
  height: 30px;
  width: 80%;
  padding: 0.2rem 1rem;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #77d37f 0%,
    #95dd9d 51%,
    #77d37f 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #77a0d349;
  border: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: #fff; text-decoration: none; }'
      : ''}
  grid-column: 5 / 6;

  ${down('sm')} {
    font-size: 0.8rem;
    box-shadow: none;
  }
`;

export const CompensateBtn = styled(UnstakeBtn)`
  background-image: linear-gradient(
    to right,
    #457fc5 0%,
    #14b4b2 51%,
    #457fc5 100%
  );
`;

export const ApproveBtn = styled(UnstakeBtn)`
  background-image: linear-gradient(
    to right,
    #2172e5 0%,
    #3884f0 51%,
    #2172e5 100%
  );
`;

export const CompensateMsg = styled.div`
  font-size: 0.75rem;
  width: 80%;
  text-align: center;
  background-color: #00000006;
  padding: 1rem;
  border-radius: 1rem;
  ${down('xs')} {
    background-color: transparent;
    width: 100%;
    padding: 0rem;
  }
`;

export const BalanceWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: flex-end;
  ${down('xs')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ExternalLink = styled(HiOutlineExternalLink)`
  margin-left: 3px;
`;

export const LoadingSkeletonWrapper = styled.div<{
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
}>`
  ${down('sm')} {
    display: ${(props) => (props.hideOnTablet ? 'none' : 'block')};
  }
  ${down('xs')} {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
  }
`;
