import { useAppSelector } from 'core/store/hooks';
import { CurrencyOption } from 'core/types';
import { useState, useEffect } from 'react';
import {
  BaseContractsLogos,
  DepositTokens as DepositTokensLogos,
} from 'core/config/contracts';
import { ciEquals } from 'utils';
import { BigNumber } from 'bignumber.js';

/// This hook will give an up to date list of the Stablecoins
export const useStablecoins = (
  withDepositTokens?: boolean
): CurrencyOption[] => {
  const [stablecoinsOptions, setStablecoinsOptions] = useState<
    CurrencyOption[]
  >([]);

  const stablecoins = useAppSelector((state) => state.contracts.stablecoins);
  const rhoTokens = useAppSelector((state) => state.contracts.rhoTokens);
  const vaults = useAppSelector((state) => state.contracts.vaults);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    // Building the list of stablecoins that can be shown to the user
    const res: CurrencyOption[] = [];
    const nonPresentTokens = [];

    for (const l of BaseContractsLogos) {
      const currentStablecoin = stablecoins.find((t) => t.key === l.key);
      if ((!user || stablecoins.length === 0) && l.showDisconnected) {
        nonPresentTokens.push(l);
        continue;
      }

      if (!currentStablecoin) continue;

      const vault = vaults.find((v) =>
        ciEquals(v.underlyingAddress, currentStablecoin.address)
      );

      if (vault) {
        const linkedToken = vault?.rhoTokenAddress;

        res.push({
          value: currentStablecoin.address,
          label: currentStablecoin.label,
          symbol: currentStablecoin.label,
          decimals: currentStablecoin.decimals,
          icon: l.logo,
          linkedToken,
          type: 'stableCoin',
          exchangeRate: new BigNumber(1),
        });

        if (withDepositTokens) {
          const rhoToken = rhoTokens.find((r) =>
            ciEquals(r.address, vault.rhoTokenAddress)
          );
          for (const token of vault.depositTokens) {
            if (
              !rhoToken?.decimals ||
              !token?.decimals ||
              !currentStablecoin?.decimals
            )
              continue;

            const logo = DepositTokensLogos.find(
              (t) => t.key === token?.key
            )?.logo;

            const ten = new BigNumber(10);

            res.push({
              value: token.address,
              label: token.label,
              symbol: token.label,
              decimals: token.decimals,
              icon: logo,
              linkedToken,
              exchangeRate: ten
                .pow(token.decimals)
                .times(token.exchangeRate)
                .times(ten.pow(rhoToken.decimals))
                .div(ten.pow(currentStablecoin.decimals))
                .div(ten.pow(new BigNumber(18))),
              type: 'depositToken',
            });
          }
        }
      }
    }

    // Some token that we still want to show but are not yet deployed (or not initialized)
    for (const l of nonPresentTokens) {
      res.push({
        value: l.key,
        label: l.key,
        symbol: l.key,
        icon: l.logo,
        linkedToken: l.linkedToken,
        disabled: true,
        exchangeRate: new BigNumber(1),
        type: 'stableCoin',
      });
    }

    setStablecoinsOptions(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stablecoins, vaults, withDepositTokens]);

  return stablecoinsOptions;
};
