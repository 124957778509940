import styled from 'styled-components';
import { FaChevronDown } from 'react-icons/fa';

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

export const Label = styled.div``;

export const Button = styled.button`
  border-radius: 10px;
  border: 1px solid #0000003b;
  font-size: 0.9rem;
  align-self: center;
  height: calc(100% - 0.8rem);
  background: white;
  cursor: pointer;
`;

export const TokenLogo = styled.img`
  width: 20px;
  height: 20px;
  padding-right: 5px;
`;

export const TokenLabel = styled.div``;

export const SelectedTokenLabel = styled(TokenLabel)`
  font-weight: bold;
  font-size: 1.1rem;
  padding-right: 5px;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const PopupBtnWrapper = styled.div`
  padding: 0.5rem 0;
`;

export const DropdownButton = styled.button`
  cursor: pointer;
  padding: 0 0.5rem;
  background-color: transparent;
  border: none;
  border-radius: 5px;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: #0000000a;
  }
`;

export const ButtonWrapper = styled.div`
  grid-column: 3 / 4;
  /* position: relative; */
`;

export const Chevron = styled(FaChevronDown)``;
