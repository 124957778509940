import { createAsyncThunk } from '@reduxjs/toolkit';
import { CurrencyOption, UserBalance } from 'core/types';
import { BN, getValueOfRef } from 'utils';
import { ContractCallContext, Multicall } from 'ethereum-multicall';

const ERC20_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
    ],
    name: 'allowance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        internalType: 'uint8',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'transferFrom',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const getTokensBalance = async (payload: {
  tokens: CurrencyOption[];
  userAddress: string;
  multicall: Multicall;
}): Promise<UserBalance[]> => {
  const tokenBalances: UserBalance[] = [];
  const { tokens, userAddress, multicall } = payload;

  // Building contract multicall
  const multicallContext: ContractCallContext[] = [];

  for (const token of tokens) {
    const tokenAddr = token.value;
    multicallContext.push({
      reference: tokenAddr,
      contractAddress: tokenAddr,
      abi: ERC20_ABI,
      calls: [
        {
          reference: 'tokenBalance',
          methodName: 'balanceOf',
          methodParameters: [userAddress],
        },
        {
          reference: 'tokenDecimals',
          methodName: 'decimals',
          methodParameters: [],
        },
      ],
    });
  }

  // Executing contract multicall
  const callResults = (await multicall.call(multicallContext)).results;

  for (const token of tokens) {
    const tokenAddr = token.value;
    const tokenRes = callResults[tokenAddr]?.callsReturnContext;
    if (tokenRes) {
      tokenBalances.push({
        currency: tokenAddr,
        amount: BN(
          getValueOfRef(tokenRes, 'tokenBalance'),
          getValueOfRef(tokenRes, 'tokenDecimals')
        ),
      });
    }
  }

  return tokenBalances;
};

export const updateOtherTokenBalances = createAsyncThunk<
  UserBalance[],
  {
    tokens: CurrencyOption[];
    userAddress: string;
    multicall: Multicall;
  }
>('balances/updateOtherTokenBalances', async (payload, thunkAPI) => {
  let tokenBalances: UserBalance[] = [];
  try {
    const { userAddress } = payload;

    if (!userAddress) {
      thunkAPI.rejectWithValue('No user address');
      throw new Error('No user address');
    }

    tokenBalances = await getTokensBalance(payload);
  } catch (e) {
    thunkAPI.rejectWithValue('Could not update Tokens user balance');
    console.error(e);
  } finally {
    return tokenBalances;
  }
});
