import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useAppDispatch, useAppSelector, useBlockNow } from 'core/store/hooks';
import { updateSupplies } from 'core/store/contracts/thunks/updateSupplies';

/**
 * Updater for tracking token supplies, performing fetches on each new
 * block
 */
const SuppliesUpdater = (): null => {
  const dispatch = useAppDispatch();
  const { currentProvider, user, network } = useAppSelector(
    (state) => state.auth
  );
  const contracts = useAppSelector((state) => state.contracts);
  const prevUser = usePrevious(user);
  const prevNetwork = usePrevious(network);

  const blockNow = useBlockNow();
  const prevBlock = usePrevious(blockNow);

  // TODO: Clear all contracts and tokens if the account/chain changes.
  useEffect(() => {
    // if (prevUser !== user || prevNetwork !== network) {
    //   dispatch(reset());
    // }
  }, [network, prevNetwork, prevUser, user]);

  useEffect(() => {
    if (!user?.address || !network || !currentProvider?.web3provider) return;

    if (blockNow !== prevBlock) {
      dispatch(updateSupplies({ contracts }));
    }
  }, [
    currentProvider,
    user,
    dispatch,
    blockNow,
    contracts,
    prevBlock,
    network,
  ]);

  return null;
};

export default SuppliesUpdater;
