import styled from 'styled-components';
import { down } from 'styled-breakpoints';
import React from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { watchAsset } from 'core/store/contracts/thunks/watchAsset';
import { Spacer } from 'components';
import metamaskLogo from 'assets/images/logos/walletLogos/metamask.svg';

const Button = styled.button`
  display: flex;
  align-items: center;
  color: black;
  padding: 0.5rem 0.7rem;
  cursor: pointer;
  background-color: #00000008;
  outline: none;
  border: none;
  border-radius: 15px;
  font-size: 0.65rem;
  ${down('xs')} {
    padding: 0.3rem 0.6rem;
    font-size: 0.6rem;
  }

  &:hover {
    background-color: #00000011;
  }

  img {
    max-width: 15px;
    height: auto;
    ${down('xs')} {
      max-width: 12px;
    }
  }
`;

type AddToWalletButtonProps = {
  buttonLabel: string;
  contractKey: string;
  className?: string;
};

const AddToWalletButton: React.FC<AddToWalletButtonProps> = ({
  buttonLabel,
  contractKey,
  className,
}) => {
  const dispatch = useAppDispatch();
  const provider = useAppSelector((state) => state.auth.currentProvider);

  if (!provider || provider.name !== 'MetaMask') {
    return <></>;
  }
  return (
    <Button
      title={buttonLabel}
      onClick={() => dispatch(watchAsset(contractKey))}
      className={className}
    >
      <img src={metamaskLogo} alt={buttonLabel} />
      <Spacer axis="horizontal" size={10} />
      {buttonLabel}
    </Button>
  );
};

export default AddToWalletButton;
