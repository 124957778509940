import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { getNetworkInfoFromChainId } from 'utils';

type NetworkFilterProps = {
  initialChains: number[];
  chains: number[];
  onChainsChanged: (chains: number[]) => void;
  isMultichoice?: boolean;
};

const Wrapper = styled.div``;
const ButtonsWrapper = styled.div`
  display: flex;
`;
const NetworkBtn = styled.button<{ isActive: boolean }>`
  outline: 0;
  border: 0;
  border-top: 1px solid #0000001d;
  border-bottom: 1px solid #0000001d;
  background: none;
  background-color: ${(props) => (props.isActive ? '#0000001c' : 'none')};
  font-weight: ${(props) => (props.isActive ? 'bold' : 'none')};
  padding: 0.3rem 0.5rem 0.2rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: #0000001c;
  }
  &:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border-left: 1px solid #0000001d;
  }
  &:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-right: 1px solid #0000001d;
  }
`;
const NetworkLogo = styled.img`
  width: 20px;
  height: auto;
`;

const NetworkFilter: React.FC<NetworkFilterProps> = ({
  initialChains,
  chains,
  onChainsChanged,
  isMultichoice = false,
}) => {
  const [currentChains, setCurrentChains] = useState(initialChains);

  useEffect(() => {
    if (!currentChains || (currentChains.length > 0 && !currentChains[0])) {
      setCurrentChains(initialChains);
    }
  }, [currentChains, initialChains]);

  const chainsOptions = useMemo(() => {
    return chains.map((c) => getNetworkInfoFromChainId(c));
  }, [chains]);

  const changeChain = useCallback(
    (chain: number) => {
      if (!isMultichoice) {
        onChainsChanged([chain]);
        setCurrentChains([chain]);
      } else {
        if (chains.indexOf(chain) !== -1 && chains.length > 1) {
          const newChains = chains.filter((e) => e !== chain);
          onChainsChanged(newChains);
          setCurrentChains(newChains);
        } else if (chains.indexOf(chain) === -1) {
          const newChains = [...chains, chain];
          onChainsChanged(newChains);
          setCurrentChains(newChains);
        }
      }
    },
    [chains, isMultichoice, onChainsChanged]
  );

  return (
    <Wrapper>
      <ButtonsWrapper>
        {chainsOptions.map((c) => (
          <NetworkBtn
            onClick={() => changeChain(c.chainId)}
            isActive={currentChains.indexOf(c.chainId) !== -1}
            key={`filterNetwork-${c.chainId}`}
          >
            <NetworkLogo alt={c.label} src={c.logo} />
          </NetworkBtn>
        ))}
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default NetworkFilter;
