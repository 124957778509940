import styled from 'styled-components/macro';
import { Image } from 'components';
import { down, up } from 'styled-breakpoints';

export const BridgeWrapper = styled.div`
  padding-top: 150px;
  ${down('xl')} {
    padding-top: 100px;
  }
  ${down('md')} {
    padding-top: 70px;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  ${up('xl')} {
    max-height: 350px;
  }
`;

export const BridgeCard = styled.div`
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  position: relative;
  margin: 0 auto;
  height: auto;
  width: 80%;
  column-gap: 15px;
  background: none;
  ${up('xl')} {
    width: 1100px;
  }
  ${up('xxl')} {
    width: 1200px;
  }
  ${down('md')} {
    display: block;
    margin: 0 auto;
    max-height: fit-content;
    height: fit-content;
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const ContentCard = styled.div`
  background-color: white;
  border-radius: 10px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${down('md')} {
    border-radius: 0px;
  }
`;

export const BridgeInfos = styled(ContentCard)``;
export const BridgeForm = styled(ContentCard)`
  display: flex;
  align-items: center;
`;
