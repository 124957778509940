import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { setTokensBalanceLoading } from 'core/store/balances/balances';
import { updateOtherTokenBalances } from 'core/store/balances/thunks/updateOtherTokenBalances';
import { CurrencyOption, InitContractPayload, NetworkInfo } from 'core/types';
import { ciEquals } from 'utils';

type FetchedToken = {
  address: string;
  chainId: number;
  decimals: number;
  logoURI?: string;
  symbol: string;
  name?: string;
};

const fetchTrustWalletTokenListData = async (
  network: NetworkInfo | undefined
): Promise<FetchedToken[]> => {
  const ethJsonUrl =
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/tokenlist.json';

  const bscJsonUrl =
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/tokenlist.json';
  const polygonJsonUrl =
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/polygon/tokenlist.json';

  let url =
    'https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/smartchain/tokenlist.json';

  if (network) {
    switch (network.chainId) {
      case 1400:
      case 1:
        url = ethJsonUrl;
        break;
      case 1401:
      case 56:
        url = bscJsonUrl;
        break;
      case 1402:
      case 137:
        url = polygonJsonUrl;
        break;
    }
  }

  try {
    const res = await axios.get(url);
    return res.data.tokens as FetchedToken[];
  } catch (e) {
    console.error(e);
    return [];
  }
};

const fetchGenericTokenListData = async (
  url: string,
  network: NetworkInfo | undefined
): Promise<FetchedToken[]> => {
  let chainId = 56;
  if (network) {
    switch (network.chainId) {
      case 1400:
      case 1:
        chainId = 1;
        break;
      case 1401:
      case 56:
        chainId = 56;
        break;
      case 1402:
      case 137:
        chainId = 137;
        break;
    }
  }
  try {
    const res = await axios.get(url);
    const filteredTokens = (res.data.tokens as FetchedToken[]).filter(
      (t) => t.chainId === chainId
    );

    return filteredTokens;
  } catch (e) {
    console.error(e);
    return [];
  }
};

const allTokensUrls = [
  'https://tokens.coingecko.com/uniswap/all.json',
  'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json',
  'https://umaproject.org/uma.tokenlist.json',
  'https://raw.githubusercontent.com/SetProtocol/uniswap-tokenlist/main/set.tokenlist.json',
  'https://app.tryroll.com/tokens.json',
  'https://www.gemini.com/uniswap/manifest.json',
  'https://unpkg.com/quickswap-default-token-list@1.0.67/build/quickswap-default.tokenlist.json',
  'https://tokens.pancakeswap.finance/pancakeswap-top-100.json',
  'https://tokens.pancakeswap.finance/pancakeswap-extended.json',
  'https://raw.githubusercontent.com/pangolindex/tokenlists/main/ab.tokenlist.json',
  'https://raw.githubusercontent.com/SpookySwap/spooky-info/master/src/constants/token/spookyswap.json',
  'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json',
];

export const initOtherTokens = createAsyncThunk<
  CurrencyOption[],
  {
    network: NetworkInfo;
    filterOutCurrencies?: CurrencyOption[];
  } & InitContractPayload
>('contracts/initOtherTokens', async (payload, thunkAPI) => {
  const options: CurrencyOption[] = [];

  try {
    thunkAPI.dispatch(setTokensBalanceLoading());

    const { network, filterOutCurrencies, multicall, userAddress } = payload;

    if (!network.disableKyberswap) {
      const fetchCalls = [];
      for (const url of allTokensUrls) {
        fetchCalls.push(fetchGenericTokenListData(url, network));
      }

      const tokens = await Promise.all([
        ...fetchCalls,
        fetchTrustWalletTokenListData(network),
      ]);

      for (const token of tokens.flat()) {
        if (
          filterOutCurrencies &&
          filterOutCurrencies.findIndex((c) =>
            ciEquals(c.value, token.address)
          ) !== -1
        ) {
          continue;
        }

        if (options.findIndex((o) => ciEquals(o.value, token.address)) !== -1)
          continue;

        options.push({
          value: token.address,
          label: token.name ?? token.symbol,
          icon: token.logoURI,
          decimals: token.decimals,
          symbol: token.symbol,
          type: 'others',
        } as CurrencyOption);
      }
    }

    thunkAPI.dispatch(
      updateOtherTokenBalances({
        tokens: options,
        userAddress,
        multicall,
      })
    );
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Other tokens');
    console.error(e);
  }
  return options;
});
