import styled from 'styled-components/macro';
import { Image, Card } from 'components';
import { down, up } from 'styled-breakpoints';

export const EarnWrapper = styled.div`
  padding-top: 150px;
  ${down('xl')} {
    padding-top: 100px;
  }
  ${down('md')} {
    padding-top: 70px;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  ${up('xl')} {
    max-height: 350px;
  }
`;

export const EarnCard = styled(Card)`
  display: grid;
  width: 80%;
  ${up('xl')} {
    width: 1100px;
    grid-template-columns: 770px 330px;
  }
  ${up('xxl')} {
    grid-template-columns: 840px 360px;
    width: 1200px;
  }
  ${down('md')} {
    margin: 0 auto;
    grid-template-columns: none;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const MainInterfaceWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 0.1fr min-content;
  padding: 2rem;
  ${down('xs')} {
    padding: 2rem 1rem;
  }
`;

export const NotificationWrapper = styled.div`
  max-height: inherit;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) -5px 0px 15px;
  background-color: white;
  ${down('md')} {
    border-top: 1px solid #00000014;
    box-shadow: none;
    border-bottom-left-radius: 10px;
    border-top-right-radius: 0px;
    max-height: 200px;
  }
`;

export const Separator = styled.span`
  grid-column: 1 / 3;
  grid-row: 2 / 3;
  background-color: #00000028;
  height: 1px;
  margin: 1rem 0;
`;
