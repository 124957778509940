import { configureStore } from '@reduxjs/toolkit';
import { combineReducers, createStore } from 'redux';
import thunk, { ThunkAction } from 'redux-thunk';
import authReducer from './auth/authReducer';
import blocksReducer from './blocks/blocks';
import contractsReducer from './contracts/contractsReducer';
import uiReducer from './ui/ui';
import farmsReducer from './farms/farms';
import balancesReducer from './balances/balancesReducer';
import subgraphReducer from './subgraph';
import transactionsReducer from './transactions/transactionsReducer';
import contentReducer from './content';
import dynamicMiddlewares from 'redux-dynamic-middlewares';
import flurryWebsocketMiddleware from './middleware/flurryWebsocketMiddleware';
import gateioWebsocketMiddleware from './middleware/gateioWebsocketMiddleware';

declare module 'redux' {
  interface Dispatch<A extends Action = AnyAction> {
    <S, E, R>(asyncAction: ThunkAction<R, S, E, A>): R;
  }
}

const rootReducer = combineReducers({
  auth: authReducer,
  blocks: blocksReducer,
  contracts: contractsReducer,
  farms: farmsReducer,
  transactions: transactionsReducer,
  ui: uiReducer,
  content: contentReducer,
  balances: balancesReducer,
  subgraph: subgraphReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  middleware: [
    thunk,
    dynamicMiddlewares,
    flurryWebsocketMiddleware,
    gateioWebsocketMiddleware,
  ] as const,
});

export type AppStore = ReturnType<typeof createStore>;
export type AppState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];
