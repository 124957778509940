import Spacer from '../Spacer/Spacer';
import React from 'react';
import * as S from './Amount.styles';
import { formatBNToString } from 'utils';
import BigNumber from 'bignumber.js';
import { useAppDispatch } from 'core/store/hooks';
import { watchAsset } from 'core/store/contracts/thunks/watchAsset';

export interface AmountProps {
  value?: BigNumber;
  currency?: string;
  currencyKey?: string;
  decimalsToShow?: number;
  nbOfDecimalsToGreyOut?: number;
  className?: string;
}

const formatAmount = (
  c: BigNumber,
  decimalsToShow: number,
  nbOfDecimalsToGreyOut: number
) => {
  const fixedCount = formatBNToString(c, decimalsToShow, true, ',');

  if (decimalsToShow === 0 || nbOfDecimalsToGreyOut >= decimalsToShow) {
    return (
      <S.AmountWrapper>
        <S.AmountMainValue>{fixedCount}</S.AmountMainValue>
      </S.AmountWrapper>
    );
  }

  const boldStr = fixedCount.slice(0, -nbOfDecimalsToGreyOut);
  const lastChars = fixedCount.substring(
    fixedCount.length - nbOfDecimalsToGreyOut,
    fixedCount.length
  );

  return (
    <S.AmountWrapper>
      <S.AmountMainValue>{boldStr}</S.AmountMainValue>
      <S.AmountGreyedDecimals>{lastChars}</S.AmountGreyedDecimals>
    </S.AmountWrapper>
  );
};

const placeholderAmount = (
  <S.AmountWrapper>
    <S.PlaceholderAmountMainValue>--.--</S.PlaceholderAmountMainValue>
    <S.PlaceholderAmountGreyedDecimals>---</S.PlaceholderAmountGreyedDecimals>
  </S.AmountWrapper>
);

// React component to show an amount
const Amount: React.FC<AmountProps> = ({
  value,
  currency = '',
  currencyKey,
  decimalsToShow = 5,
  nbOfDecimalsToGreyOut = 3,
  className,
}) => {
  const dispatch = useAppDispatch();

  if (currency) {
    return (
      <S.AmountWrapper className={className}>
        {value !== undefined && value !== null
          ? formatAmount(value, decimalsToShow, nbOfDecimalsToGreyOut)
          : placeholderAmount}
        <Spacer axis="horizontal" size={5} />
        <S.Currency
          onClick={() => {
            if (currencyKey) {
              dispatch(watchAsset(currencyKey));
            }
          }}
          isClickable={currencyKey !== undefined && currencyKey !== null}
        >
          {currency}
        </S.Currency>
      </S.AmountWrapper>
    );
  }

  return value !== undefined && value !== null
    ? formatAmount(value, decimalsToShow, nbOfDecimalsToGreyOut)
    : placeholderAmount;
};

export default Amount;
