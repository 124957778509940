import React from 'react';
import * as S from './SnowballBanner.styles';
import SnowballIllustration from 'assets/images/flurry_snowball_illustration.png';
import { Spacer } from 'components';

const StakeForm: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Illustration src={SnowballIllustration} />
      <Spacer axis="horizontal" size={40} />
      <S.Text>
        <S.Title>
          Get started with Snowball by staking a custom amount of your Flurry
          tokens
        </S.Title>
        <Spacer axis="vertical" size={10} />
        <S.SubTitle>
          The more you put in Flurry tokens, the more you get, just like
          snowflakes to a snowball!
        </S.SubTitle>
      </S.Text>
    </S.Wrapper>
  );
};
export default StakeForm;
