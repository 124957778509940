import usdtLogo from 'assets/images/logos/usdt_logo.png';
import usdcLogo from 'assets/images/logos/usdc_logo.png';
import busdLogo from 'assets/images/logos/busd_logo.png';
import rhoUsdtLogo from 'assets/images/logos/rhoUsdt_logo.png';
import rhoUsdcLogo from 'assets/images/logos/rhoUsdc_logo.png';
import rhoBusdLogo from 'assets/images/logos/rhoBusd_logo.png';

import vUsdtLogo from 'assets/images/logos/depositTokens/vusdt_logo.png';
import vUsdcLogo from 'assets/images/logos/depositTokens/vusdc_logo.png';
import vBusdLogo from 'assets/images/logos/depositTokens/vbusd_logo.png';

import alpacaLogo from 'assets/images/logos/depositTokens/alpaca_token_logo.png';
import creamLogo from 'assets/images/logos/depositTokens/cream_token_logo.png';
import rabbitLogo from 'assets/images/logos/depositTokens/rabbit_token_logo.png';
import aaveLogo from 'assets/images/logos/depositTokens/aave_token_logo.webp';
import sushiLogo from 'assets/images/logos/depositTokens/sushi_token_logo.webp';
import benqiLogo from 'assets/images/logos/depositTokens/benqi_logo.png';

export const TokenContractsLogos = [
  {
    key: 'rhoUSDT',
    label: 'rhoUSDT',
    logo: rhoUsdtLogo,
    showDisconnected: true,
  },
  {
    key: 'rhoUSDt',
    label: 'rhoUSDt',
    logo: rhoUsdtLogo,
  },
  {
    key: 'rhoUSDT.e',
    label: 'rhoUSDT.e',
    logo: rhoUsdtLogo,
  },
  {
    key: 'rhoUSDC',
    label: 'rhoUSDC',
    logo: rhoUsdcLogo,
    showDisconnected: true,
  },
  {
    key: 'rhoUSDC.e',
    label: 'rhoUSDC.e',
    logo: rhoUsdcLogo,
  },
  {
    key: 'rhoBUSD',
    label: 'rhoBUSD',
    logo: rhoBusdLogo,
    showDisconnected: true,
  },
];

export const BaseContractsLogos = [
  {
    key: 'USDT',
    label: 'USDT',
    logo: usdtLogo,
    linkedToken: 'rhoUSDT',
    showDisconnected: true,
  },
  {
    key: 'USDt',
    label: 'USDt',
    logo: usdtLogo,
    linkedToken: 'rhoUSDt',
  },
  {
    key: 'USDT.e',
    label: 'USDT.e',
    logo: usdtLogo,
    linkedToken: 'rhoUSDt',
  },
  {
    key: 'USDC',
    label: 'USDC',
    logo: usdcLogo,
    linkedToken: 'rhoUSDC',
    showDisconnected: true,
  },
  {
    key: 'USDC.e',
    label: 'USDC.e',
    logo: usdcLogo,
    linkedToken: 'rhoUSDC.e',
  },
  {
    key: 'BUSD',
    label: 'BUSD',
    logo: busdLogo,
    linkedToken: 'rhoBUSD',
    showDisconnected: true,
  },
];

export const DepositTokens = [
  {
    key: 'vUSDT',
    label: 'Venus USDT Deposit Token',
    logo: vUsdtLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'aibUSDT',
    label: 'Alpaca USDT Deposit Token',
    logo: alpacaLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'crUSDT',
    label: 'Cream USDT Deposit Token',
    logo: creamLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'ibUSDT',
    label: 'Rabbit USDT Deposit Token',
    logo: rabbitLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'vUSDC',
    label: 'Venus USDC Deposit Token',
    logo: vUsdcLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'aibUSDC',
    label: 'Alpaca USDC Deposit Token',
    logo: alpacaLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'crUSDC',
    label: 'Cream USDC Deposit Token',
    logo: creamLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'vBUSD',
    label: 'Venus BUSD Deposit Token',
    logo: vBusdLogo,
    linkedToken: 'rhoBUSD',
  },
  {
    key: 'aibBUSD',
    label: 'Alpaca BUSD Deposit Token',
    logo: alpacaLogo,
    linkedToken: 'rhoBUSD',
  },
  {
    key: 'crBUSD',
    label: 'Cream BUSD Deposit Token',
    logo: creamLogo,
    linkedToken: 'rhoBUSD',
  },
  {
    key: 'ibBUSD',
    label: 'Rabbit BUSD Deposit Token',
    logo: rabbitLogo,
    linkedToken: 'rhoBUSD',
  },
  {
    key: 'aUSDT_v2',
    label: 'AaveV2Polygon USDT Deposit Token',
    logo: aaveLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'aUSDC_v2',
    label: 'AaveV2Polygon USDC Deposit Token',
    logo: aaveLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'kmWETH/USDT-LINK',
    label: 'Sushi Kashi Pair - kmWETH/USDT-LINK',
    logo: sushiLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'kmWMATIC/USDT-LINK',
    label: 'Sushi Kashi Pair - kmWMATIC/USDT-LINK',
    logo: sushiLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'kmUSDC/USDT-LINK',
    label: 'Sushi Kashi Pair - kmUSDC/USDT-LINK',
    logo: sushiLogo,
    linkedToken: 'rhoUSDT',
  },
  {
    key: 'kmWMATIC/USDC-LINK',
    label: 'Sushi Kashi Pair - kmWMATIC/USDC-LINK',
    logo: sushiLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'kmWETH/USDC-LINK',
    label: 'Sushi Kashi Pair - kmWETH/USDC-LINK',
    logo: sushiLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'kmWETH/USDC-LINK',
    label: 'Sushi Kashi Pair - kmWETH/USDC-LINK',
    logo: sushiLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'qiUSDCn',
    label: 'Benqi USDC Deposit Token',
    logo: benqiLogo,
    linkedToken: 'rhoUSDC',
  },
  {
    key: 'qiUSDTn',
    label: 'Benqi USDT Deposit Token',
    logo: benqiLogo,
    linkedToken: 'rhoUSDT',
  },
];
