import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { FaInfoCircle } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
export const Wrapper = styled.div`
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  font-size: 1rem;
  text-align: left;
  /* width: 450px; */
  padding: 20px;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  background-color: white;
  border-radius: 15px;
  width: 420px;
  max-height: 85vh;
  ${down('sm')} {
    width: calc(100% - 2rem);
    margin: 1rem 0 0.2rem 0;
  }
  ${up('xl')} {
    width: 450px;
  }
`;

export const CloseBtn = styled(IoMdClose)`
  margin: 0.5rem auto;
  opacity: 0.3;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const Header = styled.h4`
  font-weight: 700;
  padding: 0 1rem 0 1rem;
`;

export const SubHeader = styled.h6`
  display: flex;
  font-weight: 700;
  margin: 0;
  padding: 0.3rem 1rem 0 1rem;
`;

export const SlippageOptions = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem 0.5rem 1rem;
`;
export const SlippageOption = styled.button<{ active?: boolean }>`
  cursor: pointer;
  border: 1px solid #00000000;
  border-radius: 15px;
  padding: 0.4rem 0.7rem;
  font-size: 0.85rem;
  background-color: ${(props) => (props.active ? '#96d6f0' : '#0000000e')};
  ${down('sm')} {
    padding: 0.4rem 0.3rem;
  }

  &:hover {
    border: 1px solid #0000002f;
  }
`;

export const CustomSlippageOption = styled.div`
  display: flex;
  padding: 0.4rem 0.7rem;
`;

export const SlippageInput = styled.input`
  background: none;
  outline: none;
  border: none;
`;

export const InfoIcon = styled(FaInfoCircle)`
  padding-left: 0.2rem;
  font-size: 0.65rem;
  align-self: center;
`;

export const ErrorMsg = styled.p`
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  padding: 0 1rem;
  color: #c2910c;
`;

export const InlineOption = styled.div`
  padding-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const OnOffWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: #0000000a;
  border-radius: 15px;
  padding: 0.1rem;
`;
export const SwitchBtn = styled.button<{ selected: boolean }>`
  cursor: pointer;
  border: none;
  padding: 0.2rem 0.5rem;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
  background-color: ${(props) => (props.selected ? '#96d6f0' : 'transparent')};
  border-radius: 15px;
  transition: all 0.2s linear;
  &:hover {
    color: #00000090;
  }
`;
