import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import React, { useRef, useState, useMemo } from 'react';
import * as S from './NetworkSwitcherDropdown.styles';
import { getPreferredNetworks, sendGAEvent } from 'utils';
import { switchChain } from 'core/store/auth/thunks/switchChain';
import FlurryIconOnly from 'assets/images/logos/flurry_icon_only.png';
import { Spacer, useOnClickOutside } from 'components';

const NetworkSwitcherDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const [opened, setOpened] = useState(false);

  const network = useAppSelector((state) => state.auth.network);
  const preferredNetworks = getPreferredNetworks();

  const networkList = useMemo(() => {
    return preferredNetworks.filter((n) => n.chainId !== network?.chainId);
  }, [network, preferredNetworks]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpened(false));

  if (!network || !preferredNetworks || preferredNetworks.length <= 0) {
    return <></>;
  }

  return (
    <S.NetworkDropdownWrapper ref={ref}>
      <S.SelectedOption opened={opened} onClick={() => setOpened(!opened)}>
        <S.NetworkImg
          src={network?.logo ?? FlurryIconOnly}
          alt={`${network?.label ?? 'Network'} logo`}
        />
        <Spacer axis="horizontal" size={5} />
        <S.NetworkLabel>{network?.label ?? 'Select Network...'}</S.NetworkLabel>
        <S.Chevron $isup={opened} />
      </S.SelectedOption>
      {opened && (
        <S.Options>
          <S.Label>Select Network</S.Label>
          {networkList.length > 0 ? (
            networkList.map((o) => {
              if (o.chainId !== network?.chainId) {
                return (
                  <S.BodyOption
                    key={o.chainId.toString()}
                    onClick={() => {
                      sendGAEvent('User', 'Switch Network', o.label);
                      dispatch(switchChain(o));
                    }}
                  >
                    <S.NetworkImg
                      src={o.logo ?? FlurryIconOnly}
                      alt={`${o.label} logo`}
                    />
                    <Spacer axis="horizontal" size={5} />
                    {o.label}
                  </S.BodyOption>
                );
              }
            })
          ) : (
            <S.NoNetwork>No network available</S.NoNetwork>
          )}
        </S.Options>
      )}
    </S.NetworkDropdownWrapper>
  );
};

export default NetworkSwitcherDropdown;
