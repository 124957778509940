import { NetworksInfos } from 'core/config/networks';
import { NetworkInfo } from 'core/types';
import { getArrayNumberFromStr } from './strings';

export const findNetworkInfoFromChain = (
  chainId: number
): NetworkInfo | undefined => {
  return NetworksInfos.find((n) => n.chainId === chainId);
};

export const findNetworksFromChainList = (
  chains: number[] | undefined
): NetworkInfo[] => {
  const res: NetworkInfo[] = [];

  if (!chains) {
    return res;
  }

  for (const c of chains) {
    const net = findNetworkInfoFromChain(c);
    if (net) {
      res.push(net);
    }
  }
  return res;
};

export const getAvailableNetworks = (): NetworkInfo[] | undefined => {
  const environment = window._env_.ENVIRONMENT;

  if (environment === 'mainnet') {
    const mainnetChains = getArrayNumberFromStr(window._env_.MAINNET_CHAINS);
    return findNetworksFromChainList(mainnetChains);
  }
  if (environment === 'testnet') {
    const testnetChains = getArrayNumberFromStr(window._env_.TESTNET_CHAINS);
    return findNetworksFromChainList(testnetChains);
  }
  return undefined;
};

export const getPreferredNetworks = (): NetworkInfo[] => {
  return findNetworksFromChainList(
    getArrayNumberFromStr(window._env_.PREFERRED_CHAINS)
  );
};

export const getBridgeableNetworks = (): NetworkInfo[] => {
  if (!window._env_.BRIDGEABLE_CHAINS) return [];

  return findNetworksFromChainList(
    getArrayNumberFromStr(window._env_.BRIDGEABLE_CHAINS)
  );
};
