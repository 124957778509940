import styled from 'styled-components/macro';
import { HiOutlineSwitchVertical } from 'react-icons/hi';
import { down } from 'styled-breakpoints';
import { LoadingCircle } from 'components';

export const Wrapper = styled.div`
  width: 100%;
  padding: 2rem 2rem;
  ${down('md')} {
    padding: 2rem 1rem;
  }
`;

export const TitleMobile = styled.div`
  display: none;
  ${down('md')} {
    display: block;
  }
`;
export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
`;
export const SubTitle = styled.h4`
  font-size: 0.7rem;
  color: #5e5e5e;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 1rem;
`;

export const FormWrapper = styled.div``;

export const FromInputs = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 1fr;
  ${down('md')} {
    display: block;
  }
`;

export const Label = styled.div`
  font-size: 0.7rem;
  padding: 0.5rem 0 0 0.3rem;
`;
export const Labels = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding-bottom: 0.5rem;
`;

export const SwitchButtonWrapper = styled.div`
  margin: 1rem auto 0.5rem auto;
  cursor: pointer;
  background-color: #2e3b6624;
  border-radius: 50px;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: #2e3b6636;
  }
`;
export const SwitchButton = styled(HiOutlineSwitchVertical)`
  color: black;
  font-size: 0.85rem;
`;

export const AddressBtn = styled.button`
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
  font-size: 0.65rem;
`;

export const AddressWrapper = styled.div<{ show: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: relative;
  width: 100%;
  border-radius: 3px;
  padding: 0rem 0rem;
  border: 1px solid #0000003b;
  height: 45px;
  &:hover {
    border-color: #a7dcff;
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;
  width: calc(100% - 1.5rem);
  background-color: transparent;
  margin: 0;
  padding: 0.85rem 0.8rem 0.85rem 0.8rem;
  font-size: 0.8rem;
  &:focus {
    outline: none;
  }
  ${down('xs')} {
    width: 100%;
    margin-left: 0.2rem;
    padding: 0.85rem 0.8rem 0.85rem 0.3rem;
  }
`;

export const ErrorMsg = styled.p`
  font-size: 0.65rem;
  text-align: center;
  margin: 0;
  padding: 0.2rem 0 0.4rem 0;
  color: red;
`;

export const SwapButton = styled.button`
  width: calc(100%);
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #457fc5 0%,
    #14b4b2 51%,
    #457fc5 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #77a0d349;
  border: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: #fff; text-decoration: none; }'
      : ''}
`;

export const ApproveButton = styled(SwapButton)`
  background-image: linear-gradient(
    to right,
    #2172e5 0%,
    #3884f0 51%,
    #2172e5 100%
  );
`;

export const SwitchNetworkButton = styled(SwapButton)`
  background-image: linear-gradient(
    to right,
    #77d37f 0%,
    #95dd9d 51%,
    #77d37f 100%
  );
`;

export const ApproveLoadingCircle = styled(LoadingCircle)`
  align-self: center;
  margin: 0 auto;
`;

export const ConnectButton = styled.button`
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  background: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;

  opacity: ${(props) => (!props.disabled ? '1' : '0.5')};
`;

export const LoadingWrapper = styled.div`
  width: 100%;
  height: 50px;
`;
