import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildFlurryTokenContract } from 'utils/config';
import { InitContractPayload, Contract } from 'core/types';

export const initFlurryToken = createAsyncThunk<
  Contract | null,
  InitContractPayload
>('contracts/initFlurryToken', async (payload, thunkAPI) => {
  let flurryToken: Contract | null = null;
  try {
    const { provider, contractsConfig } = payload;

    flurryToken = await buildFlurryTokenContract(contractsConfig, provider);
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Flurry Token contract');
    console.error(e);
  } finally {
    return flurryToken;
  }
});
