/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  buildStrategiesContracts,
  buildVaultContracts,
  buildDepositTokensContracts,
  buildUnwinderContract,
} from 'utils/config';
// import { initFarms } from 'core/store/farms/thunks/initFarms';
import { InitContractPayload, VaultContract } from 'core/types';
import { BN } from 'utils';

export const initVaults = createAsyncThunk<
  VaultContract[],
  InitContractPayload
>('contracts/initVaults', async (payload, thunkAPI) => {
  const vaults: VaultContract[] = [];
  try {
    const { provider, contractsConfig } = payload;

    const builtContracts = await buildVaultContracts(contractsConfig, provider);

    for (const c of builtContracts) {
      const currentVault: VaultContract = {
        ...c,
        underlyingAddress: await c.contract.underlying(),
        rhoTokenAddress: await c.contract.rhoToken(),
        depositTokens: [],
        strategies: [],
      };

      // Building strategies contracts
      const currentStratList = await c.contract.getStrategiesList();
      const stratAddressesList: string[] = [];
      for (const s of currentStratList) {
        stratAddressesList.push(s[1]);
      }

      try {
        currentVault.strategies = await buildStrategiesContracts(
          contractsConfig,
          provider,
          stratAddressesList
        );
      } catch (e) {
        console.error(e);
      }

      // Building deposit token contracts
      try {
        if (c.contract.getDepositTokens) {
          const depositTokenAddressesList = await c.contract.getDepositTokens();
          const tokensContracts = await buildDepositTokensContracts(
            contractsConfig,
            provider,
            depositTokenAddressesList
          );

          for (const token of tokensContracts) {
            const depositUnwinderAddress = await c.contract.getDepositUnwinder(
              token.address
            );
            if (depositUnwinderAddress.length > 0) {
              const depositUnwinder = await buildUnwinderContract(
                contractsConfig,
                provider,
                depositUnwinderAddress[1]
              );

              if (depositUnwinder) {
                const exchangeRate =
                  await depositUnwinder.contract.exchangeRate(token.address);
                currentVault.depositTokens.push({
                  ...token,
                  unwinder: depositUnwinder,
                  exchangeRate: BN(exchangeRate),
                });
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
      }

      vaults.push(currentVault);
    }
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Vaults contracts');
    console.error(e);
  } finally {
    return vaults;
  }
});
