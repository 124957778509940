import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  buildFlurryTokenContract,
  buildLpStakingContract,
  buildPriceOraclesContracts,
} from 'utils/config';
import {
  InitContractPayload,
  Contract,
  PriceOracle,
  NetworkInfo,
} from 'core/types';
import { initFarms } from 'core/store/farms/thunks/initFarms';

type InitResult = {
  lpStaking: Contract | null;
  priceOracles: PriceOracle[];
};

type InitLpPayload = {
  network: NetworkInfo | undefined;
} & InitContractPayload;

export const initLp = createAsyncThunk<InitResult, InitLpPayload>(
  'contracts/initLp',
  async (payload, thunkAPI) => {
    const result: InitResult = {
      lpStaking: null,
      priceOracles: [],
    };
    try {
      const { provider, contractsConfig, network, multicall } = payload;

      result.priceOracles = await buildPriceOraclesContracts(
        contractsConfig,
        provider,
        multicall
      );

      result.lpStaking = await buildLpStakingContract(
        contractsConfig,
        provider
      );

      // TODO remove
      const flurryToken = await buildFlurryTokenContract(
        contractsConfig,
        provider
      );

      thunkAPI.dispatch(
        initFarms({
          contractsConfig,
          provider,
          lpStaking: result.lpStaking,
          priceOracles: result.priceOracles,
          flurryToken,
          network,
          multicall,
        })
      );
    } catch (e) {
      thunkAPI.rejectWithValue('Could not setup LP Staking contract');
      console.error(e);
    } finally {
      return result;
    }
  }
);
