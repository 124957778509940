import React, { useCallback, useMemo, useState } from 'react';
import {
  Spacer,
  PriceInput,
  ShortenBalance,
  IntegratedPriceInput,
  ConvertAdvancedSettingsPopup,
  TokenSwapRoute,
} from 'components';
import * as S from './EarnForm.styles';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import Skeleton from 'react-loading-skeleton';
import { convert } from 'core/store/contracts/thunks/convert';
import { approveConvert } from 'core/store/contracts/thunks/approveConvert';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { ciEquals, noExponents, roundingRateNumber, sendGAEvent } from 'utils';
import { useRhoTokens, useStablecoins, useTokenSwap } from 'core/hooks';
import { CurrencyOption, User } from 'core/types';
import { updateUserConvertApproval } from 'core/store/auth/thunks/updateUserConvertApproval';
import KyberswapLogo from 'assets/images/logos/kyberswap_logo.svg';
import { displayConvertTradeRoute } from 'core/store/ui/ui';
import { useConvertDisplayTradeRoute } from 'core/store/ui/hooks';

type SubmitButtonProps = {
  onFormSubmit: () => void;
  hasValidAmount: boolean;
  needApproval: boolean | undefined;
  stablecoinLabel: string | undefined;
  user: User | undefined;
  hasEnoughBalance: boolean;
};

const DECIMAL_PRECISION = 2;

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onFormSubmit,
  hasValidAmount,
  needApproval,
  stablecoinLabel,
  user,
  hasEnoughBalance,
}) => {
  const dispatch = useAppDispatch();

  if (user) {
    if (!hasValidAmount || !stablecoinLabel) {
      return (
        <S.SubmitButton type="button" disabled>
          Enter an amount
        </S.SubmitButton>
      );
    } else if (!hasEnoughBalance) {
      return (
        <S.SubmitButton type="button" disabled>
          Insufficient {stablecoinLabel} balance
        </S.SubmitButton>
      );
    }

    return needApproval || needApproval === undefined ? (
      <S.ApproveButton
        disabled={needApproval === undefined}
        type="button"
        onClick={() => onFormSubmit()}
      >
        {needApproval === undefined ? (
          <S.ApproveLoadingCircle size={11} color="white" />
        ) : (
          `Approve ${stablecoinLabel}`
        )}
      </S.ApproveButton>
    ) : (
      <S.SubmitButton type="button" onClick={() => onFormSubmit()}>
        Convert Now
      </S.SubmitButton>
    );
  }

  return (
    <S.ConnectButton
      onClick={() => {
        sendGAEvent('Earn', 'Connect');
        dispatch(connectToProvider());
      }}
    >
      Connect Wallet
    </S.ConnectButton>
  );
};

const ConvertForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const fromTokensLoading = useAppSelector(
    (state) => state.balances.loadingState.stablecoinBalance
  );
  const otherTokensLoading = useAppSelector(
    (state) => state.balances.loadingState.tokenBalance
  );
  const rhotokensLoading = useAppSelector(
    (state) => state.balances.loadingState.rhoTokenBalance
  );

  const network = useAppSelector((state) => state.auth.network);
  const user = useAppSelector((state) => state.auth.user);
  const balances = useAppSelector((state) => state.balances.balances);
  const displayTradeRoute = useConvertDisplayTradeRoute();

  const rhoTokens = useRhoTokens();
  const stablecoins = useStablecoins(true);
  const vaults = useAppSelector((state) => state.contracts.vaults);
  const otherTokens = useAppSelector((state) => state.contracts.otherTokens);

  const fromTokens = useMemo(() => {
    const filteredOtherTokens = otherTokens.filter(
      (t) => stablecoins.findIndex((c) => ciEquals(t.value, c.value)) === -1
    );
    return [...stablecoins, ...filteredOtherTokens];
  }, [otherTokens, stablecoins]);

  // Form state
  const [fromTokenCurrency, setFromTokenCurrency] = useState(fromTokens[0]);
  const [rhoTokenCurrency, setRhoTokenCurrency] = useState(rhoTokens[0]);

  const [amountState, setAmountState] = useState({
    fromTokenAmount: '',
  });
  const [hasValidAmount, setHasValidAmount] = useState(false);
  const [formError, setFormError] = useState<string | undefined>(undefined);
  const [invertExchangeRate, setInvertExchangeRate] = useState(false);
  const [maxSlippage, setMaxSlippage] = useState(0.005);
  const [showAdvanceSettings, setShowAdvanceSettings] = useState(false);

  // Hook to get converted value from Token to Rho Token
  const {
    isLoading: isTokenSwapLoading,
    error,
    data: tokenSwapData,
  } = useTokenSwap({
    fromToken: fromTokenCurrency,
    toToken: rhoTokenCurrency,
    amountIn: amountState.fromTokenAmount,
    updateIntervalMs: 10000,
  });

  const fromTokenBalance = useMemo(() => {
    if (user && fromTokenCurrency) {
      const balance = user.balances
        .concat(balances)
        .find((b) => ciEquals(b.currency, fromTokenCurrency.value));
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [balances, fromTokenCurrency, user]);

  const isLoadingData = useMemo(() => {
    return (
      fromTokensLoading ||
      rhotokensLoading ||
      otherTokensLoading ||
      (vaults.length === 0 && user) ||
      !fromTokenBalance
    );
  }, [
    fromTokensLoading,
    rhotokensLoading,
    otherTokensLoading,
    vaults.length,
    user,
    fromTokenBalance,
  ]);

  // Tell if amount submitted is valid, if not will set error and return false
  const isAmountValid = useCallback(
    (amt: BigNumber): boolean => {
      if (amt.gt(fromTokenBalance ?? new BigNumber(-1))) {
        setFormError(
          `You do not have enough ${fromTokenCurrency.label} to convert that much.`
        );
        return false;
      } else if (amt.isZero() || amt.isNegative()) {
        setFormError(
          `${fromTokenCurrency.label} amount should be greater than zero.`
        );
        return false;
      }
      return true;
    },
    [fromTokenBalance, fromTokenCurrency]
  );

  // Check if the user has enough allowance for the amount entered
  const needApproval = useMemo((): boolean | undefined => {
    if (!user) {
      return undefined;
    }

    try {
      const approval = user.approvals.find((e) =>
        ciEquals(e.contractAddress, fromTokenCurrency.value)
      );
      if (!approval) {
        return undefined;
      }

      return !approval.isApproved;
    } catch (_) {
      return undefined;
    }
  }, [fromTokenCurrency, user]);

  // Function ran when the form is submitted
  const onSubmit = useCallback(() => {
    if (!user) return;

    const stablecoinAmt = new BigNumber(amountState.fromTokenAmount);
    if (isAmountValid(stablecoinAmt)) {
      setFormError(undefined);
      if (needApproval) {
        sendGAEvent('Earn', 'Approve', rhoTokenCurrency.label);
        dispatch(
          approveConvert({
            token: fromTokenCurrency.value,
            tokenAmount: stablecoinAmt,
            rhoToken: rhoTokenCurrency.value,
          })
        );
      } else {
        sendGAEvent('Earn', 'Convert', rhoTokenCurrency.label);
        dispatch(
          convert({
            token: fromTokenCurrency.value,
            tokenAmount: stablecoinAmt,
            rhoToken: rhoTokenCurrency.value,
            rhotokenAmount: tokenSwapData.amountOut,
            maxSlippage: maxSlippage,
            isDirectSwap: tokenSwapData.isDirectSwap,
            isUsingKyber: tokenSwapData.isUsingKyber,
          })
        );
      }
    }
  }, [
    amountState.fromTokenAmount,
    dispatch,
    fromTokenCurrency,
    isAmountValid,
    maxSlippage,
    needApproval,
    rhoTokenCurrency,
    tokenSwapData,
    user,
  ]);

  // Handles the changes in the stablecoin input value
  const handleInputChange = useCallback(
    async (
      amountStr: string | BigNumber,
      token?: CurrencyOption,
      rhoToken?: CurrencyOption
    ) => {
      if (!token || !rhoToken || !amountStr || amountStr === '') {
        setAmountState({
          fromTokenAmount: '',
        });
        setHasValidAmount(false);
      } else {
        const currentAmount = new BigNumber(amountStr);
        setAmountState({
          fromTokenAmount: noExponents(currentAmount),
        });
        setHasValidAmount(!currentAmount.isZero());

        dispatch(
          updateUserConvertApproval({
            tokenAddress: token.value,
            rhoTokenAddress: rhoToken.value,
            amount: currentAmount,
            user,
          })
        );
      }
    },
    [dispatch, user]
  );

  // Handles changes of the stablecoin dropdown
  const fromTokenChanged = useCallback(
    (fromToken: CurrencyOption) => {
      if (!fromToken) return;
      setFromTokenCurrency(fromToken);
      setFormError(undefined);

      let newRhoToken = rhoTokenCurrency;

      // For network without kyberswap
      if (network && network.disableKyberswap && fromToken.linkedToken) {
        const rhoToken = rhoTokens.find((e) =>
          ciEquals(e.value, fromToken.linkedToken)
        );

        if (rhoToken) {
          newRhoToken = rhoToken;
          setRhoTokenCurrency(rhoToken);
        }
      }

      handleInputChange(amountState.fromTokenAmount, fromToken, newRhoToken);
    },
    [
      amountState.fromTokenAmount,
      handleInputChange,
      network,
      rhoTokenCurrency,
      rhoTokens,
    ]
  );

  // Handles changes of the rhoToken dropdown
  const rhoTokenChanged = useCallback(
    (rhoToken: CurrencyOption) => {
      if (!rhoToken) return;
      setRhoTokenCurrency(rhoToken);
      setFormError(undefined);

      let newFromToken = fromTokenCurrency;

      // For network without kyberswap
      if (network && network.disableKyberswap && rhoToken.linkedToken) {
        const fromToken = stablecoins.find((e) =>
          ciEquals(e.value, rhoToken.linkedToken)
        );

        if (fromToken) {
          newFromToken = fromToken;
          setFromTokenCurrency(fromToken);
        }
      }

      if (newFromToken) {
        dispatch(
          updateUserConvertApproval({
            tokenAddress: newFromToken.value,
            rhoTokenAddress: rhoToken.value,
            amount: new BigNumber(amountState.fromTokenAmount),
            user,
          })
        );
      }
    },
    [
      amountState.fromTokenAmount,
      dispatch,
      fromTokenCurrency,
      network,
      stablecoins,
      user,
    ]
  );

  // First update to set default values
  useEffect(() => {
    if (!fromTokensLoading && !rhotokensLoading) {
      fromTokenChanged(fromTokens[0]);
      rhoTokenChanged(rhoTokens[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rhotokensLoading, fromTokens, fromTokensLoading]);

  return (
    <S.FormWrapper>
      <S.HeaderWrapper>
        <S.InputLabel>Convert</S.InputLabel>
        {tokenSwapData.isUsingKyber && (
          <S.FormBtns>
            <S.PoweredBy>
              <S.PoweredTxt>Powered by</S.PoweredTxt>
              <a
                href="https://kyberswap.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <S.PoweredLogo src={KyberswapLogo} alt="Powered by Kyberswap" />
              </a>
            </S.PoweredBy>
            <Spacer axis="horizontal" size={20} />
            <S.SettingsBtn onClick={() => setShowAdvanceSettings(true)}>
              <S.SettingsIcon />
            </S.SettingsBtn>
          </S.FormBtns>
        )}
      </S.HeaderWrapper>
      {!isLoadingData ? (
        <>
          <IntegratedPriceInput
            disableInput={!user}
            currencies={fromTokens}
            currentCurrencyValue={fromTokenCurrency?.value}
            inputValue={amountState.fromTokenAmount}
            onAmountChange={(v) =>
              handleInputChange(v, fromTokenCurrency, rhoTokenCurrency)
            }
            onCurrencyChange={(token: CurrencyOption) => {
              fromTokenChanged(token);
            }}
            onMaxBtnClicked={() => {
              handleInputChange(
                noExponents(fromTokenBalance),
                fromTokenCurrency,
                rhoTokenCurrency
              );
            }}
            maxDecimalPlace={fromTokenCurrency?.decimals}
          />
          {user && (
            <ShortenBalance
              label="Available balance:"
              balanceValue={fromTokenBalance}
              decimalPrecision={DECIMAL_PRECISION}
              currency={fromTokenCurrency?.symbol ?? fromTokenCurrency?.label}
            />
          )}
          {formError && <S.InputError>{formError ?? ''}</S.InputError>}
        </>
      ) : (
        <Skeleton height={55} />
      )}
      <Spacer axis="vertical" size={15} />
      <S.InputLabel>Into (estimated)</S.InputLabel>
      {!isLoadingData ? (
        <PriceInput
          disableInput
          currencies={rhoTokens}
          currentCurrencyValue={rhoTokenCurrency?.value}
          inputValue={
            !isTokenSwapLoading && !error
              ? tokenSwapData.amountOut.toString()
              : '0'
          }
          onCurrencyChange={(token: CurrencyOption) => {
            rhoTokenChanged(token);
          }}
          maxDecimalPlace={rhoTokenCurrency?.decimals}
        />
      ) : (
        <Skeleton height={55} />
      )}
      <S.QuoteData>
        {!isLoadingData ? (
          <>
            {!tokenSwapData.exchangeRate.isNaN() &&
            !tokenSwapData.exchangeRate.isZero() &&
            !isTokenSwapLoading ? (
              user && (
                <S.ConversionRateLabel
                  onClick={() => setInvertExchangeRate(!invertExchangeRate)}
                >
                  {!invertExchangeRate
                    ? roundingRateNumber(tokenSwapData.exchangeRate.toNumber())
                    : roundingRateNumber(
                        new BigNumber(1)
                          .div(tokenSwapData.exchangeRate)
                          .toNumber()
                      )}{' '}
                  {!invertExchangeRate
                    ? fromTokenCurrency?.symbol
                    : rhoTokenCurrency?.label}{' '}
                  = 1{' '}
                  {!invertExchangeRate
                    ? rhoTokenCurrency?.label
                    : fromTokenCurrency?.symbol}
                  <S.SwapIcon />
                </S.ConversionRateLabel>
              )
            ) : (
              <S.ConversionRateLabel>-</S.ConversionRateLabel>
            )}
            {tokenSwapData.isUsingKyber && (
              <>
                <S.MaxSlippageLabel
                  onClick={() => setShowAdvanceSettings(true)}
                >
                  Max Slippage: {maxSlippage * 100}%
                </S.MaxSlippageLabel>
              </>
            )}
          </>
        ) : (
          <>
            <Skeleton height={15} width={100} />
            <Skeleton height={15} width={100} />
          </>
        )}
      </S.QuoteData>
      <Spacer axis="vertical" size={15} />
      <SubmitButton
        onFormSubmit={() => onSubmit()}
        hasValidAmount={hasValidAmount}
        hasEnoughBalance={fromTokenBalance.gte(amountState.fromTokenAmount)}
        needApproval={needApproval}
        stablecoinLabel={fromTokenCurrency?.symbol ?? fromTokenCurrency?.label}
        user={user}
      />
      <Spacer axis="vertical" size={15} />
      {tokenSwapData.isUsingKyber &&
        tokenSwapData.route.length > 0 &&
        displayTradeRoute && (
          <TokenSwapRoute
            fromToken={fromTokenCurrency}
            toToken={rhoTokenCurrency}
            fromAmount={new BigNumber(amountState.fromTokenAmount)}
            toAmount={tokenSwapData.amountOut}
            route={tokenSwapData.route}
          />
        )}
      <ConvertAdvancedSettingsPopup
        currentSlippage={maxSlippage}
        displayRoute={displayTradeRoute}
        onMaxSlippageSelect={(v) => setMaxSlippage(v)}
        onDisplayRouteChanged={(v) => dispatch(displayConvertTradeRoute(v))}
        onClose={() => setShowAdvanceSettings(false)}
        isOpen={showAdvanceSettings}
      />
    </S.FormWrapper>
  );
};

export default ConvertForm;
