import { v4 as uuidv4 } from 'uuid';
import { noExponents, sendTransaction, waitReceipt } from 'utils';
import { ethers } from 'ethers';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import BigNumber from 'bignumber.js';
import { Contract, ProviderAccessor } from 'core/types';
import { Action, Dispatch } from '@reduxjs/toolkit';

const compensate = async (payload: {
  lpAddress: string;
  lpDecimals: number;
  label: string;
  amount: BigNumber;
  compensater: Contract | null;
  currentProvider: ProviderAccessor | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<Action<any>>;
}): Promise<void> => {
  const transactionId = uuidv4();

  const {
    compensater,
    lpDecimals,
    amount,
    currentProvider,
    lpAddress,
    label,
    dispatch,
  } = payload;

  if (!compensater || !currentProvider) return;

  try {
    if (!compensater) {
      throw Error('Could not find Compensater contract');
    }

    dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'compensation',
        type: 'claim',
        state: 'pending',
        payload: {
          amount,
          label,
        },
      })
    );

    const formatedAmount = ethers.utils.parseUnits(
      noExponents(amount),
      lpDecimals
    );

    const tx = await sendTransaction(
      compensater.contract.compensateForLp,
      lpAddress,
      formatedAmount
    );

    dispatch(
      updateTransactionHash({
        id: transactionId,
        hash: tx.hash,
      })
    );

    await waitReceipt(tx);
    dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'success',
      })
    );
  } catch (error) {
    console.error(error.toString());
    dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'error',
      })
    );
  }
};

export default compensate;
