import styled from 'styled-components';
import { down, up } from 'styled-breakpoints';
import { FaInfoCircle } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
export const Wrapper = styled.div`
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  font-size: 1rem;
  text-align: left;
  padding: 20px;
`;

export const Popup = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 3rem auto;
  background-color: white;
  border-radius: 15px;
  width: 400px;
  max-height: 85vh;
  ${down('sm')} {
    width: calc(100% - 2rem);
    margin: 1rem 0 0.2rem 0;
  }
  ${up('xl')} {
    width: 450px;
  }
`;

export const Header = styled.h4`
  font-weight: 700;
  margin: 10px 0;
  padding: 0.8rem 1rem 0 1rem;
`;

export const SearchBarWrapper = styled.div`
  padding: 0 1rem;
`;

export const InfoIcon = styled(FaInfoCircle)`
  padding-left: 0.2rem;
  font-size: 0.6rem;
  align-self: center;
`;

export const CloseBtn = styled(IoMdClose)`
  position: absolute;
  top: 0.5rem;
  right: 1rem;
  opacity: 0.3;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;
