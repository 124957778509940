import styled from 'styled-components';

export const OptionWrapper = styled.div<{ isActive?: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0 1rem;
  background-color: ${(props) =>
    props.isActive ? '#00000008' : 'transparent'};
  transition: background-color 0.2s linear;
  &:hover {
    background-color: #00000008;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 30px;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 180px;
  margin: 0 10px;
`;

export const Symbol = styled.div`
  font-size: 0.85rem;
  font-weight: bold;
`;

export const Label = styled.div`
  font-size: 0.5rem;
`;
