import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const AmountWrapper = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const AmountMainValue = styled.div`
  font-weight: normal;
  font-size: 1.2rem;
`;

export const AmountGreyedDecimals = styled.div`
  display: block;
  opacity: 0.5;
  font-size: 1.2rem;
  ${down('xs')} {
    display: none;
  }
`;

export const PlaceholderAmountMainValue = styled(AmountMainValue)`
  font-size: 1.2rem;
`;

export const PlaceholderAmountGreyedDecimals = styled(AmountGreyedDecimals)`
  font-size: 1.2rem;
`;

export const Currency = styled.div<{ isClickable: boolean }>`
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  opacity: 0.5;
  font-size: 0.75rem;
  line-height: 1.4rem;
`;
