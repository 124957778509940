import React from 'react';
import styled, { keyframes } from 'styled-components/macro';
import FlurryLogo from 'assets/images/white_logo.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: #164255;
`;

const rotate = keyframes`
    100% {
        transform: rotate(360deg);
    }
`;

const FlurryLogoRotating = styled.div`
  height: 135px;
  width: 150px;
  animation: ${rotate} 10s linear infinite;
  background-image: url('${FlurryLogo}');
  background-size: cover;
`;

const LoadingText = styled.div`
  padding: 2rem 0;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
`;

const GoBackBtn = styled.a`
  cursor: pointer;
  padding: 0.5rem 1rem;
  color: white;
  font-size: 0.8rem;
  border: 1px solid white;
  border-radius: 15px;
  text-decoration: none;
`;

const LoadingCacheCheck: React.FC = () => {
  return (
    <Wrapper>
      <FlurryLogoRotating />
      <LoadingText>Checking your browser cache...</LoadingText>
      <GoBackBtn href="https://flurry.finance">Go back to Flurry</GoBackBtn>
    </Wrapper>
  );
};

export default LoadingCacheCheck;
