import styled from 'styled-components/macro';

export const UserDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #00000021;
`;

export const UserInfosWrapper = styled.div`
  width: calc(100% - 1rem);
  padding: 0.5rem;
`;
