import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as S from './CompensationTable.styles';
import BigNumber from 'bignumber.js';
import { ciEquals } from 'utils';
import HeaderItem from './HeaderItem';
import { Sorting } from 'core/types';
import FarmRowUI from './FarmRow';
import { FarmType } from '../controller/useFarmsStats';

const sortBN = (
  a: BigNumber | undefined,
  b: BigNumber | undefined,
  sortingType: 'asc' | 'desc'
): number => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return sortingType === 'asc' ? -1 : 1;
  }
  if (!b) {
    return sortingType === 'asc' ? 1 : -1;
  }

  return sortingType === 'asc' ? a.minus(b).toNumber() : b.minus(a).toNumber();
};

const CompensationTable: React.FC<{
  farms: FarmType[];
  loading: boolean;
}> = ({ farms, loading }) => {
  const [sortedFarms, setSortedFarms] = useState(farms);
  const [firstSetup, setFirstSetup] = useState(true);

  const [currentColumnnSorted, setCurrentColumnnSorted] = useState<
    'farm' | 'staking' | 'balance' | undefined
  >(undefined);

  const sortFarms = useCallback(
    (farmColumn: 'farm' | 'staking' | 'balance', sortingType: Sorting) => {
      setCurrentColumnnSorted(farmColumn);

      if (sortingType === 'none') {
        setSortedFarms(farms);
        return;
      }

      const newList = [...farms];

      switch (farmColumn) {
        case 'farm':
          if (sortingType === 'asc') {
            newList.sort((a, b) => (a.label > b.label ? 1 : -1));
          } else if (sortingType === 'desc') {
            newList.sort((a, b) => (a.label < b.label ? 1 : -1));
          }
          break;
        case 'staking':
          newList.sort((a, b) => sortBN(a.staking, b.staking, sortingType));
          break;
        case 'balance':
          newList.sort((a, b) =>
            sortBN(a.compensation, b.compensation, sortingType)
          );
          break;
      }

      setSortedFarms(newList);
    },
    [farms]
  );

  useEffect(() => {
    if (firstSetup && !loading && farms.length > 0) {
      setSortedFarms(farms);
      setFirstSetup(false);
    }
  }, [farms, firstSetup, loading, sortedFarms]);

  useEffect(() => {
    if (firstSetup) {
      return;
    }

    // update sorted farm when farms update
    const updatedFarms = [...sortedFarms];
    for (let i = 0; i < updatedFarms.length; i++) {
      const newFarm = farms.find((e) =>
        ciEquals(e.address, updatedFarms[i].address)
      );
      if (newFarm) {
        updatedFarms[i] = newFarm;
      }
    }

    setSortedFarms(updatedFarms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms]);

  const rows = useMemo(() => {
    if (loading || sortedFarms.length === 0) {
      return Array.from(Array(4).keys()).map((e) => (
        <FarmRowUI key={e.toString() + '_loadingrow'} loading />
      ));
    }

    return sortedFarms.map((row) => {
      return <FarmRowUI key={row.address + '_row'} farm={row} />;
    });
  }, [loading, sortedFarms]);

  return (
    <>
      <S.Wrapper>
        <S.FarmTableWrapper key="farm-table">
          <S.Header>
            <S.HeaderItem></S.HeaderItem>
            <HeaderItem
              farmSorting="farm"
              isCurrentlySorted={currentColumnnSorted === 'farm'}
              onClick={(farmColumn, sortingType) =>
                sortFarms(farmColumn, sortingType)
              }
            >
              Farm
            </HeaderItem>
            <HeaderItem
              farmSorting="staking"
              isCurrentlySorted={currentColumnnSorted === 'staking'}
              onClick={(farmColumn, sortingType) =>
                sortFarms(farmColumn, sortingType)
              }
              hideOnTablet
              hideOnMobile
            >
              Staking
            </HeaderItem>
            <HeaderItem
              farmSorting="balance"
              isCurrentlySorted={currentColumnnSorted === 'balance'}
              onClick={(farmColumn, sortingType) =>
                sortFarms(farmColumn, sortingType)
              }
              hideOnTablet
              hideOnMobile
            >
              Compensation
            </HeaderItem>
          </S.Header>
          {rows}
        </S.FarmTableWrapper>
      </S.Wrapper>
    </>
  );
};

export default CompensationTable;
