import styled from 'styled-components/macro';
import React from 'react';
import { Link } from 'react-router-dom';
import { IoMdSwap } from 'react-icons/io';

const BridgeBtn = styled(Link)<{ disabled?: boolean; $lightStyle?: boolean }>`
  cursor: pointer;
  padding: 0;
  height: 22px;
  width: 22px;
  margin-left: 5px;
  outline: 0;
  border: 1px solid
    ${(props) => (props.$lightStyle ? '#ffffff1a' : '#0000001a')};
  background-color: ${(props) => (props.$lightStyle ? 'transparent' : 'white')};
  border-radius: 13px;
  text-align: center;
  transition: all 0.2s linear;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
  color: ${(props) => (props.$lightStyle ? 'white' : 'black')};
  &:hover {
    border: 1px solid
      ${(props) => (props.$lightStyle ? '#ffffff40' : '#00000040')};
  }
`;

const BridgeIcon = styled(IoMdSwap)`
  font-size: 14px;
  margin-top: 4px;
`;

const BridgeButton: React.FC<{
  tokenLabel: string;
  tokenAddress: string;
  disabled?: boolean;
  lightStyle?: boolean;
}> = ({ tokenLabel, tokenAddress, disabled, lightStyle }) => {
  return (
    <BridgeBtn
      to={`/bridge/${tokenAddress}`}
      replace
      disabled={disabled}
      title={`Bridge ${tokenLabel}`}
      $lightStyle={lightStyle}
    >
      <BridgeIcon />
    </BridgeBtn>
  );
};

export default BridgeButton;
