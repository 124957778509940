import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 2.5rem 4rem;
  height: calc(100% - 2rem);
  ${down('sm')} {
    flex-direction: column;
    padding: 1rem 2rem;
  }
`;

export const Illustration = styled.img`
  max-width: 130px;
  height: auto;
`;

export const Text = styled.div`
  display: flex;
  flex-direction: column;
  ${down('sm')} {
    padding: 1rem 0;
    text-align: center;
  }
`;

export const Title = styled.h5`
  margin: 0;
  padding: 0;
  color: white;
  font-size: 1.2rem;
`;
export const SubTitle = styled.h6`
  margin: 0;
  padding: 0;
  color: white;
  opacity: 0.7;
  font-size: 1.1rem;
  font-weight: normal;
`;
