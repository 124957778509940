import styled from 'styled-components';
import { FiSearch } from 'react-icons/fi';

export const Wrapper = styled.div`
  position: relative;
  border-radius: 3px;
  padding: 0rem 0rem;
  border: 1px solid #0000003b;
  &:hover {
    border-color: #a7dcff;
  }
`;

export const Input = styled.input`
  border: none;
  outline: none;
  width: calc(100% - 2rem);
  background-color: transparent;
  margin: 0;
  padding: 0.7rem 1rem 0.7rem 1rem;
  font-size: 0.8rem;
  &:focus {
    outline: none;
  }
`;

export const SearchIcon = styled(FiSearch)`
  opacity: 0.5;
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;
