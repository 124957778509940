import React from 'react';
import { useAppSelector } from 'core/store/hooks';
import * as S from './UserDetails.styles';
import { UserAddressButton, NetworkSwitcherDropdown } from 'components';

const UserDetails: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);

  if (!user) {
    return <></>;
  }

  return (
    <S.UserDetails>
      <S.UserInfosWrapper>
        <UserAddressButton />
      </S.UserInfosWrapper>
      <NetworkSwitcherDropdown />
    </S.UserDetails>
  );
};

export default UserDetails;
