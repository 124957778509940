import React, { useMemo, useState } from 'react';
import * as S from './RewardLock.styles';
import ProgressCircle from './ProgressCircle';
import { getEtherscanLink, secToRelevantString } from 'utils';
import BigNumber from 'bignumber.js';
import { NetworkInfo } from 'core/types';
import { GAOutboundLink } from 'components';

type RewardLockProps = {
  rewardUnlockedBlock?: BigNumber;
  rewardEndBlock?: BigNumber;
  currentBlock: BigNumber | null;
  network: NetworkInfo | undefined;
};

type RewardState = {
  block: BigNumber;
  etherscanLink: string;
  timeLeft: string;
  percentage?: number;
};

const RewardLock: React.FC<RewardLockProps> = ({
  rewardUnlockedBlock,
  rewardEndBlock,
  currentBlock,
  network,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const lockedState = useMemo<RewardState | undefined>(() => {
    if (
      network &&
      currentBlock &&
      rewardUnlockedBlock &&
      currentBlock.lte(rewardUnlockedBlock)
    ) {
      const timeLeft = secToRelevantString(
        rewardUnlockedBlock
          .minus(currentBlock)
          .times(network.avgBlockTime)
          .toNumber()
      );
      return {
        block: rewardUnlockedBlock,
        etherscanLink:
          getEtherscanLink(
            network,
            undefined,
            undefined,
            rewardUnlockedBlock.toNumber()
          ) ?? '',
        timeLeft: timeLeft ? `(in ~${timeLeft})` : '',
      };
    }

    return undefined;
  }, [currentBlock, network, rewardUnlockedBlock]);

  const rewardState = useMemo<RewardState | undefined>(() => {
    if (!rewardEndBlock || rewardEndBlock.isZero()) {
      return undefined;
    }

    if (network && currentBlock && currentBlock.lt(rewardEndBlock)) {
      const secondsLeft = rewardEndBlock
        .minus(currentBlock)
        .times(network.avgBlockTime)
        .toNumber();
      const timeLeft = secToRelevantString(secondsLeft);

      const endTimestamp = Date.now() + secondsLeft * 1000;
      const totalDurationMs = endTimestamp - 1.577e10 - endTimestamp;
      const percentage = (secondsLeft * 1000) / totalDurationMs;
      return {
        block: rewardEndBlock,
        etherscanLink:
          getEtherscanLink(
            network,
            undefined,
            undefined,
            rewardEndBlock.toNumber()
          ) ?? '',
        timeLeft: timeLeft ? `(in ~${timeLeft})` : '',
        percentage,
      };
    }

    return undefined;
  }, [currentBlock, network, rewardEndBlock]);

  if (!rewardState && !lockedState) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Icon onClick={() => setShowTooltip(!showTooltip)}>
        {rewardState?.percentage && (
          <ProgressCircle
            sizePx={40}
            radius={15}
            percentage={rewardState.percentage * 100}
            strokeWidth={5}
          ></ProgressCircle>
        )}
        {lockedState && (
          <S.LockIconWrapper>
            <S.LockIcon />
          </S.LockIconWrapper>
        )}
      </S.Icon>
      <S.Tooltip show={showTooltip}>
        {lockedState && (
          <S.BlockMsg>
            Claimable after block{' '}
            <GAOutboundLink
              eventLabel={lockedState.etherscanLink}
              to={lockedState.etherscanLink}
              target="_blank"
              rel="noreferrer"
            >
              {lockedState.block.toString()}
              <S.ExternalLinkIcon />
            </GAOutboundLink>{' '}
            {lockedState.timeLeft}
          </S.BlockMsg>
        )}
        {rewardState && (
          <S.BlockMsg>
            Earnings end at block{' '}
            <GAOutboundLink
              eventLabel={rewardState.etherscanLink}
              to={rewardState.etherscanLink}
              target="_blank"
              rel="noreferrer"
            >
              {rewardState.block.toString()}
              <S.ExternalLinkIcon />
            </GAOutboundLink>{' '}
            {rewardState.timeLeft}
          </S.BlockMsg>
        )}
      </S.Tooltip>
    </S.Wrapper>
  );
};

export default RewardLock;
