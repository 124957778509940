import { v4 as uuidv4 } from 'uuid';
import { noExponents, sendTransaction, waitReceipt } from 'utils';
import { ethers } from 'ethers';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import BigNumber from 'bignumber.js';
import { Contract } from 'core/types';
import { Action, Dispatch } from '@reduxjs/toolkit';

const unstakeFarmForCompensation = async (payload: {
  lpAddress: string;
  lpDecimals: number;
  label: string;
  amount: BigNumber;
  lpStaking: Contract | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<Action<any>>;
}): Promise<void> => {
  const transactionId = uuidv4();

  const { lpStaking, lpAddress, label, lpDecimals, amount, dispatch } = payload;

  if (!lpStaking) return;

  try {
    if (!lpStaking) {
      throw Error('Could not find LP Staking contract');
    }

    dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'farm',
        type: 'unstake',
        state: 'pending',
        payload: {
          amount,
          currency: label,
        },
      })
    );

    const formatedAmount = ethers.utils.parseUnits(
      noExponents(amount),
      lpDecimals
    );

    const tx = await sendTransaction(
      lpStaking.contract.withdraw,
      lpAddress,
      formatedAmount
    );
    dispatch(
      updateTransactionHash({
        id: transactionId,
        hash: tx.hash,
      })
    );

    await waitReceipt(tx);
    dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'success',
      })
    );
  } catch (error) {
    console.error(error.toString());
    dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'error',
      })
    );
  }
};

export default unstakeFarmForCompensation;
