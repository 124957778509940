import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkInfo } from 'core/types';
import { getContractsManualConfig } from 'utils';

type NotificationsModalUIState = {
  show: boolean;
  showPendings: boolean;
  showConfirmed: boolean;
  showErrors: boolean;
};

export type UIState = {
  displayConvertTradeRoute: boolean;
  showWalletConnectPopup: boolean;
  showClaimRewardsPopup: boolean;
  userModal: NotificationsModalUIState;
  getUsdtUrl: string | undefined;
  getUsdcUrl: string | undefined;
  getBusdUrl: string | undefined;
};

const initialState: UIState = {
  displayConvertTradeRoute: false,
  showWalletConnectPopup: false,
  showClaimRewardsPopup: false,
  userModal: {
    show: false,
    showPendings: true,
    showConfirmed: true,
    showErrors: true,
  },
  getUsdtUrl: undefined,
  getUsdcUrl: undefined,
  getBusdUrl: undefined,
};

export const getTestnetStablecoinUrl = createAsyncThunk<
  {
    usdtUrl: string | undefined;
    usdcUrl: string | undefined;
    busdUrl: string | undefined;
  },
  NetworkInfo
>('ui/getTestnetStablecoinUrl', async (network) => {
  const result = {
    usdtUrl: undefined,
    usdcUrl: undefined,
    busdUrl: undefined,
  };
  try {
    if (network) {
      const url = window._env_.CONTRACTS_CONFIG_URL;

      if (!url) {
        throw Error('No contracts config URL found!');
      }
      const contractConfig = await getContractsManualConfig(
        url,
        network.folder
      );

      if (contractConfig.hasOwnProperty('getUsdtUrl')) {
        result.usdtUrl = contractConfig.getUsdtUrl;
      }

      if (contractConfig.hasOwnProperty('getUsdcUrl')) {
        result.usdcUrl = contractConfig.getUsdcUrl;
      }

      if (contractConfig.hasOwnProperty('getBusdUrl')) {
        result.busdUrl = contractConfig.getBusdUrl;
      }
    }
  } catch (error) {
    // console.error(error.toString());
  } finally {
    return result;
  }
});

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    showConnectPopup: (state, action: PayloadAction<boolean>) => {
      state.showWalletConnectPopup = action.payload;
    },
    showClaimRewardsPopup: (state, action: PayloadAction<boolean>) => {
      state.showClaimRewardsPopup = action.payload;
    },
    showUserModal: (state, action: PayloadAction<boolean>) => {
      state.userModal.show = action.payload;
    },
    showPendingNotifications: (state, action: PayloadAction<boolean>) => {
      state.userModal.showPendings = action.payload;
    },
    showConfirmedNotifications: (state, action: PayloadAction<boolean>) => {
      state.userModal.showConfirmed = action.payload;
    },
    showErrorsNotifications: (state, action: PayloadAction<boolean>) => {
      state.userModal.showErrors = action.payload;
    },
    displayConvertTradeRoute: (state, action: PayloadAction<boolean>) => {
      state.displayConvertTradeRoute = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getTestnetStablecoinUrl.fulfilled, (state, action) => {
      state.getUsdtUrl = action.payload.usdtUrl;
      state.getUsdcUrl = action.payload.usdcUrl;
      state.getBusdUrl = action.payload.busdUrl;
    });
  },
});

export const {
  displayConvertTradeRoute,
  showConnectPopup,
  showClaimRewardsPopup,
  showUserModal,
  showPendingNotifications,
  showConfirmedNotifications,
  showErrorsNotifications,
} = uiSlice.actions;

export default uiSlice.reducer;
