import { createAsyncThunk } from '@reduxjs/toolkit';
import { Contract, UserBalance } from 'core/types';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { BN, getValueOfRef } from 'utils';

export const updateStablecoinBalances = createAsyncThunk<
  UserBalance[],
  {
    stablecoins: Contract[];
    userAddress: string;
    multicall: Multicall;
  }
>('balances/updateStablecoinBalances', async (payload, thunkAPI) => {
  const balances: UserBalance[] = [];
  try {
    const { stablecoins, userAddress, multicall } = payload;

    if (stablecoins.length === 0) {
      thunkAPI.rejectWithValue('No stablecoin Contract');
      throw new Error('No stablecoin Contract');
    }
    if (!userAddress) {
      thunkAPI.rejectWithValue('No user address');
      throw new Error('No user address');
    }

    // Building contract multicall
    const multicallContext: ContractCallContext[] = [];

    for (const c of stablecoins) {
      multicallContext.push({
        reference: c.address,
        contractAddress: c.address,
        abi: c.abi ?? [],
        calls: [
          {
            reference: 'balance',
            methodName: 'balanceOf',
            methodParameters: [userAddress],
          },
        ],
      });
    }

    // Executing contract multicall
    const callResults = (await multicall.call(multicallContext)).results;

    for (const c of stablecoins) {
      const res = callResults[c.address].callsReturnContext;
      if (res) {
        balances.push({
          currency: c.address,
          amount: BN(getValueOfRef(res, 'balance'), c.decimals),
        });
      }
    }
  } catch (e) {
    thunkAPI.rejectWithValue(
      'Could not initialize Flurry Staking user balance'
    );
    console.error(e);
  } finally {
    return balances;
  }
});
