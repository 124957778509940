import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type BlocksState = {
  [chainId: number]: {
    blockNow: number | undefined;
    block24h: number | undefined;
    block7d: number | undefined;
  };
};

const initialState: BlocksState = {};

const blocksSlice = createSlice({
  name: 'blocks',
  initialState,
  reducers: {
    setBlockNow: (
      state,
      action: PayloadAction<{ chainId: number; blockNow: number | undefined }>
    ) => {
      const { chainId, blockNow } = action.payload;
      const blocks = state[chainId];
      state[chainId] = {
        ...blocks,
        blockNow,
      };
    },
    setAllBlocks: (
      state,
      action: PayloadAction<{
        chainId: number;
        blockNow: number | undefined;
        block24h: number | undefined;
        block7d: number | undefined;
      }>
    ) => {
      const { chainId, blockNow, block24h, block7d } = action.payload;
      state = {
        ...state,
        [chainId]: {
          blockNow,
          block24h,
          block7d,
        },
      };
    },
  },
});

export const { setBlockNow } = blocksSlice.actions;

export default blocksSlice.reducer;
