import styled from 'styled-components/macro';

export const ComingSoonWrapper = styled.div`
  position: absolute;
  z-index: 7;
  display: flex;
  align-items: center;
  background-color: #ffffffee;
  width: 100%;
  height: 100%;
  border-radius: 7px;
`;

export const NotAvailableNetwork = styled.div`
  margin: 0 auto;
  font-size: 1.2rem;
  color: #3f3f3f;
`;
