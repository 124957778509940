import { IoMdClose, IoMdEye } from 'react-icons/io';
import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  user-select: none;
  z-index: 5;
  position: absolute;
  top: 110px;
  right: 1rem;
  width: 350px;
  background-color: #f8f7fc;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${down('xs')} {
    position: fixed;
    top: auto;
    bottom: 1rem;
    width: 90%;
    margin: 0 auto;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: #6d6d6d;
  padding: 0.5rem 0.8rem 0rem 0.8rem;
`;

export const SingleNotifTitle = styled.div`
  font-size: 0.7rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const CloseBtn = styled(IoMdClose)`
  cursor: pointer;
  color: #000000a4;
  background-color: #1c009708;
  padding: 0.2rem;
  margin-left: 0.35rem;
  border-radius: 50px;
  transition: background-color 0.2s linear;
  &:hover {
    background-color: #1c009718;
  }
`;

export const ShowNotifsBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  color: #000000c1;
  background-color: #1c009708;
  padding: 0.2rem 0.5rem;
  border: none;
  border-radius: 50px;
  transition: background-color 0.2s linear;
  font-size: 0.7rem;
  &:hover {
    background-color: #1c009718;
  }
`;

export const EyeIcon = styled(IoMdEye)`
  margin-left: 0.2rem;
`;

export const BottomTimerLineWrapper = styled.div`
  height: 5px;
  width: 100%;
  background-color: #00000039;
`;

export const BottomTimerLine = styled.div<{ percent: number }>`
  height: 100%;
  transition: width 0.1s linear;
  width: ${(props) => `${props.percent}%`};
  background-color: #03a6a6;
`;
