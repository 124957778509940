/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { UseFormRegister, RegisterOptions } from 'react-hook-form';
import { CurrencyOption } from 'core/types';
import * as S from './PriceInput.styles';
import CurrenciesDropdown from './CurrenciesDropdown';
import { ciEquals } from 'utils';

export interface PriceInputProps {
  currencies?: CurrencyOption[];
  currentCurrencyValue?: string;
  register?: UseFormRegister<any>;
  registerOptions?: RegisterOptions<any>;
  keyPrice?: string;
  onAmountChange?: (amount: string) => void;
  onCurrencyChange?: (ccy: CurrencyOption) => void;
  onMaxBtnClicked?: () => void;
  inputValue?: string;
  maxDecimalPlace?: number;
  disableInput?: boolean;
}

const PriceInput: React.FC<PriceInputProps> = ({
  currencies,
  currentCurrencyValue,
  register,
  registerOptions,
  keyPrice,
  onMaxBtnClicked,
  onAmountChange,
  onCurrencyChange,
  inputValue,
  maxDecimalPlace,
  disableInput,
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies?.find((e) => ciEquals(e.value, currentCurrencyValue))
  );

  useEffect(() => {
    if (currencies && currentCurrencyValue) {
      const newCcy = currencies.find((e) =>
        ciEquals(e.value, currentCurrencyValue)
      );
      if (newCcy) {
        setSelectedCurrency(newCcy);
      }
    }
  }, [currencies, currentCurrencyValue]);

  const inputSize = useMemo(():
    | 'normal'
    | 'noMax'
    | 'noCurrencies'
    | 'full' => {
    if (!onMaxBtnClicked && !currencies) {
      return 'full';
    } else if (!onMaxBtnClicked) {
      return 'noMax';
    } else if (!currencies) {
      return 'noCurrencies';
    }
    return 'normal';
  }, [currencies, onMaxBtnClicked]);

  const checkDecimalPlace = (baseValue: string) => {
    if (!maxDecimalPlace) return baseValue;

    if (baseValue.indexOf('.') >= 0) {
      return `${baseValue.substr(0, baseValue.indexOf('.'))}.${baseValue.substr(
        baseValue.indexOf('.') + 1,
        maxDecimalPlace
      )}`;
    }
    return baseValue;
  };

  return (
    <S.Wrapper disabled={disableInput}>
      {register && keyPrice ? (
        <S.Input
          min="0"
          type="number"
          placeholder="Enter Amount"
          step="any"
          {...register(keyPrice, registerOptions)}
          onChange={(e) => {
            if (onAmountChange) {
              onAmountChange(checkDecimalPlace(e.target.value));
            }
          }}
          inputWidth={inputSize}
          disabled={disableInput}
        />
      ) : (
        <S.Input
          min="0"
          type="number"
          placeholder="Enter Amount"
          step="any"
          value={inputValue}
          onChange={(e) => {
            if (onAmountChange) {
              onAmountChange(checkDecimalPlace(e.target.value));
            }
          }}
          inputWidth={inputSize}
          disabled={disableInput}
        />
      )}
      {onMaxBtnClicked && (
        <S.MaxBtn type="button" onClick={() => onMaxBtnClicked()}>
          MAX
        </S.MaxBtn>
      )}

      {currencies && (
        <CurrenciesDropdown
          currencies={currencies}
          selectedCurrency={selectedCurrency}
          onCurrencyChange={onCurrencyChange}
        />
      )}
    </S.Wrapper>
  );
};

export default PriceInput;
