import React, { useState, useMemo, useEffect } from 'react';
import * as S from './TabLayout.styles';

type TabContent = {
  tabLabel: string;
  tabContent: JSX.Element;
};

export interface TabLayoutProps {
  tabs: TabContent[];
}

const TabLayout: React.FC<TabLayoutProps> = ({ tabs }) => {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  useEffect(() => {
    setCurrentTab(tabs[0]);
  }, [tabs]);

  const tabContent = useMemo(() => {
    for (const e of tabs) {
      if (e === currentTab) {
        return <S.TabContent>{e.tabContent}</S.TabContent>;
      }
    }

    return <S.TabContent>{tabs[0].tabContent}</S.TabContent>;
  }, [tabs, currentTab]);

  if (!tabs || tabs.length <= 0) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.TabsWrapper>
        {tabs.map((e) => (
          <S.Tab
            onClick={() => setCurrentTab(e)}
            active={e === currentTab}
            key={e.tabLabel}
          >
            {e.tabLabel}
          </S.Tab>
        ))}
      </S.TabsWrapper>
      <S.ContentWrapper>{tabContent}</S.ContentWrapper>
    </S.Wrapper>
  );
};

export default TabLayout;
