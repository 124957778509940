import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildStablecoinsContracts } from 'utils/config';
import { InitContractPayload, Contract } from 'core/types';
import { setStablecoinBalanceLoading } from 'core/store/balances/balances';
import { updateStablecoinBalances } from 'core/store/balances/thunks/updateStablecoinBalances';

export const initStablecoins = createAsyncThunk<
  Contract[],
  InitContractPayload
>('contracts/initStablecoins', async (payload, thunkAPI) => {
  let stablecoins: Contract[] = [];
  try {
    const { provider, contractsConfig, multicall, userAddress } = payload;

    thunkAPI.dispatch(setStablecoinBalanceLoading());

    stablecoins = await buildStablecoinsContracts(contractsConfig, provider);

    thunkAPI.dispatch(
      updateStablecoinBalances({
        stablecoins,
        userAddress,
        multicall,
      })
    );
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Stablecoins contracts');
    console.error(e);
  } finally {
    return stablecoins;
  }
});
