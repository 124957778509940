import BigNumber from 'bignumber.js';
import { useAppSelector } from 'core/store/hooks';
import { ContractCallContext } from 'ethereum-multicall';
import { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';
import { BN, findLogoOfContract, getValueOfRef } from 'utils';
import farmAbi from './farmAbi.json';

export type FarmType = {
  label: string;
  address: string;
  rhoToken: string;
  logo0?: string;
  logo1?: string;
  decimals: number;
  balance: BigNumber;
  staking: BigNumber;
  compensation: BigNumber;
  allowance: BigNumber;
};

export const useFarmsStats = (
  addresses: string[]
): { farms: FarmType[]; loading: boolean } => {
  const [loading, setLoading] = useState<boolean>(false);
  const [farms, setFarms] = useState<FarmType[]>([]);
  const {
    user,
    network,
    currentProvider: provider,
  } = useAppSelector((state) => state.auth);
  const { lpStaking, compensater } = useAppSelector((state) => state.contracts);

  useEffect(() => {
    const setupFarms = async () => {
      if (!user || !provider || !lpStaking || !compensater || !network) {
        return;
      }

      const farmsResult: FarmType[] = [];
      const allTokens: string[] = [];

      setLoading(true);

      // Building contract multicall
      const multicallContext: ContractCallContext[] = [];
      for (const address of addresses) {
        multicallContext.push({
          reference: address,
          contractAddress: address,
          abi: farmAbi,
          calls: [
            {
              reference: 'decimals',
              methodName: 'decimals',
              methodParameters: [],
            },
            {
              reference: 'token0',
              methodName: 'token0',
              methodParameters: [],
            },
            {
              reference: 'token1',
              methodName: 'token1',
              methodParameters: [],
            },
            {
              reference: 'farmBalance',
              methodName: 'balanceOf',
              methodParameters: [user.address],
            },
            {
              reference: 'allowance',
              methodName: 'allowance',
              methodParameters: [user.address, compensater.address],
            },
          ],
        });

        multicallContext.push({
          reference: `staking-${address}`,
          contractAddress: lpStaking.address,
          abi: lpStaking.abi ?? [],
          calls: [
            {
              reference: 'farmStakingBalance',
              methodName: 'stakeOf',
              methodParameters: [user.address, address],
            },
          ],
        });
      }

      // Executing contract multicall
      const callResults = (await provider.multicall.call(multicallContext))
        .results;

      const multicallCompensationContext: ContractCallContext[] = [];

      for (const address of addresses) {
        const farmRes = callResults[address]?.callsReturnContext;
        const stakingRes =
          callResults[`staking-${address}`]?.callsReturnContext;
        if (farmRes && stakingRes) {
          allTokens.push(getValueOfRef(farmRes, 'token0'));
          allTokens.push(getValueOfRef(farmRes, 'token1'));

          const decimals = getValueOfRef(farmRes, 'decimals');
          const balance = getValueOfRef(farmRes, 'farmBalance');
          const staking = getValueOfRef(stakingRes, 'farmStakingBalance');
          const allowance = getValueOfRef(farmRes, 'allowance');

          multicallCompensationContext.push({
            reference: `compensation-${address}`,
            contractAddress: compensater.address,
            abi: compensater.abi ?? [],
            calls: [
              {
                reference: 'compensationBalance',
                methodName: 'getCompensationForLp',
                methodParameters: [address, balance],
              },
            ],
          });

          farmsResult.push({
            label: '',
            rhoToken: '',
            address,
            decimals,
            balance: BN(balance, decimals),
            staking: BN(staking, decimals),
            compensation: new BigNumber(0),
            allowance: BN(allowance, decimals),
          });
        }
      }

      // Executing compensation call
      const callCompensationResults = (
        await provider.multicall.call(multicallCompensationContext)
      ).results;

      // Building tokens multicall
      const multicallTokens: ContractCallContext[] = [];
      for (const token of allTokens) {
        multicallTokens.push({
          reference: token,
          contractAddress: token,
          abi: farmAbi,
          calls: [
            {
              reference: 'symbol',
              methodName: 'symbol',
              methodParameters: [],
            },
          ],
        });
      }

      // Executing contract multicall
      const tokenCallResult = (await provider.multicall.call(multicallTokens))
        .results;
      const tokenSymbols: {
        [address: string]: string;
      } = {};

      for (const token of allTokens) {
        const tokenRes = tokenCallResult[token]?.callsReturnContext;
        if (tokenRes) {
          tokenSymbols[token] = getValueOfRef(tokenRes, 'symbol');
        }
      }

      for (const farm of farmsResult) {
        const farmRes = callResults[farm.address]?.callsReturnContext;

        if (farmRes) {
          const token0 = getValueOfRef(farmRes, 'token0');
          const token1 = getValueOfRef(farmRes, 'token1');

          if (tokenSymbols[token0] && tokenSymbols[token1]) {
            const logo0 = findLogoOfContract(tokenSymbols[token0]);
            const logo1 = findLogoOfContract(tokenSymbols[token1]);

            farm.label = `${tokenSymbols[token0]}/${tokenSymbols[token1]}`;
            farm.logo0 = logo0;
            farm.logo1 = logo1;

            if (tokenSymbols[token0].includes('rho')) {
              farm.rhoToken = tokenSymbols[token0];
            } else if (tokenSymbols[token1].includes('rho')) {
              farm.rhoToken = tokenSymbols[token1];
            }
          }
        }

        const compensationRes =
          callCompensationResults[`compensation-${farm.address}`]
            ?.callsReturnContext;

        if (compensationRes) {
          const compensation = getValueOfRef(
            compensationRes,
            'compensationBalance'
          );
          farm.compensation = BN(compensation, farm.decimals);
        }
      }

      setFarms(farmsResult);
      setLoading(false);
    };

    if (user && provider && lpStaking) {
      setupFarms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lpStaking, user?.address, provider]);

  useInterval(async () => {
    if (!user || !provider || !lpStaking || !compensater || !network) {
      return;
    }

    const updatedFarms: FarmType[] = [];

    // Building contract multicall
    const multicallContext: ContractCallContext[] = [];

    for (const farm of farms) {
      multicallContext.push({
        reference: farm.address,
        contractAddress: farm.address,
        abi: farmAbi,
        calls: [
          {
            reference: 'farmBalance',
            methodName: 'balanceOf',
            methodParameters: [user.address],
          },
          {
            reference: 'allowance',
            methodName: 'allowance',
            methodParameters: [user.address, compensater.address],
          },
        ],
      });

      multicallContext.push({
        reference: `staking-${farm.address}`,
        contractAddress: lpStaking.address,
        abi: lpStaking.abi ?? [],
        calls: [
          {
            reference: 'farmStakingBalance',
            methodName: 'stakeOf',
            methodParameters: [user.address, farm.address],
          },
        ],
      });
    }

    // Executing contract multicall
    const callResults = (await provider.multicall.call(multicallContext))
      .results;

    const multicallCompensationContext: ContractCallContext[] = [];

    for (const farm of farms) {
      const farmRes = callResults[farm.address]?.callsReturnContext;
      const stakingRes =
        callResults[`staking-${farm.address}`]?.callsReturnContext;

      if (farmRes && stakingRes) {
        const balance = getValueOfRef(farmRes, 'farmBalance');
        const staking = getValueOfRef(stakingRes, 'farmStakingBalance');
        const allowance = getValueOfRef(farmRes, 'allowance');

        multicallCompensationContext.push({
          reference: `compensation-${farm.address}`,
          contractAddress: compensater.address,
          abi: compensater.abi ?? [],
          calls: [
            {
              reference: 'compensationBalance',
              methodName: 'getCompensationForLp',
              methodParameters: [farm.address, balance],
            },
          ],
        });

        updatedFarms.push({
          ...farm,
          balance: BN(balance, farm.decimals),
          staking: BN(staking, farm.decimals),
          allowance: BN(allowance, farm.decimals),
        });
      }
    }

    // Executing compensation call
    const callCompensationResults = (
      await provider.multicall.call(multicallCompensationContext)
    ).results;

    for (const farm of updatedFarms) {
      const compensationRes =
        callCompensationResults[`compensation-${farm.address}`]
          ?.callsReturnContext;

      if (compensationRes) {
        const compensation = getValueOfRef(
          compensationRes,
          'compensationBalance'
        );

        farm.compensation = BN(compensation, farm.decimals);
      }
    }

    setFarms(updatedFarms);
  }, 7000);

  return useMemo(() => {
    return {
      loading,
      farms,
    };
  }, [loading, farms]);
};
