import { createSlice } from '@reduxjs/toolkit';
import { TokensData } from 'core/types/subgraph';
import { getRhoTokensIndicativeSupplyRate } from './thunks/getRhoTokensIndicativeSupplyRate';
import { getRhoTokensTotalSupply } from './thunks/getRhoTokensTotalSupply';
import { getFarmsLiquidity } from './thunks/getFarmsLiquidity';
import { getFlurryStaked } from './thunks/getFlurryStaked';
import { getStrategiesSupplyRates } from './thunks/getStrategiesSupplyRates';

export type SubgraphState = {
  rhoTokens: {
    indicativeSupplyRates: TokensData;
    totalSupplies: TokensData;
  };
  farms: {
    liquidities: TokensData;
  };
  flurry: {
    staked: TokensData;
  };
  strategies: {
    supplyRate: TokensData;
  };
  loadingStates: {
    rhoTokens: {
      indicativeSupplyRates: boolean;
      totalSupplies: boolean;
    };
    farms: {
      liquidities: boolean;
    };
    flurry: {
      staked: boolean;
    };
    strategies: {
      supplyRate: boolean;
    };
  };
  errors: {
    rhoTokens: {
      indicativeSupplyRates: string | undefined;
      totalSupplies: string | undefined;
    };
    farms: {
      liquidities: string | undefined;
    };
    flurry: {
      staked: string | undefined;
    };
    strategies: {
      supplyRate: string | undefined;
    };
  };
};

const initialState: SubgraphState = {
  rhoTokens: {
    indicativeSupplyRates: {},
    totalSupplies: {},
  },
  farms: {
    liquidities: {},
  },
  flurry: {
    staked: {},
  },
  strategies: {
    supplyRate: {},
  },
  loadingStates: {
    rhoTokens: {
      indicativeSupplyRates: false,
      totalSupplies: false,
    },
    farms: {
      liquidities: false,
    },
    flurry: {
      staked: false,
    },
    strategies: {
      supplyRate: false,
    },
  },
  errors: {
    rhoTokens: {
      indicativeSupplyRates: undefined,
      totalSupplies: undefined,
    },
    farms: {
      liquidities: undefined,
    },
    flurry: {
      staked: undefined,
    },
    strategies: {
      supplyRate: undefined,
    },
  },
};

const subgraphSlice = createSlice({
  name: 'subgraph',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getRhoTokensTotalSupply.pending, (state) => {
        state.loadingStates.rhoTokens.totalSupplies = true;
      })
      .addCase(getRhoTokensTotalSupply.fulfilled, (state, action) => {
        state.loadingStates.rhoTokens.totalSupplies = false;
        state.errors.rhoTokens.totalSupplies = undefined;
        state.rhoTokens.totalSupplies = action.payload;
      })
      .addCase(getRhoTokensTotalSupply.rejected, (state, action) => {
        state.loadingStates.rhoTokens.totalSupplies = false;
        state.errors.rhoTokens.totalSupplies = action.error.message;
      })
      .addCase(getRhoTokensIndicativeSupplyRate.pending, (state) => {
        state.loadingStates.rhoTokens.indicativeSupplyRates = true;
      })
      .addCase(getRhoTokensIndicativeSupplyRate.fulfilled, (state, action) => {
        state.loadingStates.rhoTokens.indicativeSupplyRates = false;
        state.errors.rhoTokens.indicativeSupplyRates = undefined;
        state.rhoTokens.indicativeSupplyRates = action.payload;
      })
      .addCase(getRhoTokensIndicativeSupplyRate.rejected, (state, action) => {
        state.loadingStates.rhoTokens.indicativeSupplyRates = false;
        state.errors.rhoTokens.indicativeSupplyRates = action.error.message;
      })
      .addCase(getFarmsLiquidity.pending, (state) => {
        state.loadingStates.farms.liquidities = true;
      })
      .addCase(getFarmsLiquidity.fulfilled, (state, action) => {
        state.loadingStates.farms.liquidities = false;
        state.errors.farms.liquidities = undefined;
        state.farms.liquidities = action.payload;
      })
      .addCase(getFarmsLiquidity.rejected, (state, action) => {
        state.loadingStates.farms.liquidities = false;
        state.errors.farms.liquidities = action.error.message;
      })
      .addCase(getFlurryStaked.pending, (state) => {
        state.loadingStates.flurry.staked = true;
      })
      .addCase(getFlurryStaked.fulfilled, (state, action) => {
        state.loadingStates.flurry.staked = false;
        state.errors.flurry.staked = undefined;
        state.flurry.staked = action.payload;
      })
      .addCase(getFlurryStaked.rejected, (state, action) => {
        state.loadingStates.flurry.staked = false;
        state.errors.flurry.staked = action.error.message;
      })
      .addCase(getStrategiesSupplyRates.pending, (state) => {
        state.loadingStates.strategies.supplyRate = true;
      })
      .addCase(getStrategiesSupplyRates.fulfilled, (state, action) => {
        state.loadingStates.strategies.supplyRate = false;
        state.errors.strategies.supplyRate = undefined;
        state.strategies.supplyRate = action.payload;
      })
      .addCase(getStrategiesSupplyRates.rejected, (state, action) => {
        state.loadingStates.strategies.supplyRate = false;
        state.errors.strategies.supplyRate = action.error.message;
      });
  },
});

export default subgraphSlice.reducer;
