import { v4 as uuidv4 } from 'uuid';
import { sendTransaction, waitReceipt } from 'utils';
import { ethers } from 'ethers';
import farmAbi from './farmAbi.json';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import BigNumber from 'bignumber.js';
import { Contract, ProviderAccessor } from 'core/types';
import { Action, Dispatch } from '@reduxjs/toolkit';

const approveCompensation = async (payload: {
  lpAddress: string;
  lpDecimals: number;
  label: string;
  compensater: Contract | null;
  currentProvider: ProviderAccessor | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<Action<any>>;
}): Promise<void> => {
  const transactionId = uuidv4();

  const { compensater, currentProvider, lpAddress, label, dispatch } = payload;

  if (!compensater || !currentProvider) return;

  try {
    if (!compensater) {
      throw Error('Could not find Compensater contract');
    }

    dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'compensation',
        type: 'approval',
        state: 'pending',
        payload: {
          amount: new BigNumber(0),
          label,
        },
      })
    );

    const farm = new ethers.Contract(
      lpAddress,
      farmAbi,
      currentProvider.web3provider.getSigner(0)
    );

    const tx = await sendTransaction(
      farm.approve,
      compensater.address,
      ethers.constants.MaxUint256
    );

    dispatch(
      updateTransactionHash({
        id: transactionId,
        hash: tx.hash,
      })
    );

    await waitReceipt(tx);
    dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'success',
      })
    );
  } catch (error) {
    console.error(error.toString());
    dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'error',
      })
    );
  }
};

export default approveCompensation;
