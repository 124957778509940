import { createAsyncThunk } from '@reduxjs/toolkit';
import { Contract, UserBalance } from 'core/types';
import { BigNumber, EventFilter } from 'ethers';
import { updateBalances } from './updateBalances';
import { BN } from 'utils';

export const initRhoSupply = createAsyncThunk<
  UserBalance[],
  {
    rhoTokens: Contract[];
  }
>('balances/initRhoSupply', async (payload, thunkAPI) => {
  const rhoBalances: UserBalance[] = [];
  try {
    const { rhoTokens } = payload;

    if (rhoTokens.length === 0) {
      thunkAPI.rejectWithValue('No rhoToken Contract');
      throw new Error('No rhoToken Contract');
    }

    for (const rhoToken of rhoTokens) {
      const decimals = rhoToken.decimals;
      const currencyTotalSupply = `${rhoToken.key}Supply`;

      // event listener
      const eventFilter: EventFilter =
        rhoToken.contract.filters.RhoTokenSupplyChanged();
      rhoToken.contract.on(eventFilter, async (totalSupply: BigNumber) => {
        thunkAPI.dispatch(
          updateBalances({
            currency: currencyTotalSupply,
            amount: BN(totalSupply, decimals),
          })
        );
      });

      // init value
      rhoBalances.push({
        currency: currencyTotalSupply,
        amount: BN(await rhoToken.contract.totalSupply(), decimals),
      });
    }
  } catch (e) {
    thunkAPI.rejectWithValue(
      'Could not initialize Flurry Staking user balance'
    );
    console.error(e);
  } finally {
    return rhoBalances;
  }
});
