import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { DateRange } from 'core/types';
import { TokensData } from 'core/types/subgraph';
import { getTime, startOfHour } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { getPeriodTimestamps } from 'utils';
import { getStrategiesSupplyRates } from '../thunks/getStrategiesSupplyRates';

export const useStrategiesTotalSupply = (
  period: DateRange
): {
  data: TokensData;
  isLoading: boolean;
  error?: string;
} => {
  const strategiesData = useAppSelector(
    (state) => state.subgraph.strategies.supplyRate
  );
  const isLoading = useAppSelector(
    (state) => state.subgraph.loadingStates.strategies.supplyRate
  );
  const error = useAppSelector(
    (state) => state.subgraph.errors.strategies.supplyRate
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timestamps: number[] = [];
    let currentTs = getTime(startOfHour(Date.now()));
    if (Date.now() - currentTs <= 300000) {
      // If latest hour is less than 5min ago, then we take the previous hour
      // This is in case the graph has not synchronized with latest data
    }
    currentTs = currentTs - 3.6e6;

    switch (period) {
      case '24h':
        timestamps = getPeriodTimestamps(currentTs, 'hour', 24, true);
        break;
      case '7day':
        timestamps = getPeriodTimestamps(currentTs, 'day', 7, true);
        break;
      case '30day':
        timestamps = getPeriodTimestamps(currentTs, 'day', 30, true);
        break;
      case '90day':
        timestamps = getPeriodTimestamps(currentTs, 'day', 90, true);
        break;
    }

    dispatch(getStrategiesSupplyRates({ chains: [1, 56, 137], timestamps }));
  }, [dispatch, period]);

  return useMemo(() => {
    return {
      data: strategiesData,
      isLoading,
      error,
    };
  }, [error, isLoading, strategiesData]);
};
