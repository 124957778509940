/* eslint-disable @typescript-eslint/no-explicit-any */
import { BigNumber, ethers } from 'ethers';
import {
  ChainId,
  CurrencyAmount,
  ETHER,
  TokenAmount,
  TradeOptions,
  TradeOptionsDeadline,
  TradeType,
  validateAndParseAddress,
} from '@dynamic-amm/sdk';
import {
  Aggregator,
  encodeFeeConfig,
  encodeSimpleModeData,
  encodeSwapExecutor,
  isEncodeUniswapCallback,
} from './aggregator';
import invariant from 'tiny-invariant';
import AGGREGATOR_EXECUTOR_ABI from './aggregation-executor.json';

const ETHER_ADDRESS = '0xEeeeeEeeeEeEeeEeEeEeeEEEeeeeEeeeeeeeEEeE';
const ZERO_HEX = '0x0';

const AGGREGATION_EXECUTOR: { [chainId in ChainId]?: string } = {
  [ChainId.BSCMAINNET]: '0xd12bcdfb9a39be79da3bdf02557efdcd5ca59e77',
  [ChainId.MATIC]: '0xd12bcdfb9a39be79da3bdf02557efdcd5ca59e77',
  [ChainId.AVAXMAINNET]: '0xd12bcdfb9a39be79da3bdf02557efdcd5ca59e77',
  [ChainId.MAINNET]: '0xd12bcdfb9a39be79da3bdf02557efdcd5ca59e77',
  [ChainId.FANTOM]: '0xd12bcdfb9a39be79da3bdf02557efdcd5ca59e77',
  [ChainId.CRONOS]: '0xd12bcdfb9a39be79da3bdf02557efdcd5ca59e77',
};

export interface SwapV2Parameters {
  methodNames: string[];
  args: Array<string | Array<string | string[]>>;
  value: string;
}

interface FeeConfig {
  chargeFeeBy: 'tokenIn' | 'tokenOut';
  feeReceiver: string;
  isInBps: boolean;
  feeAmount: string;
}

function toHex(currencyAmount: CurrencyAmount) {
  return `0x${currencyAmount.raw.toString(16)}`;
}

function numberToHex(num: number) {
  return `0x${num.toString(16)}`;
}

function toSwapAddress(currencyAmount: CurrencyAmount) {
  if (currencyAmount.currency === ETHER) {
    return ETHER_ADDRESS;
  }
  return currencyAmount instanceof TokenAmount
    ? currencyAmount.token.address
    : '';
}

function getSigner(
  library: ethers.providers.Web3Provider,
  account: string
): ethers.providers.JsonRpcSigner {
  return library.getSigner(account).connectUnchecked();
}

function getProviderOrSigner(
  library: ethers.providers.Web3Provider,
  account?: string
): ethers.providers.Web3Provider | ethers.providers.JsonRpcSigner {
  return account ? getSigner(library, account) : library;
}

function getContract(
  address: string,
  ABI: any,
  library: ethers.providers.Web3Provider,
  account?: string
): ethers.Contract {
  return new ethers.Contract(
    address,
    ABI,
    getProviderOrSigner(library, account) as any
  );
}

export function getAggregationExecutorAddress(chainId: ChainId): string {
  return AGGREGATION_EXECUTOR[chainId] || '';
}

function getAggregationExecutorContract(
  chainId: ChainId,
  library: ethers.providers.Web3Provider,
  account?: string
): ethers.Contract {
  return getContract(
    getAggregationExecutorAddress(chainId),
    AGGREGATOR_EXECUTOR_ABI,
    library,
    account
  );
}

export function getSwapCallParameters(
  trade: Aggregator,
  options: TradeOptions | TradeOptionsDeadline,
  chainId: ChainId,
  library: ethers.providers.Web3Provider
): SwapV2Parameters {
  const etherIn = trade.inputAmount.currency === ETHER;
  const etherOut = trade.outputAmount.currency === ETHER;
  // the router does not support both ether in and out
  invariant(!(etherIn && etherOut), 'ETHER_IN_OUT');
  invariant(!('ttl' in options) || options.ttl > 0, 'TTL');

  const to: string = validateAndParseAddress(options.recipient);
  const tokenIn: string = toSwapAddress(trade.inputAmount);
  const tokenOut: string = toSwapAddress(trade.outputAmount);
  const amountIn: string = toHex(
    trade.maximumAmountIn(options.allowedSlippage)
  );
  const amountOut: string = toHex(
    trade.minimumAmountOut(options.allowedSlippage)
  );
  const deadline =
    'ttl' in options
      ? `0x${(Math.floor(new Date().getTime() / 1000) + options.ttl).toString(
          16
        )}`
      : `0x${options.deadline.toString(16)}`;

  const feeConfig: FeeConfig | undefined = undefined as any;

  const destTokenFeeData =
    feeConfig && feeConfig.chargeFeeBy === 'tokenOut'
      ? encodeFeeConfig({
          feeReceiver: feeConfig.feeReceiver,
          isInBps: feeConfig.isInBps,
          feeAmount: feeConfig.feeAmount,
        })
      : '0x';
  let methodNames: string[] = [];
  let args: Array<string | Array<string | string[]>> = [];
  let value: string = ZERO_HEX;

  switch (trade.tradeType) {
    case TradeType.EXACT_INPUT: {
      methodNames = ['swap'];
      if (!tokenIn || !tokenOut || !amountIn || !amountOut) {
        break;
      }
      const aggregationExecutorAddress = getAggregationExecutorAddress(chainId);
      if (!aggregationExecutorAddress) {
        break;
      }
      const aggregationExecutorContract = getAggregationExecutorContract(
        chainId,
        library
      );
      const src: { [p: string]: BigNumber } = {};

      // check chain ID first, to load the correct dex config
      const isEncodeUniswap = isEncodeUniswapCallback(chainId);
      if (feeConfig && feeConfig.chargeFeeBy === 'tokenIn') {
        const { feeReceiver, isInBps, feeAmount } = feeConfig;
        src[feeReceiver] = isInBps
          ? BigNumber.from(amountIn).mul(feeAmount).div('100')
          : BigNumber.from(feeAmount);
      }
      // Use swap simple mode when tokenIn is not ETH and every firstPool is encoded by uniswap.
      let isUseSwapSimpleMode = !etherIn;
      if (isUseSwapSimpleMode) {
        // if not ether to other
        for (let i = 0; i < trade.swaps.length; i++) {
          // for each route
          const sequence = trade.swaps[i];
          // the first pool in each route
          const firstPool = sequence[0];
          // is the dex type out of {1,2,4,6}?
          if (!isEncodeUniswap(firstPool)) {
            // if yes then break and use normal mode
            isUseSwapSimpleMode = false;
            break;
          }
        }
      }
      const getSwapSimpleModeArgs = () => {
        // always use simple mode as BSC dex are all type 0
        const firstPools: string[] = [];
        const firstSwapAmounts: string[] = [];

        trade.swaps.forEach((sequence) => {
          // for each route
          for (let i = 0; i < sequence.length; i++) {
            // for each pool
            if (i === 0) {
              // if the first pool
              const firstPool = sequence[0];
              // record pool address and swap amount
              firstPools.push(firstPool.pool);
              firstSwapAmounts.push(firstPool.swapAmount);

              // if type 0 (yes always)
              if (isEncodeUniswap(firstPool)) {
                firstPool.collectAmount = '0';
              }
              if (sequence.length === 1 && isEncodeUniswap(firstPool)) {
                firstPool.recipient =
                  etherOut || feeConfig?.chargeFeeBy === 'tokenOut'
                    ? aggregationExecutorAddress
                    : to;
              }
            } else {
              const A = sequence[i - 1]; //!!!!!!!!!!!!!!!!!!!!!!!!!!
              const B = sequence[i];
              if (isEncodeUniswap(A) && isEncodeUniswap(B)) {
                A.recipient = B.pool;
                B.collectAmount = '0';
              } else if (isEncodeUniswap(B)) {
                B.collectAmount = '1';
              } else if (isEncodeUniswap(A)) {
                A.recipient = aggregationExecutorAddress;
              }
              if (i === sequence.length - 1 && isEncodeUniswap(B)) {
                B.recipient =
                  etherOut || feeConfig?.chargeFeeBy === 'tokenOut'
                    ? aggregationExecutorAddress
                    : to;
              }
            }
          }
        });
        const swapSequences = encodeSwapExecutor(trade.swaps, chainId);
        const sumSrcAmounts = Object.values(src).reduce(
          (sum, v) => sum.add(v),
          BigNumber.from('0')
        );
        const sumFirstSwapAmounts = firstSwapAmounts.reduce(
          (sum, v) => sum.add(v),
          BigNumber.from('0')
        );
        const amount = sumSrcAmounts.add(sumFirstSwapAmounts).toString();
        const swapDesc = [
          tokenIn,
          tokenOut,
          Object.keys(src), // srcReceivers
          Object.values(src).map((a) => a.toString()), // srcAmounts
          to,
          amount,
          amountOut,
          numberToHex(32),
          destTokenFeeData,
        ];
        const executorDataForSwapSimpleMode = encodeSimpleModeData({
          firstPools,
          firstSwapAmounts,
          swapSequences,
          deadline,
          destTokenFeeData,
        });
        args = [
          aggregationExecutorAddress,
          swapDesc,
          executorDataForSwapSimpleMode,
        ];
      };
      const getSwapNormalModeArgs = () => {
        trade.swaps.forEach((sequence) => {
          for (let i = 0; i < sequence.length; i++) {
            if (i === 0) {
              const firstPool = sequence[0];
              if (etherIn) {
                if (isEncodeUniswap(firstPool)) {
                  firstPool.collectAmount = firstPool.swapAmount;
                }
              } else {
                if (isEncodeUniswap(firstPool)) {
                  firstPool.collectAmount = firstPool.swapAmount;
                }
                src[aggregationExecutorAddress] = BigNumber.from(
                  firstPool.swapAmount
                ).add(src[aggregationExecutorAddress] ?? '0');
              }
              if (sequence.length === 1 && isEncodeUniswap(firstPool)) {
                firstPool.recipient =
                  etherOut || feeConfig?.chargeFeeBy === 'tokenOut'
                    ? aggregationExecutorAddress
                    : to;
              }
            } else {
              const A = sequence[i - 1];
              const B = sequence[i];
              if (isEncodeUniswap(A) && isEncodeUniswap(B)) {
                A.recipient = B.pool;
                B.collectAmount = '0';
              } else if (isEncodeUniswap(B)) {
                B.collectAmount = '1';
              } else if (isEncodeUniswap(A)) {
                A.recipient = aggregationExecutorAddress;
              }
              if (i === sequence.length - 1 && isEncodeUniswap(B)) {
                B.recipient =
                  etherOut || feeConfig?.chargeFeeBy === 'tokenOut'
                    ? aggregationExecutorAddress
                    : to;
              }
            }
          }
        });
        const swapSequences = encodeSwapExecutor(trade.swaps, chainId);
        const swapDesc = [
          tokenIn,
          tokenOut,
          Object.keys(src), // srcReceivers
          Object.values(src).map((amount) => amount.toString()), // srcAmounts
          to,
          amountIn,
          amountOut,
          etherIn ? numberToHex(0) : numberToHex(4),
          destTokenFeeData,
        ];
        let executorData =
          aggregationExecutorContract.interface.encodeFunctionData(
            'nameDoesntMatter',
            [
              [
                swapSequences,
                tokenIn,
                tokenOut,
                amountOut,
                to,
                deadline,
                destTokenFeeData,
              ],
            ]
          );
        // Remove method id (slice 10).
        executorData = '0x' + executorData.slice(10);
        args = [aggregationExecutorAddress, swapDesc, executorData];
      };
      if (isUseSwapSimpleMode) {
        getSwapSimpleModeArgs();
      } else {
        getSwapNormalModeArgs();
      }
      value = etherIn ? amountIn : ZERO_HEX;
      break;
    }
  }

  return {
    methodNames,
    args,
    value,
  };
}
