/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Contract, UserBalance } from 'core/types';
import { BigNumber, EventFilter } from 'ethers';
import { BN } from 'utils';
import { updateBalances } from './updateBalances';

export const initFlurryStakingSupply = createAsyncThunk<
  UserBalance | null,
  {
    flurryStaking: Contract | null;
    decimals: number | undefined;
  }
>('balances/initFlurryStakingSupply', async (payload, thunkAPI) => {
  let totalSupply: UserBalance | null = null;
  try {
    const { flurryStaking, decimals } = payload;

    if (!flurryStaking) {
      thunkAPI.rejectWithValue('No Flurry Staking Contract');
    }
    if (!flurryStaking!.contract.filters.TotalStakesChanged) {
      thunkAPI.rejectWithValue('Event not found on contract');
    }

    const currency = 'FlurryStakingSupply';

    // event listener
    const eventFilter: EventFilter =
      flurryStaking!.contract.filters.TotalStakesChanged();

    flurryStaking!.contract.on(eventFilter, (totalStakes: BigNumber) => {
      thunkAPI.dispatch(
        updateBalances({
          currency: currency,
          amount: BN(totalStakes, decimals),
        })
      );
    });

    // init value
    totalSupply = {
      currency: currency,
      amount: BN(await flurryStaking!.contract.totalStakes(), decimals),
    };
  } catch (e) {
    thunkAPI.rejectWithValue(
      'Could not initialize Flurry Staking user balance'
    );
    console.error(e);
  } finally {
    return totalSupply;
  }
});
