import { FaInfoCircle } from 'react-icons/fa';
import styled from 'styled-components/macro';

const tableBorderRadius = '7px';

export const StatTableWrapper = styled.table`
  height: 100%;
  border-spacing: 0;
  border-collapse: separate !important;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0.04em 0.1em,
    rgba(0, 0, 0, 0.08) 0px 0.1em 0.2em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: ${tableBorderRadius};
`;

export const StatTableHead = styled.thead`
  border-radius: ${tableBorderRadius} ${tableBorderRadius} 0 0;
  box-shadow: rgba(33, 35, 38, 0.384) 0px 10px 10px -10px;
`;

export const StatTableHeadElement = styled.th`
  text-align: center;
  cursor: default;
  font-size: 0.6rem;
  padding: 0.4rem 0.7rem;
  white-space: nowrap;
`;

export const StatTableBodyElement = styled.td`
  text-align: center;
  border-top: 1px solid #00000016;
  font-size: 0.65rem;
  padding: 0 0.5rem;
`;

export const InfoIcon = styled(FaInfoCircle)`
  padding-left: 0.2rem;
  font-size: 0.6rem;
  align-self: center;
`;
