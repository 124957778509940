import styled from 'styled-components/macro';
import { FaChevronUp } from 'react-icons/fa';
import { down } from 'styled-breakpoints';

export const AccordionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  padding: 1rem 1.3rem 1rem 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  ${down('xs')} {
    padding: 1rem 0.8rem 1rem 0.8rem;
  }
`;

export const Button = styled.button`
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  background: none;
  cursor: pointer;
  outline: none;
  border: none;
`;

export const Chevron = styled(FaChevronUp)<{ $isup: boolean }>`
  margin-left: auto;
  transform: ${(props) => (!props.$isup ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
`;

export const InternalWrapper = styled.div<{ open?: boolean }>`
  width: 100%;
  max-height: ${(props) => (props.open ? '700px' : '0')};
  transition: all 0.3s ease-in-out;
  overflow-y: hidden;
  overflow-x: visible;
`;
