import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  width: 100%;
`;

export const GraphRow = styled.div`
  width: calc(100% - 4rem);
  padding: 0 2rem 1rem 2rem;
  display: flex;
  justify-content: space-between;
  ${down('sm')} {
    flex-direction: column;
  }
`;

export const GraphWrapper = styled.div`
  display: flex;
  flex-flow: column;
  border-radius: 10px;
  border: 1px solid #352b8d16;
  width: calc(50% - 1.5rem);
  ${down('sm')} {
    margin-bottom: 1rem;
    width: calc(100% - 1.5rem);
  }
  height: 400px;
  padding: 1rem 0.5rem;
`;

export const GraphTitle = styled.h2`
  font-size: 0.8rem;
  margin: 0;
  padding: 0 0 1rem 0.5rem;
`;
