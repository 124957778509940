import React, { useRef, useState, useEffect } from 'react';
import FlurryLogo from 'assets/images/logo_with_text.png';
import * as S from './Navbar.styles';
import {
  ClaimRewardsPopup,
  UserModal,
  Spacer,
  useOnClickOutside,
  DisconnectButton,
  UserAddressButton,
  SinglePopupNotification,
  NetworkSwitcherDropdown,
  NotificationPopup,
} from 'components';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { shortenStringInMiddle } from '../../utils/strings';
import FlurryInfos from './FlurryInfos';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import AuthButton from './AuthButton';
import TransactionsButton from './TransactionsButton';
import { Transaction } from 'core/types';
import { selectLatestPendingTransaction } from 'core/store/transactions/selectors';
import { sendGAEvent } from 'utils';

const Navbar: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const mobileMenuRef = useRef(null);
  useOnClickOutside(mobileMenuRef, () => setShowMobileMenu(false));

  const latestTransaction = useAppSelector((state) =>
    selectLatestPendingTransaction(state)
  );

  const [singleNotificationShowing, setSingleNotificationShowing] =
    useState(false);
  const [lastShownNotifId, setLastShownNotifId] = useState<string | undefined>(
    undefined
  );
  const [currentNotif, setCurrentNotif] = useState<Transaction | undefined>(
    undefined
  );

  useEffect(() => {
    if (latestTransaction && latestTransaction.id !== lastShownNotifId) {
      setSingleNotificationShowing(true);
      setCurrentNotif(latestTransaction);
      setLastShownNotifId(latestTransaction.id);
    }
  }, [
    currentNotif,
    lastShownNotifId,
    latestTransaction,
    singleNotificationShowing,
  ]);

  const dispatch = useAppDispatch();

  return (
    <>
      <S.Nav>
        <S.A href="https://www.flurry.finance">
          <S.Logo src={FlurryLogo} alt="Flurry logo" />
        </S.A>
        <S.NavbarSpacer />
        <S.NavMenu>
          <S.NavLink to="/" exact={true}>
            Earn
          </S.NavLink>
          <S.NavLink to="/snowball">Snowball</S.NavLink>
          <S.NavLink to="/farms">Farms</S.NavLink>
          <S.NavLink to="/bridge">Bridge</S.NavLink>
          <S.NavLink to="/statistics">Stats</S.NavLink>
        </S.NavMenu>
        <S.RightNav>
          <FlurryInfos />
          <Spacer axis="horizontal" size={40} />
          <AuthButton />
        </S.RightNav>

        <S.MobileFlurryInfoWrapper>
          <FlurryInfos />
        </S.MobileFlurryInfoWrapper>
        <S.Bars onClick={() => setShowMobileMenu(true)} />
        <S.MobileMenu show={showMobileMenu} ref={mobileMenuRef}>
          <S.TopMenu>
            <S.NavBtn>
              <S.Btn
                onClick={() => {
                  if (user === undefined || user.address === undefined) {
                    sendGAEvent('Navbar', 'Connect');
                    dispatch(connectToProvider());
                    setShowMobileMenu(false);
                  }
                }}
              >
                {user && user.address
                  ? shortenStringInMiddle(user.address)
                  : 'Connect Wallet'}
              </S.Btn>
            </S.NavBtn>
            <S.CloseMenu onClick={() => setShowMobileMenu(false)} />
          </S.TopMenu>
          <S.MenuWrapper>
            <S.MobileMenuItems>
              <S.NavLink to="/" exact={true}>
                Earn
              </S.NavLink>
              <S.NavLink to="/snowball">Snowball</S.NavLink>
              <S.NavLink to="/farms">Farms</S.NavLink>
              <S.NavLink to="/bridge">Bridge</S.NavLink>
              <S.NavLink to="/statistics">Stats</S.NavLink>
            </S.MobileMenuItems>
          </S.MenuWrapper>
          <S.BottomMenu>
            <S.UserBtnWrapper>
              <TransactionsButton onClick={() => setShowMobileMenu(false)} />
            </S.UserBtnWrapper>
            <S.UserBtnWrapper>
              <UserAddressButton />
            </S.UserBtnWrapper>
            <Spacer axis="vertical" size={15} />
            <NetworkSwitcherDropdown />
            <DisconnectButton />
          </S.BottomMenu>
        </S.MobileMenu>
      </S.Nav>
      <ClaimRewardsPopup />
      <UserModal />
      {singleNotificationShowing && (
        <SinglePopupNotification
          hideNotif={() => {
            setCurrentNotif(undefined);
            setSingleNotificationShowing(false);
          }}
          transactionId={currentNotif?.id}
          notifLifetimeS={10}
        />
      )}
      <NotificationPopup />
    </>
  );
};

export default Navbar;
