import React, { useRef, useState, useMemo } from 'react';
import * as S from './NetworkDropdown.styles';
import { getBridgeableNetworks, getNetworkInfoFromChainId } from 'utils';
import FlurryIconOnly from 'assets/images/logos/flurry_icon_only.png';
import { Spacer, useOnClickOutside } from 'components';
import { NetworkInfo } from 'core/types';

export type NetworkDropdownProps = {
  selectedChain?: number;
  onNetworkChanged: (n: NetworkInfo) => void;
  hiddenChains?: number[];
  disabled?: boolean;
};

const NetworkDropdown: React.FC<NetworkDropdownProps> = ({
  selectedChain,
  onNetworkChanged,
  hiddenChains = [],
  disabled,
}) => {
  const [opened, setOpened] = useState(false);
  const preferredNetworks = getBridgeableNetworks();

  const networkList = useMemo(() => {
    return preferredNetworks.filter(
      (n) =>
        hiddenChains.indexOf(n.chainId) === -1 && n.chainId !== selectedChain
    );
  }, [hiddenChains, preferredNetworks, selectedChain]);

  const selectedNetwork = useMemo(() => {
    return selectedChain ? getNetworkInfoFromChainId(selectedChain) : undefined;
  }, [selectedChain]);

  const isBridgeable = useMemo(
    () =>
      preferredNetworks.findIndex((e) => e.chainId === selectedChain) !== -1,
    [preferredNetworks, selectedChain]
  );

  const ref = useRef(null);
  useOnClickOutside(ref, () => setOpened(false));

  if (!selectedNetwork || !preferredNetworks || preferredNetworks.length <= 0) {
    return <S.NoNetworkPlaceholder></S.NoNetworkPlaceholder>;
  }

  return (
    <S.NetworkDropdownWrapper ref={ref}>
      <S.SelectedOption
        opened={opened}
        onClick={() => setOpened(!opened)}
        warning={!isBridgeable}
      >
        <S.NetworkImg
          src={selectedNetwork?.logo ?? FlurryIconOnly}
          alt={`${selectedNetwork?.label ?? 'Network'} logo`}
        />
        <Spacer axis="horizontal" size={5} />
        <S.NetworkLabel>
          {selectedNetwork?.label ?? 'Select Network...'}
        </S.NetworkLabel>
        <S.Chevron $isup={opened} />
      </S.SelectedOption>
      {opened && (
        <S.Options>
          {networkList.length > 0 ? (
            networkList.map((o) => {
              return (
                <S.BodyOption
                  key={o.chainId.toString()}
                  disabled={disabled}
                  onClick={() => {
                    if (!disabled) {
                      onNetworkChanged(o);
                      setOpened(false);
                    }
                  }}
                >
                  <S.NetworkImg
                    src={o.logo ?? FlurryIconOnly}
                    alt={`${o.label} logo`}
                  />
                  <Spacer axis="horizontal" size={5} />
                  {o.label}
                </S.BodyOption>
              );
            })
          ) : (
            <S.NoNetwork>No network available</S.NoNetwork>
          )}
        </S.Options>
      )}
      {!isBridgeable && (
        <S.ChainNotSupported>
          This network does not support the Bridge
        </S.ChainNotSupported>
      )}
    </S.NetworkDropdownWrapper>
  );
};

export default NetworkDropdown;
