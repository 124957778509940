import { FaChevronUp } from 'react-icons/fa';
import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const NetworkDropdownWrapper = styled.div`
  position: relative;
  padding-right: 0.5rem;
  min-width: 0;
  ${down('md')} {
    padding-right: 0;
    margin-bottom: 0.5rem;
  }
`;

export const NoNetwork = styled.div`
  width: calc(100% - 1rem);
  padding: 1.5rem 0.5rem;
  color: #707070;
  text-align: center;
  font-size: 0.6rem;
`;

export const Option = styled.button`
  cursor: pointer;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
  font-size: 0.85rem;
`;

export const BodyOption = styled(Option)`
  background-color: white;
  transition: background-color 0.2s linear;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  white-space: nowrap;
  &:disabled {
    opacity: 0.5;
  }
  &:hover {
    background-color: #f7f6fb;
  }
`;

export const SelectedOption = styled(Option)<{
  opened: boolean;
  warning: boolean;
}>`
  border-radius: 0.5rem;
  background-color: #f7f6fb;
  transition: border 0.2s linear;
  box-sizing: border-box;
  border: ${(props) =>
    props.warning ? '2px solid #f0b70e' : '2px solid transparent'};

  &:hover {
    border: ${(props) =>
      props.warning ? '2px solid #fcc624' : '2px solid #efedf7'};
  }
`;
export const Chevron = styled(FaChevronUp)<{ $isup: boolean }>`
  margin-left: auto;
  transform: ${(props) => (!props.$isup ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
  justify-self: flex-end;
`;

export const Options = styled.div`
  z-index: 5;
  position: absolute;
  background-color: white;
  width: calc(100% - 1.5rem);
  top: calc(3.5rem);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  border-radius: 0.5rem;
  max-height: 6rem;
  overflow: auto;

  ${down('md')} {
    width: calc(100% - 1rem);
    top: calc(2.8rem);
  }
`;

export const Label = styled.div`
  font-size: 0.6rem;
  font-weight: bold;
  padding: 0.2rem 0.2rem 0.3rem 0.2rem;
  color: #575757;
`;

export const NetworkImg = styled.img`
  width: 25px;
  height: auto;
`;
export const NetworkLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const NoNetworkPlaceholder = styled.div`
  width: calc(100% - 0.5rem);
  background-color: black;
  height: 100%;
`;

export const ChainNotSupported = styled.p`
  margin: 0;
  padding: 0.2rem 0 0 0.2rem;
  text-align: center;
  color: red;
  font-size: 0.5rem;
`;
