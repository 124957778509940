import styled from 'styled-components/macro';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div<{ disabled?: boolean }>`
  position: relative;
  display: grid;
  grid-template-columns: 1fr 0.2fr 0.35fr 0.002fr;
  column-gap: 0.7rem;
  width: 100%;
  border-radius: 3px;
  padding: 0rem 0rem;
  border: 1px solid #0000003b;
  font-size: 1.2rem;
  height: 57px;
  &:hover {
    border-color: ${(props) => (props.disabled ? '#0000003b' : '#a7dcff')};
  }
`;

export const Input = styled.input<{
  inputWidth: 'full' | 'noMax' | 'noCurrencies' | 'normal';
}>`
  grid-column: ${(props) => {
    if (props.inputWidth === 'noMax' || props.inputWidth === 'noCurrencies')
      return '1 / 3';
    else if (props.inputWidth === 'full') return '1 / 4';
    return '1 / 2';
  }};
  border: none;
  outline: none;
  width: calc(100% - 1.5rem);
  background-color: transparent;
  margin: 0;
  padding: 0.85rem 1rem 0.85rem 1rem;
  font-size: 1.2rem;
  &:focus {
    outline: none;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }
  ${down('xs')} {
    width: 100%;
    margin-left: 0.2rem;
    padding: 0.85rem 0.8rem 0.85rem 0.3rem;
  }
`;

export const MaxBtn = styled.button`
  border-radius: 10px;
  border: 1px solid #0000003b;
  font-size: 0.9rem;
  align-self: center;
  height: calc(100% - 0.8rem);
  background: white;
  cursor: pointer;
`;
