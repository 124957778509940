import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  padding: 1rem 2rem;
`;

export const NotificationWrapper = styled.div`
  z-index: 2;
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const NotificationBody = styled.div`
  a {
    color: blue;
    font-size: 0.6rem;
  }
  svg {
    margin-left: 2px;
    width: 8px;
    height: 8px;
  }
`;

export const NotificationText = styled.div`
  font-size: 0.8rem;
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: 0;
`;
export const SubTitle = styled.h2`
  opacity: 0.5;
  font-size: 0.8rem;
  font-weight: normal;
`;
