import React from 'react';
import * as S from './NotificationPanel.styles';

export interface NotificationProps {
  body: JSX.Element;
}

const CustomNotification: React.FC<NotificationProps> = ({ body }) => {
  return <S.NotificationWrapper>{body}</S.NotificationWrapper>;
};

export default CustomNotification;
