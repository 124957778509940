import styled from 'styled-components/macro';
import { IoMdClose } from 'react-icons/io';
import { FaInfoCircle } from 'react-icons/fa';
import { down, up } from 'styled-breakpoints';

export const Wrapper = styled.div`
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000050;
  font-size: 1rem;
  text-align: left;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
  background-color: white;
  border-radius: 15px;
  width: 620px;
  padding: 1.2rem 1rem;
  max-height: 85vh;
  overflow: auto;
  ${down('sm')} {
    width: calc(100% - 4rem);
  }
  ${up('xl')} {
    width: 670px;
  }
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const CloseBtn = styled(IoMdClose)`
  opacity: 0.3;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const Title = styled.h4`
  margin: 0;
  margin-left: 10px;
  padding: 0;
  line-height: 1.8rem;
  text-align: center;
`;

export const Body = styled.div`
  width: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
  padding: 0.2rem 0.5rem;
`;

export const APRRow = styled.div`
  padding: 0.2rem 0.5rem;
  display: grid;
  grid-template-columns: 190px 120px 100px 70px 70px;
  column-gap: 10px;
  width: calc(100% - 1rem);
  align-items: center;
  justify-content: space-between;
  ${down('md')} {
    grid-template-columns: 1.5fr 1fr 0.7fr;
  }
`;

export const RowHeader = styled.h6<{ isDetail?: boolean }>`
  justify-self: end;
  margin: 0;
  padding: 0;
  ${down('md')} {
    display: ${(props) => (props.isDetail ? 'none' : 'block')};
  }
`;

export const RowLabel = styled.h6<{ isDetail?: boolean }>`
  font-weight: normal;
  margin: 0;
  padding: 0;
  ${down('md')} {
    display: ${(props) => (props.isDetail ? 'none' : 'block')};
  }
`;

export const RowAmount = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  font-size: 0.8rem;
  justify-content: space-between;
  justify-self: end;
`;

export const RowPercentage = styled.p<{
  isClickable?: boolean;
  isDetail?: boolean;
}>`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  justify-self: end;
  font-size: 0.8rem;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
  ${down('md')} {
    display: ${(props) => (props.isDetail ? 'none' : 'block')};
  }
`;

export const RowPercentageTotal = styled(RowPercentage)`
  font-size: 1rem;
  ${down('md')} {
    font-size: 0.8rem;
  }
`;
export const APRRowTotal = styled.div`
  padding: 0.5rem 0;
  border-radius: 5px;
  background-color: #a8d2fc14;
`;

export const APRTotalSubRow = styled(APRRow)<{ isClickable?: boolean }>`
  padding: 0 0.5rem;
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
`;

export const TotalLabel = styled(RowLabel)`
  font-weight: bold;
  font-size: 0.8rem;
  line-height: 1.4rem;
`;

export const TotalAmount = styled(RowPercentage)`
  font-weight: bold;
  font-size: 0.9rem;
  line-height: 1.4rem;
`;

export const APRDetailedTotalSubRow = styled(APRTotalSubRow)`
  opacity: 0.7;
  ${TotalLabel} {
    font-weight: normal;
    font-size: 0.65rem;
    line-height: 0.9rem;
  }
  ${TotalAmount} {
    font-weight: normal;
    font-size: 0.7rem;
    line-height: 0.9rem;
  }
`;

export const InfoIcon = styled(FaInfoCircle)`
  padding-left: 0.2rem;
  font-size: 0.6rem;
  align-self: center;
`;

export const APRDetailsContent = styled.div`
  background-color: #679ca514;
  margin: 1rem 2rem;
  padding: 1rem;
  color: #000000a7;
  line-height: 1rem;
  font-size: 0.75rem;
`;

export const NormalizedAPR = styled.p`
  color: #000000;
  margin: 0;
  padding: 0;
  font-size: 0.8rem;
  display: flex;
`;

export const APRDetailsTitle = styled.h5`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 1rem;
`;

export const TitleIndicativeApr = styled(APRDetailsTitle)``;
export const TitleInstantApr = styled.h6`
  margin: 0;
  padding-left: 2.1rem;
  padding-top: 0.2rem;
  opacity: 0.7;
  font-weight: normal;
`;

export const RebasingPercentage = styled(RowPercentage)`
  font-weight: normal;
  font-size: 0.7rem;
  line-height: 0.9rem;
  text-align: end;
`;
