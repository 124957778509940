import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContractsState } from 'core/store/contracts/contracts';
import { FarmContract, FarmLiquidity } from 'core/types';
import { Multicall } from 'ethereum-multicall';
import { calculateFarmsLiquidities } from 'utils';

export const updateFarmLiquidities = createAsyncThunk<
  FarmLiquidity[],
  {
    farms: FarmContract[];
    contracts: ContractsState;
    multicall: Multicall;
  }
>('farms/updateFarmLiquidities', async (payload) => {
  let result: FarmLiquidity[] = [];
  const { farms, contracts, multicall } = payload;
  if (!farms || farms.length === 0) {
    return [];
  }
  try {
    result = await calculateFarmsLiquidities({
      farms,
      flurryToken: contracts.flurryToken,
      lpStaking: contracts.lpStaking,
      multicall,
    });
  } catch (e) {
    console.error(e);
  } finally {
    return result;
  }
});
