import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import styled from 'styled-components/macro';

export const ModalHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid #0000001d;
`;

export const ModalTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem 1rem 1rem 0.8rem;
`;
export const ModalTitle = styled.h4`
  font-size: 0.9rem;
  font-weight: normal;
`;

export const ModalSettings = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem 0.5rem 0.6rem;
`;

export const Setting = styled.button<{ active: boolean }>`
  opacity: ${(props) => (props.active ? '1' : '0.5')};
  user-select: none;
  cursor: pointer;
  outline: 0;
  background-color: #1c009708;
  border-radius: 15px;
  border: none;
  color: #004caf;
  font-size: 0.7rem;
  padding: 0.3rem 0.6rem;
`;

export const ConfirmedSetting = styled(Setting)`
  color: #27ae60;
`;
export const ErrorSetting = styled(Setting)`
  color: red;
`;

export const NoTransaction = styled.div`
  user-select: none;
  width: 100%;
  line-height: 100px;
  text-align: center;
  margin: 0 auto;
  font-size: 0.75rem;
  color: #00000036;
`;

export const Notifications = styled.div`
  padding: 0.5rem 0.5rem 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  max-height: 300px;
  overflow-y: auto;
`;

// ----- SINGLE NOTIFICAION STYLE -------

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #1c009708;
  padding: 0.8rem 1rem 0.8rem 0.7rem;
  border-radius: 5px;
  margin-bottom: 0.5rem;
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
`;

export const SourceImg = styled.img`
  width: 1.5rem;
  padding-right: 0.5rem;
`;

export const NotificationMainTitle = styled.h5`
  display: flex;
  align-items: flex-end;
  font-size: 0.75rem;
`;

export const Time = styled.div`
  color: #696969;
  font-weight: normal;
  font-size: 0.6rem;
  padding: 0 0 0.05rem 0.2rem;
`;

export const NotificationDescription = styled.a<{ isLink: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  font-size: 0.65rem;
  text-decoration: ${(props) => (props.isLink ? 'underline' : 'none')};
  width: fit-content;
  color: ${(props) => (props.isLink ? '#0000bd' : '#000')};

  &:visited {
    color: ${(props) => (props.isLink ? '#0000bd' : '#000')};
  }
`;
export const DescriptionText = styled.div``;

export const LinkIcon = styled.span`
  font-size: 0.5rem;
  text-decoration: none !important;
  font-weight: bold;
`;

export const ConfirmedIcon = styled(IoMdCheckmarkCircleOutline)`
  color: #27ae60;
`;
export const ErrorIcon = styled(IoMdCloseCircleOutline)`
  color: red;
`;
