import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import URL from 'core/config/api';
import { EarnAnnouncement } from 'core/types';

export const getEarnAnnouncements = createAsyncThunk<EarnAnnouncement[], void>(
  'content/getEarnAnnouncements',
  async (_, thunkAPI) => {
    let result: EarnAnnouncement[] = [];
    try {
      const response = await axios.get(
        `${window._env_.FLURRY_API_URL}${URL.flurryApi.EARN_ANNOUNCEMENTS}`
      );
      result = response.data;
    } catch (e) {
      thunkAPI.rejectWithValue('Could not retrieve Earn page announcements');
    } finally {
      return result;
    }
  }
);
