import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContractsState } from 'core/store/contracts/contracts';
import { initFlurryStakingBalance } from './initFlurryStakingBalance';
import { initFlurryStakingSupply } from './initFlurryStakingSupply';
import { initRhoSupply } from './initRhoSupply';

export const initBalances = createAsyncThunk<void, { userAddress: string }>(
  'balances/initBalances',
  async (payload, thunkAPI) => {
    try {
      const { userAddress } = payload;
      const contracts = (thunkAPI.getState() as { contracts: ContractsState })
        .contracts;
      const flurryToken = contracts.flurryToken;
      const flurryDecimals = await flurryToken?.decimals;
      const flurryStaking = contracts.flurryStaking;
      const rhoTokens = contracts.rhoTokens;

      thunkAPI.dispatch(
        initFlurryStakingBalance({
          flurryStaking,
          decimals: flurryDecimals,
          userAddress,
        })
      );

      thunkAPI.dispatch(
        initFlurryStakingSupply({
          flurryStaking,
          decimals: flurryDecimals,
        })
      );

      thunkAPI.dispatch(
        initRhoSupply({
          rhoTokens,
        })
      );
    } catch (e) {
      thunkAPI.rejectWithValue(
        'Could not initialize Flurry Staking user balance'
      );
      console.error(e);
    } finally {
      return;
    }
  }
);
