import styled from 'styled-components';

export const Title = styled.h5`
  font-size: 0.8rem;
  display: flex;
  align-items: end;
  padding: 0 1rem;
`;

export const CommonBasesTokens = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  padding: 0 0.9rem;
`;

export const CommonBaseButton = styled.button<{ isActive?: boolean }>`
  cursor: pointer;
  margin: 0.2rem 0.2rem;
  padding-right: 0.5rem;
  border-radius: 30px;
  border: 5px;
  height: 30px;
  min-width: 50px;
  display: flex;
  align-items: center;
  opacity: ${(props) => (props.isActive ? 0.5 : 1)};
  background-color: #00000014;
  font-weight: ${(props) => (props.isActive ? 'bold' : 'none')};
  transition: background-color 0.2s linear;

  &:hover {
    background-color: #0000000a;
  }
`;

export const Icon = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 5px;
`;

export const SortButton = styled.button`
  border-radius: 30px;
  border: 5px;
  height: 20px;
  min-width: 50px;
  align-self: flex-end;
  border-color: grey;
  &:hover {
    cursor: pointer;
  }
`;
