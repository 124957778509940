import styled from 'styled-components/macro';
import { IoMdLock } from 'react-icons/io';
import { down } from 'styled-breakpoints';
import { HiOutlineExternalLink } from 'react-icons/hi';

export const Wrapper = styled.div`
  position: relative;
  z-index: 4;
  height: 40px;
  width: 40px;
  transform: scale(1);
  ${down('xs')} {
    transform: scale(0.7);
  }
`;

export const Icon = styled.div`
  cursor: pointer;
  height: 100%;
  transform: scale(1);
  transition: transform 0.2s linear;
  &:hover {
    transform: scale(1.1);
  }
`;
export const LockIcon = styled(IoMdLock)`
  color: #0b9bc7;
  font-size: 1rem;
`;
export const LockIconWrapper = styled.div`
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  left: 50%;
  transform: translateX(-0.5rem) translateY(-0.5rem);
  ${down('xs')} {
  }
`;

export const Tooltip = styled.div<{ show?: boolean }>`
  display: ${(props) => (props.show ? 'block' : 'none')};
  position: absolute;
  top: 50%;
  transform: translateX(-101%) translateY(-50%);
  font-size: 0.6rem;
  white-space: nowrap;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #00000016;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 3px;
  color: black;
  padding: 0.3rem 0.8rem;
  text-align: left;

  ${down('xs')} {
    font-size: 0.8rem;
    padding: 0.5rem 0.8rem;
  }
`;
export const BlockMsg = styled.p`
  padding: 0.1rem 0;
  margin: 0;
`;
export const ExternalLinkIcon = styled(HiOutlineExternalLink)`
  width: 11px;
  height: 11px;
  vertical-align: middle;
`;

export const DateMsg = styled.p`
  padding: 0;
  margin: 5px 0 0 0;
`;
