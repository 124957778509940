import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { NotificationMessage } from 'core/types';
import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { CONTENT_WS, ENTITIES } from '..';
import { getNotificationMessages } from '../thunks/getNotificationMessages';

export const useNotificationMessages = (): {
  data: NotificationMessage[];
  isLoading: boolean;
  error?: string;
} => {
  const [subscribed, setSubscribed] = useState(false);
  const notifications = useAppSelector(
    (state) => state.content.notificationMessages
  );
  const isLoading = useAppSelector(
    (state) => state.content.loadingStates.notificationMessages
  );
  const prevIsLoading = usePrevious(isLoading);
  const error = useAppSelector(
    (state) => state.content.errors.notificationMessages
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getNotificationMessages());
  }, [dispatch]);

  useEffect(() => {
    if (prevIsLoading && !isLoading && !subscribed) {
      dispatch({
        type: CONTENT_WS.SUBSCRIBE,
        message: ENTITIES.NOTIFICATION_MESSAGES,
      });
      setSubscribed(true);
    }
  }, [dispatch, isLoading, prevIsLoading, subscribed]);

  return useMemo(() => {
    const filtered = notifications.filter((n) => n.ShowMessage);
    return {
      data: filtered,
      isLoading,
      error,
    };
  }, [notifications, error, isLoading]);
};
