import { createSlice } from '@reduxjs/toolkit';

import { FarmContract, FarmLiquidity } from 'core/types';

import { initFarms } from './thunks/initFarms';
import { updateFarmLiquidities } from './thunks/updateFarmLiquidities';
import { updateFarmAprs } from './thunks/updateFarmAprs';
import { updateFarmSupplies } from './thunks/updateFarmSupplies';

export type FarmsState = {
  list: FarmContract[];
  liquidities: FarmLiquidity[];
  loading: boolean;
  error: string | null;
};

const initialState: FarmsState = {
  list: [],
  liquidities: [],
  loading: false,
  error: null,
};

const farmsSlice = createSlice({
  name: 'farms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(initFarms.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(initFarms.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload as [];
      })
      .addCase(initFarms.rejected, (state, action) => {
        if (action.payload === undefined) state.error = 'Connection failed';
        else state.error = null;
        state.loading = false;
      })
      .addCase(updateFarmLiquidities.fulfilled, (currentState, action) => {
        for (const newFarm of action.payload) {
          const idx = currentState.list.findIndex(
            (e) => e.key === newFarm.farmKey
          );
          if (idx !== -1) {
            if (
              newFarm.liquidity &&
              !currentState.list[idx].liquidity?.eq(newFarm.liquidity)
            ) {
              currentState.list[idx] = {
                ...currentState.list[idx],
                liquidity: newFarm.liquidity,
              };
            }
          }
        }
      })
      .addCase(updateFarmSupplies.fulfilled, (currentState, action) => {
        for (const newFarm of action.payload) {
          const idx = currentState.list.findIndex(
            (e) => e.key === newFarm.farmKey
          );
          if (idx !== -1) {
            if (
              currentState.list[idx].totalSupply &&
              newFarm.totalSupply &&
              !currentState.list[idx].totalSupply?.eq(newFarm.totalSupply)
            ) {
              currentState.list[idx] = {
                ...currentState.list[idx],
                totalSupply: newFarm.totalSupply,
              };
            }
          }
        }
      })
      .addCase(updateFarmAprs.fulfilled, (currentState, action) => {
        for (const newFarm of action.payload) {
          const idx = currentState.list.findIndex(
            (e) => e.key === newFarm.farmKey
          );
          if (idx !== -1) {
            if (
              newFarm.apr !== undefined &&
              currentState.list[idx].apr !== newFarm.apr
            ) {
              currentState.list[idx] = {
                ...currentState.list[idx],
                apr: newFarm.apr,
              };
            }
          }
        }
      });
  },
});

export default farmsSlice.reducer;
