import React, { useMemo } from 'react';
import { BridgePayload, NetworkInfo, Transaction } from 'core/types';
import * as S from './Notification.styles';
import {
  getEtherscanLink,
  getNetworkInfoFromChainId,
  getNotificationDescription,
  getNotificationTitle,
  getSourceIcon,
  shortenStringInMiddle,
  tsTohhmm,
} from 'utils';
import { LoadingCircle } from 'components';
import { useAppDispatch } from 'core/store/hooks';
import { switchChain } from 'core/store/auth/thunks/switchChain';

type NotificationProps = {
  transaction: Transaction;
  network?: NetworkInfo;
};

const Notification: React.FC<NotificationProps> = ({
  transaction,
  network,
}) => {
  const dispatch = useAppDispatch();

  const transactionMsg = useMemo(
    () => getNotificationDescription(transaction),
    [transaction]
  );

  const title = useMemo(() => getNotificationTitle(transaction), [transaction]);

  const IconState = useMemo(() => {
    switch (transaction.state) {
      case 'pending':
        return <LoadingCircle size={10} color="#0071bd" />;
      case 'success':
        return <S.ConfirmedIcon />;
      case 'error':
        return <S.ErrorIcon />;
    }
  }, [transaction.state]);

  const SourceIcon = useMemo(() => {
    const logo = getSourceIcon(transaction);
    const networkLogo = transaction.network?.logo;
    return (
      <S.NotifLogoWrapper>
        <S.SourceImg src={logo} alt={`${transaction.source} icon`} />
        {networkLogo && (
          <S.NetworkLogo
            src={networkLogo}
            alt={`${transaction?.network?.label} icon`}
          />
        )}
      </S.NotifLogoWrapper>
    );
  }, [transaction]);

  const url = useMemo(() => {
    return getEtherscanLink(transaction.network, transaction.hash);
  }, [transaction.hash, transaction.network]);

  const showSwitchNetwork = useMemo(() => {
    const trsPayload = transaction.payload as BridgePayload;

    return (
      trsPayload &&
      trsPayload.dstChain &&
      transaction.source === 'bridge' &&
      transaction.state === 'unknown' &&
      trsPayload.dstChain !== network?.chainId
    );
  }, [network, transaction.payload, transaction.source, transaction.state]);

  const bridgeTransactionDesc = useMemo(() => {
    if (transaction.source !== 'bridge') return <></>;

    const payload = transaction.payload as BridgePayload;
    const dstNetwork = getNetworkInfoFromChainId(payload.dstChain);
    const srcNetwork = getNetworkInfoFromChainId(payload.srcChain);

    const fromAddress =
      payload.receiverAddress &&
      payload.senderAddress &&
      payload.receiverAddress !== payload.senderAddress
        ? shortenStringInMiddle(payload.senderAddress)
        : undefined;
    const toAddress =
      payload.receiverAddress &&
      payload.senderAddress &&
      payload.receiverAddress !== payload.senderAddress
        ? shortenStringInMiddle(payload.receiverAddress)
        : undefined;

    return (
      <S.BridgeDescriptions>
        <S.BridgeDescription>
          From:{' '}
          <S.NotificationDescription
            target="_blank"
            rel="noopener noreferrer"
            href={`${srcNetwork.scanUrl}/address/${payload.senderAddress}#tokentxns`}
            isLink={fromAddress !== undefined}
          >
            {fromAddress ? `${fromAddress} on ` : ''}
            {srcNetwork.label}
            {fromAddress ? '↗' : ''}
          </S.NotificationDescription>
        </S.BridgeDescription>
        <S.BridgeDescription>
          To:{' '}
          <S.NotificationDescription
            target="_blank"
            rel="noopener noreferrer"
            href={`${dstNetwork.scanUrl}/address/${payload.receiverAddress}#tokentxns`}
            isLink={toAddress !== undefined}
          >
            {toAddress ? `${toAddress} on ` : ''}
            {dstNetwork.label}
            {toAddress ? '↗' : ''}
          </S.NotificationDescription>
        </S.BridgeDescription>
      </S.BridgeDescriptions>
    );
  }, [transaction.payload, transaction.source]);

  return (
    <S.NotificationWrapper>
      <S.LeftPart>
        {SourceIcon}
        <div>
          <S.NotificationMainTitle>
            {title}
            <S.Time>at {tsTohhmm(transaction.createdAtTs)}</S.Time>
          </S.NotificationMainTitle>
          <S.NotificationDescription
            target="_blank"
            rel="noopener noreferrer"
            href={url}
            isLink={url !== undefined}
          >
            <S.DescriptionText>
              {transactionMsg}
              {url ? '↗' : ''}
            </S.DescriptionText>
          </S.NotificationDescription>
          {bridgeTransactionDesc}
          {showSwitchNetwork && (
            <S.SwitchNetworkButton
              onClick={() => {
                dispatch(
                  switchChain(
                    getNetworkInfoFromChainId(
                      (transaction.payload as BridgePayload).dstChain
                    )
                  )
                );
              }}
            >
              Switch to{' '}
              {
                getNetworkInfoFromChainId(
                  (transaction.payload as BridgePayload).dstChain
                ).label
              }
            </S.SwitchNetworkButton>
          )}
        </div>
      </S.LeftPart>
      <S.IconWrapper>{IconState}</S.IconWrapper>
    </S.NotificationWrapper>
  );
};

export default Notification;
