import EarnBackgroundWebp from 'assets/images/earn_bg.webp';
import EarnBackgroundPng from 'assets/images/earn_bg.png';
import * as S from './Earn.styles';
import EarnHeader from './EarnHeader';
import EarnForm from './EarnForm';
import NotificationPanel from './NotificationPanel';
import { ComingSoon, Spacer } from 'components';

const Earn: React.FC = () => {
  return (
    <S.EarnWrapper>
      <S.BackgroundImage
        src={EarnBackgroundWebp}
        fallback={EarnBackgroundPng}
        alt="earn background image"
      />
      <S.EarnCard>
        <ComingSoon chains={[1]} />
        <S.MainInterfaceWrapper>
          <EarnHeader />
          <S.Separator />
          <EarnForm />
        </S.MainInterfaceWrapper>
        <S.NotificationWrapper>
          <NotificationPanel />
        </S.NotificationWrapper>
      </S.EarnCard>
      <Spacer axis="vertical" size={50} />
    </S.EarnWrapper>
  );
};

export default Earn;
