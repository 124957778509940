import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from 'react';
import { usePrevious } from 'react-use';
import {
  AddToWalletButton,
  Collapsable,
  RewardLock,
  Spacer,
  useOnClickOutside,
} from 'components';
import { useAppDispatch, useAppSelector, useBlockNow } from 'core/store/hooks';

import FlurryIconOnly from 'assets/images/logos/flurry_icon_only.png';
import SnowballIcon from 'assets/images/flurry_snowball_illustration.png';
import RhoTokenLogo from 'assets/images/logos/rhoToken_logo.png';
import FarmLogo from 'assets/images/logos/farm_icon.png';

import { selectLatestClaimRewardTransaction } from 'core/store/transactions/selectors';
import * as S from './ClaimRewardsPopup.styles';
import { showClaimRewardsPopup } from 'core/store/ui/ui';
import { TokenContractsLogos } from 'core/config/contracts';
import { claimAllRewards } from 'core/store/contracts/thunks/claimAllRewards';
import { User } from 'core/types';
import {
  ciEquals,
  findLogoOfContract,
  formatAPR,
  formatBNToString,
  sendGAEvent,
} from 'utils';
import FarmIcon from 'components/FarmIcon';
import Skeleton from 'react-loading-skeleton';
import { BigNumber } from 'bignumber.js';

const ClaimRewardsPopup: React.FC = () => {
  const isPopupShowing = useAppSelector(
    (state) => state.ui.showClaimRewardsPopup
  );
  const ref = useRef(null);
  const dispatch = useAppDispatch();
  const [showStakeRewardsBtn, setShowStakeRewardsBtn] = useState(false);

  const onClose = useCallback(() => {
    dispatch(showClaimRewardsPopup(false));
    setShowStakeRewardsBtn(false);
  }, [dispatch]);

  useOnClickOutside(ref, () => onClose());

  const flurryContract = useAppSelector((state) => state.contracts.flurryToken);
  const loadingUser = useAppSelector(
    (state) => state.auth.balancesStates.rewards
  );
  const user = useAppSelector((state) => state.auth.user);
  const aprs = useAppSelector((state) => state.contracts.aprs);
  const rhoTokens = useAppSelector((state) => state.contracts.rhoTokens);
  const flurryStaking = useAppSelector(
    (state) => state.contracts.flurryStaking
  );
  const farms = useAppSelector((state) => state.farms.list);
  const claimAllTransaction = useAppSelector((state) =>
    selectLatestClaimRewardTransaction(state)
  );
  const currentBlock = useBlockNow();
  const network = useAppSelector((state) => state.auth.network);

  const getSumBalanceFromContracts = (
    currentUser: User | undefined,
    contracts: string[]
  ): BigNumber => {
    let totalBalance = new BigNumber(0);
    if (currentUser) {
      for (const b of contracts) {
        const balance = currentUser.balances.find((e) => e.currency === b);
        if (balance) {
          totalBalance = totalBalance.plus(balance.amount);
        }
      }
      return totalBalance;
    }
    return totalBalance;
  };

  const userTotalRewardBalance = useMemo(() => {
    if (user) {
      const allRewards = user.balances.find((e) => e.currency === `AllRewards`);
      if (allRewards) {
        return allRewards.amount;
      }
    }
    return new BigNumber(0);
  }, [user]);

  const userTotalClaimableRewardBalance = useMemo(() => {
    if (user) {
      const allClaimableRewards = user.balances.find(
        (e) => e.currency === `AllClaimableRewards`
      );
      if (allClaimableRewards) {
        return allClaimableRewards.amount;
      }
    }
    return new BigNumber(0);
  }, [user]);

  const userTokenRewardBalance = useMemo(() => {
    const rewardTokenContracts = rhoTokens.map((e) => `${e.key}Rewards`);
    return getSumBalanceFromContracts(user, rewardTokenContracts);
  }, [rhoTokens, user]);

  const userFlurryStakingRewardBalance = useMemo(() => {
    if (user && flurryStaking) {
      const balance = user.balances.find(
        (e) => e.currency === `${flurryStaking.key}Rewards`
      );
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [flurryStaking, user]);

  const userFlurryFarmsRewardsBalance = useMemo(() => {
    const farmsContracts = farms.map((e) => `FarmRewards${e.key}`);
    return getSumBalanceFromContracts(user, farmsContracts);
  }, [farms, user]);

  const isTransactionLoading = useMemo(() => {
    return claimAllTransaction && claimAllTransaction.state === 'pending';
  }, [claimAllTransaction]);

  const prevIsTransactionLoading = usePrevious(isTransactionLoading);

  const ClaimAllBtn = useMemo(() => {
    return (
      <S.ClaimAllButton
        disabled={
          userTotalClaimableRewardBalance.isZero() ||
          loadingUser ||
          isTransactionLoading === true
        }
        onClick={() => {
          sendGAEvent('Rewards', 'Claim All');
          dispatch(claimAllRewards());
        }}
      >
        {!isTransactionLoading ? 'Claim All' : 'Claiming all...'}
      </S.ClaimAllButton>
    );
  }, [
    dispatch,
    isTransactionLoading,
    loadingUser,
    userTotalClaimableRewardBalance,
  ]);

  useEffect(() => {
    if (
      prevIsTransactionLoading === true &&
      isTransactionLoading == false &&
      claimAllTransaction?.state !== 'error'
    ) {
      setShowStakeRewardsBtn(true);
    }
  }, [claimAllTransaction, isTransactionLoading, prevIsTransactionLoading]);

  if (!isPopupShowing || !user) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Popup ref={ref}>
        <S.CloseBtnWrapper>
          <S.CloseBtn onClick={() => onClose()} />
        </S.CloseBtnWrapper>
        <S.HeaderWrapper>
          <S.Logo src={FlurryIconOnly} alt="Flurry logo" />
          <div>
            <S.TotalRewardLabel>TOTAL REWARDS EARNED</S.TotalRewardLabel>
            {!loadingUser ? (
              <S.TotalRewardAmount>
                {formatBNToString(userTotalRewardBalance, 2, true, ',')} FLURRY
              </S.TotalRewardAmount>
            ) : (
              <>
                <Spacer axis="vertical" size={10} />
                <Skeleton height={20} />
              </>
            )}
          </div>
        </S.HeaderWrapper>
        <Spacer axis="vertical" size={10} />
        {flurryContract && (
          <AddToWalletButton
            buttonLabel="Add FLURRY to Metamask"
            contractKey={flurryContract.key}
          />
        )}
        <S.Title>Claim your rewards!</S.Title>
        <Spacer axis="vertical" size={20} />
        <S.CurrentClaimableRewards
          hasReward={!userTotalClaimableRewardBalance.isZero()}
        >
          Total Rewards Claimable:{' '}
          <b>
            {formatBNToString(userTotalClaimableRewardBalance, 2, true, ',')}{' '}
            FLURRY
          </b>
        </S.CurrentClaimableRewards>
        <Spacer axis="vertical" size={10} />
        {ClaimAllBtn}
        {showStakeRewardsBtn && (
          <>
            <Spacer axis="vertical" size={10} />
            <S.SnowballRewardsButtonLink
              to="/snowball"
              onClick={() => onClose()}
            >
              <S.SnowballRewardsButton>
                Snowball your claimed FLURRY!
              </S.SnowballRewardsButton>
            </S.SnowballRewardsButtonLink>
          </>
        )}
        <Spacer axis="vertical" size={20} />
        <S.RewardsContainer>
          {rhoTokens.length > 0 && (
            <>
              <Collapsable
                header={
                  <S.RewardRowHeader>
                    <S.ContractLogo src={RhoTokenLogo} />
                    <Spacer axis="horizontal" size={20} />
                    <S.TextWrapper>
                      <S.AmountLabel>
                        Rewards from holding Rho tokens
                      </S.AmountLabel>
                      <S.Amount>
                        {formatBNToString(
                          userTokenRewardBalance,
                          2,
                          false,
                          ','
                        )}{' '}
                        FLURRY
                      </S.Amount>
                    </S.TextWrapper>
                  </S.RewardRowHeader>
                }
                body={
                  <S.RewardRowBody>
                    <S.DetailedReward>
                      <thead>
                        <S.DetailedRewardHead>
                          <th></th>
                          <th>Token</th>
                          <th>Amount earned</th>
                          <th>FLURRY APY</th>
                          <th></th>
                        </S.DetailedRewardHead>
                      </thead>
                      <tbody>
                        {rhoTokens.map((e) => {
                          const contractName = `${e.key}Rewards`;
                          const apr = aprs?.rhoTokens?.find((v) =>
                            ciEquals(v.tokenAddress, e.address)
                          )?.reward?.apr;
                          const balance = user.balances.find(
                            (b) => b.currency === contractName
                          )?.amount;
                          const logo = TokenContractsLogos.find(
                            (t) => t.key === e.key
                          )?.logo;

                          if (balance === undefined) return <></>;
                          return (
                            <S.DetailedRewardRow key={contractName}>
                              <td>
                                <S.ContractLogo small src={logo} />
                              </td>
                              <td>{e.label}</td>
                              <td>
                                {formatBNToString(balance, 2, false, ',')}{' '}
                                FLURRY
                              </td>
                              <td>{apr ? `${formatAPR(apr, 2)}%` : '-'}</td>
                              <td>
                                {
                                  <RewardLock
                                    rewardUnlockedBlock={e.rewardUnlockedBlock}
                                    rewardEndBlock={e.rewardEndBlock}
                                    currentBlock={
                                      currentBlock
                                        ? new BigNumber(currentBlock)
                                        : null
                                    }
                                    network={network}
                                  />
                                }
                              </td>
                            </S.DetailedRewardRow>
                          );
                        })}
                      </tbody>
                    </S.DetailedReward>
                  </S.RewardRowBody>
                }
              />
              <Spacer axis="vertical" size={20} />
            </>
          )}
          <S.RewardRowWrapper>
            <S.RewardRowHeader>
              <S.ContractLogo src={SnowballIcon} />
              <Spacer axis="horizontal" size={20} />
              <S.TextWrapper>
                <S.AmountLabel>Rewards from snowballing Flurry</S.AmountLabel>
                <S.Amount>
                  {formatBNToString(
                    userFlurryStakingRewardBalance,
                    2,
                    false,
                    ','
                  )}{' '}
                  FLURRY
                </S.Amount>
              </S.TextWrapper>
              {
                <S.LockWrapper>
                  <RewardLock
                    rewardUnlockedBlock={flurryStaking?.rewardUnlockedBlock}
                    rewardEndBlock={flurryStaking?.rewardEndBlock}
                    currentBlock={
                      currentBlock ? new BigNumber(currentBlock) : null
                    }
                    network={network}
                  />
                </S.LockWrapper>
              }
            </S.RewardRowHeader>
          </S.RewardRowWrapper>
          {farms.length > 0 && (
            <>
              <Spacer axis="vertical" size={20} />
              <Collapsable
                header={
                  <S.RewardRowHeader>
                    <S.ContractLogo src={FarmLogo} />
                    <Spacer axis="horizontal" size={20} />
                    <S.TextWrapper>
                      <S.AmountLabel>Rewards from the Farms</S.AmountLabel>
                      <S.Amount>
                        {formatBNToString(
                          userFlurryFarmsRewardsBalance,
                          2,
                          false,
                          ','
                        )}{' '}
                        FLURRY
                      </S.Amount>
                    </S.TextWrapper>
                  </S.RewardRowHeader>
                }
                body={
                  <S.RewardRowBody>
                    <S.DetailedReward>
                      <thead>
                        <S.DetailedRewardHead>
                          <th></th>
                          <th>Farm</th>
                          <th>Amount earned</th>
                          <th>FLURRY APY</th>
                          <th></th>
                        </S.DetailedRewardHead>
                      </thead>
                      <tbody>
                        {farms.map((e) => {
                          if (e.unreleased) return <></>;

                          const contractName = `FarmRewards${e.key}`;
                          const balance = user.balances.find(
                            (b) => b.currency === contractName
                          )?.amount;

                          if (balance === undefined) return <></>;

                          const foundContracts = e.key.split('/');
                          const logoOne = findLogoOfContract(foundContracts[0]);
                          const logoTwo = findLogoOfContract(foundContracts[1]);

                          return (
                            <S.DetailedRewardRow key={contractName}>
                              <S.FarmIconWrapper>
                                <FarmIcon
                                  small
                                  backgroundIcon={logoOne}
                                  foregroundIcon={logoTwo}
                                />
                              </S.FarmIconWrapper>
                              <td>{e.label}</td>
                              <td>
                                {formatBNToString(balance, 2, false, ',')}{' '}
                                FLURRY
                              </td>
                              <td>{formatAPR(e.apr, 2)} %</td>
                              <td>
                                {
                                  <RewardLock
                                    rewardUnlockedBlock={e.rewardUnlockedBlock}
                                    rewardEndBlock={e.rewardEndBlock}
                                    currentBlock={
                                      currentBlock
                                        ? new BigNumber(currentBlock)
                                        : null
                                    }
                                    network={network}
                                  />
                                }
                              </td>
                            </S.DetailedRewardRow>
                          );
                        })}
                      </tbody>
                    </S.DetailedReward>
                  </S.RewardRowBody>
                }
              />
            </>
          )}
        </S.RewardsContainer>
        <Spacer axis="vertical" size={20} />
      </S.Popup>
    </S.Wrapper>
  );
};

export default ClaimRewardsPopup;
