/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Transaction } from 'core/types';
import { getStorageValue } from 'utils';

export const initTransactions = createAsyncThunk<Transaction[], void>(
  'transactions/initTransactions',
  async () => {
    const result: Transaction[] = [];
    const transactions = getStorageValue<Transaction[]>('flurry-transactions');
    if (transactions) {
      const currentTs = Date.now();
      for (const t of transactions) {
        if (
          t.createdAtTs - currentTs <= 86400000 &&
          !(t.state === 'error' && !t.hash)
        ) {
          if (t.state === 'pending') t.state = 'unknown';
          result.push({ ...t, isFromCache: true });
        }
      }
    }

    localStorage.setItem('flurry-transactions', JSON.stringify(result));
    return result;
  }
);
