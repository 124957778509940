/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Transaction,
  UpdateTransactionHashReq,
  UpdateTransactionPayload,
  UpdateTransactionStateReq,
} from 'core/types';
import { addNewTransaction } from './thunks/addNewTransaction';
import { initTransactions } from './thunks/initTransactions';

export type TransactionsState = {
  list: Transaction[];
};

const initialState: TransactionsState = {
  list: [],
};

const transactionsSlice = createSlice({
  name: 'transactions',
  initialState,
  reducers: {
    updateTransactionHash: (
      currentState,
      action: PayloadAction<UpdateTransactionHashReq>
    ) => {
      const idx = currentState.list.findIndex(
        (t) => t.id === action.payload.id
      );
      if (idx !== -1) {
        const updatedTransaction: Transaction = {
          ...(currentState.list[idx] as any),
          hash: action.payload.hash,
        };

        currentState.list[idx] = updatedTransaction;
        localStorage.setItem(
          'flurry-transactions',
          JSON.stringify(currentState.list)
        );
      }
    },
    updateTransactionState: (
      currentState,
      action: PayloadAction<UpdateTransactionStateReq>
    ) => {
      const idx = currentState.list.findIndex(
        (t) => t.id === action.payload.id
      );
      if (idx !== -1) {
        const updatedTransaction: Transaction = {
          ...(currentState.list[idx] as any),
          state: action.payload.state,
        };

        currentState.list[idx] = updatedTransaction;
        localStorage.setItem(
          'flurry-transactions',
          JSON.stringify(currentState.list)
        );
      }
    },
    updateTransactionPayload: (
      currentState,
      action: PayloadAction<UpdateTransactionPayload>
    ) => {
      const idx = currentState.list.findIndex(
        (t) => t.id === action.payload.id
      );
      if (idx !== -1) {
        const updatedTransaction: Transaction = {
          ...currentState.list[idx],
          payload: action.payload.payload,
        };

        currentState.list[idx] = updatedTransaction;
        localStorage.setItem(
          'flurry-transactions',
          JSON.stringify(currentState.list)
        );
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addNewTransaction.fulfilled, (currentState, action) => {
        const { transaction } = action.payload;
        const idx = currentState.list.findIndex((t) => t.id === transaction.id);
        if (idx === -1) {
          currentState.list = [transaction, ...currentState.list];
          localStorage.setItem(
            'flurry-transactions',
            JSON.stringify(currentState.list)
          );
        }
      })
      .addCase(initTransactions.fulfilled, (currentState, action) => {
        currentState.list = action.payload;
      });
  },
});

export const {
  updateTransactionHash,
  updateTransactionState,
  updateTransactionPayload,
} = transactionsSlice.actions;

export * from './thunks/addNewTransaction';

export default transactionsSlice.reducer;
