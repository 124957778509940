import { PriceInput, ShortenBalance, Spacer } from 'components';
import React, { useCallback, useMemo, useState } from 'react';
import { useForm, SubmitHandler, FieldError } from 'react-hook-form';
import * as S from './SnowballForm.styles';
import BigNumber from 'bignumber.js';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { noExponents, sendGAEvent } from 'utils';

interface IFormValues {
  unstakeAmount: string;
}

interface UnstakeFormProps {
  onFormSubmit: (amount: BigNumber) => void;
  stakingBalance: BigNumber;
  disabled?: boolean;
  loading: boolean;
  maxDecimalPlace?: number;
}

const FormLoader = (
  <S.FormLoaderWrapper>
    <S.InputSkeleton />
    <S.BalanceSkeleton />
    <Spacer axis="vertical" size={15} />
    <S.ButtonSkeleton />
  </S.FormLoaderWrapper>
);

const FormDisconnected = () => {
  const dispatch = useAppDispatch();
  return (
    <S.FormLoaderWrapper>
      <S.InputSkeleton />
      <S.BalanceSkeleton />
      <Spacer axis="vertical" size={15} />
      <S.ConnectButton
        onClick={() => {
          sendGAEvent('Snowball', 'Connect');
          dispatch(connectToProvider());
        }}
      >
        Connect Wallet
      </S.ConnectButton>
    </S.FormLoaderWrapper>
  );
};

const UnstakeForm: React.FC<UnstakeFormProps> = ({
  onFormSubmit,
  stakingBalance,
  disabled,
  loading,
  maxDecimalPlace,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IFormValues>();
  const [hasValidAmount, setHasValidAmount] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    if (!user) return;

    const amt = new BigNumber(data.unstakeAmount);
    if (amt.gt(stakingBalance)) {
      setError('unstakeAmount', {
        type: 'max',
        message: 'You do not have enough staked FLURRY to unstake that much.',
      });
    } else if (amt.isZero() || amt.isNegative()) {
      setError('unstakeAmount', {
        type: 'min',
        message: 'Unstaking amount should be greater than zero.',
      });
    } else {
      onFormSubmit(amt);
    }
  };

  const checkAmount = useCallback((amountStr: string | BigNumber) => {
    if (!amountStr || amountStr === '') {
      setHasValidAmount(false);
      return;
    }
    const currentAmount = new BigNumber(amountStr);
    if (currentAmount.isZero()) {
      setHasValidAmount(false);
      return;
    }
    setHasValidAmount(true);
  }, []);

  const SubmitButton = useMemo(() => {
    if (user) {
      if (!hasValidAmount) {
        return (
          <S.UnstakeButton type="submit" disabled>
            Enter an amount
          </S.UnstakeButton>
        );
      } else {
        return (
          <S.UnstakeButton type="submit" disabled={disabled}>
            Unstake
          </S.UnstakeButton>
        );
      }
    }
    return (
      <S.ConnectButton
        onClick={() => {
          sendGAEvent('Snowball', 'Connect');
          dispatch(connectToProvider());
        }}
      >
        Connect Wallet
      </S.ConnectButton>
    );
  }, [disabled, dispatch, hasValidAmount, user]);

  if (!user) {
    return <FormDisconnected />;
  }

  if (loading) {
    return FormLoader;
  }

  return (
    <>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <PriceInput
          register={register}
          registerOptions={{ required: true }}
          keyPrice="unstakeAmount"
          onMaxBtnClicked={() => {
            setValue('unstakeAmount', noExponents(stakingBalance));
            checkAmount(stakingBalance);
          }}
          onAmountChange={(v) => {
            checkAmount(v);
            setValue('unstakeAmount', v);
          }}
          maxDecimalPlace={maxDecimalPlace}
        />
        <ShortenBalance
          label="Currently staking:"
          balanceValue={stakingBalance}
          decimalPrecision={2}
          currency="FLURRY"
        />
        {errors.unstakeAmount && (
          <S.InputError>
            {(errors.unstakeAmount as FieldError).message ?? ''}
          </S.InputError>
        )}
        <Spacer axis="vertical" size={15} />
        {SubmitButton}
      </S.Form>
    </>
  );
};
export default UnstakeForm;
