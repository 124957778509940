import { IoMdLogOut } from 'react-icons/io';
import styled from 'styled-components';

export const DisconnectWrapper = styled.div`
  border-top: 1px solid #00000021;
`;
export const DisconnectBtn = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100% - 1rem);
  padding: 0.5rem;
  margin: 0.5rem;
  background-color: transparent;
  outline: none;
  border: none;
  border-radius: 1rem;
  font-size: 0.8rem;
  transition: background-color 0.2s linear;

  &:hover {
    background-color: #1c009708;
  }
`;
export const DisconnectIcon = styled(IoMdLogOut)`
  align-self: flex-start;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.5rem;
  background-color: #1c009708;
  margin-right: 0.5rem;
`;
