import { LoadingCircle, Spacer } from 'components';
import { down, up } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';

export const Wrapper = styled.div`
  position: relative;
  padding: 1rem 2rem;
  ${down('sm')} {
    padding: 0.3rem 2rem 0 2rem;
  }
  ${up('xxl')} {
    padding-bottom: 2.5rem;
  }
`;

export const NotificationWrapper = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

export const NotificationBody = styled.div`
  a {
    color: blue;
    font-size: 0.6rem;
  }
  svg {
    margin-left: 2px;
    width: 8px;
    height: 8px;
  }
`;

export const NotificationText = styled.div`
  font-size: 0.8rem;
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: 0;
`;
export const SubTitle = styled.h2`
  opacity: 0.5;
  font-size: 0.8rem;
  font-weight: normal;
`;

export const Body = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 5fr 1.5fr;
  height: 100%;
  ${down('md')} {
    display: flex;
    flex-direction: column-reverse;
  }
`;

export const FormWrapper = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${down('md')} {
    margin-bottom: 2rem;
  }
`;

export const ToggleWrapper = styled.div`
  align-self: center;
`;

export const BalanceText = styled.p`
  margin: 0;
  padding: 0.4rem 0 0 0.5rem;
  font-size: 0.7rem;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 600px;
  ${down('sm')} {
    width: 80% !important;
  }
  ${down('xs')} {
    width: 100% !important;
  }
`;

export const ConnectButton = styled.button`
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  background: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-size: 0.9rem;

  opacity: ${(props) => (!props.disabled ? '1' : '0.5')};
`;

export const StakeButton = styled.button`
  cursor: ${(props) => (!props.disabled ? 'pointer' : 'default')};
  width: 100%;
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #457fc5 0%,
    #14b4b2 51%,
    #457fc5 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #77a0d349;
  border: none;
  border-radius: 10px;
  display: block;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  font-size: 0.9rem;

  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: #fff; text-decoration: none; }'
      : ''}
`;

export const UnstakeButton = styled(StakeButton)`
  background-image: linear-gradient(
    to right,
    #3f748a 0%,
    #4a8ca8 51%,
    #3f748a 100%
  );
  color: white;
  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: white; text-decoration: none; }'
      : ''}
`;

export const ApproveButton = styled(StakeButton)`
  background-image: linear-gradient(
    to right,
    #2172e5 0%,
    #3884f0 51%,
    #2172e5 100%
  );
`;

export const ApproveLoadingCircle = styled(LoadingCircle)`
  align-self: center;
  margin: 0 auto;
`;

export const InputError = styled.p`
  min-height: 0.7rem;
  margin: 0.5rem 0 0 0.5rem;
  color: red;
  line-height: 0.7rem;
  font-size: 0.7rem;
`;

export const Stats = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-self: center;
  width: calc(100%);
  padding: 1rem;
  ${down('md')} {
    margin-bottom: 0.7rem;
  }
`;

export const StatWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.5rem 0.8rem;
  width: calc(100% - 1.6rem);
  font-size: 0.95rem;
  background-color: #0c738515;
  border-radius: 5px;
  ${down('md')} {
    font-size: 0.75rem;
  }
`;

export const StatSpacer = styled(Spacer)`
  ${down('md')} {
    height: 0.5rem;
    min-height: 0.5rem;
  }
`;

export const StatLabel = styled.div`
  font-weight: bold;
`;

export const StatAmount = styled.div`
  justify-self: end;
`;

export const FormLoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 600px;
  ${down('sm')} {
    width: 80% !important;
  }
  ${down('xs')} {
    width: 100% !important;
  }
`;

export const InputSkeleton = styled(Skeleton)`
  width: 100% !important;
  height: 50px;
`;

export const ButtonSkeleton = styled(Skeleton)`
  width: 100% !important;
  height: 35px;
`;

export const BalanceSkeleton = styled(Skeleton)`
  width: 350px !important;
  height: 10px;
  ${down('sm')} {
    width: 80% !important;
  }
`;
