/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import {
  Contract,
  NetworkInfo,
  PriceOracle,
  RhoTokenAprs,
  VaultContract,
} from 'core/types';
import {
  ciEquals,
  apyNormalized,
  BN,
  findUsdPriceOracle,
  aprToNumber,
  getValueOfRef,
} from 'utils';
import { BigNumber } from 'bignumber.js';
import { ContractCallContext, Multicall } from 'ethereum-multicall';

type UpdateRhotokensAprParams = {
  stablecoins: Contract[];
  rhoTokens: Contract[];
  vaults: VaultContract[];
  priceOracles: PriceOracle[];
  flurryToken: Contract | null;
  rhoTokenRewards: Contract | null;
  network: NetworkInfo;
  multicall: Multicall;
  flurryUsdPrice: BigNumber | undefined;
};

export const updateRhotokensApr = createAsyncThunk<
  RhoTokenAprs[],
  UpdateRhotokensAprParams
>('contracts/updateRhotokensApr', async (payload) => {
  const aprResults: RhoTokenAprs[] = [];
  try {
    const {
      stablecoins,
      rhoTokens,
      vaults,
      priceOracles,
      flurryToken,
      rhoTokenRewards,
      network,
      multicall,
      flurryUsdPrice,
    } = payload;

    const multicallContext: ContractCallContext[] = [];

    // Building contract multicall
    for (const vault of vaults) {
      const coin = stablecoins.find((c) =>
        ciEquals(c.address, vault.underlyingAddress)
      );
      const token = rhoTokens.find((c) =>
        ciEquals(c.address, vault.rhoTokenAddress)
      );

      multicallContext.push({
        reference: vault.address,
        contractAddress: vault.address,
        abi: vault.abi ?? [],
        calls: [
          {
            reference: 'totalCash',
            methodName: 'reserve',
            methodParameters: [],
          },
          {
            reference: 'vaultIndicativeApr',
            methodName: 'indicativeSupplyRate',
            methodParameters: [],
          },
          {
            reference: 'vaultInstantApr',
            methodName: 'supplyRate',
            methodParameters: [],
          },
        ],
        context: {
          decimals: coin?.decimals,
        },
      });

      if (token && rhoTokenRewards) {
        multicallContext.push({
          reference: `reward-${vault.rhoTokenAddress}`,
          contractAddress: rhoTokenRewards.address,
          abi: rhoTokenRewards.abi ?? [],
          calls: [
            {
              reference: 'rewardRatePerRhoToken',
              methodName: 'rewardRatePerRhoToken',
              methodParameters: [vault.rhoTokenAddress],
            },
          ],
          context: {
            label: `${token.label} Rewards APY`,
          },
        });
      }

      if (token) {
        multicallContext.push({
          reference: `rebasing-${token.address}`,
          contractAddress: token.address,
          abi: token.abi ?? [],
          calls: [
            {
              reference: 'totalSupply',
              methodName: 'totalSupply',
              methodParameters: [],
            },
            {
              reference: 'tokenRebasingSupply',
              methodName: 'adjustedRebasingSupply',
              methodParameters: [],
            },
          ],
          context: {
            label: `${vault.label} Rebase Percentage`,
          },
        });
      }

      for (const currentStrat of vault.strategies) {
        multicallContext.push({
          reference: currentStrat.address,
          contractAddress: currentStrat.address,
          abi: currentStrat.abi ?? [],
          calls: [
            {
              reference: 'apr',
              methodName: 'effectiveSupplyRate()',
              methodParameters: [],
            },
            {
              reference: 'supplyRateApr',
              methodName: 'supplyRate',
              methodParameters: [],
            },
            {
              reference: 'supplyRatePerBlock',
              methodName: 'supplyRatePerBlock',
              methodParameters: [],
            },
            {
              reference: 'bonusApr',
              methodName: 'bonusSupplyRate',
              methodParameters: [],
            },
            {
              reference: 'bonusRatePerBlock',
              methodName: 'bonusRatePerBlock',
              methodParameters: [],
            },
            {
              reference: 'amount',
              methodName: 'balanceOfUnderlying',
              methodParameters: [],
            },
          ],
          context: {
            vault: vault.address,
            label: currentStrat.label,
            key: currentStrat.key,
          },
        });
      }
    }

    if (flurryToken) {
      const priceOracle = findUsdPriceOracle(priceOracles, flurryToken.address);

      if (priceOracle) {
        multicallContext.push({
          reference: 'flurryPrice',
          contractAddress: priceOracle.address,
          abi: priceOracle.abi ?? [],
          calls: [
            {
              reference: 'flurryPrice',
              methodName: 'price',
              methodParameters: [flurryToken.address],
            },
          ],
          context: {
            decimals: priceOracle.decimals,
          },
        });
      }
    }

    // Executing contract multicall
    const callResults = (await multicall.call(multicallContext)).results;

    // Formating all contract result into usable value
    for (const vault of vaults) {
      const vaultRes = callResults[vault.address]?.callsReturnContext;
      const vaultContext =
        callResults[vault.address]?.originalContractCallContext.context;

      if (!vaultRes) {
        continue;
      }

      // Formatting vault results
      const currentApr: RhoTokenAprs = {
        stablecoinAddress: vault.underlyingAddress,
        tokenAddress: vault.rhoTokenAddress,
        totalCash: BN(
          getValueOfRef(vaultRes, 'totalCash'),
          vaultContext.decimals
        ),
        vaultIndicativeApr: {
          label: `${vault.label} Indicative APY`,
          apr: aprToNumber(getValueOfRef(vaultRes, 'vaultIndicativeApr')),
        },
        vaultInstantApr: {
          label: `${vault.label} Instant APY`,
          apr: aprToNumber(getValueOfRef(vaultRes, 'vaultInstantApr')),
        },
        strategies: [],
      };

      // Formatting strats results
      for (const strat of vault.strategies) {
        const stratRes = callResults[strat.address]?.callsReturnContext;
        const stratContext =
          callResults[strat.address]?.originalContractCallContext.context;
        if (!stratRes) continue;

        currentApr.strategies?.push({
          label: stratContext.label,
          key: stratContext.key,
          apr: aprToNumber(getValueOfRef(stratRes, 'apr')),
          supplyRateApr: aprToNumber(getValueOfRef(stratRes, 'supplyRateApr')),
          normalizedSupplyRateApr: apyNormalized(
            stratContext.key,
            getValueOfRef(stratRes, 'supplyRatePerBlock'),
            network.blocksPerDay
          ),
          bonusApr: aprToNumber(getValueOfRef(stratRes, 'bonusApr')),
          normalizedBonusApr: apyNormalized(
            stratContext.key,
            getValueOfRef(stratRes, 'bonusRatePerBlock'),
            network.blocksPerDay
          ),
          amount: BN(getValueOfRef(stratRes, 'amount'), vaultContext.decimals),
        });
      }

      // Formating reward apr results
      const rewardRateRes = callResults[`reward-${vault.rhoTokenAddress}`];
      if (rewardRateRes) {
        const rewardRateToken = getValueOfRef(
          rewardRateRes.callsReturnContext,
          'rewardRatePerRhoToken'
        );

        if (rewardRateToken.gte(ethers.constants.MaxUint256)) {
          currentApr.reward = {
            label: rewardRateRes.originalContractCallContext.context.label,
            apr: parseFloat(
              ethers.utils.formatEther(ethers.constants.MaxUint256)
            ),
          };
        } else {
          const flurryPriceOracle = callResults.flurryPrice;

          let flurryPrice: BigNumber | undefined = flurryUsdPrice;
          if (flurryPriceOracle?.callsReturnContext) {
            flurryPrice = BN(
              getValueOfRef(
                flurryPriceOracle.callsReturnContext,
                'flurryPrice'
              ),
              flurryPriceOracle.originalContractCallContext.context.decimals
            );
          }

          if (flurryPrice) {
            const apr =
              parseFloat(ethers.utils.formatUnits(rewardRateToken)) *
              flurryPrice.toNumber() *
              network.blocksPerYear;

            currentApr.reward = {
              label: rewardRateRes.originalContractCallContext.context.label,
              apr: apr,
            };
          }
        }
      }

      // Rebasing Percentage
      const rebasePercentageRes =
        callResults[`rebasing-${vault.rhoTokenAddress}`];
      if (rebasePercentageRes) {
        const tokenSupply = getValueOfRef(
          rebasePercentageRes.callsReturnContext,
          'totalSupply'
        );
        const tokenRebasingSupply = getValueOfRef(
          rebasePercentageRes.callsReturnContext,
          'tokenRebasingSupply'
        );

        currentApr.rebasePercentage = {
          label: rebasePercentageRes.originalContractCallContext.context.label,
          apr: !BN(tokenSupply).isZero()
            ? BN(tokenRebasingSupply).div(BN(tokenSupply)).toNumber()
            : undefined,
        };

        aprResults.push(currentApr);
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    return aprResults;
  }
});
