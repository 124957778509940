import { createAsyncThunk } from '@reduxjs/toolkit';
import { BN, getValueOfRef } from 'utils';
import { FarmContract, User, UserBalance } from 'core/types';
import { ContractsState } from 'core/store/contracts/contracts';
import { ContractCallContext, Multicall } from 'ethereum-multicall';

export const updateRewardsBalances = createAsyncThunk<
  UserBalance[],
  {
    user: User;
    farms: FarmContract[];
    contracts: ContractsState;
    multicall: Multicall;
  }
>('auth/updateRewardsBalances', async (payload) => {
  const balances: UserBalance[] = [];

  try {
    const { multicall, contracts } = payload;
    const {
      flurryStaking,
      rhoTokenRewards,
      lpStaking,
      rhoTokens,
      flurryToken,
    } = contracts;

    const flurryDecimals = flurryToken?.decimals;

    // Building contract multicall
    const multicallContext: ContractCallContext[] = [];
    if (lpStaking) {
      for (const f of payload.farms) {
        multicallContext.push({
          reference: f.address,
          contractAddress: lpStaking.address,
          abi: lpStaking.abi ?? [],
          calls: [
            {
              reference: 'rewardBalance',
              methodName: 'rewardOf',
              methodParameters: [payload.user.address, f.address],
            },
          ],
          context: {
            key: `FarmRewards${f.key}`,
            decimals: flurryDecimals,
          },
        });
      }
    }

    if (rhoTokenRewards) {
      for (const rhoToken of rhoTokens) {
        multicallContext.push({
          reference: rhoToken.address,
          contractAddress: rhoTokenRewards.address,
          abi: rhoTokenRewards.abi ?? [],
          calls: [
            {
              reference: 'rewardBalance',
              methodName: 'rewardOf',
              methodParameters: [payload.user.address, rhoToken.address],
            },
          ],
          context: {
            key: `${rhoToken.key}Rewards`,
            decimals: flurryDecimals,
          },
        });
      }
    }
    if (flurryStaking) {
      multicallContext.push({
        reference: flurryStaking.address,
        contractAddress: flurryStaking.address,
        abi: flurryStaking.abi ?? [],
        calls: [
          {
            reference: 'rewardBalance',
            methodName: 'rewardOf',
            methodParameters: [payload.user.address],
          },
          {
            reference: 'totalRewardBalance',
            methodName: 'totalRewardOf',
            methodParameters: [payload.user.address],
          },
          {
            reference: 'totalClaimableRewardBalance',
            methodName: 'totalClaimableRewardOf',
            methodParameters: [payload.user.address],
          },
        ],
        context: {
          decimals: flurryDecimals,
        },
      });
    }

    // Executing contract multicall
    const callResults = (await multicall.call(multicallContext)).results;

    // Formating all contract result into usable value

    if (lpStaking) {
      // Update rewards from farms
      for (const f of payload.farms) {
        const res = callResults[f.address].callsReturnContext;
        const context =
          callResults[f.address].originalContractCallContext.context;
        if (res && context) {
          balances.push({
            currency: context.key,
            amount: BN(getValueOfRef(res, 'rewardBalance'), context.decimals),
          });
        }
      }
    }

    if (rhoTokenRewards) {
      // update rewards from rho tokens
      for (const rhoToken of rhoTokens) {
        const res = callResults[rhoToken.address].callsReturnContext;
        const context =
          callResults[rhoToken.address].originalContractCallContext.context;
        if (res && context) {
          balances.push({
            currency: context.key,
            amount: BN(getValueOfRef(res, 'rewardBalance'), flurryDecimals),
          });
        }
      }
    }

    if (flurryStaking) {
      const res = callResults[flurryStaking.address].callsReturnContext;
      const context =
        callResults[flurryStaking.address].originalContractCallContext.context;
      if (res && context) {
        balances.push({
          currency: `${flurryStaking.key}Rewards`,
          amount: BN(getValueOfRef(res, 'rewardBalance'), context.decimals),
        });
        balances.push({
          currency: `AllRewards`,
          amount: BN(
            getValueOfRef(res, 'totalRewardBalance'),
            context.decimals
          ),
        });
        balances.push({
          currency: `AllClaimableRewards`,
          amount: BN(
            getValueOfRef(res, 'totalClaimableRewardBalance'),
            context.decimals
          ),
        });
      }
    }
  } catch (e) {
    console.error(e);
  }

  return balances;
});
