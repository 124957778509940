import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { TokensChainData, TokensData } from 'core/types/subgraph';
import { ethers } from 'ethers';
import {
  ciEquals,
  getBlocksOfTimestamps,
  getNetworkInfoFromChainId,
  getSubgraphUrl,
} from 'utils';

type GetRhoTokenReq = {
  chains: number[];
  timestamps: number[];
};

const formatSubgraphFarmData = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  unreleasedFarms: string[]
): TokensChainData => {
  const res: TokensChainData = {};
  for (const d of Object.keys(data)) {
    const ts = parseInt(d.replace('t', ''));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any[] = data[d];

    for (const v of value) {
      if (
        v.liquidity &&
        unreleasedFarms.findIndex((f) => ciEquals(f, v.id)) === -1
      ) {
        res[ts] = {
          ...res[ts],
          [v.name]: v.liquidity.simple,
        };
      }
    }
  }

  return res;
};

export const getFarmsLiquidity = createAsyncThunk<TokensData, GetRhoTokenReq>(
  'content/getFarmsLiquidity',
  async (payload) => {
    const result: TokensData = {};
    for (const chain of payload.chains) {
      try {
        const network = getNetworkInfoFromChainId(chain);
        const subgraphUrl = getSubgraphUrl(chain);

        if (!subgraphUrl) {
          continue;
        }

        const provider = new ethers.providers.JsonRpcProvider(network.rpcUrl);

        const currentBlock = await provider.getBlock(
          await provider.getBlockNumber()
        );

        const blocksToQuery = getBlocksOfTimestamps(
          payload.timestamps,
          currentBlock,
          network.avgBlockTime
        );

        let unreleasedFarms: string[] = [];

        if (window._env_.UNRELEASED_FARMS_BY_CHAIN) {
          const unreleasedFarmsByChain = JSON.parse(
            window._env_.UNRELEASED_FARMS_BY_CHAIN
          );

          if (unreleasedFarmsByChain.hasOwnProperty(chain)) {
            unreleasedFarms = unreleasedFarmsByChain[chain];
          }
        }

        let currentQuery = '';

        for (let i = 0; i < payload.timestamps.length; ++i) {
          const block = blocksToQuery[i];
          const ts = payload.timestamps[i];

          currentQuery += `
            t${ts}: farms(block: { number: ${block} }) {
              id
              name
              liquidity {
                simple
              }
            }`;
        }

        try {
          const res = await axios({
            url: subgraphUrl,
            method: 'post',
            data: {
              query: `
                      query {
                        ${currentQuery}
                      }`,
            },
          });

          if (res?.data?.data) {
            result[chain] = formatSubgraphFarmData(
              res.data.data,
              unreleasedFarms
            );
          }
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    }

    return result;
  }
);
