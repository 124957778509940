import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Sorting } from '../../../core/types';
import * as S from './CompensationTable.styles';

type HeaderItemProps = {
  farmSorting: 'farm' | 'staking' | 'balance';
  sortingType?: Sorting;
  onClick: (farm: 'farm' | 'staking' | 'balance', sortingType: Sorting) => void;
  isCurrentlySorted: boolean;
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
};

const HeaderItem: React.FC<HeaderItemProps> = ({
  children,
  farmSorting,
  sortingType = 'none',
  onClick,
  isCurrentlySorted,
  hideOnTablet,
  hideOnMobile,
}) => {
  const [currentSorting, setCurrentSorting] = useState<Sorting>(sortingType);

  const onSortClicked = useCallback(() => {
    let newSorting = currentSorting;
    if (newSorting === 'none') {
      newSorting = 'desc';
    } else if (newSorting === 'desc') {
      newSorting = 'asc';
    } else {
      newSorting = 'none';
    }
    setCurrentSorting(newSorting);
    onClick(farmSorting, newSorting);
  }, [currentSorting, farmSorting, onClick]);

  useEffect(() => {
    if (!isCurrentlySorted) {
      setCurrentSorting('none');
    }
  }, [isCurrentlySorted]);

  const sortArrow = useMemo(() => {
    if (currentSorting === 'asc') {
      return <S.ArrowUp />;
    } else if (currentSorting === 'desc') {
      return <S.ArrowDown />;
    }

    return <></>;
  }, [currentSorting]);

  return (
    <S.HeaderItem
      isSorted={currentSorting !== 'none'}
      onClick={() => onSortClicked()}
      hideOnTablet={hideOnTablet}
      hideOnMobile={hideOnMobile}
    >
      {children}
      {sortArrow}
    </S.HeaderItem>
  );
};

export default HeaderItem;
