import styled, { keyframes } from 'styled-components/macro';

const checkmark = keyframes`
    0% {
        height: 0;
        width: 0;
        opacity: 1;
    }
    20% {
        height: 0;
        width: 20%;
        opacity: 1;
    }
    40% {
        height: 40%;
        width: 20%;
        opacity: 1;
    }
    100% {
        height: 40%;
        width: 20%;
        opacity: 1;
    }
`;

export const CheckMarkCircle = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  vertical-align: top;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #2eb150;
  position: absolute;
`;

export const CheckmarkDraw = styled.div`
  border-radius: 5px;

  &::after {
    animation-delay: 100ms;
    animation-duration: 1s;
    animation-timing-function: ease;
    animation-name: ${checkmark};
    transform: scaleX(-1) rotate(135deg);
    animation-fill-mode: forwards;

    opacity: 1;
    height: 40%;
    width: 20%;
    transform-origin: left top;
    border-right: 5px solid white;
    border-top: 5px solid white;
    border-radius: 1px !important;
    content: '';
    left: 18%;
    top: 50%;
    position: absolute;
  }
`;
