import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import { sendTransaction, waitReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';
import BigNumber from 'bignumber.js';
import { AuthState } from 'core/store/auth/auth';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import { getContractByAddress } from 'utils';
import { updateUserBridgeApproval } from 'core/store/auth/thunks/updateUserBridgeApproval';

export const approveBridge = createAsyncThunk<
  void,
  { amountNeeded: BigNumber; tokenAddress: string }
>('contracts/approveBridge', async (payload, thunkAPI) => {
  const { amountNeeded, tokenAddress } = payload;
  const { user } = (thunkAPI.getState() as { auth: AuthState }).auth;
  const { contracts } = thunkAPI.getState() as { contracts: ContractsState };
  const transactionId = uuidv4();

  try {
    if (!user) {
      throw Error('No User found');
    }

    const token = await getContractByAddress(tokenAddress, contracts);

    thunkAPI.dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'bridge',
        type: 'approval',
        state: 'pending',
        payload: {
          amount: amountNeeded,
          currency: token.label,
        },
      })
    );

    const bridge = contracts.bridge;

    if (!bridge) {
      throw Error('No Bridge contract found');
    }

    const tx = await sendTransaction(
      token.contract.approve,
      bridge.address,
      ethers.constants.MaxUint256
    );

    thunkAPI.dispatch(
      updateTransactionHash({
        id: transactionId,
        hash: tx.hash,
      })
    );

    await waitReceipt(tx);
    thunkAPI.dispatch(
      updateUserBridgeApproval({
        user,
        amount: amountNeeded,
        tokenAddress: token.address,
      })
    );

    thunkAPI.dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'success',
      })
    );
  } catch (error) {
    console.error(error.toString());
    thunkAPI.dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'error',
      })
    );
  }
});
