import { PriceInput, ShortenBalance, Spacer } from 'components';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useForm, SubmitHandler, FieldError } from 'react-hook-form';
import * as S from './SnowballForm.styles';
import BigNumber from 'bignumber.js';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { updateUserFlurryStakingApproval } from 'core/store/auth/thunks/updateUserFlurryStakingApproval';
import { noExponents, sendGAEvent } from 'utils';

interface IFormValues {
  stakeAmount: string;
}

interface StakeFormProps {
  onStakeSubmit: (amount: BigNumber) => void;
  onApproveSubmit: (amount: BigNumber) => void;
  flurryBalance: BigNumber;
  needApproval: boolean | undefined;
  approvalLoading: boolean;
  disabled?: boolean;
  loading: boolean;
  maxDecimalPlace?: number;
}

const FormLoader = (
  <S.FormLoaderWrapper>
    <S.InputSkeleton />
    <S.BalanceSkeleton />
    <Spacer axis="vertical" size={15} />
    <S.ButtonSkeleton />
  </S.FormLoaderWrapper>
);

const FormDisconnected = () => {
  const dispatch = useAppDispatch();
  return (
    <S.FormLoaderWrapper>
      <S.InputSkeleton />
      <S.BalanceSkeleton />
      <Spacer axis="vertical" size={15} />
      <S.ConnectButton
        onClick={() => {
          sendGAEvent('Snowball', 'Connect');
          dispatch(connectToProvider());
        }}
      >
        Connect Wallet
      </S.ConnectButton>
    </S.FormLoaderWrapper>
  );
};

const DECIMAL_PRECISION = 2;

const StakeForm: React.FC<StakeFormProps> = ({
  onStakeSubmit,
  onApproveSubmit,
  flurryBalance,
  needApproval,
  approvalLoading,
  disabled,
  loading,
  maxDecimalPlace,
}) => {
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors },
  } = useForm<IFormValues>();
  const [hasValidAmount, setHasValidAmount] = useState(false);
  const user = useAppSelector((state) => state.auth.user);
  const dispatch = useAppDispatch();
  const [initAmount, setInitAmount] = useState(false);

  const onSubmit: SubmitHandler<IFormValues> = (data) => {
    if (!user) return;

    const amt = new BigNumber(data.stakeAmount);
    if (amt.gt(flurryBalance)) {
      setError('stakeAmount', {
        type: 'max',
        message: 'You do not have enough FLURRY to stake that much.',
      });
    } else if (amt.isZero() || amt.isNegative()) {
      setError('stakeAmount', {
        type: 'min',
        message: 'Staking amount should be greater than zero.',
      });
    } else {
      if (needApproval) {
        onApproveSubmit(amt);
      } else {
        onStakeSubmit(amt);
      }
    }
  };

  const checkAmount = useCallback(
    (amountStr: string | BigNumber) => {
      if (!amountStr || amountStr === '') {
        setHasValidAmount(false);
        return;
      }
      const currentAmount = new BigNumber(amountStr);
      if (currentAmount.isZero()) {
        setHasValidAmount(false);
        return;
      }
      setHasValidAmount(true);
      dispatch(
        updateUserFlurryStakingApproval({
          amount: currentAmount,
          user,
        })
      );
    },
    [dispatch, user]
  );

  useEffect(() => {
    if (!loading && !initAmount) {
      const amount = flurryBalance.dp(DECIMAL_PRECISION, BigNumber.ROUND_FLOOR);
      setValue('stakeAmount', noExponents(amount));
      checkAmount(amount);
      setInitAmount(true);
    }
  }, [checkAmount, flurryBalance, initAmount, loading, setValue]);

  const SubmitButton = useMemo(() => {
    if (user) {
      if (!hasValidAmount) {
        return (
          <S.StakeButton type="button" disabled>
            Enter an amount
          </S.StakeButton>
        );
      }
      if (needApproval || approvalLoading) {
        return (
          <S.ApproveButton type="submit" disabled={disabled || approvalLoading}>
            {approvalLoading ? (
              <S.ApproveLoadingCircle size={11} color="white" />
            ) : (
              'Approve FLURRY'
            )}
          </S.ApproveButton>
        );
      } else {
        return (
          <S.StakeButton type="submit" disabled={disabled}>
            Stake
          </S.StakeButton>
        );
      }
    }
    return (
      <S.ConnectButton
        onClick={() => {
          sendGAEvent('Snowball', 'Connect');
          dispatch(connectToProvider());
        }}
      >
        Connect Wallet
      </S.ConnectButton>
    );
  }, [approvalLoading, disabled, dispatch, hasValidAmount, needApproval, user]);

  if (!user) {
    return <FormDisconnected />;
  }

  if (loading) {
    return FormLoader;
  }

  return (
    <>
      <S.Form onSubmit={handleSubmit(onSubmit)}>
        <PriceInput
          register={register}
          registerOptions={{ required: true }}
          keyPrice="stakeAmount"
          onMaxBtnClicked={() => {
            setValue('stakeAmount', noExponents(flurryBalance));
            checkAmount(flurryBalance);
          }}
          onAmountChange={(v) => {
            checkAmount(v);
            setValue('stakeAmount', v);
          }}
          maxDecimalPlace={maxDecimalPlace}
        />
        <ShortenBalance
          label="Available to stake:"
          balanceValue={flurryBalance}
          decimalPrecision={DECIMAL_PRECISION}
          currency="FLURRY"
        />
        {errors.stakeAmount && (
          <S.InputError>
            {(errors.stakeAmount as FieldError).message ?? ''}
          </S.InputError>
        )}
        <Spacer axis="vertical" size={15} />
        {SubmitButton}
      </S.Form>
    </>
  );
};
export default StakeForm;
