import React, { useMemo } from 'react';
import { CurrencyOption, NetworkInfo, SwapRoute } from 'core/types';
import * as S from './TokenSwapRoute.styles';
import { BigNumber } from 'bignumber.js';
import { formatBNToString, getEtherscanLink } from 'utils';
import { useAppSelector } from 'core/store/hooks';

type TokenSwapRouteProps = {
  fromAmount: BigNumber;
  fromToken: CurrencyOption;
  toAmount: BigNumber;
  toToken: CurrencyOption;
  route: SwapRoute[];
};

const TokenDisplay: React.FC<{
  tokenName: string;
  amount?: string;
  tokenLogo?: string;
  showLogoLeft?: boolean;
  fullWidth?: boolean;
  network?: NetworkInfo | undefined;
  tokenAddress: string;
}> = ({
  tokenName,
  amount,
  tokenLogo,
  showLogoLeft = true,
  fullWidth = true,
  tokenAddress,
  network,
}) => {
  return (
    <S.TokenWrapper fullWidth={fullWidth}>
      {showLogoLeft ? (
        <S.TokenLink
          href={getEtherscanLink(network, undefined, tokenAddress)}
          target="_blank"
          rel="noopener noreferrer"
          fullWidth={fullWidth}
        >
          <S.TokenLogo
            src={tokenLogo}
            alt={`${tokenName} logo`}
            isLeft={showLogoLeft}
          />
          {amount ?? ''} {tokenName}
        </S.TokenLink>
      ) : (
        <S.TokenLink
          href={getEtherscanLink(network, undefined, tokenAddress)}
          target="_blank"
          rel="noopener noreferrer"
          fullWidth={fullWidth}
        >
          {amount ?? ''} {tokenName}
          <S.TokenLogo
            src={tokenLogo}
            alt={`${tokenName} logo`}
            isLeft={showLogoLeft}
          />
        </S.TokenLink>
      )}
    </S.TokenWrapper>
  );
};

const ExchangeDisplay: React.FC<{
  exchangeName: string;
  exchangeLogo?: string;
  percentage: BigNumber;
  network?: NetworkInfo | undefined;
  poolAddress: string;
}> = ({ exchangeName, exchangeLogo, percentage, network, poolAddress }) => {
  const percentageFormatted = useMemo(() => {
    const str = percentage.times(100).toFixed(0);
    if (str === '0') return '~0%';

    return `${str}%`;
  }, [percentage]);
  return (
    <S.TokenWrapper fullWidth>
      <S.TokenLink
        href={getEtherscanLink(network, undefined, poolAddress)}
        target="_blank"
        rel="noopener noreferrer"
        fullWidth
      >
        <S.TokenLogo src={exchangeLogo} alt={`${exchangeName} logo`} isLeft />
        {exchangeName}: {percentageFormatted}
      </S.TokenLink>
    </S.TokenWrapper>
  );
};

const TokenSwapRoute: React.FC<TokenSwapRouteProps> = ({
  fromToken,
  toToken,
  fromAmount,
  toAmount,
  route,
}) => {
  const totalRouteAmount = useMemo(
    () =>
      route.reduce(
        (prev, curr) => prev.plus(curr.swapTotalAmount),
        new BigNumber(0)
      ),
    [route]
  );

  const network = useAppSelector((state) => state.auth.network);

  return (
    <S.Wrapper>
      <S.Title>Your Trade Route</S.Title>
      <S.MainTokens>
        <TokenDisplay
          tokenName={fromToken.symbol ?? fromToken.label}
          amount={formatBNToString(fromAmount, 3, false, ',')}
          tokenLogo={fromToken.icon}
          network={network}
          tokenAddress={fromToken.value}
          fullWidth={false}
        />
        <TokenDisplay
          tokenName={toToken.symbol ?? toToken.label}
          amount={formatBNToString(toAmount, 3, false, ',')}
          tokenLogo={toToken.icon}
          network={network}
          tokenAddress={toToken.value}
          showLogoLeft={false}
          fullWidth={false}
        />
      </S.MainTokens>
      <S.RouteWrapper>
        <S.LeftRouteDot></S.LeftRouteDot>
        <S.RightRouteDot></S.RightRouteDot>
        {route
          .sort((a, b) =>
            b.swapTotalAmount
              .div(totalRouteAmount)
              .minus(a.swapTotalAmount.div(totalRouteAmount))
              .toNumber()
          )
          .map((line, stepIdx) => {
            return (
              <S.StepsWrapper key={`step-route-${stepIdx}`}>
                <S.Percent>
                  {line.swapTotalAmount
                    .div(totalRouteAmount)
                    .times(100)
                    .toString()}
                  %
                </S.Percent>
                <S.RouteLine />
                <S.ScrollContainerWrapper>
                  <S.CustomScrollContainer>
                    <S.RouteSteps>
                      {line.steps.map((r, idx) => {
                        const totalExchangeAmount = r.exchanges.reduce(
                          (prev, curr) => prev.plus(curr.swapAmount),
                          new BigNumber(0)
                        );
                        return (
                          <>
                            <S.Step
                              key={`singlestep-${r.token}-${stepIdx}-${idx}`}
                            >
                              <S.StepTop>
                                <TokenDisplay
                                  tokenName={r.token.symbol.substring(0, 5)}
                                  tokenLogo={r.token.logo}
                                  network={network}
                                  tokenAddress={r.token.address}
                                />
                              </S.StepTop>
                              <S.StepBottom>
                                {r.exchanges
                                  .sort((a, b) =>
                                    b.swapAmount
                                      .div(totalExchangeAmount)
                                      .minus(
                                        a.swapAmount.div(totalExchangeAmount)
                                      )
                                      .toNumber()
                                  )
                                  .map((e) => (
                                    <ExchangeDisplay
                                      exchangeName={e.name}
                                      exchangeLogo={e.logo}
                                      percentage={e.swapAmount.div(
                                        totalExchangeAmount
                                      )}
                                      network={network}
                                      poolAddress={e.pool}
                                      key={`${e.name}-${stepIdx}-${idx}`}
                                    />
                                  ))}
                              </S.StepBottom>
                            </S.Step>
                            {idx !== line.steps.length - 1 && (
                              <S.StepChevronWrapper
                                key={`chevron-${r.token}-${stepIdx}-${idx}`}
                              >
                                <S.StepChevronRight></S.StepChevronRight>
                              </S.StepChevronWrapper>
                            )}
                          </>
                        );
                      })}
                    </S.RouteSteps>
                  </S.CustomScrollContainer>
                </S.ScrollContainerWrapper>
                <S.StepChevronWrapper>
                  <S.StepChevronRight></S.StepChevronRight>
                </S.StepChevronWrapper>
              </S.StepsWrapper>
            );
          })}
      </S.RouteWrapper>
    </S.Wrapper>
  );
};

export default TokenSwapRoute;
