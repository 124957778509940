import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import CacheBuster from 'react-cache-buster';
import { version } from '../../../package.json';
import { Navbar, Footer, LoadingCacheCheck } from 'components';
import * as S from './App.styles';
import Earn from 'containers/Earn';
import Snowball from 'containers/Snowball';
import Farms from 'containers/Farms';
import Bridge from 'containers/Bridge';
import Statistics from 'containers/Statistics';
import Compensation from 'containers/Compensation';
import { useAppDispatch } from 'core/store/hooks';
import { useGaTracker } from 'core/hooks';
import { ThemeProvider } from 'styled-components/macro';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { getStorageValue } from 'utils';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useGaTracker();

  useEffect(() => {
    // checking if user has previously connected his wallet
    const provider = getStorageValue<string>('WEB3_CONNECT_CACHED_PROVIDER');
    if (provider) {
      dispatch(connectToProvider());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const theme = {
    breakpoints: {
      xs: '0px',
      sm: '576px',
      md: '768px',
      lg: '992px',
      xl: '1200px',
      xxl: '2050px',
      xxxl: '2560px',
    },
  };

  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true}
      isVerboseMode={false}
      loadingComponent={<LoadingCacheCheck />} //If not pass, nothing appears at the time of new version check.
    >
      <ThemeProvider theme={theme}>
        <S.Wrapper>
          <S.GlobalStyles />
          <Navbar />
          <S.ContentContainer>
            <Switch>
              <Route path="/" exact component={Earn} />
              <Route path="/snowball" exact component={Snowball} />
              <Route path="/farms" exact component={Farms} />
              <Route path="/statistics" exact component={Statistics} />
              <Route path="/bridge" exact component={Bridge} />
              <Route path="/bridge/:token" exact component={Bridge} />
              <Route path="/compensation" exact component={Compensation} />
            </Switch>
          </S.ContentContainer>
          <Footer />
        </S.Wrapper>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
