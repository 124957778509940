import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

const Card = styled.div`
  position: relative;
  margin: 0 auto;
  background-color: white;
  border-radius: 10px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${down('md')} {
  }
`;

export default Card;
