import styled from 'styled-components/macro';
import { Image, SearchBar } from 'components';
import { down, up } from 'styled-breakpoints';

export const StatisticsWrapper = styled.div`
  padding-top: 150px;
  ${down('xl')} {
    padding-top: 100px;
  }
  ${down('md')} {
    padding-top: 70px;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  ${up('xl')} {
    max-height: 350px;
  }
`;

export const StatisticsCard = styled.div`
  position: relative;
  margin: 0 auto;
  height: auto;
  width: 80%;
  column-gap: 15px;
  background-color: white;
  border-radius: 10px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${up('xl')} {
    width: 1100px;
  }
  ${up('xxl')} {
    width: 1200px;
  }
  ${down('md')} {
    display: block;
    margin: 0 auto;
    max-height: fit-content;
    height: fit-content;
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
  padding: 0 2rem;
  padding-top: 1.8rem;
`;

export const SubTitle = styled.h4`
  font-size: 0.7rem;
  color: #5e5e5e;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 0;
  padding: 0 2rem 1rem 2rem;
`;

export const StatisticsTypeWrapper = styled.div`
  padding: 1rem 2rem;
  background-color: #0000ff08;
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StatisticsTypeSelector = styled.div`
  width: fit-content;
  height: fit-content;
  border: 1px solid #0000001d;
  border-radius: 20px;
`;
export const StatisticsTypeBtn = styled.button<{ isActive?: boolean }>`
  font-size: 1rem;
  outline: 0;
  border: 0;
  background: none;
  background-color: ${(props) => (props.isActive ? '#1e0281b9' : 'none')};
  border-radius: 25px;
  color: ${(props) => (props.isActive ? '#fff' : '#000')};
  font-weight: bold;
  padding: 0.5rem 0.8rem 0.4rem 0.8rem;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    color: ${(props) => (props.isActive ? '#ffffff8f' : '#0000008f')};
  }
`;

export const TokenSearchBar = styled(SearchBar)`
  width: 40%;
`;
