import { createAsyncThunk } from '@reduxjs/toolkit';
import { BN } from 'utils';
import { Contract, User, UserBalance } from '../../../types';

export const updateDepositTokensBalances = createAsyncThunk<
  UserBalance[],
  { user: User; depositTokens: Contract[] }
>('auth/updateDepositTokensBalances', async (payload) => {
  const balances: UserBalance[] = [];

  try {
    for (const c of payload.depositTokens) {
      const balance = await c.contract.balanceOf(payload.user.address);

      balances.push({
        currency: c.address,
        amount: BN(balance, c.decimals),
      });
    }
  } catch (e) {
    console.error(e);
  }

  return balances;
});
