import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  buildRhoTokenRewardsContract,
  buildRhoTokensContracts,
} from 'utils/config';
import { InitContractPayload, Contract } from 'core/types';
import { BigNumber } from 'bignumber.js';
import { setRhoTokensBalanceLoading } from 'core/store/balances/balances';
import { updateRhoBalances } from 'core/store/balances/thunks/updateRhoBalances';

type InitResult = {
  rhoTokenRewards: Contract | null;
  rhoTokens: Contract[];
};

export const initRhoTokens = createAsyncThunk<InitResult, InitContractPayload>(
  'contracts/initRhoTokens',
  async (payload, thunkAPI) => {
    const result: InitResult = {
      rhoTokenRewards: null,
      rhoTokens: [],
    };
    try {
      const { provider, contractsConfig, multicall, userAddress } = payload;

      thunkAPI.dispatch(setRhoTokensBalanceLoading());

      try {
        result.rhoTokenRewards = await buildRhoTokenRewardsContract(
          contractsConfig,
          provider
        );
      } catch (_) {
        console.error('Could not set Rho Token Rewards');
      }

      result.rhoTokens = await buildRhoTokensContracts(
        contractsConfig,
        provider
      );

      thunkAPI.dispatch(
        updateRhoBalances({
          rhoTokens: result.rhoTokens,
          userAddress,
          multicall,
        })
      );

      if (
        result.rhoTokenRewards &&
        result.rhoTokenRewards.contract.rhoTokenInfo
      ) {
        for (const token of result.rhoTokens) {
          const rewardUnlockedBlock = (
            await result.rhoTokenRewards.contract.rhoTokenInfo(token.address)
          ).lockEndBlock;
          token.rewardUnlockedBlock = rewardUnlockedBlock
            ? new BigNumber(rewardUnlockedBlock.toString())
            : undefined;

          const rewardEndBlock = (
            await result.rhoTokenRewards.contract.rhoTokenInfo(token.address)
          ).rewardEndBlock;
          token.rewardEndBlock = rewardEndBlock
            ? new BigNumber(rewardEndBlock.toString())
            : undefined;
        }
      }
    } catch (e) {
      thunkAPI.rejectWithValue('Could not setup Rho Token contracts');
      console.error(e);
    } finally {
      return result;
    }
  }
);
