import styled from 'styled-components/macro';
import { FaChevronRight } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { IoMdWarning } from 'react-icons/io';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000050;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
  background-color: white;
  border-radius: 15px;
  width: 350px;
  padding: 1.2rem 1rem;
  ${down('xs')} {
    padding: 1rem 0.5rem;
    width: calc(100% - 2rem);
  }
`;

export const CloseBtn = styled(IoMdClose)`
  align-self: flex-end;
  opacity: 0.3;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const Logo = styled.img`
  max-width: 70px;
  height: auto;
`;

export const Title = styled.h5`
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
`;

export const WalletsContainer = styled.div`
  width: calc(100% - 2rem);
  padding: 0 1rem;
`;

export const WalletBtn = styled.button`
  display: grid;
  grid-template-columns: 0.3fr 1fr 0.1fr;
  padding: 1rem 1rem;
  align-items: center;
  text-align: left;
  border: none;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 8px;
  cursor: pointer;
  transition: background-color linear 0.2s;

  &:hover {
    background-color: #b8fdff57;
  }
`;

export const WalletLogo = styled.img`
  max-width: 35px;
  height: auto;
`;

export const WalletName = styled.h6`
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
`;

export const RightChevron = styled(FaChevronRight)`
  justify-self: flex-end;
  color: #2552ff;
`;

export const TestnetMessage = styled.div`
  font-size: 1rem;
  width: fit-content;
  padding: 0.5rem 1rem;
  color: #000000;
  text-align: center;
`;

export const WarningIcon = styled(IoMdWarning)`
  padding: 0 0.5rem 0 0;
`;
