import styled from 'styled-components/macro';
import { IoMdClose } from 'react-icons/io';
import { LoadingCircle } from 'components';

export const Wrapper = styled.div`
  position: relative;
  background-color: white;
  border-radius: 5px;
  //border: 1px solid black;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 7px;
  height: 60px;
`;

export const CloseBtn = styled(IoMdClose)`
  opacity: 0.5;
  cursor: pointer;
  position: absolute;
  width: 10px;
  top: 0.2rem;
  right: 0.5rem;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 0.9;
  }
`;

export const BodyWrapper = styled.div`
  display: grid;
  height: calc(100% - 0.4rem);
  padding: 0.2rem 1.5rem 0.2rem 0.5rem;
  grid-template-columns: 0.3fr 2fr;
  align-items: center;
`;

export const StateWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TextWrapper = styled.div`
  padding: 0 0.5rem;
`;

export const NotifLoadingCircle = styled(LoadingCircle)`
  margin: 0 0.5rem;
`;
