import { createAsyncThunk } from '@reduxjs/toolkit';
import { resetBalances } from 'core/store/balances/thunks/resetBalances';

import { AuthState } from '../auth';

export const disconnect = createAsyncThunk<void, void>(
  'auth/disconnect',
  async (_, thunkAPI) => {
    try {
      const auth = (thunkAPI.getState() as { auth: AuthState }).auth;
      if (auth.currentProvider && auth.currentProvider.accessor) {
        if (auth.currentProvider.accessor.close) {
          await auth.currentProvider.accessor.close();
        }
        if (auth.web3modal) {
          await auth.web3modal.clearCachedProvider();
        }
        thunkAPI.dispatch(resetBalances());
      }
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue('Could not disconnect');
    }
  }
);
