/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/ban-types */
import BigNumber from 'bignumber.js';
import { NetworksInfos } from 'core/config/networks';
import { NetworkInfo } from 'core/types';
import { ethers } from 'ethers';
import flurryLogo from '../assets/images/logos/flurry_icon_only.png';
import { getArrayNumberFromStr } from './strings';

export const sendTransactionWithReceipt = async (
  f: Function,
  ...args: any
): Promise<any> => {
  // We send the transaction, and save its hash in the Dapp's state. This
  // way we can indicate that we are waiting for it to be mined.
  const tx = await f(...args);

  // We use .wait() to wait for the transaction to be mined. This method
  // returns the transaction's receipt.
  const receipt = await tx.wait();

  // The receipt, contains a status flag, which is 0 to indicate an error.
  if (receipt.status === 0) {
    // We can't know the exact error that make the transaction fail once it
    // was mined, so we throw this generic one.
    throw new Error('Transaction failed');
  }

  return tx.hash;
};

export const sendTransaction = async (
  f: Function,
  ...args: any
): Promise<any> => {
  // We send the transaction, and save its hash in the Dapp's state. This
  // way we can indicate that we are waiting for it to be mined.
  const tx = await f(...args);

  return tx;
};

export const waitReceipt = async (tx: any): Promise<any> => {
  // We use .wait() to wait for the transaction to be mined. This method
  // returns the transaction's receipt.
  const receipt = await tx.wait();

  // The receipt, contains a status flag, which is 0 to indicate an error.
  if (receipt.status === 0) {
    // We can't know the exact error that make the transaction fail once it
    // was mined, so we throw this generic one.
    throw new Error('Transaction failed');
  }

  return receipt;
};

export const callContractFunction = async (
  f: Function,
  ...args: any
): Promise<any> => {
  let res = undefined;
  try {
    res = await f(...args);
  } catch (e) {
    console.error(f.name);
  } finally {
    return res;
  }
};

export const getNetworkInfo = (
  network: ethers.providers.Network
): NetworkInfo => {
  const networkInfo = NetworksInfos.find((e) => e.chainId === network.chainId);

  if (!networkInfo) {
    return {
      chainId: 1337,
      name: 'flurry',
      label: 'Flurry',
      folder: 'flurry',
      logo: flurryLogo,
      rpcUrl: 'http://192.168.1.154:8545',
      avgBlockTime: 5,
      blocksPerYear: Math.floor((365 * 86400) / 5),
      blocksPerDay: Math.floor(86400 / 5),
    };
  }

  return networkInfo;
};

export const getNetworkInfoFromChainId = (chainId: number): NetworkInfo => {
  const networkInfo = NetworksInfos.find((e) => e.chainId === chainId);

  if (!networkInfo) {
    return {
      chainId: 1337,
      name: 'flurry',
      label: 'Flurry',
      folder: 'flurry',
      logo: flurryLogo,
      rpcUrl: 'http://192.168.1.154:8545',
      avgBlockTime: 5,
      blocksPerYear: Math.floor((365 * 86400) / 5),
      blocksPerDay: Math.floor(86400 / 5),
    };
  }

  return networkInfo;
};

export const getSubgraphUrl = (chainId: number): string | undefined => {
  if (window._env_.SUBGRAPH_IDS) {
    const subgraphsByChain = JSON.parse(window._env_.SUBGRAPH_IDS);

    if (subgraphsByChain.hasOwnProperty(chainId)) {
      return `https://api.thegraph.com/subgraphs/id/${subgraphsByChain[chainId]}`;
    }
  }

  return undefined;
};

export const redirectBasedOnNetwork = (
  chain: number,
  currentChain?: number,
  shouldReload?: boolean
) => {
  const environment = window._env_.ENVIRONMENT;
  const testnetChains = getArrayNumberFromStr(window._env_.TESTNET_CHAINS);
  const mainnetChains = getArrayNumberFromStr(window._env_.MAINNET_CHAINS);
  const chainId = new BigNumber(chain).toNumber();

  if (environment === 'mainnet') {
    const testneturl = window._env_.TESTNET_URL;
    if (
      mainnetChains &&
      mainnetChains.indexOf(chainId) === -1 &&
      testnetChains &&
      testnetChains.indexOf(chainId) >= 0 &&
      testneturl
    ) {
      if (window.location.origin !== testneturl) {
        window.location.replace(testneturl);
      }
    }
  }

  if (environment === 'testnet') {
    const mainnetUrl = window._env_.MAINNET_URL;
    if (
      testnetChains &&
      testnetChains.indexOf(chainId) === -1 &&
      mainnetChains &&
      mainnetChains.indexOf(chainId) >= 0 &&
      mainnetUrl
    ) {
      if (window.location.origin !== mainnetUrl) {
        window.location.replace(mainnetUrl);
      }
    }
  }

  if (shouldReload && chainId !== currentChain) {
    window.location.reload();
  }
};
