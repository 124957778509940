import { createAsyncThunk } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { Contract, FarmContract, InitFarmContractsPayload } from 'core/types';
import { ContractCallContext } from 'ethereum-multicall';
import {
  findUsdPriceOracle,
  BN,
  getValueOfRef,
  calculateFarmsLiquidities,
  calculateFarmsApr,
} from 'utils';
import { buildERC20Contract, buildFarmContract } from 'utils/config';

export const initFarms = createAsyncThunk<
  FarmContract[],
  InitFarmContractsPayload
>('farms/initFarms', async (payload) => {
  let farms: FarmContract[] = [];
  try {
    const {
      lpStaking,
      priceOracles,
      provider,
      contractsConfig,
      flurryToken,
      network,
      multicall,
    } = payload;

    if (!lpStaking) {
      return farms;
    }

    const poolList: string[] = await lpStaking.contract.getPoolList();

    let unreleasedFarms: string[] = [];

    if (window._env_.UNRELEASED_FARMS_BY_CHAIN) {
      const { chainId } = await provider.getNetwork();

      const unreleasedFarmsByChain = JSON.parse(
        window._env_.UNRELEASED_FARMS_BY_CHAIN
      );

      if (unreleasedFarmsByChain.hasOwnProperty(chainId)) {
        unreleasedFarms = unreleasedFarmsByChain[chainId];
      }
    }

    const poolContracts: Contract[] = [];
    // Building contract multicall and farms list
    const multicallContext: ContractCallContext[] = [];
    for (const p of poolList) {
      const pool = await buildFarmContract(contractsConfig, p, provider);
      if (!pool) {
        console.warn(`Farm contract: ${p}, did not match pool list`);
        continue;
      }

      multicallContext.push({
        reference: pool.address,
        contractAddress: pool.address,
        abi: pool.abi ?? [],
        calls: [
          {
            reference: 'token0',
            methodName: 'token0',
            methodParameters: [],
          },
          {
            reference: 'token1',
            methodName: 'token1',
            methodParameters: [],
          },
          {
            reference: 'totalSupply',
            methodName: 'totalSupply',
            methodParameters: [],
          },
        ],
      });
      multicallContext.push({
        reference: `poolInfo-${pool.address}`,
        contractAddress: lpStaking.address,
        abi: lpStaking.abi ?? [],
        calls: [
          {
            reference: 'poolInfo',
            methodName: 'poolInfo',
            methodParameters: [pool.address],
          },
        ],
      });

      poolContracts.push(pool);
    }

    // Executing contract multicall
    const callResults = (await multicall.call(multicallContext)).results;

    for (const pool of poolContracts) {
      try {
        const farmRes = callResults[pool.address]?.callsReturnContext;
        const farmInfo = getValueOfRef(
          callResults[`poolInfo-${pool.address}`]?.callsReturnContext,
          'poolInfo'
        );
        if (!farmRes || !farmInfo) continue;

        const token0 = await buildERC20Contract(
          getValueOfRef(farmRes, 'token0'),
          contractsConfig,
          provider
        );
        const token1 = await buildERC20Contract(
          getValueOfRef(farmRes, 'token1'),
          contractsConfig,
          provider
        );

        const priceOracleToken0 = findUsdPriceOracle(
          priceOracles,
          token0.address
        );
        const priceOracleToken1 = findUsdPriceOracle(
          priceOracles,
          token1.address
        );

        farms.push({
          ...pool,
          unreleased: unreleasedFarms.indexOf(pool.address) !== -1,
          token0,
          token1,
          priceOracleToken0,
          priceOracleToken1,
          rewardUnlockedBlock: farmInfo[7]?.hex
            ? new BigNumber(farmInfo[7].hex)
            : undefined,
          rewardEndBlock: farmInfo[5]?.hex
            ? new BigNumber(farmInfo[5].hex)
            : undefined,
          totalSupply: BN(getValueOfRef(farmRes, 'totalSupply'), pool.decimals),
          apr: undefined,
          liquidity: undefined,
        });
      } catch (_) {
        console.error(`Could not setup farm: ${pool.label}`);
        continue;
      }
    }

    farms = farms.sort((a, b) => (a.unreleased && !b.unreleased ? 1 : -1));

    const liquidities = await calculateFarmsLiquidities({
      farms,
      flurryToken,
      lpStaking,
      multicall,
    });

    for (const f of farms) {
      const liquidity = liquidities.find((l) => l.farmKey === f.key);
      f.liquidity = liquidity?.liquidity;
    }

    const aprs = await calculateFarmsApr({
      farms,
      flurryToken,
      lpStaking,
      priceOracles,
      multicall,
      network,
      flurryUsdPrice: undefined,
    });

    for (const f of farms) {
      const apr = aprs.find((l) => l.farmKey === f.key);
      f.apr = apr?.apr;
    }
  } catch (error) {
    console.error(error);
  } finally {
    return farms;
  }
});
