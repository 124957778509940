import React from 'react';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { showUserModal } from 'core/store/ui/ui';
import styled, { keyframes } from 'styled-components/macro';
import { selectPendingTransactions } from 'core/store/transactions/selectors';
import { sendGAEvent } from 'utils';

const glowing = (color: string) => keyframes`
  0% {
    box-shadow: ${color} 0px 0px 1px;
  }
  50% {
    box-shadow: ${color} 0px 0px 6px;
  }
  100% {
    box-shadow: ${color} 0px 0px 1px;
  }
`;

const UserTransactionsWrapper = styled.button`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: calc(100%);
  padding: 1rem;
  border: none;
  outline: none;
  border-radius: 1rem;
  background-color: #1c009708;
  font-size: 0.85rem;
`;

const Circle = styled.div<{ active: boolean }>`
  background-color: ${(props) => (props.active ? '#5dff72' : '#dddddd')};
  border-radius: 1.5rem;
  height: 0.5rem;
  width: 0.5rem;
  margin-right: 1rem;
  animation: ${(props) => glowing(props.active ? '#5dff72' : '#dddddd')} 1s
    infinite;
  transform: scale(0.8);
`;

type TransactionsButtonProps = {
  onClick: () => void;
};

const TransactionsButton: React.FC<TransactionsButtonProps> = ({ onClick }) => {
  const userModalShowing = useAppSelector((state) => state.ui.userModal.show);

  const user = useAppSelector((state) => state.auth.user);
  const pendingTransactions = useAppSelector((state) =>
    selectPendingTransactions(state)
  );

  const dispatch = useAppDispatch();

  if (!user) {
    return <></>;
  }

  return (
    <UserTransactionsWrapper
      onClick={() => {
        sendGAEvent(
          'Navbar',
          !userModalShowing ? 'Show User Modal' : 'Hide User Modal'
        );
        dispatch(showUserModal(!userModalShowing));
        onClick();
      }}
    >
      <Circle active={pendingTransactions.length > 0} />
      Transactions
    </UserTransactionsWrapper>
  );
};

export default TransactionsButton;
