import { DateRange } from 'core/types';
import { useCallback, useState } from 'react';
import styled from 'styled-components';

type DateRangeFilterProps = {
  initialRange: DateRange;
  onDateRangeChanged: (range: DateRange) => void;
};

const Wrapper = styled.div``;
const ButtonsWrapper = styled.div`
  display: flex;
`;
const RangeBtn = styled.button<{ isActive: boolean }>`
  outline: 0;
  border: 0;
  border-top: 1px solid #0000001d;
  border-bottom: 1px solid #0000001d;
  background: none;
  background-color: ${(props) => (props.isActive ? '#0000001c' : 'none')};
  font-weight: ${(props) => (props.isActive ? 'bold' : 'none')};
  padding: 0.5rem 0.5rem;
  cursor: pointer;
  transition: all 0.2s linear;

  &:hover {
    background-color: #0000001c;
  }
  &:first-child {
    border-radius: 5px 0 0 5px;
    border-left: 1px solid #0000001d;
  }
  &:last-child {
    border-radius: 0 5px 5px 0;
    border-right: 1px solid #0000001d;
  }
`;

const DateRangeFilter: React.FC<DateRangeFilterProps> = ({
  initialRange,
  onDateRangeChanged,
}) => {
  const [currentRange, setCurrentRange] = useState(initialRange);

  const changeRange = useCallback(
    (range: DateRange) => {
      onDateRangeChanged(range);
      setCurrentRange(range);
    },
    [onDateRangeChanged]
  );

  return (
    <Wrapper>
      <ButtonsWrapper>
        <RangeBtn
          onClick={() => changeRange('24h')}
          isActive={currentRange === '24h'}
        >
          24 hour
        </RangeBtn>
        <RangeBtn
          onClick={() => changeRange('7day')}
          isActive={currentRange === '7day'}
        >
          7 day
        </RangeBtn>
        <RangeBtn
          onClick={() => changeRange('30day')}
          isActive={currentRange === '30day'}
        >
          30 day
        </RangeBtn>
        <RangeBtn
          onClick={() => changeRange('90day')}
          isActive={currentRange === '90day'}
        >
          90 day
        </RangeBtn>
      </ButtonsWrapper>
    </Wrapper>
  );
};

export default DateRangeFilter;
