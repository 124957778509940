import { FC } from 'react';
import BalancesUpdater from './balancesUpdater';
import BlockUpdater from './blockUpdater';
import AprUpdater from './aprUpdater';
import SuppliesUpdater from './suppliesUpdater';
import FarmAprsUpdater from './farmAprsUpdater';
import FarmLiquiditiesUpdater from './farmLiquiditiesUpdater';
import FarmSuppliesUpdater from './farmSuppliesUpdater';

const Updaters: FC = () => {
  return (
    <>
      <BlockUpdater />
      <BalancesUpdater />
      <AprUpdater />
      <SuppliesUpdater />
      <FarmAprsUpdater />
      <FarmLiquiditiesUpdater />
      <FarmSuppliesUpdater />
    </>
  );
};

export default Updaters;
