/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Contract, PriceOracle } from 'core/types';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { ethers } from 'ethers';
import { ciEquals, getValueOfRef } from 'utils';

export type ContractConfig = {
  key: string;
  label: string;
  address: string;
  abi: any;
};

const buildContracts = async (
  propertyName: string,
  contractsConfig: any,
  provider: ethers.providers.Web3Provider,
  addressFilter?: string[]
) => {
  const result: Contract[] = [];
  if (!contractsConfig.hasOwnProperty(propertyName)) {
    throw Error(
      `"${propertyName}" property is not defined in contracts config`
    );
  }

  for (const c of contractsConfig[propertyName] as ContractConfig[]) {
    const { key, label, address, abi } = c;
    if (
      addressFilter &&
      addressFilter.length > 0 &&
      address &&
      addressFilter.indexOf(address) === -1
    ) {
      continue;
    }

    const contract = new ethers.Contract(address, abi, provider.getSigner(0));

    let decimals = undefined;
    if (contract.decimals) {
      decimals = await contract.decimals();
    }

    result.push({
      key,
      label,
      address,
      abi,
      contract,
      decimals:
        !decimals || typeof decimals === 'number'
          ? decimals
          : decimals.toNumber(),
    });
  }

  return result;
};

export const getContractsConfig = async (
  url: string,
  networkFolderName: string
) => {
  return (
    await fetch(`${url}/contracts/${networkFolderName}/contracts.json`)
  ).json();
};

export const getContractsManualConfig = async (
  url: string,
  networkFolderName: string
) => {
  return (
    await fetch(
      `${url}/contracts/${networkFolderName}/contracts-manual-config.json`
    )
  ).json();
};

export const buildFarmContract = async (
  contractsConfig: any,
  farmAddress: string,
  provider: ethers.providers.Web3Provider
): Promise<Contract | undefined> => {
  if (!contractsConfig.hasOwnProperty('LPTokens')) {
    throw Error('"LPTokens" property is not defined in contracts config');
  }

  for (const c of contractsConfig.LPTokens as ContractConfig[]) {
    if (ciEquals(c.address, farmAddress)) {
      const { key, label, address, abi } = c;
      const contract = new ethers.Contract(address, abi, provider.getSigner(0));
      const decimals = await contract.decimals();

      return {
        key,
        label,
        address,
        abi,
        contract,
        decimals:
          !decimals || typeof decimals === 'number'
            ? decimals
            : decimals.toNumber(),
      };
    }
  }

  return undefined;
};

export const buildFlurryTokenContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  if (!contractsConfig.hasOwnProperty('FlurryToken')) {
    throw Error('"FlurryToken" property is not defined in contracts config');
  }

  const c = contractsConfig.FlurryToken as ContractConfig;
  const { key, label, address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));
  const decimals = await contract.decimals();

  return {
    key,
    label,
    address,
    abi,
    contract,
    decimals:
      !decimals || typeof decimals === 'number'
        ? decimals
        : decimals.toNumber(),
  };
};

export const buildKyberswapContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  if (!contractsConfig.hasOwnProperty('KyberAggregationExchange')) {
    throw Error(
      '"KyberAggregationExchange" property is not defined in contracts config'
    );
  }

  const c = contractsConfig.KyberAggregationExchange as ContractConfig;
  const { key, label, address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key,
    label,
    address,
    abi,
    contract,
  };
};

const getRewardContract = (
  contractsConfig: any,
  rewardContractKey: string
): any => {
  if (!contractsConfig.hasOwnProperty('Rewards')) {
    throw Error('"Rewards" property is not defined in contracts config');
  }

  if (Array.isArray(contractsConfig.Rewards)) {
    // TODO structure change backward compatitibility (should not be array)
    const c = (contractsConfig.Rewards as ContractConfig[]).find(
      (e) => e.key === rewardContractKey
    );

    if (!c) {
      throw Error(
        `"${rewardContractKey}" contract is not defined in contracts config`
      );
    }

    return c;
  }

  if (!contractsConfig.Rewards.hasOwnProperty('FlurryStakingRewards')) {
    throw Error(
      `"${rewardContractKey}" contract is not defined in contracts config`
    );
  }

  return contractsConfig.Rewards[rewardContractKey];
};

export const buildFlurryStakingContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  const c = getRewardContract(contractsConfig, 'FlurryStakingRewards');

  const { address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key: 'FlurryStaking',
    label: 'Flurry Staking',
    abi,
    address,
    contract,
  };
};

export const buildCompensaterContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  if (!contractsConfig.hasOwnProperty('Compensater')) {
    throw Error('"Compensater" property is not defined in contracts config');
  }

  const c = contractsConfig.Compensater as ContractConfig;
  const { key, label, address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key,
    label,
    address,
    abi,
    contract,
  };
};

export const buildBridgeContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  if (!contractsConfig.hasOwnProperty('Bridge')) {
    throw Error('"Bridge" property is not defined in contracts config');
  }

  const c = contractsConfig.Bridge as ContractConfig;
  const { key, label, address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key,
    label,
    address,
    abi,
    contract,
  };
};

export const buildRegistryContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  if (!contractsConfig.hasOwnProperty('Registry')) {
    throw Error('"Registry" property is not defined in contracts config');
  }

  const c = contractsConfig.Registry as ContractConfig;
  const { key, label, address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key,
    label,
    address,
    abi,
    contract,
  };
};

export const buildLpStakingContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  const c = getRewardContract(contractsConfig, 'LPStakingRewards');

  const { address, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key: 'LPStaking',
    label: 'LP Staking',
    address,
    abi,
    contract,
  };
};

export const buildRhoTokenRewardsContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  const c = getRewardContract(contractsConfig, 'RhoTokenRewards');

  const { address, key, label, abi } = c;
  const contract = new ethers.Contract(address, abi, provider.getSigner(0));

  return {
    key,
    label,
    abi,
    address,
    contract,
  };
};

export const buildVaultContracts = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract[]> => {
  try {
    return await buildContracts('Vaults', contractsConfig, provider);
  } catch (e) {
    throw e;
  }
};

export const buildStablecoinsContracts = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract[]> => {
  try {
    return await buildContracts('StableCoins', contractsConfig, provider);
  } catch (e) {
    throw e;
  }
};

export const buildRhoTokensContracts = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract[]> => {
  try {
    return await buildContracts('RhoTokens', contractsConfig, provider);
  } catch (e) {
    throw e;
  }
};

export const buildDepositTokensContracts = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider,
  addressFilter: string[]
): Promise<Contract[]> => {
  try {
    return await buildContracts(
      'DepositTokens',
      contractsConfig,
      provider,
      addressFilter
    );
  } catch (e) {
    throw e;
  }
};

export const buildUnwinderContract = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider,
  addressFilter: string
): Promise<Contract | null> => {
  try {
    const contracts = await buildContracts(
      'DepositUnwinders',
      contractsConfig,
      provider,
      [addressFilter]
    );
    if (contracts.length > 0) {
      return contracts[0];
    }
    return null;
  } catch (e) {
    throw e;
  }
};

export const buildStrategiesContracts = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider,
  addressFilter: string[]
): Promise<Contract[]> => {
  try {
    return await buildContracts(
      'Strategies',
      contractsConfig,
      provider,
      addressFilter
    );
  } catch (e) {
    throw e;
  }
};

export const buildERC20Contract = async (
  contractAddress: string,
  contractsConfig: any,
  provider: ethers.providers.Web3Provider
): Promise<Contract> => {
  try {
    if (!contractsConfig.hasOwnProperty('InterfaceABI')) {
      throw Error(`"InterfaceABI" property is not defined in contracts config`);
    }
    const abiInterface = contractsConfig.InterfaceABI as any[];
    const abiObj = abiInterface.find(
      (e) => e.key === 'IERC20MetadataUpgradeable'
    );

    if (!abiObj || !abiObj.hasOwnProperty('abi')) {
      throw Error(`IERC20 ABI is not defined in contracts config`);
    }

    const contract = new ethers.Contract(
      contractAddress,
      abiObj.abi,
      provider.getSigner(0)
    );

    return {
      key: contractAddress,
      label: contractAddress,
      address: contractAddress,
      contract,
      abi: abiObj.abi,
    };
  } catch (e) {
    throw e;
  }
};

export const buildPriceOraclesContracts = async (
  contractsConfig: any,
  provider: ethers.providers.Web3Provider,
  multicall: Multicall
): Promise<PriceOracle[]> => {
  if (!contractsConfig.hasOwnProperty('PriceOracles')) {
    throw Error('"PriceOracles" property is not defined in contracts config');
  }
  const result: PriceOracle[] = [];

  const multicallContext: ContractCallContext[] = [];

  for (const c of contractsConfig.PriceOracles as ContractConfig[]) {
    const { key, address, abi, label } = c;
    const splitKey = key.indexOf('-') >= 0 ? key.split('-') : key.split('/'); // TODO structure change backward compatitibility (should be '-' only)

    if (splitKey.length > 1) {
      multicallContext.push({
        reference: address,
        contractAddress: address,
        abi: abi,
        calls: [
          {
            reference: 'decimals',
            methodName: 'decimals',
            methodParameters: [],
          },
        ],
        context: {
          label,
          fromTokenAddress: splitKey[0],
          toTokenAddress: splitKey[1],
          abi,
          address,
        },
      });
    }
  }

  const callResults = (await multicall.call(multicallContext)).results;

  for (const priceOracle of Object.values(callResults)) {
    const decimals = getValueOfRef(priceOracle.callsReturnContext, 'decimals');
    const context = priceOracle.originalContractCallContext.context;
    if (priceOracle && context && decimals) {
      const contract = new ethers.Contract(
        context.address,
        context.abi,
        provider.getSigner(0)
      );
      result.push({
        ...context,
        contract,
        decimals:
          !decimals || typeof decimals === 'number'
            ? decimals
            : decimals.toNumber(),
      });
    }
  }

  return result;
};
