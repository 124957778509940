import * as S from './GeneralStatistics.styles';
import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { ChartData, DateRange } from 'core/types';
import {
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Area,
} from 'recharts';
import { formatAPR, getFormatFromRange } from 'utils';
import DateRangeFilter from './DateRangeFilter';
import { Spacer } from 'components';
import styled from 'styled-components';
import { useStrategiesTotalSupply } from 'core/store/subgraph/hooks/useStrategiesRates';
import NetworkFilter from './NetworkFilter';

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GraphSkeleton = styled.div`
  height: 100%;
  span {
    height: 100%;
  }
`;

const StrategiesSupplyChart: React.FC = () => {
  const [dateRange, setDateRange] = useState<DateRange>('7day');
  const { data, isLoading } = useStrategiesTotalSupply(dateRange);
  const [chain, setChain] = useState(
    !data ? Object.keys(data).map(Number)[0] : undefined
  );

  const chartProps: ChartData | undefined = useMemo(() => {
    const chainToUse = chain ?? Object.keys(data).map(Number)[0];

    if (!data[chainToUse]) return undefined;

    const res: ChartData = {
      data: [],
      metrics: [],
    };

    const dataOfChain = data[chainToUse];

    for (const ts of Object.keys(dataOfChain).map(Number)) {
      const dataValues = dataOfChain[ts];

      const strategyNames = Object.keys(dataValues);
      if (strategyNames.length > 0) {
        res.data.push({
          time: ts,
          ...dataValues,
        });

        if (res.metrics.length === 0) {
          for (const strategy of strategyNames) {
            const color = `#${Math.floor(Math.random() * 16777215).toString(
              16
            )}`;

            res.metrics.push({
              key: strategy,
              color,
            });
          }
        }
      }
    }
    return res;
  }, [chain, data]);

  return (
    <>
      <S.GraphTitle>Strategies Total Supplies</S.GraphTitle>
      <FiltersWrapper>
        <DateRangeFilter
          initialRange={dateRange}
          onDateRangeChanged={(v) => setDateRange(v)}
        />

        <Spacer axis="vertical" size={5} />
        {data ? (
          <NetworkFilter
            initialChains={[Object.keys(data).map(Number)[0]]}
            chains={Object.keys(data).map(Number)}
            onChainsChanged={(c) => setChain(c[0])}
          />
        ) : (
          <Skeleton height={35} />
        )}
      </FiltersWrapper>
      <Spacer axis="vertical" size={10} />
      {!isLoading && chartProps ? (
        <>
          <ResponsiveContainer height="100%" width="100%">
            <AreaChart data={chartProps.data}>
              <defs>
                {chartProps.metrics.map(({ key, color }) => {
                  return (
                    <linearGradient
                      id={`color${key.replaceAll(' ', '')}`}
                      x1="0"
                      y1="0"
                      x2="0"
                      y2="1"
                      key={`color${key.replaceAll(' ', '')}`}
                    >
                      <stop offset="5%" stopColor={color} stopOpacity={0.8} />
                      <stop offset="95%" stopColor={color} stopOpacity={0} />
                    </linearGradient>
                  );
                })}
              </defs>
              {chartProps.metrics.map(({ key, color }) => (
                <Area
                  type="monotone"
                  dataKey={key}
                  stroke={color}
                  fill={`url(#color${key.replaceAll(' ', '')})`}
                  dot={false}
                  strokeWidth="3px"
                  key={key}
                />
              ))}
              <XAxis
                dataKey="time"
                tick={{ fontSize: '0.6rem' }}
                axisLine={false}
                tickMargin={8}
                tickSize={8}
                tickLine={{ stroke: '#0000003e', strokeWidth: '1px' }}
                tickFormatter={(v) => {
                  if (isNaN(v)) {
                    return '';
                  }
                  return format(
                    (v as number) * 1000,
                    getFormatFromRange(dateRange)
                  );
                }}
              />
              <YAxis
                orientation="right"
                tick={{ fontSize: '0.6rem' }}
                tickFormatter={(v) => `${formatAPR(v as number, 2)}%` ?? ''}
                axisLine={false}
                tickSize={8}
                tickLine={{ stroke: '#0000003e', strokeWidth: '1px' }}
                domain={[0, 'dataMax + 0.005']}
              />
              <Tooltip
                cursor
                labelFormatter={(timestamp) => {
                  if (isNaN(timestamp)) {
                    return '';
                  }
                  return format(
                    (timestamp as number) * 1000,
                    'yyyy-MM-dd HH:mm'
                  );
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatter={(v: any) => `${formatAPR(v as number, 2)}%` ?? ''}
                separator=": "
                contentStyle={{
                  fontSize: '14px',
                  padding: '8px',
                  background: 'rgba(255, 255, 255, 0.8)',
                  textAlign: 'right',
                  border: 'none',
                  borderRadius: '4px',
                  color: '#000',
                }}
                wrapperStyle={{
                  top: 0,
                  left: 0,
                }}
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      ) : (
        <GraphSkeleton>
          <Skeleton height="100%" />
        </GraphSkeleton>
      )}
    </>
  );
};

export default StrategiesSupplyChart;
