import { PriceOracle } from 'core/types';
import { ciEquals } from 'utils';

const usdAddress = '0xFFfFfFffFFfffFFfFFfFFFFFffFFFffffFfFFFfF';

export const findUsdPriceOracle = (
  priceOracles: PriceOracle[],
  tokenAddress: string
): PriceOracle | undefined => {
  return priceOracles.find(
    (o) =>
      ciEquals(o.fromTokenAddress, tokenAddress) &&
      ciEquals(o.toTokenAddress, usdAddress)
  );
};

export const findPriceOracle = (
  priceOracles: PriceOracle[],
  fromTokenAddress: string,
  toTokenAddress: string
): PriceOracle | undefined => {
  return priceOracles.find(
    (o) =>
      ciEquals(o.fromTokenAddress, fromTokenAddress) &&
      ciEquals(o.toTokenAddress, toTokenAddress)
  );
};
