import { NetworkInfo } from 'core/types';

export const getEtherscanLink = (
  network?: NetworkInfo,
  transactionHash?: string,
  contractAddress?: string,
  blockNumber?: number
): string | undefined => {
  let baseUrl = '';
  if (network) {
    if (network?.scanUrl) {
      baseUrl = network.scanUrl;
    } else {
      baseUrl = `https://${network.name}.etherscan.io`;
    }
  }

  if (contractAddress) {
    return `${baseUrl}/address/${contractAddress}`;
  }

  if (transactionHash) {
    return `${baseUrl}/tx/${transactionHash}`;
  }

  if (blockNumber) {
    return `${baseUrl}/block/${blockNumber}`;
  }

  return undefined;
};
