import { createAsyncThunk } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { buildFlurryStakingContract } from 'utils/config';
import { InitContractPayload, Contract } from 'core/types';

export const initFlurryStaking = createAsyncThunk<
  Contract | null,
  InitContractPayload
>('contracts/initFlurryStaking', async (payload, thunkAPI) => {
  let flurryStaking: Contract | null = null;
  try {
    const { provider, contractsConfig } = payload;
    flurryStaking = await buildFlurryStakingContract(contractsConfig, provider);

    const rewardEndBlock = await flurryStaking.contract.rewardsEndBlock();
    flurryStaking.rewardEndBlock = rewardEndBlock
      ? new BigNumber(rewardEndBlock.toString())
      : undefined;

    const unlockedBlock = await flurryStaking.contract.lockEndBlock();
    flurryStaking.rewardUnlockedBlock = unlockedBlock
      ? new BigNumber(unlockedBlock.toString())
      : undefined;
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Flurry Staking contract');
    console.error(e);
  } finally {
    return flurryStaking;
  }
});
