import React, { useMemo, useState } from 'react';
import List, { ListRowProps } from 'react-virtualized/dist/commonjs/List';
import * as S from './TokenOptionsList.styles';
import { CurrencyOption } from 'core/types';
import { CustomOption } from './CustomOption';
import { Spacer } from 'components';
import { AutoSizer } from 'react-virtualized/dist/es/AutoSizer';

type TokenOptionListProps = {
  options: CurrencyOption[];
  currentOption: string;
  onSelected: (o: CurrencyOption) => void;
  filter?: string;
};

const optionRender = (
  props: ListRowProps & {
    options: CurrencyOption[];
    currentOption: string;
    onSelect: (op: CurrencyOption) => void;
  }
) => {
  return (
    <CustomOption
      style={props.style}
      option={props.options[props.index]}
      onClick={() => props.onSelect(props.options[props.index])}
      isActive={props.options[props.index].value === props.currentOption}
      key={props.key}
    />
  );
};

const TokenOptionsList: React.FC<TokenOptionListProps> = ({
  options,
  currentOption,
  onSelected,
  filter,
}) => {
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');

  const filteredOptions = useMemo(() => {
    let reducedOptions = options;
    if (filter) {
      const loweredFiltered = filter.toLowerCase();
      reducedOptions = reducedOptions.filter(
        (token) =>
          token.value.toLowerCase().includes(loweredFiltered) ||
          token.label.toLowerCase().includes(loweredFiltered) ||
          (token.symbol && token.symbol.toLowerCase().includes(loweredFiltered))
      );
    }

    const sortedOptions = reducedOptions.sort((a, b) => {
      if (a.symbol && b.symbol) {
        if (sortOrder === 'asc') {
          return a.symbol > b.symbol ? 1 : b.symbol > a.symbol ? -1 : 0;
        } else {
          return a.symbol > b.symbol ? -1 : b.symbol > a.symbol ? 1 : 0;
        }
      }
      return 0;
    });

    return sortedOptions.filter((o) => !o.disabled);
  }, [filter, options, sortOrder]);

  return (
    <>
      <S.TableHeader>
        <div>Token Name</div>
        <S.SortBtn
          onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
        >
          {sortOrder === 'asc' ? <S.ArrowUp /> : <S.ArrowDown />}
        </S.SortBtn>
      </S.TableHeader>
      <Spacer axis="vertical" size={10} />
      <S.TableWrapper>
        {filteredOptions.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <List
                rowCount={filteredOptions.length}
                rowRenderer={(props) =>
                  optionRender({
                    ...props,
                    options: filteredOptions,
                    onSelect: onSelected,
                    currentOption,
                  })
                }
                height={height}
                rowHeight={60}
                width={width}
                overscanRowCount={15}
              />
            )}
          </AutoSizer>
        ) : (
          <S.NoValues>No token found.</S.NoValues>
        )}
      </S.TableWrapper>
    </>
  );
};

export default TokenOptionsList;
