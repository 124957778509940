import { createSlice } from '@reduxjs/toolkit';
import type { NetworkInfo, ProviderAccessor, User } from '../../types';

import Web3Modal from '@flurryfinance/web3modal';
import { disconnect } from './thunks/disconnect';
import { connectToProvider } from './thunks/connectToProvider';
import { updateFarmsTokenBalances } from './thunks/updateFarmsTokenBalances';
import { updateRewardsBalances } from './thunks/updateRewardsBalances';
import { updateUser } from './thunks/updateUser';
import { updateUserFlurryStakingApproval } from './thunks/updateUserFlurryStakingApproval';
import { updateUserConvertApproval } from './thunks/updateUserConvertApproval';
import { updateUserBridgeApproval } from './thunks/updateUserBridgeApproval';
import { updateUserFarmApproval } from './thunks/updateUserFarmApproval';
import { switchChain } from './thunks/switchChain';
import { updateDepositTokensBalances } from './thunks/updateDepositTokensBalances';

export type BalancesLoadingStates = {
  stablecoins: boolean;
  flurryStaking: boolean;
  farmsToken: boolean;
  rewards: boolean;
};

export type ApprovalsLoadingStates = {
  convert: boolean;
  flurryToken: boolean;
  farmsToken: boolean;
  bridge: boolean;
};

export type AuthState = {
  currentProvider: ProviderAccessor | undefined;
  user: User | undefined;
  network: NetworkInfo | undefined;
  balancesStates: BalancesLoadingStates;
  approvalsStates: ApprovalsLoadingStates;
  error: string | null;
  web3modal: Web3Modal | null;
};

export const initialAuthState: AuthState = {
  currentProvider: undefined,
  user: undefined,
  network: undefined,
  balancesStates: {
    stablecoins: false,
    flurryStaking: false,
    farmsToken: false,
    rewards: false,
  },
  approvalsStates: {
    convert: false,
    flurryToken: false,
    farmsToken: false,
    bridge: false,
  },
  error: null,
  web3modal: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(connectToProvider.pending, (state) => {
        // Connection started
        state.balancesStates = {
          stablecoins: true,
          flurryStaking: true,
          farmsToken: true,
          rewards: true,
        };
        state.approvalsStates = {
          convert: true,
          flurryToken: true,
          farmsToken: true,
          bridge: true,
        };
        state.error = null;
      })
      .addCase(connectToProvider.fulfilled, (state, action) => {
        // Connection succeeded
        state.currentProvider = action.payload.provider;
        state.user = action.payload.user;
        state.network = action.payload.network;
        state.web3modal = action.payload.web3modal;
      })
      .addCase(connectToProvider.rejected, (state) => {
        // Connection failed
        state.error = null;
        state.balancesStates = {
          stablecoins: false,
          flurryStaking: false,
          farmsToken: false,
          rewards: false,
        };
        state.approvalsStates = {
          convert: false,
          flurryToken: false,
          farmsToken: false,
          bridge: false,
        };
      })
      .addCase(switchChain.fulfilled, (state, action) => {
        state.network = action.payload;
      })
      .addCase(disconnect.fulfilled, (state) => {
        state.currentProvider = undefined;
        state.user = undefined;
        state.network = initialAuthState.network;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state) => {
        state.user = undefined;
      })
      .addCase(updateFarmsTokenBalances.fulfilled, (state, action) => {
        if (state.user) {
          for (const newBalance of action.payload) {
            const idx = state.user.balances.findIndex(
              (e) => e.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.user.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.user.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.user.balances.push(newBalance);
            }
          }
          if (state.balancesStates.farmsToken) {
            state.balancesStates.farmsToken = false;
          }
        }
      })
      .addCase(updateRewardsBalances.fulfilled, (state, action) => {
        if (state.user) {
          for (const newBalance of action.payload) {
            const idx = state.user.balances.findIndex(
              (e) => e.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.user.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.user.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.user.balances.push(newBalance);
            }
          }
          if (state.balancesStates.rewards) {
            state.balancesStates.rewards = false;
          }
        }
      })
      .addCase(updateUserFlurryStakingApproval.pending, (state) => {
        state.approvalsStates.flurryToken = true;
      })
      .addCase(updateUserFlurryStakingApproval.fulfilled, (state, action) => {
        if (state.user) {
          state.user.approvals = action.payload;
          state.approvalsStates.flurryToken = false;
        }
      })
      .addCase(updateUserConvertApproval.pending, (state) => {
        state.approvalsStates.convert = true;
      })
      .addCase(updateUserConvertApproval.fulfilled, (state, action) => {
        if (state.user) {
          state.user.approvals = action.payload;
          state.approvalsStates.convert = false;
        }
      })
      .addCase(updateUserBridgeApproval.pending, (state) => {
        state.approvalsStates.bridge = true;
      })
      .addCase(updateUserBridgeApproval.fulfilled, (state, action) => {
        if (state.user) {
          state.user.approvals = action.payload;
          state.approvalsStates.bridge = false;
        }
      })
      .addCase(updateUserFarmApproval.pending, (state) => {
        state.approvalsStates.farmsToken = true;
      })
      .addCase(updateUserFarmApproval.fulfilled, (state, action) => {
        if (state.user) {
          state.user.approvals = action.payload;
          state.approvalsStates.farmsToken = false;
        }
      })
      .addCase(updateDepositTokensBalances.fulfilled, (state, action) => {
        if (state.user) {
          for (const newBalance of action.payload) {
            const idx = state.user.balances.findIndex(
              (e) => e.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.user.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.user.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.user.balances.push(newBalance);
            }
          }
          if (state.balancesStates.stablecoins) {
            state.balancesStates.stablecoins = false;
          }
        }
      });
  },
});

export default authSlice.reducer;
