import { createAsyncThunk } from '@reduxjs/toolkit';
import { FarmContract, FarmTotalSupplies } from 'core/types';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { BN, getValueOfRef } from 'utils';

export const updateFarmSupplies = createAsyncThunk<
  FarmTotalSupplies[],
  { farms: FarmContract[]; multicall: Multicall }
>('contracts/updateFarmSupplies', async (payload) => {
  const result: FarmTotalSupplies[] = [];
  const { farms, multicall } = payload;
  if (!farms || farms.length === 0) {
    return [];
  }
  try {
    // Building contract multicall
    const multicallContext: ContractCallContext[] = [];
    for (const f of farms) {
      if (f.unreleased) {
        continue;
      }
      multicallContext.push({
        reference: f.address,
        contractAddress: f.address,
        abi: f.abi ?? [],
        calls: [
          {
            reference: 'totalSupply',
            methodName: 'totalSupply',
            methodParameters: [],
          },
        ],
        context: {
          key: f.key,
          decimals: f.decimals,
        },
      });
    }

    // Executing contract multicall
    const callResults = (await multicall.call(multicallContext)).results;

    // Formating all contract result into usable value
    for (const supplies of Object.values(callResults)) {
      const res = getValueOfRef(supplies.callsReturnContext, 'totalSupply');

      if (res) {
        const context = supplies.originalContractCallContext.context;
        const totalSupply = BN(res, context.decimals);
        result.push({
          farmKey: context.key,
          totalSupply,
        });
      }
    }
  } catch (e) {
    console.error(e);
  } finally {
    return result;
  }
});
