import { createAsyncThunk } from '@reduxjs/toolkit';
import { Approval, User } from 'core/types';
import BigNumber from 'bignumber.js';
import { BN } from 'utils';
import { ContractsState } from 'core/store/contracts/contracts';

export const updateUserFlurryStakingApproval = createAsyncThunk<
  Approval[],
  { amount: BigNumber; user: User | undefined }
>('auth/updateUserFlurryStakingApproval', async (payload, thunkAPI) => {
  const approvals: Approval[] = [];
  const { user, amount } = payload;
  const contracts = (thunkAPI.getState() as { contracts: ContractsState })
    .contracts;

  try {
    if (!user) {
      throw Error('No User found');
    }

    const flurry = contracts.flurryToken;
    const stakingReward = contracts.flurryStaking;

    if (!stakingReward) {
      throw Error('No Flurry Staking contract found');
    }
    if (!flurry) {
      throw Error('No Flurry contract found');
    }

    const allowance = BN(
      await flurry.contract.allowance(user.address, stakingReward.address),
      flurry.decimals
    );

    approvals.push({
      contractAddress: stakingReward.address,
      isApproved: allowance.gte(amount),
    });
  } catch (e) {
    console.error(e);
  }

  return approvals;
});
