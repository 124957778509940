import { DateRange } from 'core/types';

export const formatDate = (date: string): string => {
  const d = new Date(date),
    year = d.getFullYear();
  let month = '' + (d.getMonth() + 1),
    day = '' + d.getDate();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
};

export const secToString = (sec: number): string => {
  const days = Math.floor(sec / (60 * 60 * 24));
  const hours = Math.floor((sec % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((sec % (60 * 60)) / 60);
  const seconds = Math.floor(sec % 60);

  if (sec < 0) {
    return '';
  }

  let resStr = seconds + 's';
  if (minutes > 0) {
    resStr = minutes + 'm ' + resStr;
  }
  if (hours > 0) {
    resStr = hours + 'h ' + resStr;
  }
  if (days > 0) {
    resStr = days + 'd ' + resStr;
  }

  return resStr;
};

export const secToRelevantString = (sec: number): string | undefined => {
  const days = Math.floor(sec / (60 * 60 * 24));
  const hours = Math.floor((sec % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((sec % (60 * 60)) / 60);

  if (sec < 0) {
    return undefined;
  }

  if (days > 0) {
    return `${days + 1} ${days + 1 > 1 ? 'days' : 'day'}`;
  }
  if (hours > 0) {
    return `${hours + 1} ${hours + 1 > 1 ? 'hours' : 'hour'}`;
  }
  if (minutes > 0) {
    return `${minutes + 1} ${minutes + 1 > 1 ? 'minutes' : 'minute'}`;
  }
  return undefined;
};

export const tsTohhmm = (ts: number): string => {
  const date = new Date(ts);
  let hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  const minutesStr = minutes < 10 ? '0' + minutes : minutes;
  const secondsStr = seconds < 10 ? '0' + seconds : seconds;
  const strTime = hours + ':' + minutesStr + ':' + secondsStr + ' ' + ampm;
  return strTime;
};

export const getPeriodTimestamps = (
  startTs: number,
  period: 'hour' | 'day' | 'week' | 'month',
  amountOfTs: number,
  inSecond: boolean
): number[] => {
  const dateArray: number[] = [];

  let periodInMs = 3.6e6;
  switch (period) {
    case 'hour':
      periodInMs = 3.6e6;
      break;
    case 'day':
      periodInMs = 8.64e7;
      break;
    case 'week':
      periodInMs = 6.048e8;
      break;
    case 'month':
      periodInMs = 2.628e9;
      break;
  }

  let currentDate = startTs;

  for (let i = 0; i < amountOfTs; i++) {
    if (inSecond) {
      dateArray.push(Math.floor(currentDate / 1000));
    } else {
      dateArray.push(currentDate);
    }
    currentDate = currentDate - periodInMs;
  }

  return dateArray;
};

export const getFormatFromRange = (range: DateRange): string => {
  switch (range) {
    case '24h':
      return 'HH:mm';
    case '7day':
    case '30day':
    case '90day':
      return 'dd-MM';
  }
};
