/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { getContractsConfig } from 'utils/config';
import { getNetworkInfo } from 'utils/web3';
import { initVaults } from './inits/initVaults';
import { initRhoTokens } from './inits/initRhoTokens';
import { initFlurryToken } from './inits/initFlurryToken';
import { initFlurryStaking } from './inits/initFlurryStaking';
import { initLp } from './inits/initLp';
import { Multicall } from 'ethereum-multicall';
import { initStablecoins } from './inits/initStablecoins';
import { initBridge } from './inits/initBridge';
import { initKyberswap } from './inits/initKyberswap';
import { initCompensater } from './inits/initCompensater';
import { initOtherTokens } from './inits/initOtherTokens';

export const initContracts = createAsyncThunk<
  void,
  { accessor: any; multicall: Multicall; userAddress: string }
>('contracts/initContracts', async (payload, thunkAPI) => {
  try {
    const { accessor, multicall, userAddress } = payload;
    const provider = new ethers.providers.Web3Provider(accessor);

    const currentNetwork = await provider.getNetwork();
    const network = getNetworkInfo(currentNetwork);

    const url = window._env_.CONTRACTS_CONFIG_URL;
    if (!url) {
      throw Error('No contracts config URL found!');
    }

    const contractsConfig = await getContractsConfig(url, network.folder);

    await Promise.all([
      thunkAPI.dispatch(
        initLp({
          provider,
          contractsConfig,
          network,
          multicall,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initKyberswap({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initVaults({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initStablecoins({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initOtherTokens({
          network,
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initRhoTokens({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initFlurryToken({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initFlurryStaking({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initBridge({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
      thunkAPI.dispatch(
        initCompensater({
          provider,
          multicall,
          contractsConfig,
          userAddress,
        })
      ),
    ]);
  } catch (e) {
    console.error(e);
  }
});
