import EarnBackgroundWebp from 'assets/images/earn_bg.webp';
import EarnBackgroundPng from 'assets/images/earn_bg.png';
import * as S from './Bridge.styles';
import { Spacer } from 'components';
import BridgeForm from './BridgeForm/BridgeForm';
import BridgeInfos from './BridgeInfos/BridgeInfos';

const Bridge: React.FC = () => {
  return (
    <S.BridgeWrapper>
      <S.BackgroundImage
        src={EarnBackgroundWebp}
        fallback={EarnBackgroundPng}
        alt="bridge background image"
      />
      <S.BridgeCard>
        <S.BridgeInfos>
          <BridgeInfos />
        </S.BridgeInfos>
        <S.BridgeForm>
          <BridgeForm />
        </S.BridgeForm>
      </S.BridgeCard>
      <Spacer axis="vertical" size={50} />
    </S.BridgeWrapper>
  );
};

export default Bridge;
