import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EarnAnnouncement, NotificationMessage } from 'core/types';
import { getEarnAnnouncements } from './thunks/getEarnAnnouncements';
import { getNotificationMessages } from './thunks/getNotificationMessages';

export type ContentState = {
  earnAnnouncements: EarnAnnouncement[];
  notificationMessages: NotificationMessage[];
  loadingStates: {
    earnAnnouncements: boolean;
    notificationMessages: boolean;
  };
  errors: {
    earnAnnouncements: string | undefined;
    notificationMessages: string | undefined;
  };
};

const initialState: ContentState = {
  earnAnnouncements: [],
  notificationMessages: [],
  loadingStates: {
    earnAnnouncements: false,
    notificationMessages: false,
  },
  errors: {
    earnAnnouncements: undefined,
    notificationMessages: undefined,
  },
};

const contentSlice = createSlice({
  name: 'content',
  initialState,
  reducers: {
    addEarnAnnouncement: (state, action: PayloadAction<EarnAnnouncement>) => {
      const idx = state.earnAnnouncements.findIndex(
        (e) => e.id === action.payload.id
      );
      if (idx === -1) state.earnAnnouncements.push(action.payload);
    },
    updateEarnAnnouncement: (
      state,
      action: PayloadAction<EarnAnnouncement>
    ) => {
      const idx = state.earnAnnouncements.findIndex(
        (e) => e.id === action.payload.id
      );
      if (idx !== -1) state.earnAnnouncements[idx] = action.payload;
    },
    deleteEarnAnnouncement: (state, action: PayloadAction<number>) => {
      const idx = state.earnAnnouncements.findIndex(
        (e) => e.id === action.payload
      );
      if (idx !== -1) state.earnAnnouncements.splice(idx, 1);
    },
    addNotificationMessage: (
      state,
      action: PayloadAction<NotificationMessage>
    ) => {
      const idx = state.notificationMessages.findIndex(
        (e) => e.id === action.payload.id
      );
      if (idx === -1) state.notificationMessages.push(action.payload);
    },
    updateNotificationMessage: (
      state,
      action: PayloadAction<NotificationMessage>
    ) => {
      const idx = state.notificationMessages.findIndex(
        (e) => e.id === action.payload.id
      );
      if (idx !== -1) state.notificationMessages[idx] = action.payload;
    },
    deleteNotificationMessage: (state, action: PayloadAction<number>) => {
      const idx = state.notificationMessages.findIndex(
        (e) => e.id === action.payload
      );
      if (idx !== -1) state.notificationMessages.splice(idx, 1);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEarnAnnouncements.pending, (state) => {
        state.loadingStates.earnAnnouncements = true;
      })
      .addCase(getEarnAnnouncements.fulfilled, (state, action) => {
        state.loadingStates.earnAnnouncements = false;
        state.errors.earnAnnouncements = undefined;
        state.earnAnnouncements = action.payload;
      })
      .addCase(getEarnAnnouncements.rejected, (state, action) => {
        state.loadingStates.earnAnnouncements = false;
        state.errors.earnAnnouncements = action.error.message;
      })
      .addCase(getNotificationMessages.pending, (state) => {
        state.loadingStates.notificationMessages = true;
      })
      .addCase(getNotificationMessages.fulfilled, (state, action) => {
        state.loadingStates.notificationMessages = false;
        state.errors.notificationMessages = undefined;
        state.notificationMessages = action.payload;
      })
      .addCase(getNotificationMessages.rejected, (state, action) => {
        state.loadingStates.notificationMessages = false;
        state.errors.notificationMessages = action.error.message;
      });
  },
});

export const {
  updateEarnAnnouncement,
  updateNotificationMessage,
  addEarnAnnouncement,
  addNotificationMessage,
  deleteEarnAnnouncement,
  deleteNotificationMessage,
} = contentSlice.actions;

export default contentSlice.reducer;

export const ENTITIES = {
  EARN_ANNOUNCEMENTS: 'earn-announcements',
  NOTIFICATION_MESSAGES: 'notification-messages',
};

export const CONTENT_WS = {
  CONNECT_SOCKET: 'CONNECT_SOCKET',
  SUBSCRIBE: 'SUBSCRIBE_SOCKET',
};
