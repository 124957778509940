import BigNumber from 'bignumber.js';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { SearchBar, Spacer, ComingSoon } from 'components';
import { FarmRow, FarmSorting, Sorting, User } from 'core/types';
import { ciEquals, sendGAEvent } from 'utils';
import FarmRowUI from '../FarmRow';
import * as S from './FarmsTable.styles';
import HeaderItem from './HeaderItem';
import { useAppDispatch } from 'core/store/hooks';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';

type FormsTableProps = {
  farms: FarmRow[];
  user?: User;
  loading: boolean;
};

const sortBN = (
  a: BigNumber | undefined,
  b: BigNumber | undefined,
  sortingType: 'asc' | 'desc'
): number => {
  if (!a && !b) {
    return 0;
  }
  if (!a) {
    return sortingType === 'asc' ? -1 : 1;
  }
  if (!b) {
    return sortingType === 'asc' ? 1 : -1;
  }

  return sortingType === 'asc' ? a.minus(b).toNumber() : b.minus(a).toNumber();
};

const FarmsTable: React.FC<FormsTableProps> = ({ farms, user, loading }) => {
  const dispatch = useAppDispatch();
  const [sortedFarms, setSortedFarms] = useState(farms);
  const [filteredFarms, setFilteredFarms] = useState(farms);
  const [firstSetup, setFirstSetup] = useState(true);
  const [searchText, setSearchText] = useState('');

  const [currentColumnnSorted, setCurrentColumnnSorted] = useState<
    FarmSorting | undefined
  >(undefined);

  const sortFarms = useCallback(
    (farmColumn: FarmSorting, sortingType: Sorting) => {
      setCurrentColumnnSorted(farmColumn);

      if (sortingType === 'none') {
        setSortedFarms(farms);
        return;
      }

      let newList = [...farms];
      if (farmColumn !== 'farm') {
        newList = farms.filter((f) => !f.unreleased);
      }

      switch (farmColumn) {
        case 'farm':
          if (sortingType === 'asc') {
            newList.sort((a, b) => (a.farm.label > b.farm.label ? 1 : -1));
          } else if (sortingType === 'desc') {
            newList.sort((a, b) => (a.farm.label < b.farm.label ? 1 : -1));
          }
          break;
        case 'liquidity':
          newList.sort((a, b) => sortBN(a.liquidity, b.liquidity, sortingType));
          break;
        case 'rewardApr':
          newList.sort((a, b) =>
            sortingType === 'asc'
              ? (a.rewardAPR ?? 0) - (b.rewardAPR ?? 0)
              : (b.rewardAPR ?? 0) - (a.rewardAPR ?? 0)
          );
          break;
        case 'staking':
          newList.sort((a, b) => sortBN(a.staked, b.staked, sortingType));
          break;
        case 'earned':
          newList.sort((a, b) => sortBN(a.earned, b.earned, sortingType));
          break;
      }

      if (farmColumn === 'farm') {
        setSortedFarms(newList);
      } else {
        setSortedFarms(newList.concat(farms.filter((f) => f.unreleased)));
      }
    },
    [farms]
  );

  useEffect(() => {
    if (firstSetup && !loading && farms.length > 0) {
      setSortedFarms(farms);
      setFirstSetup(false);
    }
  }, [farms, firstSetup, loading, sortedFarms]);

  useEffect(() => {
    if (firstSetup) {
      return;
    }

    // update sorted farm when farms update
    const updatedFarms = [...sortedFarms];
    for (let i = 0; i < updatedFarms.length; i++) {
      const newFarm = farms.find((e) =>
        ciEquals(e.farm.address, updatedFarms[i].farm.address)
      );
      if (newFarm) {
        updatedFarms[i] = newFarm;
      }
    }

    setSortedFarms(updatedFarms);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [farms]);

  useEffect(() => {
    if (!searchText || searchText === '') {
      setFilteredFarms(sortedFarms);
      return;
    }

    const clonedFilteredFarms = sortedFarms.filter(
      (f) => f.farm.label.toLowerCase().indexOf(searchText.toLowerCase()) !== -1
    );

    setFilteredFarms(clonedFilteredFarms);
  }, [searchText, sortedFarms]);

  const rows = useMemo(() => {
    if (loading) {
      return Array.from(Array(6).keys()).map((e) => (
        <FarmRowUI key={e.toString() + '_loadingrow'} loading />
      ));
    }

    if (filteredFarms.length === 0) {
      return <S.EmptyFarms>No farms found</S.EmptyFarms>;
    }

    return filteredFarms.map((row) => {
      if (!row.farm.unreleased) {
        return (
          <FarmRowUI
            unreleased={row.farm.unreleased}
            key={row.farm.address + '_row'}
            user={user}
            loading={row.loading}
            logoOne={row.logo1}
            logoTwo={row.logo2}
            title={row.farm.label}
            farmAddress={row.farm.address}
            disabled={loading}
            rewards={row.earned}
            apr={row.farm.apr}
            liquidity={row.farm.liquidity}
            totalSupply={row.farm.totalSupply}
            availableBalance={row.availableBalance}
            stakedBalance={row.staked}
            contractKey={row.farm.key}
            liquidityUrl={row.liquidityUrl}
            decimals={row.farm.decimals}
          />
        );
      }
    });
  }, [loading, filteredFarms, user]);

  return (
    <>
      <SearchBar
        key="farm-table-searchbar"
        onChange={(v) => setSearchText(v)}
        placeholder="Search for a Farm"
      />
      <Spacer key="farm-table-spacer" axis="vertical" size={10} />
      <S.FarmTableWrapper key="farm-table">
        {!user && (
          <S.ConnectWalletWrapper>
            <S.ConnectButton
              onClick={() => {
                sendGAEvent('Farms', 'Connect');
                dispatch(connectToProvider());
              }}
            >
              Connect Wallet
            </S.ConnectButton>
          </S.ConnectWalletWrapper>
        )}
        <ComingSoon chains={[1]} />
        <S.Header>
          <S.HeaderItem></S.HeaderItem>
          <HeaderItem
            farmSorting="farm"
            isCurrentlySorted={currentColumnnSorted === 'farm'}
            onClick={(farmColumn, sortingType) =>
              sortFarms(farmColumn, sortingType)
            }
          >
            Farm
          </HeaderItem>
          <HeaderItem
            farmSorting="liquidity"
            isCurrentlySorted={currentColumnnSorted === 'liquidity'}
            onClick={(farmColumn, sortingType) =>
              sortFarms(farmColumn, sortingType)
            }
            hideOnTablet
            hideOnMobile
          >
            Liquidity
          </HeaderItem>
          <HeaderItem
            farmSorting="rewardApr"
            isCurrentlySorted={currentColumnnSorted === 'rewardApr'}
            onClick={(farmColumn, sortingType) =>
              sortFarms(farmColumn, sortingType)
            }
            hideOnMobile
          >
            FLURRY APY
          </HeaderItem>
          <HeaderItem
            farmSorting="staking"
            isCurrentlySorted={currentColumnnSorted === 'staking'}
            onClick={(farmColumn, sortingType) =>
              sortFarms(farmColumn, sortingType)
            }
            hideOnTablet
            hideOnMobile
          >
            Staking
          </HeaderItem>
          <HeaderItem
            farmSorting="earned"
            isCurrentlySorted={currentColumnnSorted === 'earned'}
            onClick={(farmColumn, sortingType) =>
              sortFarms(farmColumn, sortingType)
            }
            hideOnMobile
          >
            Earned
          </HeaderItem>
        </S.Header>
        {rows}
      </S.FarmTableWrapper>
    </>
  );
};

export default FarmsTable;
