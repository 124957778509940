import EarnBackgroundWebp from 'assets/images/earn_bg.webp';
import EarnBackgroundPng from 'assets/images/earn_bg.png';
import * as S from './Compensation.styles';
import { Spacer } from 'components';
import CompensationTable from './CompensationTable/CompensationTable';
import { useFarmsStats } from './controller/useFarmsStats';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';

const getFarmsBasedOnNetwork = (chain?: number) => {
  switch (chain) {
    case 56:
      return [
        '0x6733E57E736165C2d5D816CbDeC4FDe4eC02432B',
        '0x61Ec1FcfF6555E222968A4d413e906A8724B16c9',
        '0xF163FaD45f72A639fc4Bb1b65C7dD2E844750C6d',
        '0x1Ec3108E72F2a000Befa543b3957c8C1D854ccF9',
      ];
    case 97:
      return [
        '0x6e1932fafcDC44118B2a7251B6829C7b4a9eeAf7',
        '0xeECdA4636E04c187259f8349f783fA91C9CFd3ae',
        '0x31c0a65196D113918F0A1372066c6e819A256c9c',
        '0xB348dd894983d03aF75D8591ea6ed4675036086c',
      ];
    case 1401:
      return [
        '0x769376618A27E7690FAa429acAc117490F679D0A',
        '0x1A2c7CC8Df8d349406E0d4D26E3A3958665933b7',
        '0xb8482E8b4B8FeAB6Ea3DB91CF7Acd61d1778b738',
        '0xcB0b4A77834c781a4E2408570AE0bb66C8f0078D',
      ];
    default:
      return [];
  }
};

const Compensation: React.FC = () => {
  const { network, user } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const { farms, loading } = useFarmsStats(
    getFarmsBasedOnNetwork(network?.chainId)
  );

  return (
    <S.BridgeWrapper>
      <S.BackgroundImage
        src={EarnBackgroundWebp}
        fallback={EarnBackgroundPng}
        alt="compensation background image"
      />
      <S.BridgeCard>
        <S.Title>Compensations</S.Title>
        {!user && (
          <>
            <Spacer axis="vertical" size={20} />
            <S.ConnectButton
              onClick={() => {
                dispatch(connectToProvider());
              }}
            >
              Connect Wallet
            </S.ConnectButton>
          </>
        )}
        {user &&
          network &&
          network.chainId !== 56 &&
          network.chainId !== 97 &&
          network.chainId !== 1401 && (
            <>
              <Spacer axis="vertical" size={20} />
              <S.NetworkMsg>
                Compensations are only available on <u>Binance Smart Chain</u>,
                please switch your network accordingly.
              </S.NetworkMsg>
            </>
          )}
        <CompensationTable farms={farms} loading={loading} />
      </S.BridgeCard>
      <Spacer axis="vertical" size={50} />
    </S.BridgeWrapper>
  );
};

export default Compensation;
