import React from 'react';
import * as S from './FarmRow.styles';
import { EtherscanLink, Spacer } from 'components';
import FarmIcon from '../../../components/FarmIcon';
import { formatBNToString, sendGAEvent } from 'utils';
import Skeleton from 'react-loading-skeleton';
import { FarmType } from '../controller/useFarmsStats';
import unstakeFarmForCompensation from '../controller/unstakeFarmForCompensation';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import approveCompensation from '../controller/approveCompensation';
import compensate from '../controller/compensate';

interface FarmRowProps {
  loading?: boolean;
  farm?: FarmType;
}

const LoadingRow = (
  <>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
  </>
);

const FarmRow: React.FC<FarmRowProps> = ({ loading, farm }) => {
  const dispatch = useAppDispatch();
  const { lpStaking, compensater } = useAppSelector((state) => state.contracts);
  const { currentProvider } = useAppSelector((state) => state.auth);

  return (
    <>
      <S.Wrapper>
        {loading ? (
          LoadingRow
        ) : farm ? (
          <>
            <FarmIcon
              foregroundIcon={farm.logo1 ?? ''}
              backgroundIcon={farm.logo0 ?? ''}
            />
            <S.FarmTitle>
              {farm.label}
              <EtherscanLink contractAddress={farm.address} />
            </S.FarmTitle>
            <S.Value>
              <S.ValueTitle>Staked LP Tokens:</S.ValueTitle>
              <S.StakedTokenValue>
                {farm.staking
                  ? `${formatBNToString(farm.staking, 8, false)}`
                  : '-'}
              </S.StakedTokenValue>
              <Spacer axis="vertical" size={10} />
              <S.UnstakeBtn
                disabled={farm.staking.isZero()}
                onClick={() => {
                  sendGAEvent('Compensation', 'Unstake');
                  unstakeFarmForCompensation({
                    lpAddress: farm.address,
                    lpDecimals: farm.decimals,
                    label: farm.label,
                    amount: farm.staking,
                    lpStaking,
                    dispatch,
                  });
                }}
              >
                Unstake
              </S.UnstakeBtn>
            </S.Value>
            <S.Value>
              {farm.staking.isZero() ? (
                <>
                  <S.ValueTitle>Compensation:</S.ValueTitle>
                  <S.StakedTokenValue>
                    {farm.compensation
                      ? `${formatBNToString(farm.compensation, 6, false)} ${
                          farm.rhoToken
                        }`
                      : '-'}
                  </S.StakedTokenValue>
                  <Spacer axis="vertical" size={10} />
                  {farm.balance.isZero() || farm.allowance.gte(farm.balance) ? (
                    <S.CompensateBtn
                      disabled={farm.balance.isZero()}
                      onClick={() => {
                        sendGAEvent('Compensation', 'Compensate');
                        compensate({
                          lpAddress: farm.address,
                          lpDecimals: farm.decimals,
                          label: farm.label,
                          amount: farm.balance,
                          compensater,
                          currentProvider,
                          dispatch,
                        });
                      }}
                    >
                      Get Compensation
                    </S.CompensateBtn>
                  ) : (
                    <S.ApproveBtn
                      onClick={() => {
                        sendGAEvent('Compensation', 'Approve');
                        approveCompensation({
                          lpAddress: farm.address,
                          lpDecimals: farm.decimals,
                          label: farm.label,
                          compensater,
                          currentProvider,
                          dispatch,
                        });
                      }}
                    >
                      Approve Compensator
                    </S.ApproveBtn>
                  )}
                </>
              ) : (
                <S.CompensateMsg>
                  Unstake all your tokens before asking for compensation
                </S.CompensateMsg>
              )}
            </S.Value>
          </>
        ) : (
          <></>
        )}
      </S.Wrapper>
    </>
  );
};

export default FarmRow;
