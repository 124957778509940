import { createAsyncThunk } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { ContractsState } from 'core/store/contracts/contracts';
import { FarmsState } from '../farms';
import { ethers } from 'ethers';
import { v4 as uuidv4 } from 'uuid';
import { ciEquals, noExponents, sendTransaction, waitReceipt } from 'utils';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';

export const unstakeFarm = createAsyncThunk<
  void,
  { contract: string; amount: BigNumber }
>('farms/unstakeFarm', async (payload, thunkAPI) => {
  const farms = (thunkAPI.getState() as { farms: FarmsState }).farms.list;
  const lpStaking = (thunkAPI.getState() as { contracts: ContractsState })
    .contracts.lpStaking;

  const transactionId = uuidv4();

  try {
    const farm = farms.find((e) => ciEquals(e.address, payload.contract));

    if (!farm) {
      throw Error('Farm could not be found');
    }
    if (!lpStaking) {
      throw Error('Could not find LP Staking contract');
    }

    thunkAPI.dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'farm',
        type: 'unstake',
        state: 'pending',
        payload: {
          amount: payload.amount,
          currency: farm.label,
        },
      })
    );

    const formatedAmount = ethers.utils.parseUnits(
      noExponents(payload.amount),
      farm.decimals
    );

    const tx = await sendTransaction(
      lpStaking.contract.withdraw,
      farm.address,
      formatedAmount
    );
    thunkAPI.dispatch(
      updateTransactionHash({
        id: transactionId,
        hash: tx.hash,
      })
    );

    await waitReceipt(tx);
    thunkAPI.dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'success',
      })
    );
  } catch (error) {
    console.error(error.toString());
    thunkAPI.dispatch(
      updateTransactionState({
        id: transactionId,
        state: 'error',
      })
    );
  }
});
