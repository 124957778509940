/* eslint-disable @typescript-eslint/no-explicit-any */
import BigNumber from 'bignumber.js';
import { BigNumber as EthersBN } from 'ethers';
import { CallReturnContext } from 'ethereum-multicall';

export function noExponents(num: number | BigNumber): string {
  let number = num.toString();
  const numberHasSign = number.startsWith('-') || number.startsWith('+');
  const sign = numberHasSign ? number[0] : '';
  number = numberHasSign ? number.replace(sign, '') : number;

  // if the number is in scientific notation remove it
  if (/\d+\.?\d*e[+-]*\d+/i.test(number)) {
    const zero = '0';
    const parts = number.toString().toLowerCase().split('e'); // split into coeff and exponent
    const e = parseFloat(parts.pop() ?? ''); // store the exponential part
    let l = Math.abs(e); // get the number of zeros
    const s = e / l;
    const coeffArray = parts[0].split('.');

    if (s === -1) {
      coeffArray[0] = Math.abs(parseFloat(coeffArray[0])).toString();
      number = `${zero}.${new Array(l).join(zero)}${coeffArray.join('')}`;
    } else {
      const dec = coeffArray[1];
      if (dec) l -= dec.length;
      number = coeffArray.join('') + new Array(l + 1).join(zero);
    }
  }

  return `${sign}${number}`;
}

export function countDecimals(num: number): number {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
  return noExponents(num).split('.')[1].length || 0;
}

export function getMulticallValueOfRef(
  results: CallReturnContext[],
  ref: string
): any {
  const resultOfRef = results.find((r) => r.reference === ref);
  if (!resultOfRef) {
    // console.error(`Could not find ref in multicall results`);
    return undefined;
  }

  if (!resultOfRef.success) {
    console.error(`Error calling function: ${resultOfRef.methodName}`);
    return undefined;
  }

  const values = resultOfRef.returnValues;
  if (!resultOfRef.decoded) return values;
  if (values.length <= 0) return undefined;

  if (values.length > 1) return values;

  if (values[0].type === 'BigNumber') {
    return EthersBN.from(values[0]);
  }

  return values[0];
}
