/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useMemo } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { AppState, AppDispatch } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<AppState> = useSelector;

export const useBlockNow = () => {
  const { network } = useAppSelector((state) => state.auth);
  const blocks = useAppSelector((state) => state.blocks);

  const blockNow = useMemo(() => {
    if (network) {
      if (blocks[network.chainId]) {
        return blocks[network.chainId].blockNow;
      }
    }
    return undefined;
  }, [blocks, network]);

  return blockNow;
};
