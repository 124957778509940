import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserBalance } from 'core/types';
import BigNumber from 'bignumber.js';
import { BalancesState } from '../balances';

export const updateBalances = createAsyncThunk<
  UserBalance | null,
  { currency: string; amount: BigNumber }
>('balances/updateBalances', async (payload, thunkAPI) => {
  let balance: UserBalance | null = null;
  try {
    const { currency, amount } = payload;

    const balances = (thunkAPI.getState() as { balances: BalancesState })
      .balances;
    const currentBalance = balances.balances.find(
      (v) => v.currency === currency
    );

    if (!currentBalance?.amount.eq(amount)) {
      balance = {
        currency: currency,
        amount: amount,
      };
      thunkAPI.rejectWithValue('Amount not changed');
    }
  } catch (e) {
    thunkAPI.rejectWithValue('Could not update balance');
    console.error(e);
  } finally {
    return balance;
  }
});
