import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { FlurrySettings } from 'core/types';
import { useEffect } from 'react';
import { getStorageValue } from 'utils';
import { displayConvertTradeRoute } from '../ui';

export const useConvertDisplayTradeRoute = (): boolean => {
  const dispatch = useAppDispatch();
  const displayTradeRoute = useAppSelector(
    (state) => state.ui.displayConvertTradeRoute
  );

  useEffect(() => {
    // first load
    const flurrySettings = getStorageValue<FlurrySettings>('flurry-settings');
    if (flurrySettings) {
      if (flurrySettings.displayConvertTradeRoute && !displayTradeRoute) {
        dispatch(displayConvertTradeRoute(true));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const flurrySettings = getStorageValue<FlurrySettings>('flurry-settings');
    if (flurrySettings) {
      localStorage.setItem(
        'flurry-settings',
        JSON.stringify({
          ...flurrySettings,
          displayConvertTradeRoute: displayTradeRoute,
        })
      );
    } else {
      const newSettings = {
        displayConvertTradeRoute: displayTradeRoute,
      };
      localStorage.setItem('flurry-settings', JSON.stringify(newSettings));
    }
  }, [dispatch, displayTradeRoute]);

  return displayTradeRoute;
};
