import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useAppDispatch, useAppSelector, useBlockNow } from 'core/store/hooks';
import { updateRhotokensApr } from 'core/store/contracts/thunks/apr/updateRhotokensApr';
import { updateFlurryStakingApr } from 'core/store/contracts/thunks/apr/updateFlurryStakingApr';

/**
 * Updater for tracking token APRs, performing fetches on each new
 * block
 */
const AprUpdater = (): null => {
  const dispatch = useAppDispatch();
  const { currentProvider, user, network } = useAppSelector(
    (state) => state.auth
  );
  const contracts = useAppSelector((state) => state.contracts);
  const prevUser = usePrevious(user);
  const prevNetwork = usePrevious(network);

  const blockNow = useBlockNow();
  const prevBlock = usePrevious(blockNow);

  // TODO: Clear all contracts and tokens if the account/chain changes.
  useEffect(() => {
    // if (prevUser !== user || prevNetwork !== network) {
    //   dispatch(reset());
    // }
  }, [network, prevNetwork, prevUser, user]);

  useEffect(() => {
    if (!user?.address || !network || !currentProvider?.web3provider) return;

    if (blockNow !== prevBlock) {
      const {
        vaults,
        stablecoins,
        rhoTokens,
        priceOracles,
        flurryToken,
        rhoTokenRewards,
        flurryStaking,
      } = contracts;

      // Get APR related to rho tokens
      dispatch(
        updateRhotokensApr({
          vaults,
          stablecoins,
          rhoTokens,
          priceOracles,
          flurryToken,
          rhoTokenRewards,
          flurryUsdPrice: contracts.flurryPrice,
          network,
          multicall: currentProvider.multicall,
        })
      );

      // Get Flurry Staking APR
      dispatch(
        updateFlurryStakingApr({
          flurryStaking,
          network,
        })
      );
    }
  }, [
    currentProvider,
    user,
    dispatch,
    blockNow,
    contracts,
    prevBlock,
    network,
  ]);

  return null;
};

export default AprUpdater;
