/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Contract } from 'core/types';

export const getLogIndex = (
  bridge: Contract,
  receipt: any
): number | undefined => {
  if (receipt.status === 0) {
    throw new Error('Transaction failed');
  }
  const topics = bridge.contract.filters.Cross().topics;
  if (!topics) {
    throw new Error('No Topics found for bridge');
  }
  return receipt.logs.find((l: any) => l.topics[0] === topics[0])?.logIndex;
};
