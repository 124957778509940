import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { NetworkInfo, APR, Contract } from 'core/types';

export const updateFlurryStakingApr = createAsyncThunk<
  APR | undefined,
  { flurryStaking: Contract | null; network: NetworkInfo }
>('contracts/updateFlurryStakingApr', async (payload) => {
  let result: APR | undefined;
  try {
    const { flurryStaking, network } = payload;
    if (flurryStaking) {
      const rewardRateFlurry =
        await flurryStaking.contract.rewardRatePerTokenStaked();
      const apr =
        parseFloat(ethers.utils.formatUnits(rewardRateFlurry)) *
        network.blocksPerYear;

      result = {
        label: `${flurryStaking.label} APY`,
        apr: apr,
      };
    }
  } catch (e) {
    console.error(e);
  } finally {
    return result;
  }
});
