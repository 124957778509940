import React from 'react';
import ReactTooltip from 'react-tooltip';
import { InfoIcon as SInfoIcon } from './SelectTokenPopup.styles';
import * as S from './CommonBases.styles';
import { CurrencyOption } from 'core/types';
import { Spacer } from 'components';

type CommonBasesProps = {
  tokens: CurrencyOption[];
  onSelected: (t: CurrencyOption) => void;
  currentToken: string;
};

export const CommonBases: React.FC<CommonBasesProps> = ({
  tokens,
  onSelected,
  currentToken,
}) => {
  return (
    <>
      <ReactTooltip id={'commonBase'} multiline={true} />
      <S.Title>
        Common bases
        <div
          data-for="commonBase"
          data-tip={'These tokens are commonly<br />paired with Rho Tokens'}
          data-iscapture={'true'}
        >
          <SInfoIcon />
        </div>
      </S.Title>
      <Spacer axis="vertical" size={5} />
      <S.CommonBasesTokens>
        {tokens.map((x: CurrencyOption) => {
          if (!x.disabled) {
            return (
              <S.CommonBaseButton
                key={`${x.label}-commonBase`}
                onClick={() => onSelected(x)}
                isActive={currentToken === x.value}
              >
                <S.Icon src={x.icon} alt={x.label} />
                {x.label}
              </S.CommonBaseButton>
            );
          }
        })}
      </S.CommonBasesTokens>
    </>
  );
};
