/* eslint-disable @typescript-eslint/no-explicit-any */
import { CallReturnContext } from 'ethereum-multicall';
import { BigNumber as EthersBN } from 'ethers';

export const getValueOfRef = (
  results: CallReturnContext[],
  ref: string
): any => {
  const resultOfRef = results.find((r) => r.reference === ref);
  if (!resultOfRef) {
    // console.error(`Could not find ref in multicall results`);
    return undefined;
  }

  if (!resultOfRef.success) {
    console.error(`Error calling function: ${resultOfRef.methodName}`);
    return undefined;
  }

  const values = resultOfRef.returnValues;
  if (!resultOfRef.decoded) return values;
  if (values.length <= 0) return undefined;

  if (values.length > 1) return values;

  if (values[0].type === 'BigNumber') {
    return EthersBN.from(values[0]);
  }

  return values[0];
};
