import {
  IoMdCheckmarkCircleOutline,
  IoMdCloseCircleOutline,
} from 'react-icons/io';
import styled from 'styled-components/macro';

// ----- SINGLE NOTIFICAION STYLE -------

export const NotificationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f8f7fc;
  padding: 0.8rem 1rem 0.8rem 0.7rem;
  border-radius: 5px;
`;

export const LeftPart = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  width: 20px;
  height: 20px;
`;

export const NotifLogoWrapper = styled.div`
  position: relative;
  width: 1.5rem;
  padding-right: 0.5rem;
`;
export const SourceImg = styled.img`
  width: 1.5rem;
`;
export const NetworkLogo = styled.img`
  position: absolute;
  bottom: 0px;
  right: 5px;
  width: 15px;
`;

export const NotificationMainTitle = styled.h5`
  display: inline;
  align-items: flex-end;
  font-size: 0.75rem;
`;

export const Time = styled.div`
  display: inline;
  color: #696969;
  font-weight: normal;
  font-size: 0.6rem;
  padding: 0 0 0.05rem 0.2rem;
`;

export const NotificationDescription = styled.a<{ isLink: boolean }>`
  display: flex;
  align-items: center;
  padding-top: 0.2rem;
  font-size: 0.65rem;
  text-decoration: ${(props) => (props.isLink ? 'underline' : 'none')};
  width: fit-content;
  color: ${(props) => (props.isLink ? '#0000bd' : '#000')};
  pointer-events: ${(props) => (props.isLink ? 'auto' : 'none')};

  &:visited {
    color: ${(props) => (props.isLink ? '#0000bd' : '#000')};
  }
`;
export const DescriptionText = styled.div``;

export const LinkIcon = styled.span`
  font-size: 0.5rem;
  text-decoration: none !important;
  font-weight: bold;
`;

export const ConfirmedIcon = styled(IoMdCheckmarkCircleOutline)`
  color: #27ae60;
`;
export const ErrorIcon = styled(IoMdCloseCircleOutline)`
  color: red;
`;

export const SwitchNetworkButton = styled.button`
  cursor: pointer;
  margin-top: 0.5rem;
  outline: 0;
  border: 0;
  padding: 0.3rem 0.5rem;
  font-size: 0.7rem;
  border-radius: 10px;
  background-color: #27ae5f;
  color: white;
  font-weight: bold;
  transition: all 0.2s linear;

  &:hover {
    border: #1c8045;
  }
`;

export const BridgeDescriptions = styled.div``;

export const BridgeDescription = styled.div`
  font-size: 0.6rem;
  a {
    font-size: 0.6rem;
    display: inline;
  }
`;
