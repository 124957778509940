import ethLogo from 'assets/images/logos/networksLogos/eth_network.svg';
import avaxLogo from 'assets/images/logos/networksLogos/avax_network.svg';
import bscLogo from 'assets/images/logos/networksLogos/bsc_network.svg';
import polygonLogo from 'assets/images/logos/networksLogos/polygon_network.svg';
import flurryLogo from 'assets/images/logos/flurry_icon_only.png';

import { NetworkInfo } from 'core/types';

export const NetworksInfos: NetworkInfo[] = [
  {
    chainId: 1,
    name: 'homestead',
    label: 'Ethereum',
    folder: 'ethMainnet',
    scanUrl: 'https://etherscan.io',
    logo: ethLogo,
    rpcUrl: 'https://mainnet-nethermind.blockscout.com/',
    avgBlockTime: 13.2,
    blocksPerYear: Math.floor((365 * 86400) / 13.2),
    blocksPerDay: Math.floor(86400 / 13.2),
    disableKyberswap: true,
  },
  {
    chainId: 42,
    name: 'kovan',
    label: 'Kovan (Testnet)',
    folder: 'ethKovan',
    scanUrl: 'https://kovan.etherscan.io',
    logo: ethLogo,
    rpcUrl: 'https://kovan.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    avgBlockTime: 4,
    blocksPerYear: Math.floor((365 * 86400) / 4),
    blocksPerDay: Math.floor(86400 / 4),
    disableKyberswap: true,
  },
  {
    chainId: 3,
    name: 'ropsten',
    label: 'Ropsten (Testnet)',
    folder: 'ethRopsten',
    scanUrl: 'https://ropsten.etherscan.io',
    logo: ethLogo,
    rpcUrl: 'https://ropsten.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    avgBlockTime: 15,
    blocksPerYear: Math.floor((365 * 86400) / 15),
    blocksPerDay: Math.floor(86400 / 15),
    disableKyberswap: true,
  },
  {
    chainId: 4,
    name: 'rinkeby',
    label: 'Rinkeby (Testnet)',
    folder: 'ethRinkeby',
    scanUrl: 'https://rinkeby.etherscan.io',
    logo: ethLogo,
    rpcUrl: 'https://rinkeby.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    avgBlockTime: 15,
    blocksPerYear: Math.floor((365 * 86400) / 15),
    blocksPerDay: Math.floor(86400 / 15),
    disableKyberswap: true,
  },
  {
    chainId: 420,
    name: 'goerli',
    label: 'Goerli (Testnet)',
    folder: 'ethGoerli',
    scanUrl: 'https://goerli.etherscan.io',
    logo: ethLogo,
    rpcUrl: 'https://goerli.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161',
    avgBlockTime: 15,
    blocksPerYear: Math.floor((365 * 86400) / 15),
    blocksPerDay: Math.floor(86400 / 15),
    disableKyberswap: true,
  },
  {
    chainId: 56,
    name: 'bnb',
    label: 'Binance Smart Chain',
    folder: 'bscMainnet',
    scanUrl: 'https://bscscan.com',
    logo: bscLogo,
    rpcUrl: 'https://bsc-dataseed.binance.org/',
    avgBlockTime: 3,
    blocksPerYear: Math.floor((365 * 86400) / 3),
    blocksPerDay: Math.floor(86400 / 3),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
  },
  {
    chainId: 97,
    name: 'bnbt',
    label: 'Binance Smart Chain (Testnet)',
    folder: 'bscTestnet',
    scanUrl: 'https://testnet.bscscan.com',
    logo: bscLogo,
    rpcUrl: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
    avgBlockTime: 3,
    blocksPerYear: Math.floor((365 * 86400) / 3),
    blocksPerDay: Math.floor(86400 / 3),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
  },
  {
    chainId: 137,
    name: 'matic',
    label: 'Polygon',
    folder: 'polygonMainnet',
    scanUrl: 'https://polygonscan.com',
    logo: polygonLogo,
    rpcUrl: 'https://polygon-rpc.com',
    avgBlockTime: 2,
    blocksPerYear: Math.floor((365 * 86400) / 2),
    blocksPerDay: Math.floor(86400 / 2),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
    disableKyberswap: true,
  },
  {
    chainId: 80001,
    name: 'maticmum',
    label: 'Mumbai',
    folder: 'polygonMumbai',
    scanUrl: 'https://mumbai.polygonscan.com',
    logo: polygonLogo,
    rpcUrl: 'https://rpc-mumbai.matic.today',
    avgBlockTime: 2,
    blocksPerYear: Math.floor((365 * 86400) / 2),
    blocksPerDay: Math.floor(86400 / 2),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
    disableKyberswap: true,
  },
  {
    chainId: 1400,
    name: 'flurry',
    label: 'Flurry Testnet',
    folder: 'flurry',
    scanUrl: 'https://etherscan.io',
    logo: flurryLogo,
    rpcUrl: 'http://192.168.1.33:8550',
    avgBlockTime: 13.25,
    blocksPerYear: Math.floor((365 * 86400) / 13.25),
    blocksPerDay: Math.floor(86400 / 13.25),
    multicallContract: '0x5BA1e12693Dc8F9c48aAD8770482f4739bEeD696',
    disableKyberswap: true,
  },
  {
    chainId: 1401,
    name: 'flurry-bsc',
    label: 'Flurry BSC Testnet',
    folder: 'flurry-bsc',
    scanUrl: 'https://bscscan.com',
    logo: flurryLogo,
    rpcUrl: 'http://192.168.1.33:8551',
    avgBlockTime: 3,
    blocksPerYear: Math.floor((365 * 86400) / 3),
    blocksPerDay: Math.floor(86400 / 3),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
  },
  {
    chainId: 1402,
    name: 'flurry-polygon',
    label: 'Flurry Polygon Testnet',
    folder: 'flurry-polygon',
    scanUrl: 'https://polygonscan.com',
    logo: flurryLogo,
    rpcUrl: 'http://192.168.1.33:8552/',
    avgBlockTime: 2.4,
    blocksPerYear: Math.floor((365 * 86400) / 2.4),
    blocksPerDay: Math.floor(86400 / 2.4),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
    disableKyberswap: true,
  },
  {
    chainId: 1403,
    name: 'flurry-avax',
    label: 'Flurry Avax Testnet',
    folder: 'flurry-avax',
    scanUrl: 'https://snowtrace.io',
    logo: flurryLogo,
    rpcUrl: 'http://192.168.1.33:8553/',
    avgBlockTime: 3,
    blocksPerYear: Math.floor((365 * 86400) / 3),
    blocksPerDay: Math.floor(86400 / 3),
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
    disableKyberswap: true,
  },
  {
    chainId: 43114,
    name: 'avax',
    label: 'Avalanche',
    folder: 'avaxMainnet',
    scanUrl: 'https://snowtrace.io/',
    logo: avaxLogo,
    rpcUrl: 'https://api.avax.network/ext/bc/C/rpc',
    avgBlockTime: 3,
    blocksPerYear: Math.floor((365 * 86400) / 3),
    blocksPerDay: Math.floor(86400 / 3),
    disableKyberswap: true,
    multicallContract: '0xed386Fe855C1EFf2f843B910923Dd8846E45C5A4',
  },
];
