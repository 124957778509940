import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div<{ active?: boolean }>`
  user-select: none;
  cursor: pointer;
  font-size: 0.8rem;
  /* font-weight: ${(props) => (props.active ? 'bold' : 'normal')}; */
  border-radius: 5px;
  background-color: ${(props) => (props.active ? '#2195f2' : 'transparent')};
  color: ${(props) => (props.active ? 'white' : '#000000a4')};
  box-shadow: rgba(0, 0, 0, 0.24) 0px 0px 3px;
  /* border: 1px solid ${(props) =>
    props.active ? '#00000090' : '#00000040'}; */
  padding: 0.3rem 0.8rem;
`;

/* The box around the slider */
export const SliderWrapper = styled.label`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
`;

export const Slider = styled.span<{ iconRight?: string }>`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #2196f3;
  transition: 0.4s;
  border-radius: 17px;

  &:before {
    position: absolute;
    content: '';
    height: 20px;
    width: 20px;
    left: -2px;
    bottom: 2px;
    top: 0;
    bottom: 0;
    margin: auto 0;
    transition: 0.4s;
    box-shadow: 0 0px 7.5px #2020203d;
    background: white
      ${(props) => (props.iconRight ? `url(${props.iconRight})` : '')};
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 50%;
  }
`;

export const Btn = styled.input<{ iconLeft?: string }>`
  opacity: 0;
  width: 0;
  height: 0;

  &:checked + ${Slider} {
    background-color: #ccc;
  }
  &:focus + ${Slider} {
    box-shadow: 0 0 1px #ccc;
  }
  &:checked + ${Slider}:before {
    transform: translateX(14px);
    background: white
      ${(props) => (props.iconLeft ? `url(${props.iconLeft})` : '')};
    background-repeat: no-repeat;
    background-position: center;
  }
`;
