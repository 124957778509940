import React from 'react';
import * as S from './ComingSoon.styles';
import { useAppSelector } from 'core/store/hooks';

const ComingSoon: React.FC<{ chains: number[] }> = ({ chains }) => {
  const network = useAppSelector((state) => state.auth.network);
  const user = useAppSelector((state) => state.auth.user);

  if (!user || !network || chains.indexOf(network.chainId) === -1) {
    return <></>;
  }

  return (
    <S.ComingSoonWrapper>
      <S.NotAvailableNetwork>
        Soon available on {network.label}
      </S.NotAvailableNetwork>
    </S.ComingSoonWrapper>
  );
};

export default ComingSoon;
