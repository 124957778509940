import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildCompensaterContract } from 'utils/config';
import { InitContractPayload, Contract } from 'core/types';

export const initCompensater = createAsyncThunk<
  Contract | null,
  InitContractPayload
>('contracts/initCompensater', async (payload, thunkAPI) => {
  let compensater: Contract | null = null;
  try {
    const { provider, contractsConfig } = payload;

    compensater = await buildCompensaterContract(contractsConfig, provider);
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Compensater contract');
    console.error(e);
  } finally {
    return compensater;
  }
});
