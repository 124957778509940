import type { AppState } from '../store';
import { Transaction, TransactionSource, TransactionType } from 'core/types';

const findLatestTransactionOf = (
  state: AppState,
  source: TransactionSource,
  type?: TransactionType
): Transaction | undefined => {
  let onlyRewardTransactions: Transaction[] = [];
  if (type) {
    onlyRewardTransactions = state.transactions.list.filter(
      (t) => t.source === source
    );
  } else {
    onlyRewardTransactions = state.transactions.list.filter(
      (t) => t.source === source && t.type === type
    );
  }
  if (onlyRewardTransactions.length === 0) return undefined;

  const latest = onlyRewardTransactions.reduce((prev, current) => {
    return prev.createdAtTs > current.createdAtTs ? prev : current;
  });

  return latest;
};

export const selectLatestClaimRewardTransaction = (
  state: AppState
): Transaction | undefined => {
  return findLatestTransactionOf(state, 'reward', 'claim');
};

export const selectLatestBridgeTransaction = (
  state: AppState
): Transaction | undefined => {
  return findLatestTransactionOf(state, 'bridge', 'swap');
};

export const selectLatestSnowballTransaction = (
  state: AppState
): Transaction | undefined => {
  return findLatestTransactionOf(state, 'snowball');
};

export const selectLatestStakeTransaction = (
  state: AppState
): Transaction | undefined => {
  return findLatestTransactionOf(state, 'snowball', 'stake');
};

export const selectLatestUnstakeTransaction = (
  state: AppState
): Transaction | undefined => {
  return findLatestTransactionOf(state, 'snowball', 'unstake');
};

export const selectFarmsTransactions = (state: AppState): Transaction[] => {
  return state.transactions.list.filter((t) => t.source === 'farm');
};

export const selectPendingTransactions = (state: AppState): Transaction[] => {
  return state.transactions.list.filter(
    (t) => t.state === 'pending' && !t.isFromCache
  );
};

export const selectLatestPendingTransaction = (
  state: AppState
): Transaction | undefined => {
  const pendings = selectPendingTransactions(state);
  return pendings.length > 0 ? pendings[0] : undefined;
};
