import styled, { keyframes } from 'styled-components/macro';
import { Amount } from 'components';
import { FaInfoCircle } from 'react-icons/fa';
import Skeleton from 'react-loading-skeleton';
import { down } from 'styled-breakpoints';

export const EarnHeaderWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1.2fr 0.1fr 0.8fr;
  column-gap: 0.2rem;
  ${down('xs')} {
    grid-template-columns: none;
  }
`;

export const BalanceWrapper = styled.div`
  grid-column: 1 / 2;
  display: grid;
  grid-template-columns: 3fr 0.8fr;
  column-gap: 0.4rem;
  width: 100%;
  ${down('xs')} {
    grid-column: 1 / 3;
  }
`;

export const AmountBalance = styled(Amount)`
  justify-self: end;
`;

export const BalanceLabel = styled.h5`
  font-size: 0.7rem;
  line-height: 0.8rem;
  text-align: left;
  grid-column: 1 / 3;
`;

export const InterestRateHeader = styled.h6`
  cursor: default;
  grid-column: 4 / 5;
  display: flex;
  align-items: center;
  justify-self: center;
  margin: 0;
  font-weight: normal;
`;

export const InfoIcon = styled(FaInfoCircle)`
  padding-left: 0.2rem;
`;

export const LoadingRow = styled(Skeleton)`
  margin: 0.4rem 0.8rem;
  width: calc(100% - 2.5rem) !important;
  height: 1.2rem;
`;

export const BalanceRow = styled.div<{
  disabled?: boolean;
  withBridgeBtn: boolean;
}>`
  display: grid;
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
  grid-template-columns: ${(props) =>
    props.withBridgeBtn ? '0.1fr 1fr 0.02fr 22px' : '0.1fr 1fr 0.02fr'};
  align-items: center;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  width: calc(100% - 1rem);
  opacity: ${(props) => (props.disabled ? 0.3 : 1)};
`;

export const TokenEtherscanLink = styled.div`
  a {
    color: black;
  }
`;

export const RhoLogo = styled.img`
  justify-self: end;
  max-width: 23px;
  height: auto;
`;

const innershadowAnim = keyframes`
  0% {
    text-shadow: none;
  }
  50% {
    text-shadow: 0px 0px 5px #00000039;
  }
  100% {
    text-shadow: none;
  }
`;
export const InterestRateWrapper = styled.div<{ disabled?: boolean }>`
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? '0.5' : '1')};
  font-size: 0.7rem;
  padding: 0 0.5rem;
  font-weight: 500;
  color: black;
  text-align: center;
  animation: 2s ${innershadowAnim} infinite;
`;

export const ChartWrapper = styled.div`
  ${down('xs')} {
    display: none;
  }
  position: relative;
`;

export const ComingSoon = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(100% + 1px);
  border-radius: 7px;
  height: 100%;
  z-index: 2;
  background-color: #ffffffdc;
  color: #00000094;
  font-weight: bold;
  font-size: 1.5rem;
  user-select: none;
`;

export const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
  align-self: end;
  border-radius: 7px;
  & > div {
    margin-top: 20px;
  }
  svg {
    border-right: 1px solid #00000055;
    border-bottom: 1px solid #00000055;
    border-radius: 0px 0px 7px 7px;
  }
`;

export const ChartTitle = styled.h6`
  position: absolute;
  font-size: 0.8rem;
  margin: 0;
  padding: 0.5rem 0.8rem;
  top: 0;
  left: 0;
`;

export const TooltipWrapper = styled.div``;
