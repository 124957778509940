import { createAsyncThunk } from '@reduxjs/toolkit';
import { utils } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import { EarnPayload } from 'core/types';
import { sendTransaction, waitReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';
import { ciEquals, getContractByAddress, noExponents } from 'utils';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';

export const redeem = createAsyncThunk<void, EarnPayload>(
  'contracts/redeem',
  async (transPayload, thunkAPI) => {
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };
    const transactionId = uuidv4();

    try {
      if (!contracts) {
        throw new Error('No contracts');
      }

      if (!transPayload) {
        throw new Error('No payload');
      }

      const token = await getContractByAddress(transPayload.token, contracts);
      const rhoToken = await getContractByAddress(
        transPayload.rhoToken,
        contracts
      );

      thunkAPI.dispatch(
        addNewTransaction({
          id: transactionId,
          source: 'earn',
          type: 'redeem',
          state: 'pending',
          payload: {
            ...transPayload,
            token: token.label,
            rhoToken: rhoToken.label,
          },
        })
      );

      const vault = contracts.vaults.find((v) =>
        ciEquals(v.rhoTokenAddress, rhoToken.address)
      );

      if (!vault) {
        throw new Error(`Could not find vault of ${rhoToken.label}`);
      }

      const formatedAmount = utils.parseUnits(
        noExponents(transPayload.tokenAmount),
        rhoToken.decimals
      );
      const tx = await sendTransaction(vault.contract.redeem, formatedAmount);
      thunkAPI.dispatch(
        updateTransactionHash({ id: transactionId, hash: tx.hash })
      );

      await waitReceipt(tx);

      thunkAPI.dispatch(
        updateTransactionState({ id: transactionId, state: 'success' })
      );
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        updateTransactionState({ id: transactionId, state: 'error' })
      );
    }
  }
);
