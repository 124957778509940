import BigNumber from 'bignumber.js';
import { Contract, FarmContract, FarmLiquidity, NetworkInfo } from 'core/types';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { ethers } from 'ethers';
import { getValueOfRef, BN } from 'utils';

export const calculateFarmsLiquidities = async (payload: {
  farms: FarmContract[];
  lpStaking: Contract | null;
  flurryToken: Contract | null;
  multicall: Multicall;
}): Promise<FarmLiquidity[]> => {
  const result: FarmLiquidity[] = [];
  const { farms, lpStaking, flurryToken, multicall } = payload;

  if (!lpStaking) {
    throw Error('No LP staking contracts');
  }
  if (!flurryToken) {
    throw Error('No Flurry token contract');
  }

  // Building contract multicall
  const multicallContext: ContractCallContext[] = [];
  for (const f of farms) {
    if (f.unreleased) {
      continue;
    }

    multicallContext.push({
      reference: f.address,
      contractAddress: f.address,
      abi: f.abi ?? [],
      calls: [
        {
          reference: 'reserves',
          methodName: 'getReserves',
          methodParameters: [],
        },
      ],
    });

    multicallContext.push({
      reference: f.token0.address,
      contractAddress: f.token0.address,
      abi: f.token0.abi ?? [],
      calls: [
        {
          reference: 'decimals',
          methodName: 'decimals',
          methodParameters: [],
        },
      ],
    });

    multicallContext.push({
      reference: f.token1.address,
      contractAddress: f.token1.address,
      abi: f.token1.abi ?? [],
      calls: [
        {
          reference: 'decimals',
          methodName: 'decimals',
          methodParameters: [],
        },
      ],
    });

    if (f.priceOracleToken0) {
      multicallContext.push({
        reference: f.priceOracleToken0.address,
        contractAddress: f.priceOracleToken0.address,
        abi: f.priceOracleToken0.abi ?? [],
        calls: [
          {
            reference: 'price',
            methodName: 'price',
            methodParameters: [f.token0.address],
          },
        ],
      });
    }

    if (f.priceOracleToken1) {
      multicallContext.push({
        reference: f.priceOracleToken1.address,
        contractAddress: f.priceOracleToken1.address,
        abi: f.priceOracleToken1.abi ?? [],
        calls: [
          {
            reference: 'price',
            methodName: 'price',
            methodParameters: [f.token1.address],
          },
        ],
      });
    }
  }

  // Executing contract multicall
  const callResults = (await multicall.call(multicallContext)).results;

  for (const f of farms) {
    if (f.unreleased) {
      continue;
    }

    let liquidity = undefined;

    const reserves = getValueOfRef(
      callResults[f.address].callsReturnContext,
      'reserves'
    );

    let token0Reserve = new BigNumber(0);
    let token1Reserve = new BigNumber(0);

    if (reserves.length >= 2) {
      const token0Decimal = getValueOfRef(
        callResults[f.token0.address].callsReturnContext,
        'decimals'
      );
      const token1Decimal = getValueOfRef(
        callResults[f.token1.address].callsReturnContext,
        'decimals'
      );
      token0Reserve = BN(reserves[0], token0Decimal);
      token1Reserve = BN(reserves[1], token1Decimal);
    }

    let priceToken0 = new BigNumber(-1);
    let priceToken1 = new BigNumber(-1);

    if (f.priceOracleToken0) {
      priceToken0 = BN(
        getValueOfRef(
          callResults[f.priceOracleToken0.address].callsReturnContext,
          'price'
        ),
        f.priceOracleToken0.decimals
      );
      if (priceToken0.gte(BN(ethers.constants.MaxUint256))) {
        priceToken0 = new BigNumber(-1);
      }
    }

    if (f.priceOracleToken1) {
      priceToken1 = BN(
        getValueOfRef(
          callResults[f.priceOracleToken1.address].callsReturnContext,
          'price'
        ),
        f.priceOracleToken1.decimals
      );
      if (priceToken1.gte(BN(ethers.constants.MaxUint256))) {
        priceToken1 = new BigNumber(-1);
      }
    }

    if (!priceToken0.isNegative() && !priceToken1.isNegative()) {
      liquidity = token0Reserve
        .times(priceToken0)
        .plus(token1Reserve.times(priceToken1));
    }

    result.push({
      farmKey: f.key,
      liquidity,
    });
  }
  return result;
};

export const getLiquidityLink = (
  network: NetworkInfo,
  token0: string,
  token1: string
): string | undefined => {
  switch (network.chainId) {
    case 1: {
      return `https://app.uniswap.org/#/add/v2/${token0}/${token1}`;
    }
    case 97: {
      return undefined;
    }
    case 56: {
      return `https://pancakeswap.finance/add/${token0}/${token1}`;
    }
    case 137: {
      return `https://quickswap.exchange/#/add/${token0}/${token1}`;
    }
    default: {
      return `https://app.uniswap.org/#/add/v2/${token0}/${token1}`;
    }
  }
};
