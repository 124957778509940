import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import URL from 'core/config/api';
import { NotificationMessage } from 'core/types';

export const getNotificationMessages = createAsyncThunk<
  NotificationMessage[],
  void
>('content/getNotificationMessages', async (_, thunkAPI) => {
  let result: NotificationMessage[] = [];
  try {
    const response = await axios.get(
      `${window._env_.FLURRY_API_URL}${URL.flurryApi.NOTIFICATION_MESSAGES}`
    );
    result = response.data;
  } catch (e) {
    thunkAPI.rejectWithValue('Could not retrieve notification messages');
  } finally {
    return result;
  }
});
