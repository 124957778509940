import React, { useCallback, useMemo, useEffect, useState } from 'react';
import * as S from './FarmRow.styles';
import { PriceInput, Spacer, ShortenBalance } from 'components';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { stakeFarm } from 'core/store/farms/thunks/stakeFarm';
import { ciEquals, formatBNToString, noExponents, sendGAEvent } from 'utils';
import { User } from 'core/types';
import BigNumber from 'bignumber.js';
import { updateUserFarmApproval } from 'core/store/auth/thunks/updateUserFarmApproval';
import { approveFarm } from 'core/store/farms/thunks/approveFarm';

interface FarmStakingFormProps {
  contractKey: string;
  contractAddress: string;
  user?: User;
  availableBalance?: BigNumber;
  disabled?: boolean;
  liquidityUrl?: string;
  maxDecimalPlace?: number;
  liquidity?: BigNumber;
  totalSupply?: BigNumber;
}

const FarmStakingForm: React.FC<FarmStakingFormProps> = ({
  contractKey,
  contractAddress,
  user,
  availableBalance,
  liquidityUrl,
  disabled,
  maxDecimalPlace,
  liquidity,
  totalSupply,
}) => {
  const [stakeAmount, setStakeAmount] = useState('');
  const [stakeError, setStakeError] = useState('');
  const dispatch = useAppDispatch();

  const approvalLoading = useAppSelector(
    (state) => state.auth.approvalsStates.farmsToken
  );

  const needApproval = useMemo((): boolean | undefined => {
    if (!user) {
      return undefined;
    }

    const approval = user.approvals.find((e) =>
      ciEquals(e.contractAddress, contractAddress)
    );
    if (!approval) {
      return undefined;
    }

    return !approval.isApproved;
  }, [contractAddress, user]);

  const updateApproval = useCallback(
    (amountStr: string | BigNumber) => {
      if (!amountStr || amountStr === '') {
        return;
      }
      const currentAmount = new BigNumber(amountStr);
      if (currentAmount.isZero()) {
        return;
      }
      dispatch(
        updateUserFarmApproval({
          farmAddress: contractAddress,
          amount: currentAmount,
          user,
        })
      );
    },
    [contractAddress, dispatch, user]
  );

  useEffect(() => {
    updateApproval('');
  }, [updateApproval]);

  const onStartFarmingClicked = useCallback(
    (contract: string, amount: BigNumber) => {
      if (availableBalance) {
        setStakeError('');
        if (amount.lte(new BigNumber(0))) {
          setStakeError('Amount should be greater than zero');
        } else if (amount.gt(availableBalance)) {
          setStakeError('You do not have enough liquidity tokens');
        } else {
          if (needApproval) {
            dispatch(approveFarm({ amount, currency: contract }));
          } else {
            dispatch(stakeFarm({ contract, amount }));
          }
        }
      }
    },
    [availableBalance, dispatch, needApproval]
  );

  const SubmitButton = useMemo(() => {
    const noApprovalUpdate =
      !stakeAmount || stakeAmount === '' || new BigNumber(stakeAmount).isZero();

    const isDisabled = disabled || approvalLoading || noApprovalUpdate;
    const showLoading = approvalLoading && !noApprovalUpdate;

    if (needApproval) {
      return (
        <S.ApprovalButton
          type="button"
          disabled={isDisabled}
          onClick={() => {
            sendGAEvent('Farms', 'Approve', contractKey);
            onStartFarmingClicked(contractAddress, new BigNumber(stakeAmount));
          }}
        >
          {!showLoading ? (
            'Approve token'
          ) : (
            <S.ApproveLoadingCircle size={11} color="white" />
          )}
        </S.ApprovalButton>
      );
    }

    return (
      <S.StartFarmButton
        type="button"
        disabled={isDisabled}
        onClick={() => {
          sendGAEvent('Farms', 'Stake', contractKey);
          onStartFarmingClicked(contractAddress, new BigNumber(stakeAmount));
        }}
      >
        {!showLoading ? (
          'Start Farming'
        ) : (
          <S.ApproveLoadingCircle size={11} color="white" />
        )}
      </S.StartFarmButton>
    );
  }, [
    approvalLoading,
    contractAddress,
    contractKey,
    disabled,
    needApproval,
    onStartFarmingClicked,
    stakeAmount,
  ]);

  return (
    <>
      <S.FormWrapper>
        <S.InputWrapper>
          <S.InputLabel>
            Liquidity to provide
            <S.UsdValue>
              In USD:
              {stakeAmount && stakeAmount !== '' && totalSupply && liquidity
                ? ` $${formatBNToString(
                    !totalSupply.isZero()
                      ? new BigNumber(stakeAmount)
                          .div(totalSupply)
                          .times(liquidity)
                      : new BigNumber(0),
                    0,
                    false,
                    ','
                  )}`
                : ' -'}
            </S.UsdValue>
          </S.InputLabel>

          <PriceInput
            inputValue={stakeAmount}
            onAmountChange={(v) => {
              updateApproval(v);
              setStakeAmount(v);
            }}
            onMaxBtnClicked={() => {
              if (availableBalance !== undefined) {
                updateApproval(availableBalance);
                setStakeAmount(noExponents(availableBalance));
              }
            }}
            maxDecimalPlace={maxDecimalPlace}
          />
        </S.InputWrapper>
        <Spacer axis="horizontal" size={20} />
        {SubmitButton}
      </S.FormWrapper>
      {stakeError && <S.ErrorMsg>{stakeError}</S.ErrorMsg>}
      <S.BalanceWrapper>
        <ShortenBalance
          fontSize="0.75rem"
          label="Available balance:"
          balanceValue={availableBalance}
          decimalPrecision={5}
          currency=""
        />
        <Spacer axis="horizontal" size={10} />
        {liquidityUrl && (
          <S.GetOnUniswap href={liquidityUrl} target="_blank">
            Get liquidity tokens <S.ExternalLink />
          </S.GetOnUniswap>
        )}
      </S.BalanceWrapper>
    </>
  );
};

export default FarmStakingForm;
