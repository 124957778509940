import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateSuppliesResult } from 'core/types';
import { BN } from 'utils';
import { ContractsState } from '../contracts';

export const updateSupplies = createAsyncThunk<
  UpdateSuppliesResult,
  { contracts: ContractsState }
>('contracts/updateSupplies', async (payload) => {
  const { rhoTokens } = payload.contracts;
  const result: UpdateSuppliesResult = {
    flurryStaking: null,
    rhoTokens: [],
  };

  try {
    for (const token of rhoTokens) {
      result.rhoTokens.push({
        contractAddress: token.address,
        supply: BN(await token.contract.totalSupply(), token.decimals),
      });
    }
  } catch (e) {
    console.error(e);
  }

  return result;
});
