import React from 'react';
import * as S from './Collapsable.styles';

interface CollapsableProps {
  header: JSX.Element;
  body: JSX.Element;
}

const Collapsable: React.FC<CollapsableProps> = ({ header, body }) => {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <S.AccordionWrapper>
      <S.Button onClick={handleClick}>
        {header}
        <S.Chevron $isup={open} />
      </S.Button>
      <S.InternalWrapper open={open}>{body}</S.InternalWrapper>
    </S.AccordionWrapper>
  );
};

export default Collapsable;
