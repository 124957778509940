import EarnBackgroundWebp from 'assets/images/earn_bg.webp';
import EarnBackgroundPng from 'assets/images/earn_bg.png';
import * as S from './Snowball.styles';
import { ComingSoon, Spacer } from 'components';
import SnowballForm from './SnowballForm';
import SnowballBanner from './SnowballBanner';
import SnowballStats from './SnowballStats';
import SnowballHeader from './SnowballHeader';

const Snowball: React.FC = () => {
  return (
    <S.SnowballWrapper>
      <S.BackgroundImage
        src={EarnBackgroundWebp}
        fallback={EarnBackgroundPng}
        alt="earn background image"
      />
      <S.SnowballCard>
        <S.TopWrapper>
          <ComingSoon chains={[]} />
          <SnowballHeader />
          <SnowballStats />
          <Spacer axis="vertical" size={20} />
          <SnowballForm />
        </S.TopWrapper>
        <S.BottomWrapper>
          <SnowballBanner />
        </S.BottomWrapper>
      </S.SnowballCard>
      <Spacer axis="vertical" size={50} />
    </S.SnowballWrapper>
  );
};

export default Snowball;
