import { ethers } from 'ethers';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { countDecimals, getMulticallValueOfRef, noExponents } from './utils';
import { Aggregator } from './aggregator';
import {
  Currency,
  CurrencyAmount,
  Token,
  TokenAmount,
  ChainId,
  Percent,
} from '@dynamic-amm/sdk';
import { getSwapCallParameters, SwapV2Parameters } from './useSwapV2Callback';
import BigNumber from 'bignumber.js';
import ERC20_ABI from './erc20.json';

//These are non-upgradable contracts, KyberSwap will deploy new implementation contracts whenever updated, keep in check
//Check whether he has deployed new version at here:
//https://bscscan.com/address/0x7afac84bf3931b11548ed02b4460ad754cf54c66
//const AGGREGATOR_EXECUTOR_ADDRESS = "0xd12bcdFB9A39BE79DA3bDF02557EFdcD5CA59e77";
//const AGGREGATOR_ROUTER_ADDRESS = "0xDF1A1b60f2D438842916C0aDc43748768353EC25";

export function translateNetworkNames(chainId: number): string | undefined {
  switch (chainId) {
    case 1401:
    case 56:
      return 'bsc';
    case 1402:
    case 137:
      return 'polygon';
    case 1400:
    case 1:
      return 'ethereum';
    default:
      // console.error('Could not translate network for kyberswap, using BSC');
      return 'bsc';
  }
}

function getChainIdObj(chainId: number): ChainId | undefined {
  switch (chainId) {
    case 1401:
    case 56:
      return ChainId.BSCMAINNET;
    case 1402:
    case 137:
      return ChainId.MATIC;
    case 1400:
    case 1:
      return ChainId.MAINNET;
    default:
      // console.error('Could not translate network for kyberswap, using BSC');
      return ChainId.BSCMAINNET;
  }

  return undefined;
}
export const kyberswapEncode = async (payload: {
  srcAddress: string;
  dstAddress: string;
  amount: BigNumber;
  slippage: number;
  recipient: string;
  ttl: number;
  chainIdNumber: number;
  provider: ethers.providers.Web3Provider;
  multicall: Multicall;
}): Promise<SwapV2Parameters | undefined> => {
  const {
    srcAddress,
    dstAddress,
    amount,
    recipient,
    slippage,
    ttl,
    chainIdNumber,
    provider,
    multicall,
  } = payload;

  // translate network name so that kyber can understand
  const name: string | undefined = translateNetworkNames(chainIdNumber);
  const chainID = getChainIdObj(chainIdNumber);
  if (!name || !chainID) throw Error('Could find network');

  const multicallContext: ContractCallContext[] = [
    {
      reference: srcAddress,
      contractAddress: srcAddress,
      abi: ERC20_ABI,
      calls: [
        {
          reference: 'decimals',
          methodName: 'decimals',
          methodParameters: [],
        },
        {
          reference: 'symbol',
          methodName: 'symbol',
          methodParameters: [],
        },
        {
          reference: 'name',
          methodName: 'name',
          methodParameters: [],
        },
      ],
    },
    {
      reference: dstAddress,
      contractAddress: dstAddress,
      abi: ERC20_ABI,
      calls: [
        {
          reference: 'decimals',
          methodName: 'decimals',
          methodParameters: [],
        },
        {
          reference: 'symbol',
          methodName: 'symbol',
          methodParameters: [],
        },
        {
          reference: 'name',
          methodName: 'name',
          methodParameters: [],
        },
      ],
    },
  ];

  // Executing contract multicall
  const callResults = (await multicall.call(multicallContext)).results;
  const srcRes = callResults[srcAddress]?.callsReturnContext;
  const dstRes = callResults[dstAddress]?.callsReturnContext;
  if (!srcRes || !dstRes) throw Error('Could not get tokens');

  // construct the token objects
  const inputToken = new Token(
    chainID,
    srcAddress,
    getMulticallValueOfRef(srcRes, 'decimals'),
    getMulticallValueOfRef(srcRes, 'symbol'),
    getMulticallValueOfRef(srcRes, 'name')
  );
  const outputToken = new Token(
    chainID,
    dstAddress,
    getMulticallValueOfRef(dstRes, 'decimals'),
    getMulticallValueOfRef(dstRes, 'symbol'),
    getMulticallValueOfRef(dstRes, 'name')
  );

  const scaledAmount = amount.times(
    new BigNumber(10).pow(getMulticallValueOfRef(srcRes, 'decimals'))
  );

  const toCurrencyAmount = (
    value: BigNumber,
    currency: Currency
  ): CurrencyAmount => {
    return currency instanceof Token
      ? new TokenAmount(currency, noExponents(value))
      : CurrencyAmount.ether(noExponents(value));
  };

  // construct the aggregator object, represent the best trading route(s)
  const bestTrade = await Aggregator.bestTradeExactIn(
    'https://aggregator-api.kyberswap.com/' + name + '/route',
    toCurrencyAmount(scaledAmount, inputToken),
    outputToken
  );
  if (!bestTrade) {
    console.error(
      'Cannot get the Aggregator object. No route exists for this pair.'
    );
    return undefined;
  }

  const slippageDenom = 10 ** countDecimals(slippage);
  const slippageNom = BigInt(slippage * slippageDenom);

  const result = getSwapCallParameters(
    bestTrade,
    {
      allowedSlippage: new Percent(slippageNom, BigInt(slippageDenom)),
      ttl: ttl,
      recipient: recipient,
      feeOnTransfer: false, //currently this param is not being used in this function
    },
    chainID,
    provider
  );

  return result; // the call data
};
