import React, { useMemo } from 'react';
import * as S from './NotificationPanel.styles';
import Notification from './Notification';
import CustomNotification from './CustomNotification';
import { useAppSelector } from 'core/store/hooks';
import { Spacer } from 'components';
import { getPreferredNetworks } from 'utils';
import { useEarnAnnouncements } from 'core/store/content/hooks';
import { NetworkInfo } from 'core/types';
import Skeleton from 'react-loading-skeleton';

const TestnetNotification: React.FC<{
  preferedNetworks: NetworkInfo[];
  usdtLink: string | undefined;
  usdcLink: string | undefined;
  busdLink: string | undefined;
}> = ({ preferedNetworks, usdtLink, usdcLink, busdLink }) => {
  return (
    <CustomNotification
      body={
        <S.TestnetNotifWrapper>
          <S.TestnetTitle>This is the testnet version of Flurry</S.TestnetTitle>
          <Spacer axis="vertical" size={10} />
          <S.TestnetText>
            Feel free to test all the functionalities of Flurry using{' '}
            {preferedNetworks.map((e) => `${e.label}, `)} testnet networks.
            <Spacer axis="vertical" size={10} />
            Don’t forget to give us feedback on our socials, we are eager to
            improve your experience.
          </S.TestnetText>
          <Spacer axis="vertical" size={5} />
          {(usdtLink || usdcLink) && (
            <S.TestnetGetStablecoins>
              <S.CoinsLabel>Get some testnet stablecoins</S.CoinsLabel>
              <Spacer axis="vertical" size={5} />
              <S.CoinsWrapper>
                {usdtLink && (
                  <S.GetUSDTLink href={usdtLink} target="_blank">
                    Get <b>USDT</b>
                  </S.GetUSDTLink>
                )}
                {usdcLink && (
                  <S.GetUSDCLink href={usdcLink} target="_blank">
                    Get <b>USDC</b>
                  </S.GetUSDCLink>
                )}
                {busdLink && (
                  <S.GetBUSDLink href={busdLink} target="_blank">
                    Get <b>BUSD</b>
                  </S.GetBUSDLink>
                )}
              </S.CoinsWrapper>
            </S.TestnetGetStablecoins>
          )}
        </S.TestnetNotifWrapper>
      }
    />
  );
};

const NotificationPanel: React.FC = () => {
  const usdtLink = useAppSelector((state) => state.ui.getUsdtUrl);
  const usdcLink = useAppSelector((state) => state.ui.getUsdcUrl);
  const busdLink = useAppSelector((state) => state.ui.getBusdUrl);

  const preferedNetworks = getPreferredNetworks();
  const env = window._env_.ENVIRONMENT;
  const { data, error, isLoading } = useEarnAnnouncements();

  const notifications = useMemo(
    () =>
      !data || error ? [] : data.slice().sort((a, b) => a.Order - b.Order),
    [data, error]
  );

  return (
    <S.Wrapper>
      {isLoading ? (
        <Skeleton height={90} width="100%" />
      ) : (
        notifications.map((n, idx) => (
          <Notification key={`earnAnnouncement_${idx}`} text={n.Announcement} />
        ))
      )}
      {env && env !== 'mainnet' && (
        <TestnetNotification
          preferedNetworks={preferedNetworks}
          usdtLink={usdtLink}
          usdcLink={usdcLink}
          busdLink={busdLink}
        />
      )}
    </S.Wrapper>
  );
};

export default NotificationPanel;
