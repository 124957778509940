import styled from 'styled-components/macro';
import { LoadingCircle } from 'components';
import { GoSettings } from 'react-icons/go';
import { VscArrowSwap } from 'react-icons/vsc';

export const Wrapper = styled.div`
  grid-column: 1 / 3;
  grid-row: 3 / 4;
`;

export const FormWrapper = styled.div``;

export const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`;

export const FormBtns = styled.div`
  display: flex;
  margin-bottom: 0.2rem;
`;

export const SettingsBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  border-radius: 5px;
  transition: background-color 0.2s linear;
  padding: 0.2rem 0.2rem 0 0.2rem;
  &:hover {
    background-color: #0000000a;
  }
`;

export const SettingsIcon = styled(GoSettings)`
  font-size: 1.1rem;
  transform: rotate(90deg);
`;

export const PoweredBy = styled.div`
  display: flex;
  align-items: center;
  font-size: 0.55rem;
  opacity: 0.7;
`;
export const PoweredTxt = styled.div`
  margin-left: auto;
`;
export const PoweredLogo = styled.img`
  margin-left: 0.4rem;
  height: 1rem;
  width: auto;
`;

export const InputLabel = styled.h6`
  padding: 0 0 0.5rem 0.3rem;
  margin: 0;
  font-size: 0.8rem;
  opacity: 0.7;
  font-weight: normal;
`;

export const QuoteData = styled.div`
  width: calc(100% - 1rem);
  display: flex;
  justify-content: space-between;
  padding: 0 0.5rem;
`;

export const ConversionRateLabel = styled.p`
  cursor: pointer;
  font-size: 0.7rem;
  line-height: 0.8rem;
  text-align: left;
  grid-column: 1 / 3;
  color: #0000008d;
  justify-self: end;
  display: flex;
  align-items: center;
`;
export const SwapIcon = styled(VscArrowSwap)`
  font-size: 0.7rem;
  margin-left: 0.4rem;
  &:hover {
    opacity: 0.8;
  }
`;

export const MaxSlippageLabel = styled.p`
  cursor: pointer;
  font-size: 0.7rem;
  line-height: 0.8rem;
  text-align: left;
  grid-column: 1 / 3;
  color: #0000008d;
  margin-left: auto;
`;

export const InputError = styled.p`
  min-height: 0.7rem;
  margin: 0.5rem 0 0 0.5rem;
  color: red;
  line-height: 0.7rem;
  font-size: 0.7rem;
`;
export const SubmitButton = styled.button`
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #457fc5 0%,
    #14b4b2 51%,
    #457fc5 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #77a0d349;
  border: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: #fff; text-decoration: none; }'
      : ''}
`;

export const ApproveButton = styled(SubmitButton)`
  background-image: linear-gradient(
    to right,
    #2172e5 0%,
    #3884f0 51%,
    #2172e5 100%
  );
`;

export const ApproveLoadingCircle = styled(LoadingCircle)`
  align-self: center;
  margin: 0 auto;
`;

export const ConnectButton = styled.button`
  width: calc(100% - 2rem);
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  background: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;

  opacity: ${(props) => (!props.disabled ? '1' : '0.5')};
`;
