import styled, { keyframes } from 'styled-components/macro';
import Skeleton from 'react-loading-skeleton';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  align-self: center;
  display: flex;
  align-items: center;
  height: fit-content;
  background-color: #ffffff20;
  border-radius: 10px;
  user-select: none;

  ${down('xs')} {
    background-color: transparent;
  }
`;

export const ClaimButton = styled.div`
  margin: 0;
  cursor: pointer;
  display: flex;
  background-color: #ffffff10;
  border-radius: 10px 0px 0px 10px;
  padding: 0.5rem 1rem;
  transition: background-color 0.2s;
  align-items: center;
  font-weight: bold;

  &:hover {
    background-color: #ffffff20;
  }

  ${down('sm')} {
    flex-direction: column;
  }
  ${down('xs')} {
    background-color: #ffffff30;
    border-radius: 10px;
  }
`;

const pulsing = keyframes`
    0% {
        width: 26px;
        opacity: .7;
    }
    50% {
        width: 30px;
        opacity: 1;
    }
    100% {        
        width: 26px;
        opacity: .7;
    }
`;

const pulsingMobile = keyframes`
    0% {
        width: 16px;
        opacity: .7;
    }
    50% {
        width: 20px;
        opacity: 1;
    }
    100% {        
        width: 16px;
        opacity: .7;
    }
`;

export const FlurryIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 35px;
  ${down('sm')} {
    height: 18px;
  }
`;

export const FlurryIcon = styled.img`
  width: 25px;
  height: auto;
  animation: ${pulsing} 0.8s infinite ease;
  ${down('sm')} {
    animation: ${pulsingMobile} 0.8s infinite ease;
    width: 20px;
  }
`;

export const Separator = styled.div`
  margin: 0;
  height: 100%;
  width: 2px;
  background-color: #00000010;
`;

export const BalanceWrapper = styled.div`
  position: relative;
  background-color: #ffffff25;
  border-radius: 0px 10px 10px 0px;
  padding: 0.5rem 1rem;
  ${down('xs')} {
    display: none;
  }
`;

export const Label = styled.div`
  color: white;
  text-align: left;
  font-size: 0.8rem;
  line-height: 1.5rem;
  ${down('lg')} {
    font-size: 0.75rem;
    line-height: 1.2rem;
  }
  ${down('sm')} {
    line-height: 1.1rem;
  }
`;

export const AmountWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const TotalAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  color: white;
  text-align: left;
  font-size: 1.1rem;
  & > div {
    margin-right: 0.3rem;
  }
  ${down('lg')} {
    font-size: 0.95rem;
  }
`;

export const FlurryCcyLabel = styled.div<{ isClickable?: boolean }>`
  cursor: ${(props) => (props.isClickable ? 'pointer' : 'default')};
`;

export const ClaimTotalAmountWrapper = styled(TotalAmountWrapper)`
  ${down('sm')} {
    display: none;
  }
`;

export const ClaimWrapper = styled.div``;

export const AmountSkeleton = styled(Skeleton)`
  margin-right: 0.3rem;
  opacity: 0.7;
`;
