import React, { useMemo } from 'react';
import * as S from './FarmRow.styles';
import { Spacer } from 'components';
import { formatAPR, formatBNToString } from 'utils';
import { User } from 'core/types';
import Skeleton from 'react-loading-skeleton';
import BigNumber from 'bignumber.js';
import FarmStakingForm from './FarmStakingForm';
import FarmUnstakingForm from './FarmUnstakingForm';

interface FarmRowBodyProps {
  unreleased?: boolean;
  contractKey: string;
  contractAddress: string;
  user?: User;
  availableBalance?: BigNumber;
  stakedBalance?: BigNumber;
  disabled?: boolean;
  loading?: boolean;
  liquidityUrl?: string;
  liquidity?: BigNumber;
  totalSupply?: BigNumber;
  apr?: number;
  rewards?: BigNumber;
  decimals?: number;
}

const LoadingFormSkeleton = (
  <S.LoadingFormSkeleton>
    <S.FormWrapper>
      <S.InputWrapper>
        <Skeleton width={200} height={10} />
        <Skeleton height={40} />
      </S.InputWrapper>
      <Spacer axis="horizontal" size={20} />
      <S.ButtonSkeletonWrapper>
        <Skeleton height={40} />
      </S.ButtonSkeletonWrapper>
    </S.FormWrapper>
    <S.BalanceSkeletonWrapper>
      <Skeleton width={200} height={10} />
    </S.BalanceSkeletonWrapper>
    <Spacer axis="vertical" size={10} />
  </S.LoadingFormSkeleton>
);

const FarmRowBody: React.FC<FarmRowBodyProps> = ({
  unreleased,
  contractKey,
  contractAddress,
  user,
  availableBalance,
  stakedBalance,
  liquidityUrl,
  loading,
  disabled,
  liquidity,
  totalSupply,
  apr,
  rewards,
  decimals,
}) => {
  const usdStakedValue = useMemo(() => {
    if (stakedBalance && totalSupply && liquidity) {
      if (totalSupply.isZero()) {
        return new BigNumber(0);
      }
      return stakedBalance.div(totalSupply).times(liquidity);
    }
    return undefined;
  }, [liquidity, stakedBalance, totalSupply]);

  return (
    <S.BodyWrapper>
      {!loading ? (
        <S.Forms>
          <S.MobileStats>
            <S.Stat showOnMobile showOnTablet>
              <S.StatTitle>Liquidity</S.StatTitle>
              <S.StatValue>
                $
                {liquidity !== undefined
                  ? formatBNToString(liquidity, 0, true, ',')
                  : ' -'}
              </S.StatValue>
            </S.Stat>
            <S.Stat showOnMobile>
              <S.StatTitle>FLURRY APY</S.StatTitle>
              <S.StatValue>
                {apr !== undefined ? `${formatAPR(apr, 2)}%` : '-'}
              </S.StatValue>
            </S.Stat>
            <S.Stat showOnMobile showOnTablet>
              <S.StatTitle>Staking</S.StatTitle>
              <S.StatValue>
                {stakedBalance
                  ? `${formatBNToString(stakedBalance, 3, false)}`
                  : '-'}
                {usdStakedValue
                  ? ` (= ${formatBNToString(
                      usdStakedValue,
                      0,
                      false,
                      ','
                    )} USD)`
                  : ''}
              </S.StatValue>
            </S.Stat>
            <S.Stat showOnMobile>
              <S.StatTitle>Earned</S.StatTitle>
              <S.StatValue>
                {rewards !== undefined
                  ? formatBNToString(rewards, 2, true, ',')
                  : '---.--'}{' '}
                FLURRY
              </S.StatValue>
            </S.Stat>
          </S.MobileStats>
          {!unreleased && <Spacer axis="vertical" size={10} />}
          {!unreleased && (
            <FarmStakingForm
              contractKey={contractKey}
              user={user}
              contractAddress={contractAddress}
              availableBalance={availableBalance}
              liquidityUrl={liquidityUrl}
              disabled={disabled}
              maxDecimalPlace={decimals}
              liquidity={liquidity}
              totalSupply={totalSupply}
            />
          )}

          {!unreleased && <Spacer axis="vertical" size={10} />}

          <FarmUnstakingForm
            contractKey={contractKey}
            contractAddress={contractAddress}
            stakedBalance={stakedBalance}
            disabled={disabled}
            maxDecimalPlace={decimals}
            liquidity={liquidity}
            totalSupply={totalSupply}
            showUSDPrice={!unreleased}
          />
          <S.AddFarmToWallet
            contractKey={contractKey}
            buttonLabel={`Add farm to MetaMask`}
          />
        </S.Forms>
      ) : (
        LoadingFormSkeleton
      )}
    </S.BodyWrapper>
  );
};

export default FarmRowBody;
