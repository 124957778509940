import styled from 'styled-components/macro';
import { Image, Card } from 'components';
import { down, up } from 'styled-breakpoints';

export const SnowballWrapper = styled.div`
  padding-top: 150px;
  ${down('xl')} {
    padding-top: 100px;
  }
  ${down('md')} {
    padding-top: 70px;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  ${up('xl')} {
    max-height: 350px;
  }
`;

export const SnowballCard = styled(Card)`
  display: flex;
  flex-direction: column;
  width: 80%;
  ${up('xl')} {
    width: 1100px;
  }
  ${up('xxl')} {
    width: 1200px;
  }
  ${down('md')} {
    margin: 0 auto;
    grid-template-columns: none;
    display: flex;
    flex-direction: column;
    max-height: fit-content;
    height: fit-content;
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const TopWrapper = styled.div`
  position: relative;
  height: 60%;
  width: 100%;
`;

export const BottomWrapper = styled.div`
  height: 40%;
  width: 100%;
  box-shadow: inset 0 12px 15px -12px rgba(0, 0, 0, 0.6);
  background-color: #536f82;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  ${down('md')} {
    border-radius: 0px;
  }
`;
