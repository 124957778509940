import { createAsyncThunk } from '@reduxjs/toolkit';
import { utils } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import { EarnPayload } from 'core/types';
import { getContractByAddress } from 'utils/contracts';
import { sendTransaction, waitReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';
import { ciEquals, noExponents } from 'utils';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import { AuthState } from 'core/store/auth/auth';
import { kyberswapEncode } from 'packages/kyberswap/kyberswap';

export const convert = createAsyncThunk<void, EarnPayload>(
  'contracts/convert',
  async (earnPayload, thunkAPI) => {
    const { currentProvider, network, user } = (
      thunkAPI.getState() as { auth: AuthState }
    ).auth;
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };

    const transactionId = uuidv4();

    try {
      if (!contracts) {
        throw new Error('No contracts');
      }

      const token = await getContractByAddress(
        earnPayload.token,
        contracts,
        currentProvider?.web3provider
      );
      const rhoToken = await getContractByAddress(
        earnPayload.rhoToken,
        contracts
      );

      thunkAPI.dispatch(
        addNewTransaction({
          id: transactionId,
          source: 'earn',
          type: 'mint',
          state: 'pending',
          payload: {
            ...earnPayload,
            token: token.label,
            rhoToken: rhoToken.label,
          },
        })
      );

      const vaultRhoToken = contracts.vaults.find((v) =>
        ciEquals(v.rhoTokenAddress, rhoToken.address)
      );

      if (!vaultRhoToken) {
        throw new Error('Could not get vault of rho token');
      }

      // Check if token is stablecoin
      const vaultStablecoin = contracts.vaults.find((v) =>
        ciEquals(v.underlyingAddress, token.address)
      );
      // Check if token is deposit token
      const vaultDepositToken = contracts.vaults.find((v) =>
        v.depositTokens.find((t) => ciEquals(t.address, token.address))
      );

      const formatedAmount = utils.parseUnits(
        noExponents(earnPayload.tokenAmount),
        token.decimals
      );

      let tx;

      if (
        vaultStablecoin &&
        ciEquals(vaultStablecoin.rhoTokenAddress, rhoToken.address) &&
        !earnPayload.isUsingKyber
      ) {
        // Treat it as Stablecoin to linked rho token (eg USDT to RhoUSDT)
        tx = await sendTransaction(
          vaultStablecoin.contract.mint,
          formatedAmount
        );
      } else if (
        vaultDepositToken &&
        ciEquals(vaultDepositToken.rhoTokenAddress, rhoToken.address) &&
        !earnPayload.isUsingKyber
      ) {
        // Treat it as Deposit token to linked rho token (eg ibUSDT to RhoUSDT)
        tx = await sendTransaction(
          vaultDepositToken.contract.mintWithDepositToken,
          formatedAmount,
          token.address
        );
      } else {
        // Treat it as any token to any rho token (use kyberswap)
        if (contracts.kyberswap && user && currentProvider && network) {
          const encodedParams = await kyberswapEncode({
            srcAddress: token.address,
            dstAddress: earnPayload.isDirectSwap
              ? rhoToken.address
              : vaultRhoToken.underlyingAddress,
            amount: earnPayload.tokenAmount,
            slippage: earnPayload.maxSlippage ?? 0.5,
            recipient: contracts.kyberswap.address,
            ttl: 180,
            chainIdNumber: network.chainId,
            provider: currentProvider.web3provider,
            multicall: currentProvider.multicall,
          });

          if (encodedParams) {
            tx = await sendTransaction(
              contracts.kyberswap.contract.toRhoToken,
              vaultRhoToken.underlyingAddress,
              encodedParams.args[0],
              encodedParams.args[1],
              encodedParams.args[2],
              earnPayload.isDirectSwap ?? false
            );
          }
        }
      }

      if (tx) {
        thunkAPI.dispatch(
          updateTransactionHash({ id: transactionId, hash: tx.hash })
        );
        await waitReceipt(tx);

        thunkAPI.dispatch(
          updateTransactionState({
            id: transactionId,
            state: 'success',
          })
        );
      }
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'error',
        })
      );
    }
  }
);
