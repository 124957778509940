import styled from 'styled-components';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  ${down('md')} {
    display: none;
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
  padding: 0 2rem;
  padding-top: 1.8rem;
`;
export const SubTitle = styled.h4`
  font-size: 0.7rem;
  color: #5e5e5e;
  font-weight: normal;
  margin-top: 0.2rem;
  margin-bottom: 0;
  padding: 0 2rem 1rem 2rem;
`;
export const Links = styled.div`
  font-size: 0.9rem;
  padding: 1.5rem 2rem;
`;

export const Link = styled.a`
  text-decoration: underline;
`;

export const BottomImg = styled.img`
  margin-top: auto;
  border-radius: 10px;
  width: 100%;
  height: auto;
`;

export const ExternalLinkIcon = styled(HiOutlineExternalLink)`
  width: 11px;
  height: 11px;
`;
