const URL = {
  flurryApi: {
    EARN_ANNOUNCEMENTS: 'earn-announcements',
    NOTIFICATION_MESSAGES: 'notification-messages',
  },
  pancakeswapApi: {
    TOKENS: '/api/v2/tokens',
    FLURRY_TOKEN: '/api/v2/tokens/0x47c9BcEf4fE2dBcdf3Abf508f147f1bBE8d4fEf2',
  },
};

export default URL;
