import { EtherscanLink, PriceInput, ShortenBalance, Spacer } from 'components';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ethers } from 'ethers';
import NetworkDropdown from './NetworkDropdown';
import * as S from './BridgeForm.styles';
import { BridgePayload, CurrencyOption, User } from 'core/types';
import BigNumber from 'bignumber.js';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import {
  ciEquals,
  findNetworkInfoFromChain,
  getBridgeableNetworks,
  getNetworkInfoFromChainId,
  noExponents,
  sendGAEvent,
} from 'utils';
import { useBridgeTokens } from 'core/hooks';
import { switchChain } from 'core/store/auth/thunks/switchChain';
import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { approveBridge } from 'core/store/contracts/thunks/approveBridge';
import { usePrevious } from 'react-use';
import Skeleton from 'react-loading-skeleton';
import { updateUserBridgeApproval } from 'core/store/auth/thunks/updateUserBridgeApproval';
import { bridgeToken } from 'core/store/contracts/thunks/bridgeToken';
import { selectLatestBridgeTransaction } from 'core/store/transactions/selectors';
import { useHistory, useParams } from 'react-router-dom';

type SubmitButtonProps = {
  onFormSubmit: () => void;
  hasValidAmount: boolean;
  hasValidNetwork: boolean;
  needApproval: boolean | undefined;
  tokenLabel: string | undefined;
  user: User | undefined;
};

const DECIMAL_PRECISION = 6;

const formatAmountToPrecision = (amount: BigNumber) => {
  return amount.dp(DECIMAL_PRECISION, BigNumber.ROUND_FLOOR);
};

const LoadingSkeleton: React.FC = () => {
  return (
    <>
      <S.FormWrapper>
        <S.Labels>
          <Skeleton width={50} height={10} />
          <Skeleton width={100} height={10} />
        </S.Labels>
        <S.FromInputs>
          <Skeleton width="95%" height={50} />
          <Skeleton width="100%" height={50} />
        </S.FromInputs>
      </S.FormWrapper>
      <Spacer axis="vertical" size={50} />
      <S.FormWrapper>
        <S.Labels>
          <Skeleton width={50} height={10} />
        </S.Labels>
        <S.FromInputs>
          <Skeleton width="95%" height={50} />
          <Skeleton width="100%" height={50} />
        </S.FromInputs>
      </S.FormWrapper>
      <Spacer axis="vertical" size={50} />
      <Skeleton width="100%" height={50} />
    </>
  );
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onFormSubmit,
  hasValidAmount,
  hasValidNetwork,
  needApproval,
  tokenLabel,
  user,
}) => {
  const dispatch = useAppDispatch();

  if (user) {
    if (!hasValidAmount || !hasValidNetwork || !tokenLabel) {
      return (
        <S.SwapButton type="button" disabled>
          {!hasValidNetwork ? 'Choose a valid Network' : 'Enter an amount'}
        </S.SwapButton>
      );
    }

    return needApproval || needApproval === undefined ? (
      <S.ApproveButton
        disabled={needApproval === undefined}
        type="button"
        onClick={() => onFormSubmit()}
      >
        {needApproval === undefined ? (
          <S.ApproveLoadingCircle size={11} color="white" />
        ) : (
          `Approve ${tokenLabel}`
        )}
      </S.ApproveButton>
    ) : (
      <S.SwapButton type="button" onClick={() => onFormSubmit()}>
        Swap Now
      </S.SwapButton>
    );
  }

  return (
    <S.ConnectButton
      onClick={() => {
        sendGAEvent('Bridge', 'Connect');
        dispatch(connectToProvider());
      }}
    >
      Connect Wallet
    </S.ConnectButton>
  );
};

const BridgeForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { token: urlToken } = useParams<{ token: string }>();
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);
  const [showSwitchNetworkBtn, setShowSwitchNetworkBtn] = useState(false);

  const tokens = useBridgeTokens();

  const bridgeTransaction = useAppSelector((state) =>
    selectLatestBridgeTransaction(state)
  );
  const prevBridgeTransaction = usePrevious(bridgeTransaction);
  useEffect(() => {
    if (
      !showSwitchNetworkBtn &&
      bridgeTransaction?.state === 'unknown' &&
      prevBridgeTransaction?.state === 'pending'
    )
      setShowSwitchNetworkBtn(true);
  }, [bridgeTransaction, prevBridgeTransaction, showSwitchNetworkBtn]);

  const balances = useAppSelector((state) => state.balances.balances);
  const user = useAppSelector((state) => state.auth.user);
  const prevUser = usePrevious(user);

  const fromNetwork = useAppSelector((state) => state.auth.network);
  const [toNetwork, setToNetwork] = useState(-1);
  const [showAddress, setShowAddress] = useState(false);
  const [address, setAddress] = useState(user?.address ?? '');

  const [token, setToken] = useState(
    tokens.find((t) => t.value === urlToken) ?? tokens[0]
  );
  const [amount, setAmount] = useState('');
  const [formInfoState, setFormInfoState] = useState<{
    hasValidAmount: boolean;
    hasValidNetwork: boolean;
    error: string | undefined;
  }>({
    hasValidAmount: false,
    hasValidNetwork: false,
    error: undefined,
  });

  const tokensLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryToken
  );

  useEffect(() => {
    const fromNetworkChain =
      fromNetwork?.chainId ?? getBridgeableNetworks()[1].chainId;
    const filteredNetworks = getBridgeableNetworks().filter(
      (n) => n.chainId !== fromNetworkChain && n.chainId !== 1
    );
    if (filteredNetworks.length > 0) {
      setToNetwork(filteredNetworks[0].chainId);
    }
  }, [fromNetwork]);

  useEffect(() => {
    if (user && !prevUser && address === '') {
      setAddress(user.address);
      setLoaded(false);
    }
  }, [user, prevUser, address]);

  // User's token balance
  const tokenBalance = useMemo(() => {
    if (user && token) {
      const balance = user && balances.find((b) => b.currency === token.value);
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [balances, token, user]);

  // Tell if form submitted is valid, if not will set error and return false
  const isFormValid = useCallback(
    (amt: BigNumber, sendTo: string): boolean => {
      let formattedAddress = undefined;
      try {
        formattedAddress = ethers.utils.getAddress(sendTo);
      } catch (_) {}

      if (!formattedAddress) {
        setFormInfoState({
          ...formInfoState,
          error: 'The address you are sending to is not valid.',
        });
        return false;
      } else if (amt.gt(tokenBalance ?? new BigNumber(-1))) {
        setFormInfoState({
          ...formInfoState,
          error: `You do not have enough ${token?.label} to swap that much.`,
        });
        return false;
      } else if (amt.isZero() || amt.isNegative()) {
        setFormInfoState({
          ...formInfoState,
          error: `${token?.label} amount should be greater than zero.`,
        });
        return false;
      }
      return true;
    },
    [formInfoState, token, tokenBalance]
  );

  // Check if the user has enough allowance for the amount entered
  const needApproval = useMemo((): boolean | undefined => {
    if (!user) {
      return undefined;
    }

    try {
      const approval = user.approvals.find((e) =>
        ciEquals(e.contractAddress, `${token.value}-bridge`)
      );
      if (!approval) {
        return undefined;
      }

      return !approval.isApproved;
    } catch (_) {
      return undefined;
    }
  }, [token, user]);

  const submitSwap = useCallback(() => {
    if (!user) return;

    const tokenAmt = new BigNumber(amount);
    if (isFormValid(tokenAmt, address) && token) {
      setFormInfoState({ ...formInfoState, error: undefined });
      if (needApproval) {
        sendGAEvent('Bridge', 'Approve', token.label);
        dispatch(
          approveBridge({
            amountNeeded: tokenAmt,
            tokenAddress: token.value,
          })
        );
      } else {
        sendGAEvent('Bridge', 'Swap', token.label);
        dispatch(
          bridgeToken({
            tokenAddress: token.value,
            tokenAmount: tokenAmt,
            srcChain: fromNetwork?.chainId ?? 0,
            dstChain: toNetwork,
            receiverAddress: address,
          })
        );
      }
    }
  }, [
    user,
    amount,
    isFormValid,
    address,
    token,
    formInfoState,
    needApproval,
    dispatch,
    fromNetwork,
    toNetwork,
  ]);

  // Checks if networks are supported
  useEffect(() => {
    const hasNetworks =
      getBridgeableNetworks().filter(
        (e) => e.chainId === fromNetwork?.chainId || e.chainId === toNetwork
      ).length === 2;

    if (formInfoState.hasValidNetwork !== hasNetworks) {
      setFormInfoState({
        ...formInfoState,
        hasValidNetwork: hasNetworks,
      });
    }
  }, [toNetwork, formInfoState, fromNetwork]);

  // Handles the changes in the input value
  const handleInputChange = useCallback(
    (amountStr: string | BigNumber, currentToken?: CurrencyOption) => {
      if (showSwitchNetworkBtn) setShowSwitchNetworkBtn(false);
      if (!currentToken || !amountStr || amountStr === '') {
        setAmount('');
        setFormInfoState({
          ...formInfoState,
          hasValidAmount: false,
        });
      } else {
        const currentAmount = new BigNumber(amountStr);
        setAmount(noExponents(currentAmount));
        setFormInfoState({
          ...formInfoState,
          hasValidAmount: !currentAmount.isZero(),
        });
        dispatch(
          updateUserBridgeApproval({
            tokenAddress: currentToken.value,
            amount: currentAmount,
            user,
          })
        );
      }
    },
    [showSwitchNetworkBtn, formInfoState, dispatch, user]
  );

  // Handles changes of the token dropdown
  const tokenChanged = useCallback(
    (option: CurrencyOption) => {
      if (!option) return;
      setToken(option);
      setFormInfoState({ ...formInfoState, error: undefined });

      if (urlToken && option.value !== urlToken) {
        history.push('/bridge');
      }

      if (showSwitchNetworkBtn) setShowSwitchNetworkBtn(false);

      const newAmount =
        user && balances.find((b) => b.currency === option.value);
      if (newAmount) {
        handleInputChange(
          formatAmountToPrecision(newAmount.amount).toString(),
          option
        );
        if (!loaded) setLoaded(true);
      }

      if (!user && !loaded) {
        setLoaded(true);
      }
    },
    [
      formInfoState,
      urlToken,
      showSwitchNetworkBtn,
      user,
      balances,
      loaded,
      history,
      handleInputChange,
    ]
  );

  // First update to set default values
  useEffect(() => {
    if (!loaded && (!tokensLoading || !user)) {
      tokenChanged(tokens.find((t) => t.value === urlToken) ?? tokens[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, tokens, tokensLoading]);

  return (
    <S.Wrapper>
      <S.TitleMobile>
        <S.Title>Flurry Bridge</S.Title>
        <S.SubTitle>Change your tokens from one network to another</S.SubTitle>
      </S.TitleMobile>
      {loaded ? (
        <>
          <S.FormWrapper>
            <S.Labels>
              <S.Label>From</S.Label>
              <ShortenBalance
                label="Available balance:"
                balanceValue={tokenBalance}
                decimalPrecision={DECIMAL_PRECISION}
                currency={token?.label}
              />
            </S.Labels>
            <S.FromInputs>
              <NetworkDropdown
                onNetworkChanged={(n) => dispatch(switchChain(n))}
                selectedChain={
                  fromNetwork?.chainId ?? getBridgeableNetworks()[1].chainId
                }
                disabled={!user}
              />
              <PriceInput
                currencies={tokens}
                currentCurrencyValue={token?.value}
                inputValue={amount}
                onAmountChange={(v) => handleInputChange(v, token)}
                onCurrencyChange={(t: CurrencyOption) => tokenChanged(t)}
                onMaxBtnClicked={() => {
                  handleInputChange(noExponents(tokenBalance), token);
                }}
                maxDecimalPlace={token?.decimals}
              />
            </S.FromInputs>
          </S.FormWrapper>
          <S.SwitchButtonWrapper>
            <S.SwitchButton
              onClick={() => {
                const network = findNetworkInfoFromChain(toNetwork);
                if (user && network) {
                  dispatch(switchChain(network));
                }
              }}
            />
          </S.SwitchButtonWrapper>
          <S.FormWrapper>
            <S.Labels>
              <S.Label>To</S.Label>
            </S.Labels>
            <S.FromInputs>
              <NetworkDropdown
                onNetworkChanged={(n) => {
                  setToNetwork(n.chainId);
                  if (showSwitchNetworkBtn) setShowSwitchNetworkBtn(false);
                }}
                selectedChain={toNetwork}
                hiddenChains={
                  fromNetwork?.chainId
                    ? [fromNetwork.chainId, 1]
                    : [getBridgeableNetworks()[1].chainId, 1]
                }
              />
              <PriceInput
                currencies={tokens}
                currentCurrencyValue={token?.value}
                inputValue={amount}
                onAmountChange={(v) => handleInputChange(v, token)}
                onCurrencyChange={(t: CurrencyOption) => tokenChanged(t)}
                maxDecimalPlace={token?.decimals}
              />
            </S.FromInputs>
            {user && (
              <>
                <Spacer axis="vertical" size={15} />
                <S.Labels>
                  <S.AddressBtn onClick={() => setShowAddress(!showAddress)}>
                    {showAddress ? '- Send To' : '+ Send To'}
                  </S.AddressBtn>
                  <EtherscanLink />
                </S.Labels>
                <S.AddressWrapper show={showAddress}>
                  <S.Input
                    type="text"
                    value={address}
                    onChange={(v) => {
                      setAddress(v.target.value);
                      if (showSwitchNetworkBtn) setShowSwitchNetworkBtn(false);
                    }}
                  />
                </S.AddressWrapper>
              </>
            )}
            <Spacer axis="vertical" size={30} />
            {formInfoState.error && (
              <S.ErrorMsg>{formInfoState.error}</S.ErrorMsg>
            )}
            {!showSwitchNetworkBtn && (
              <SubmitButton
                onFormSubmit={() => submitSwap()}
                hasValidAmount={formInfoState.hasValidAmount}
                hasValidNetwork={formInfoState.hasValidNetwork}
                needApproval={needApproval}
                tokenLabel={token?.label}
                user={user}
              />
            )}
            {showSwitchNetworkBtn && bridgeTransaction && (
              <>
                <S.SwitchNetworkButton
                  onClick={() => {
                    dispatch(
                      switchChain(
                        getNetworkInfoFromChainId(
                          (bridgeTransaction.payload as BridgePayload).dstChain
                        )
                      )
                    );
                  }}
                >
                  Switch to{' '}
                  {
                    getNetworkInfoFromChainId(
                      (bridgeTransaction.payload as BridgePayload).dstChain
                    ).label
                  }
                </S.SwitchNetworkButton>
              </>
            )}
          </S.FormWrapper>
        </>
      ) : (
        <LoadingSkeleton />
      )}
    </S.Wrapper>
  );
};

export default BridgeForm;
