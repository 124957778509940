import { createAsyncThunk } from '@reduxjs/toolkit';
import { User } from '../../../types';

export const updateUser = createAsyncThunk<User, User>(
  'auth/updateUser',
  async (user: User, thunkAPI) => {
    if (!user || !user.address) {
      localStorage.removeItem('provider');

      return thunkAPI.rejectWithValue('no user');
    } else {
      return user;
    }
  }
);
