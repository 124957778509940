import { createAsyncThunk } from '@reduxjs/toolkit';
import { ethers } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import { getContractByAddress } from 'utils/contracts';
import { sendTransaction, waitReceipt } from 'utils/web3';
import { AuthState } from '../../auth/auth';
import { ContractsState } from '../contracts';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import { updateUserConvertApproval } from 'core/store/auth/thunks/updateUserConvertApproval';
import { ciEquals } from 'utils';
import { ApprovePayload } from 'core/types';

export const approveConvert = createAsyncThunk<void, ApprovePayload>(
  'contracts/approveConvert',
  async (transPayload, thunkAPI) => {
    const { user, currentProvider } = (
      thunkAPI.getState() as { auth: AuthState }
    ).auth;
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };
    const transactionId = uuidv4();

    try {
      if (!user) {
        throw Error('No User found');
      }
      if (!transPayload) {
        throw new Error('No payload');
      }

      const token = await getContractByAddress(
        transPayload.token,
        contracts,
        currentProvider?.web3provider
      );

      thunkAPI.dispatch(
        addNewTransaction({
          id: transactionId,
          source: 'earn',
          type: 'approval',
          state: 'pending',
          payload: {
            ...transPayload,
            token: token.label,
          },
        })
      );

      const vault = contracts.vaults.find(
        (v) =>
          v.depositTokens.find((t) =>
            ciEquals(t.address, transPayload.token)
          ) || ciEquals(v.underlyingAddress, transPayload.token)
      );

      let tx;

      if (vault && vault.rhoTokenAddress === transPayload.rhoToken) {
        tx = await sendTransaction(
          token.contract.approve,
          vault.address,
          ethers.constants.MaxUint256
        );
      } else {
        // No vault linked to token, then we use kyberswap
        if (contracts.kyberswap) {
          tx = await sendTransaction(
            token.contract.approve,
            contracts.kyberswap.address,
            ethers.constants.MaxUint256
          );
        }
      }

      if (!tx) {
        throw new Error(
          `Could not find vault or kyberswap contract for ${token.label}`
        );
      }

      thunkAPI.dispatch(
        updateTransactionHash({
          id: transactionId,
          hash: tx.hash,
        })
      );

      await waitReceipt(tx);

      thunkAPI.dispatch(
        updateUserConvertApproval({
          tokenAddress: token.address,
          rhoTokenAddress: transPayload.rhoToken,
          user,
          amount: transPayload.tokenAmount,
        })
      );

      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'success',
        })
      );
    } catch (error) {
      console.error(error);
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'error',
        })
      );
    }
  }
);
