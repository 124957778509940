import { EtherscanLink, StakingNotification } from 'components';
import { useAppSelector } from 'core/store/hooks';
import { selectLatestSnowballTransaction } from 'core/store/transactions/selectors';
import { BasePayload } from 'core/types';
import React, { useMemo } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { formatBNToString } from 'utils';
import * as S from './SnowballHeader.styles';

const SnowballStats: React.FC = () => {
  const stakeTransaction = useAppSelector((state) =>
    selectLatestSnowballTransaction(state)
  );

  const [showStakingNotification, setShowStakingNotification] = useState(false);

  const notificationMessage = useMemo(() => {
    if (stakeTransaction) {
      const amount = formatBNToString(
        (stakeTransaction.payload as BasePayload)?.amount,
        2,
        false,
        ','
      );
      if (stakeTransaction.state === 'pending') {
        if (stakeTransaction.type === 'stake') {
          return `Staking ${amount} FLURRY...`;
        } else if (stakeTransaction.type === 'unstake') {
          return `Unstaking ${amount} FLURRY...`;
        } else {
          return `Waiting for approval...`;
        }
      } else if (stakeTransaction.state === 'success') {
        if (stakeTransaction.type === 'stake') {
          return `Successfully staked ${amount} FLURRY`;
        } else if (stakeTransaction.type === 'unstake') {
          return `Successfully unstaked ${amount} FLURRY`;
        } else {
          return `Approved FLURRY staking, you can now Stake`;
        }
      } else {
        if (stakeTransaction.type === 'stake') {
          return `An error occured when staking`;
        } else if (stakeTransaction.type === 'unstake') {
          return `An error occured when unstaking`;
        } else {
          return `An error occured when approving`;
        }
      }
    }
    return '';
  }, [stakeTransaction]);

  useEffect(() => {
    if (stakeTransaction && stakeTransaction.state === 'pending') {
      setShowStakingNotification(true);
    }
  }, [stakeTransaction]);

  return (
    <S.Wrapper>
      <S.NotificationWrapper>
        {stakeTransaction && showStakingNotification && (
          <StakingNotification
            body={
              <S.NotificationBody>
                <S.NotificationText>{notificationMessage}</S.NotificationText>
                <EtherscanLink
                  showWithLabel
                  transactionHash={stakeTransaction.hash}
                />
              </S.NotificationBody>
            }
            state={stakeTransaction.state}
            onCloseBtnClicked={() => setShowStakingNotification(false)}
          />
        )}
      </S.NotificationWrapper>
      <S.Title>Snowball</S.Title>
      <S.SubTitle>Stake your Flurry tokens and start earning more</S.SubTitle>
    </S.Wrapper>
  );
};
export default SnowballStats;
