import ScrollContainer from 'react-indiana-drag-scroll';
import styled, { css } from 'styled-components/macro';

export const Wrapper = styled.div`
  width: 94%;
  padding: 0.5rem 3% 1rem 3%;
  position: relative;
  background-color: #00000001;
  border-radius: 10px;
  border: 1px solid #ececec;
`;

export const Title = styled.h3`
  font-size: 0.8rem;
  color: #5c5c5c;
`;

export const MainTokens = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 0.2rem;
  font-size: 0.8rem;
  font-weight: bold;
`;

export const RouteWrapper = styled.div`
  margin: auto;
  width: auto;
  position: relative;
  padding: 20px 10px 0px;
  max-height: 250px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: #a9a9a942;
  }
`;

const RouteDot = styled.i`
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: absolute;
  top: 0px;
  z-index: 1;
  background-color: rgb(85, 84, 139);
`;
export const LeftRouteDot = styled(RouteDot)`
  left: 6.5px;
  right: unset;
`;
export const RightRouteDot = styled(RouteDot)`
  right: 6.5px;
  left: unset;
`;

export const StepsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  align-items: center;
  &::before,
  &::after {
    content: '';
    display: block;
    border-left: 1px solid rgb(64, 80, 90);
    width: 100%;
    height: calc(50% + 20px);
    position: absolute;
    border-right: 1px solid rgb(64, 80, 90);
    box-sizing: border-box;
    pointer-events: none;
  }
  &::before {
    top: -20px;
  }
  &::after {
    bottom: 0;
  }
`;

export const Percent = styled.div`
  font-size: 12px;
  line-height: 14px;
  font-weight: 700;
  position: absolute;
  top: calc(50% - 15px);
  left: 8px;
  transform: translateY(50%);
  z-index: 2;
  color: rgb(85, 84, 139);
`;

export const RouteLine = styled.div`
  position: absolute;
  border-bottom: 1px solid rgba(226, 226, 226, 0.2);
  width: calc(100% - 68px);
  left: 43px;
`;

const RouteStepsBorders = css`
  transition: all 0.1s ease 0s;
  content: '';
  display: block;
  z-index: 2;
  pointer-events: none;
  position: absolute;
  inset: 50% 0px auto auto;
  width: 40px;
  height: calc(100% - 20px);
  transform: translateY(-50%);
  opacity: 0;
`;

export const ScrollContainerWrapper = styled.div`
  width: calc(100% - 68px);
  margin: 10px 0px 10px 6px;
  &::before {
    background: linear-gradient(to left, rgb(238, 238, 238), transparent);
    right: 24px;
    ${RouteStepsBorders}
  }
  &::after {
    background: linear-gradient(to right, rgb(238, 238, 238), transparent);
    left: 42px;
    ${RouteStepsBorders}
  }
`;

export const CustomScrollContainer = styled(ScrollContainer)``;

export const RouteSteps = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
`;
export const Step = styled.div`
  color: rgb(0, 0, 0);
  padding: 8px;
  border-radius: 8px;
  background-color: rgb(245, 252, 255);
  border: 1px solid rgba(198, 226, 240, 0.521);
  height: fit-content;
  position: relative;
  flex: 0 0 146px;
  margin: auto;
  transition: filter 0.15s ease 0s;
  cursor: pointer;
  box-shadow: inset 0 0 5px #00000018;
`;
export const StepTop = styled.div`
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  white-space: nowrap;
  text-decoration: none;
  padding-bottom: 9px;
  border-bottom: 1px solid #2b373f36;
`;
export const StepBottom = styled.div`
  font-size: 0.65rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 3px 0px;
  margin-top: 4px;
  border-radius: 8px;
  line-height: 20px;
  white-space: nowrap;
  text-decoration: none;
`;

export const StepChevronWrapper = styled.div`
  min-width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
export const StepChevronRight = styled.div`
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-left: 5px solid rgb(85, 84, 139);
`;

export const TokenWrapper = styled.div<{ fullWidth: boolean }>`
  display: flex;
  align-items: center;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
`;
export const TokenLogo = styled.img<{ isLeft: boolean }>`
  height: 18px;
  width: auto;
  margin-right: ${(props) => (props.isLeft ? '.5rem' : '0')};
  margin-left: ${(props) => (!props.isLeft ? '.5rem' : '0')};
`;
export const TokenLink = styled.a<{ fullWidth: boolean }>`
  text-decoration: none;
  display: flex;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};
  align-items: center;
  padding: 0.3rem 0.5rem;
  color: black;
  margin-bottom: 0.1rem;
  border-radius: 5px;
  transition: all 0.2s linear;

  &:hover {
    background-color: #00000005;
  }
`;
