import { APRDetailsPopup } from 'components';
import { RhoTokenAprs } from 'core/types';
import { useAppSelector } from 'core/store/hooks';
import React from 'react';
import { useState, useMemo } from 'react';
import { ciEquals, formatAPR, sendGAEvent } from 'utils';
import * as S from './EarnHeader.styles';
import Skeleton from 'react-loading-skeleton';

export interface InterestRateProps {
  tokenName: string;
  tokenAddress: string;
  disabled?: boolean;
}

const InterestRate: React.FC<InterestRateProps> = ({
  tokenName,
  tokenAddress,
  disabled,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const contracts = useAppSelector((state) => state.contracts);

  const tokenAprs: RhoTokenAprs | undefined = useMemo(() => {
    if (!contracts.aprs || !contracts.aprs.rhoTokens) return undefined;

    return contracts.aprs.rhoTokens.find((t) =>
      ciEquals(t.tokenAddress, tokenAddress)
    );
  }, [contracts.aprs, tokenAddress]);

  const totalAPR = useMemo(() => {
    if (disabled) {
      return undefined;
    }
    return formatAPR(tokenAprs?.vaultIndicativeApr?.apr, 2);
  }, [disabled, tokenAprs]);

  const stablecoinName = useMemo(() => {
    if (!tokenAprs) return '';
    return (
      contracts.stablecoins.find((c) =>
        ciEquals(c.address, tokenAprs.stablecoinAddress)
      )?.label ?? ''
    );
  }, [contracts, tokenAprs]);

  return (
    <>
      <S.InterestRateWrapper
        disabled={disabled || !tokenAprs || totalAPR === undefined}
        onClick={() => {
          if (!disabled && tokenAprs && totalAPR !== undefined) {
            sendGAEvent('Earn', 'Show RhoToken APR Details', tokenName);
            setShowDetails(true);
          }
        }}
      >
        {totalAPR !== undefined ? (
          `${totalAPR}%`
        ) : (
          <Skeleton height="0.65rem" />
        )}
      </S.InterestRateWrapper>
      {!disabled && tokenAprs && showDetails && (
        <APRDetailsPopup
          tokenName={tokenName}
          stablecoinName={stablecoinName}
          tokenAprs={tokenAprs}
          show={showDetails}
          onClose={() => setShowDetails(false)}
        />
      )}
    </>
  );
};

export default InterestRate;
