import styled from 'styled-components/macro';
import { Image } from 'components';
import { down, up } from 'styled-breakpoints';

export const BridgeWrapper = styled.div`
  padding-top: 150px;
  ${down('xl')} {
    padding-top: 100px;
  }
  ${down('md')} {
    padding-top: 70px;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  ${up('xl')} {
    max-height: 350px;
  }
`;

export const BridgeCard = styled.div`
  position: relative;
  margin: 0 auto;
  height: auto;
  width: 80%;
  column-gap: 15px;
  background: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${up('xl')} {
    width: 1100px;
  }
  ${up('xxl')} {
    width: 1200px;
  }
  ${down('md')} {
    display: block;
    margin: 0 auto;
    max-height: fit-content;
    height: fit-content;
    width: 100%;
    max-width: 100%;
    border-radius: 0px;
  }
`;

export const ContentCard = styled.div`
  background-color: white;
  border-radius: 10px;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  ${down('md')} {
    border-radius: 0px;
  }
`;

export const Title = styled.h1`
  padding: 2rem 2rem 0 2rem;
  font-size: 1.2rem;
  font-weight: normal;
  margin: 0;
`;

export const BridgeInfos = styled(ContentCard)``;
export const BridgeForm = styled(ContentCard)`
  display: flex;
  align-items: center;
`;

export const ConnectButton = styled.button`
  width: calc(100% - 4rem);
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  background: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;

  opacity: ${(props) => (!props.disabled ? '1' : '0.5')};
`;

export const NetworkMsg = styled.div`
  font-weight: bold;
  text-align: center;
  width: fit-content;
  margin: 0 auto;
  color: white;
  font-size: 0.9rem;
  background-color: #8585ff;
  border-radius: 15px;
  padding: 1rem 2rem;
  ${down('sm')} {
    margin: 0 1rem;
  }
`;
