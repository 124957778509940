import { createAsyncThunk } from '@reduxjs/toolkit';
import { utils } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import { sendTransaction, waitReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';
import { getContractByAddress, getLogIndex, noExponents } from 'utils';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionPayload,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import { BridgeReq } from 'core/types';
import { AuthState } from 'core/store/auth/auth';

export const bridgeToken = createAsyncThunk<void, BridgeReq>(
  'contracts/bridgeToken',
  async (payload, thunkAPI) => {
    const {
      tokenAmount: amount,
      tokenAddress,
      dstChain,
      srcChain,
      receiverAddress,
    } = payload;
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };
    const { user } = (thunkAPI.getState() as { auth: AuthState }).auth;

    const bridge = contracts.bridge;
    const registry = contracts.registry;

    if (!bridge) {
      throw Error('No Bridge contract found');
    }
    if (!registry) {
      throw Error('No Registry contract found');
    }
    if (!user) {
      throw Error('No User found');
    }

    const transactionId = uuidv4();

    try {
      const token = await getContractByAddress(tokenAddress, contracts);
      const dstTokenAddress = await registry.contract.tokenRegistry(
        tokenAddress,
        dstChain
      );

      thunkAPI.dispatch(
        addNewTransaction({
          id: transactionId,
          source: 'bridge',
          type: 'swap',
          state: 'pending',
          payload: {
            token: token.label,
            tokenAddress: token.address,
            tokenDecimals: token.decimals,
            tokenAmount: amount,
            dstTokenAddress,
            srcChain,
            dstChain,
            receiverAddress,
            senderAddress: user.address,
          },
        })
      );

      const formatedAmount = utils.parseUnits(
        noExponents(amount),
        token.decimals
      );

      const tx = await sendTransaction(
        bridge.contract.bridgeTokenAt,
        dstChain,
        token.address,
        formatedAmount,
        receiverAddress
      );

      thunkAPI.dispatch(
        updateTransactionHash({
          id: transactionId,
          hash: tx.hash,
        })
      );

      const logIndex = getLogIndex(bridge, await waitReceipt(tx));

      thunkAPI.dispatch(
        updateTransactionPayload({
          id: transactionId,
          payload: {
            token: token.label,
            tokenAddress: token.address,
            tokenDecimals: token.decimals,
            tokenAmount: amount,
            dstTokenAddress,
            srcChain,
            dstChain,
            receiverAddress,
            senderAddress: user.address,
            logIndex,
          },
        })
      );
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'unknown',
        })
      );
    } catch (error) {
      console.error(error.toString());
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'error',
        })
      );
    }
  }
);
