import styled from 'styled-components';
import { FaArrowDown, FaArrowUp } from 'react-icons/fa';
import { down } from 'styled-breakpoints';

export const TableHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 0.8rem;
  font-weight: bold;
  padding: 0 1rem;
`;

export const SortBtn = styled.button`
  cursor: pointer;
  border: 1px solid #00000010;
  border-radius: 5px;
  background-color: #00000005;
  display: flex;
  align-items: center;
  padding: 0.3rem 0.3rem;
`;
export const ArrowUp = styled(FaArrowUp)`
  font-size: 0.65rem;
`;
export const ArrowDown = styled(FaArrowDown)`
  font-size: 0.65rem;
`;

export const TableWrapper = styled.div`
  border-top: 1px solid #00000005;
  background-color: #00000005;
  height: 250px;
  width: 100%;
  ${down('sm')} {
    height: 50vh;
  }
`;

export const NoValues = styled.h6`
  font-size: 0.9rem;
  padding: 3rem 1rem;
  text-align: center;
  margin: 0 auto;
  opacity: 0.5;
`;
