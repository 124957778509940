import React, { useMemo, useRef } from 'react';
import ReactTooltip from 'react-tooltip';
import * as S from './ConvertAdvancedSettingsPopup.styles';
import { Spacer, useOnClickOutside } from 'components';

interface ConvertAdvanceSettingsPopupProps {
  currentSlippage: number;
  displayRoute: boolean;
  onMaxSlippageSelect: (v: number) => void;
  onDisplayRouteChanged: (v: boolean) => void;
  onClose: () => void;
  isOpen: boolean;
}

const ConvertAdvancedSettingsPopup: React.FC<
  ConvertAdvanceSettingsPopupProps
> = ({
  currentSlippage,
  displayRoute,
  onMaxSlippageSelect,
  onDisplayRouteChanged,
  onClose,
  isOpen,
}: ConvertAdvanceSettingsPopupProps) => {
  const error = useMemo(() => {
    if (currentSlippage < 0.001) {
      return 'Your transaction may fail';
    } else if (currentSlippage > 0.05) {
      return 'Your transaction may be frontrun';
    }
    return undefined;
  }, [currentSlippage]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => onClose());

  if (!isOpen) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Popup ref={ref}>
        <S.CloseBtn onClick={onClose} />
        <S.Header>Advanced Settings</S.Header>
        <Spacer axis="vertical" size={10} />
        <ReactTooltip id={'slippage'} multiline={true} />
        <S.SubHeader>
          Max Slippage
          <div
            data-for="slippage"
            data-tip={
              'Transaction will revert if<br />there is an adverse rate change<br />that is higher than this %'
            }
            data-iscapture={'true'}
          >
            <S.InfoIcon />
          </div>
        </S.SubHeader>
        <Spacer axis="vertical" size={10} />
        <S.SlippageOptions>
          <S.SlippageOption
            active={currentSlippage === 0.001}
            onClick={() => onMaxSlippageSelect(0.001)}
          >
            0.1%
          </S.SlippageOption>
          <Spacer axis="horizontal" size={5} />
          <S.SlippageOption
            active={currentSlippage === 0.005}
            onClick={() => onMaxSlippageSelect(0.005)}
          >
            0.5%
          </S.SlippageOption>
          <Spacer axis="horizontal" size={5} />
          <S.SlippageOption
            active={currentSlippage === 0.01}
            onClick={() => onMaxSlippageSelect(0.01)}
          >
            1.0%
          </S.SlippageOption>
          <Spacer axis="horizontal" size={5} />
          <S.CustomSlippageOption>
            <S.SlippageInput
              onChange={(v) => {
                let newSlippage = 0.005;
                if (!isNaN(parseFloat(v.target.value))) {
                  newSlippage = parseFloat(v.target.value) / 100;
                }
                onMaxSlippageSelect(newSlippage);
              }}
              autoComplete="off"
              placeholder={
                !isNaN(currentSlippage)
                  ? (currentSlippage * 100).toString()
                  : ''
              }
            />
            %
          </S.CustomSlippageOption>
        </S.SlippageOptions>
        {error && <S.ErrorMsg>{error}</S.ErrorMsg>}
        <Spacer axis="vertical" size={20} />
        <S.Header>Display Settings</S.Header>
        <Spacer axis="vertical" size={10} />
        <S.InlineOption>
          <ReactTooltip id={'traderRoute'} multiline={true} />
          <S.SubHeader>
            Trader Route
            <div
              data-for="traderRoute"
              data-tip={'Turn on to display trade route'}
              data-iscapture={'true'}
            >
              <S.InfoIcon />
            </div>
          </S.SubHeader>
          <S.OnOffWrapper>
            <S.SwitchBtn
              selected={displayRoute}
              onClick={() => onDisplayRouteChanged(!displayRoute)}
            >
              On
            </S.SwitchBtn>
            <S.SwitchBtn
              selected={!displayRoute}
              onClick={() => onDisplayRouteChanged(!displayRoute)}
            >
              Off
            </S.SwitchBtn>
          </S.OnOffWrapper>
        </S.InlineOption>
        <Spacer axis="vertical" size={30} />
      </S.Popup>
    </S.Wrapper>
  );
};

export default ConvertAdvancedSettingsPopup;
