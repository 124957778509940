import React, { useState, useMemo } from 'react';
import * as S from './FarmRow.styles';
import { EtherscanLink } from 'components';
import FarmIcon from '../../../components/FarmIcon';
import { formatAPR, formatBNToString, sendGAEvent } from 'utils';
import Skeleton from 'react-loading-skeleton';
import BigNumber from 'bignumber.js';
import FarmRowBody from './FarmRowBody';
import { User } from 'core/types';

interface FarmRowProps {
  unreleased?: boolean;
  loading?: boolean;
  logoOne?: string;
  logoTwo?: string;
  title?: string;
  farmAddress?: string;
  rewards?: BigNumber;
  liquidity?: BigNumber;
  totalSupply?: BigNumber;
  availableBalance?: BigNumber;
  stakedBalance?: BigNumber;
  apr?: number;
  contractKey?: string;
  disabled?: boolean;
  liquidityUrl?: string;
  user?: User;
  decimals?: number;
}

const LoadingRow = (
  <>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper hideOnTablet hideOnMobile>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper hideOnMobile>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper hideOnTablet hideOnMobile>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper hideOnMobile>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
    <S.LoadingSkeletonWrapper>
      <Skeleton width={'80%'} height={30} />
    </S.LoadingSkeletonWrapper>
  </>
);

const FarmRow: React.FC<FarmRowProps> = ({
  unreleased,
  logoOne,
  logoTwo,
  title,
  farmAddress,
  user,
  rewards,
  apr,
  liquidity,
  totalSupply,
  availableBalance,
  stakedBalance,
  contractKey,
  liquidityUrl,
  loading,
  disabled,
  decimals,
}) => {
  const [isOpened, setIsFolded] = useState(false);

  const usdStakedValue = useMemo(() => {
    if (stakedBalance && totalSupply && liquidity) {
      if (totalSupply.isZero()) {
        return new BigNumber(0);
      }
      return stakedBalance.div(totalSupply).times(liquidity);
    }
    return undefined;
  }, [liquidity, stakedBalance, totalSupply]);

  const rowHeader = useMemo(() => {
    if (loading) {
      return LoadingRow;
    } else if (unreleased) {
      return (
        <>
          <FarmIcon
            foregroundIcon={logoTwo ?? ''}
            backgroundIcon={logoOne ?? ''}
          />
          <S.FarmTitle>
            {title}
            <EtherscanLink contractAddress={farmAddress} />
          </S.FarmTitle>
          <S.ComingSoonFarmRow>Coming Soon</S.ComingSoonFarmRow>
          {usdStakedValue && !usdStakedValue.isZero() && (
            <S.Chevron $isup={isOpened} />
          )}
        </>
      );
    }
    return (
      <>
        <FarmIcon
          foregroundIcon={logoTwo ?? ''}
          backgroundIcon={logoOne ?? ''}
        />
        <S.FarmTitle>
          {title}
          <EtherscanLink contractAddress={farmAddress} />
        </S.FarmTitle>
        {!loading ? (
          <S.Value hideOnTablet hideOnMobile>
            $
            {liquidity !== undefined
              ? formatBNToString(liquidity, 0, true, ',')
              : ' -'}
          </S.Value>
        ) : (
          <S.Value hideOnTablet hideOnMobile>
            $ <Skeleton width={50} />
          </S.Value>
        )}
        <S.Value hideOnMobile>
          {apr !== undefined ? (
            `${formatAPR(apr, 2)}%`
          ) : (
            <Skeleton width={'80%'} height={30} />
          )}
        </S.Value>
        <S.Value hideOnTablet hideOnMobile>
          {stakedBalance !== undefined ? (
            <>
              <S.StakedTokenValue>
                {stakedBalance
                  ? `${formatBNToString(stakedBalance, 3, false)}`
                  : '-'}
              </S.StakedTokenValue>
              <S.StakedUsdValue>
                {usdStakedValue
                  ? `= ${formatBNToString(usdStakedValue, 0, false, ',')} USD`
                  : '-'}
              </S.StakedUsdValue>
            </>
          ) : (
            <Skeleton width={'80%'} height={30} />
          )}
        </S.Value>
        {!loading && rewards !== undefined ? (
          <S.Value hideOnMobile>
            {formatBNToString(rewards, 2, true, ',')} FLURRY
          </S.Value>
        ) : (
          <Skeleton width={'80%'} height={30} />
        )}
        <S.Chevron $isup={isOpened} />
      </>
    );
  }, [
    apr,
    farmAddress,
    isOpened,
    liquidity,
    loading,
    logoOne,
    logoTwo,
    rewards,
    stakedBalance,
    title,
    unreleased,
    usdStakedValue,
  ]);

  return (
    <>
      <S.Wrapper
        isOpened={isOpened}
        onClick={() => {
          const isUnreleasedButStaked =
            unreleased && usdStakedValue && !usdStakedValue.isZero();
          if (!disabled && !loading && (!unreleased || isUnreleasedButStaked)) {
            sendGAEvent(
              'Farms',
              !isOpened ? 'Open Farm Row' : 'Close Farm Row',
              contractKey
            );
            setIsFolded(!isOpened);
          }
        }}
      >
        {rowHeader}
      </S.Wrapper>
      <S.BodyContent show={isOpened}>
        <FarmRowBody
          unreleased={unreleased}
          contractKey={contractKey ?? ''}
          contractAddress={farmAddress ?? ''}
          user={user}
          disabled={disabled}
          liquidityUrl={liquidityUrl}
          availableBalance={availableBalance}
          stakedBalance={stakedBalance}
          liquidity={liquidity}
          totalSupply={totalSupply}
          apr={apr}
          rewards={rewards}
          decimals={decimals}
        />
      </S.BodyContent>
    </>
  );
};

export default FarmRow;
