import { useIdle, useInterval } from 'react-use';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { setBlockNow } from 'core/store/blocks/blocks';

/**
 * Updater for block numbers
 */
const BlockUpdater = (): null => {
  const { currentProvider, network } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const idle = useIdle();

  useInterval(
    () => {
      if (!idle && network) {
        // Only set the new block number when the user is active
        currentProvider?.web3provider.getBlockNumber().then((latest) => {
          dispatch(setBlockNow({ chainId: network.chainId, blockNow: latest }));
        });
      }
    },
    network?.avgBlockTime ? network.avgBlockTime * 1000 : 7000
  );

  return null;
};

export default BlockUpdater;
