import { createAsyncThunk } from '@reduxjs/toolkit';
import { addMiddleware } from 'redux-dynamic-middlewares';
import { ethers } from 'ethers';
import Web3Modal, { getProviderInfo } from '@flurryfinance/web3modal';

import injectedProviderMiddleware from '../../middleware/injectedProviderMiddleware';
import { initContracts } from '../../contracts/thunks/initContracts';
import { initBalances } from 'core/store/balances/thunks/initBalances';
import { getTestnetStablecoinUrl } from '../../ui/ui';
import { AuthState } from '../auth';
import {
  getArrayNumberFromStr,
  getNetworkInfo,
  redirectBasedOnNetwork,
} from 'utils';
import { ConnectionResult } from 'core/types';

// Wallets packages
import WalletConnectProvider from '@walletconnect/web3-provider';
import MewConnect from '@myetherwallet/mewconnect-web-client';
import { Multicall } from 'ethereum-multicall';
import WalletLink from 'walletlink';
import { initTransactions } from 'core/store/transactions/thunks/initTransactions';
// import Torus from "@toruslabs/torus-embed";
// import Authereum from "authereum";
// import ethProvider from "eth-provider";
// import BurnerConnectProvider from "@burner-wallet/burner-connect-provider";

export const connectToProvider = createAsyncThunk<ConnectionResult, void>(
  'auth/connectToProvider',
  async (_, thunkAPI) => {
    let result: ConnectionResult | undefined = undefined;
    const currentNetwork = (thunkAPI.getState() as { auth: AuthState }).auth
      .network;
    try {
      const auth = (thunkAPI.getState() as { auth: AuthState }).auth;
      let web3modal: Web3Modal | undefined;
      if (auth.web3modal) {
        web3modal = auth.web3modal;
      } else {
        const providerOptions = {
          walletconnect: {
            package: WalletConnectProvider,
            options: {
              infuraId: '94ef26b533644f6ba2b5185e6f49e343',
              rpc: {
                56: 'https://bsc-dataseed.binance.org/',
                97: 'https://data-seed-prebsc-1-s1.binance.org:8545/',
                137: 'https://polygon-rpc.com/',
                80001: 'https://rpc-mumbai.matic.today',
              },
            },
          },
          mewconnect: {
            package: MewConnect,
            options: {
              infuraId: '94ef26b533644f6ba2b5185e6f49e343',
            },
          },
          walletlink: {
            package: WalletLink,
            options: {
              infuraId: '94ef26b533644f6ba2b5185e6f49e343',
              appName: 'Flurry Finance',
            },
          },
        };

        web3modal = new Web3Modal({
          cacheProvider: true,
          providerOptions,
        });
      }

      const accessor = await web3modal.connect();
      const provider = new ethers.providers.Web3Provider(accessor);

      // Get the user account
      const accounts = await provider.listAccounts();

      // Listen to accessor events
      addMiddleware(injectedProviderMiddleware(accessor));

      // Get the connected Network infos
      const newNetwork = await provider.getNetwork();
      const networkInfo = getNetworkInfo(newNetwork);

      const multicall = new Multicall({
        multicallCustomContractAddress: networkInfo.multicallContract,
        ethersProvider: provider,
        tryAggregate: true,
      });

      // Check if the chain is not disabled
      const disabledChains = getArrayNumberFromStr(
        window._env_.DISABLED_CHAINS
      );
      if (disabledChains && disabledChains.indexOf(newNetwork.chainId) !== -1) {
        return thunkAPI.rejectWithValue('Disabled chain');
      }

      // Redirect to correct environment if not available network
      redirectBasedOnNetwork(newNetwork.chainId, currentNetwork?.chainId);

      // Getting the urls for the buttons Get USDT, Get USDC
      thunkAPI.dispatch(getTestnetStablecoinUrl(networkInfo));

      // Init transactions history
      thunkAPI.dispatch(initTransactions());

      // Send request to init all contracts with newly connected accessor
      thunkAPI
        .dispatch(
          initContracts({ accessor, multicall, userAddress: accounts[0] })
        )
        .finally(() => {
          thunkAPI.dispatch(initBalances({ userAddress: accounts[0] }));
        });

      result = {
        user: {
          address: accounts[0],
          balances: [],
          approvals: [],
        },
        provider: {
          name: getProviderInfo(accessor).name,
          accessor,
          web3provider: provider,
          multicall,
        },
        network: networkInfo,
        web3modal,
      };
    } catch (e) {
      return thunkAPI.rejectWithValue(e);
    }
    if (result) {
      // localStorage.setItem("provider", p.id);
      return result;
    }
    return thunkAPI.rejectWithValue('error');
  }
);
