/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MiddlewareAPI } from '@reduxjs/toolkit';
import BigNumber from 'bignumber.js';
import { CONTENT_WS } from '../content';
import { updateFlurryPrice } from '../contracts/contracts';

const gateioWebsocketMiddleware = (store: MiddlewareAPI<any, any>) => {
  const ws = new WebSocket(window._env_.GATEIO_WEBSOCKET);

  ws.onopen = () => {
    // querying and subscribing to flurry-usdt price
    ws.send(
      JSON.stringify({
        id: 6541123,
        method: 'ticker.query',
        params: ['FLURRY_USDT', 86400],
      })
    );
    ws.send(
      JSON.stringify({
        id: 88412,
        method: 'ticker.subscribe',
        params: ['FLURRY_USDT'],
      })
    );
  };

  ws.onmessage = (ev: MessageEvent) => {
    const { result, id, method, params } = JSON.parse(ev.data);

    // Query result
    if (id === 6541123) {
      store.dispatch(updateFlurryPrice(new BigNumber(result.last)));
    }

    // Subscription ticker
    if (
      method === 'ticker.update' &&
      params.length > 1 &&
      params[0] === 'FLURRY_USDT' &&
      params[1].last
    ) {
      store.dispatch(updateFlurryPrice(new BigNumber(params[1].last)));
    }
  };

  return (next: any) => (action: any) => {
    switch (action.type) {
      case CONTENT_WS.CONNECT_SOCKET:
        break;

      case CONTENT_WS.SUBSCRIBE:
        break;

      default:
        break;
    }

    return next(action);
  };
};

export default gateioWebsocketMiddleware;
