import { createAsyncThunk } from '@reduxjs/toolkit';
import { getContractByName } from 'utils/contracts';
import { AuthState } from '../../auth/auth';
import { ContractsState } from '../contracts';
import { TokenContractsLogos } from 'core/config/contracts';
import RhoTokenLogo from 'assets/images/logos/rhoToken_logo.png';
import FlurryLogo from 'assets/images/logos/flurry_icon_only.png';
import FarmTokenLogo from 'assets/images/logos/farm_token_logo.png';
import { ciEquals } from 'utils';
import { FarmsState } from 'core/store/farms/farms';
import { Contract, FarmContract } from 'core/types';

const farmNameShortener = (farmLabel: string) => {
  const tokenNames = farmLabel.split('/');

  for (let i = 0; i < tokenNames.length; ++i) {
    if (tokenNames[i].indexOf('rho') !== -1) {
      tokenNames[i] = tokenNames[i].replace('rho', 'r');
    } else if (tokenNames[i].indexOf('FLURRY') !== -1) {
      tokenNames[i] = tokenNames[i].replace('FLURRY', 'FT');
    }
  }

  return `${tokenNames[0]}/${tokenNames[1]}`;
};

export const watchAsset = createAsyncThunk<boolean, string>(
  'contracts/watchAsset',
  async (payload, thunkAPI) => {
    try {
      const { contracts } = thunkAPI.getState() as {
        contracts: ContractsState;
      };
      const farms = (
        thunkAPI.getState() as {
          farms: FarmsState;
        }
      ).farms.list;
      const { auth } = thunkAPI.getState() as { auth: AuthState };

      let token: Contract | FarmContract | undefined = undefined;
      let isFarm = false;
      try {
        token = getContractByName(payload, contracts);
      } catch (_) {
        token = farms.find((f) => f.key === payload);
        if (token) {
          isFarm = true;
        }
      }

      if (!auth.currentProvider) {
        throw Error('No provider has been set');
      }

      if (auth.currentProvider.accessor.request && token !== undefined) {
        let logo = TokenContractsLogos.find((e) => e.key === token?.key)?.logo;
        if (!logo) {
          if (ciEquals(token.address, contracts.flurryToken?.address)) {
            logo = FlurryLogo;
          } else if (isFarm) {
            logo = FarmTokenLogo;
          } else {
            logo = RhoTokenLogo;
          }
        }

        const accepted = await auth.currentProvider.accessor.request({
          method: 'wallet_watchAsset',
          params: {
            type: 'ERC20',
            options: {
              address: token.address,
              symbol: isFarm ? farmNameShortener(token.label) : token.label,
              decimals: token.decimals,
              image: `${window.location.protocol}//${window.location.host}${logo}`,
            },
          },
        });

        return accepted;
      }
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue(e);
    }
    return false;
  }
);
