import styled from 'styled-components/macro';

export const CheckMarkCircle = styled.div`
  width: 30px;
  height: 30px;
  position: relative;
  display: inline-block;
  vertical-align: top;
`;

export const Background = styled.div`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #e21f1f;
  position: absolute;
`;

export const CrossDraw = styled.div`
  position: absolute;

  &::before,
  &::after {
    position: absolute;
    left: 13px;
    top: 5px;
    content: ' ';
    height: 20px;
    width: 4px;
    background-color: #ffffff;
  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
`;
