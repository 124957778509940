import { useEffect } from 'react';
import { usePrevious } from 'react-use';
import { useAppDispatch, useAppSelector, useBlockNow } from 'core/store/hooks';
import { updateFarmsTokenBalances } from 'core/store/auth/thunks/updateFarmsTokenBalances';
import { updateFlurryTokenBalance } from 'core/store/balances/thunks/updateFlurryTokenBalance';
import { updateRewardsBalances } from 'core/store/auth/thunks/updateRewardsBalances';
import { updateDepositTokensBalances } from 'core/store/auth/thunks/updateDepositTokensBalances';
import { Contract } from 'core/types';
import { updateRhoBalances } from 'core/store/balances/thunks/updateRhoBalances';
import { updateStablecoinBalances } from 'core/store/balances/thunks/updateStablecoinBalances';
import { updateOtherTokenBalances } from 'core/store/balances/thunks/updateOtherTokenBalances';

/**
 * Updater for tracking token balances, performing fetches on each new
 * block
 */
const BalancesUpdater = (): null => {
  const dispatch = useAppDispatch();
  const { currentProvider, user, network } = useAppSelector(
    (state) => state.auth
  );
  const contracts = useAppSelector((state) => state.contracts);
  const farms = useAppSelector((state) => state.farms.list);
  const prevUser = usePrevious(user);
  const prevNetwork = usePrevious(network);

  const blockNow = useBlockNow();
  const prevBlock = usePrevious(blockNow);

  // TODO: Clear all contracts and tokens if the account/chain changes.
  useEffect(() => {
    // if (prevUser !== user || prevNetwork !== network) {
    //   dispatch(reset());
    // }
  }, [network, prevNetwork, prevUser, user]);

  useEffect(() => {
    if (!user?.address || !currentProvider?.web3provider) return;

    if (blockNow !== prevBlock) {
      const { stablecoins, flurryToken, lpStaking, rhoTokens, otherTokens } =
        contracts;

      if (stablecoins.length > 0) {
        // Update stablecoins balances
        dispatch(
          updateStablecoinBalances({
            stablecoins,
            userAddress: user.address,
            multicall: currentProvider.multicall,
          })
        );
      }

      if (rhoTokens.length > 0) {
        dispatch(
          updateRhoBalances({
            rhoTokens,
            userAddress: user.address,
            multicall: currentProvider.multicall,
          })
        );
      }

      if (otherTokens.length > 0) {
        dispatch(
          updateOtherTokenBalances({
            tokens: otherTokens,
            userAddress: user.address,
            multicall: currentProvider.multicall,
          })
        );
      }

      if (contracts.vaults.length > 0) {
        const depositTokens: Contract[] = contracts.vaults
          .map((v) => v.depositTokens)
          .reduce(function (prev, next) {
            return prev.concat(next);
          });

        if (depositTokens.length > 0) {
          // Update deposit tokens balances
          dispatch(updateDepositTokensBalances({ user, depositTokens }));
        }
      }

      // Update Flurry token balance
      if (flurryToken) {
        dispatch(updateFlurryTokenBalance({ user, flurryToken }));
      }

      if (lpStaking) {
        // Update farms token balances and farms staking balances
        dispatch(
          updateFarmsTokenBalances({
            user,
            farms,
            lpStaking,
            multicall: currentProvider.multicall,
          })
        );
      }

      // Update all rewards balances
      dispatch(
        updateRewardsBalances({
          user,
          farms,
          contracts,
          multicall: currentProvider.multicall,
        })
      );
    }
  }, [currentProvider, user, dispatch, blockNow, contracts, prevBlock, farms]);

  return null;
};

export default BalancesUpdater;
