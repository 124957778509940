import { createAsyncThunk } from '@reduxjs/toolkit';
import { BN } from 'utils';
import { Contract, User, UserBalance } from '../../../types';

export const updateFlurryTokenBalance = createAsyncThunk<
  UserBalance | undefined,
  { user: User; flurryToken: Contract | null }
>('balances/updateFlurryTokenBalance', async (payload) => {
  try {
    const flurryBalance = await payload.flurryToken?.contract.balanceOf(
      payload.user.address
    );

    return {
      currency: payload.flurryToken ? payload.flurryToken.address : 'Flurry',
      amount: BN(flurryBalance, payload.flurryToken?.decimals),
    };
  } catch (e) {
    console.error(e);
    return undefined;
  }
});
