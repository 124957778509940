import Skeleton from 'react-loading-skeleton';
import ReactTooltip from 'react-tooltip';
import * as S from './StatTable.styles';

type StatTableProps = {
  id: string;
  tooltip?: string;
  header: string | JSX.Element;
  values: Array<{
    disabled?: boolean;
    value: JSX.Element | string | undefined;
  }>;
};

const StatTable: React.FC<StatTableProps> = ({
  id,
  tooltip,
  header,
  values,
}) => {
  return (
    <>
      {tooltip && <ReactTooltip id={id} multiline={true} />}
      <S.StatTableWrapper>
        <S.StatTableHead>
          <tr>
            <S.StatTableHeadElement>
              <div
                data-for={tooltip ? id : undefined}
                data-tip={tooltip ?? undefined}
                data-iscapture={tooltip ? 'true' : 'false'}
              >
                {header}
                {tooltip && <S.InfoIcon></S.InfoIcon>}
              </div>
            </S.StatTableHeadElement>
          </tr>
        </S.StatTableHead>
        <tbody>
          {values.map((v, idx) => {
            const val = v.disabled ? '-' : v.value;
            return (
              <tr key={`${idx}-stattablevalue-${id}`}>
                <S.StatTableBodyElement>
                  {val ?? <Skeleton height="0.65rem" />}
                </S.StatTableBodyElement>
              </tr>
            );
          })}
        </tbody>
      </S.StatTableWrapper>
    </>
  );
};

export default StatTable;
