import React, { useMemo } from 'react';
import * as S from './FlurryInfos.styles';
import FlurryIcon from 'assets/images/logos/flurry_white_icon_only.png';
import Spacer from 'components/Spacer/Spacer';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { showClaimRewardsPopup } from 'core/store/ui/ui';
import { formatBNToString, getBridgeableNetworks, sendGAEvent } from 'utils';
import EtherscanLink from 'components/EtherscanLink/EtherscanLink';
import { watchAsset } from 'core/store/contracts/thunks/watchAsset';
import BigNumber from 'bignumber.js';
import { BridgeButton } from 'components';

const FlurryInfos: React.FC = () => {
  const network = useAppSelector((state) => state.auth.network);
  const isBridgeableNetwork = useMemo(() => {
    if (!network) return false;
    const bridgeableNetworks = getBridgeableNetworks();
    return bridgeableNetworks.indexOf(network) !== -1;
  }, [network]);

  const provider = useAppSelector((state) => state.auth.currentProvider);
  const rewardsLoading = useAppSelector(
    (state) => state.auth.balancesStates.rewards
  );
  const flurryTokenLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryToken
  );
  const user = useAppSelector((state) => state.auth.user);
  const balances = useAppSelector((state) => state.balances.balances);
  const flurryContract = useAppSelector((state) => state.contracts.flurryToken);
  const dispatch = useAppDispatch();

  const userFlurryBalance = useMemo(() => {
    if (user && flurryContract) {
      const balance = balances.find(
        (e) => e.currency === flurryContract.address
      );
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [balances, flurryContract, user]);

  const userRewardBalance = useMemo(() => {
    if (user) {
      const balance = user.balances.find((e) => e.currency === `AllRewards`);
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [user]);

  return (
    <>
      <S.Wrapper>
        <S.ClaimButton
          onClick={() => {
            if (user && !rewardsLoading) {
              sendGAEvent('Navbar', 'Show Claim Rewards Popup');
              dispatch(showClaimRewardsPopup(true));
            }
          }}
        >
          <S.FlurryIconWrapper>
            <S.FlurryIcon src={FlurryIcon} />
          </S.FlurryIconWrapper>
          <Spacer axis="horizontal" size={10} />
          <S.ClaimWrapper>
            <S.Label>EARNED</S.Label>
            <S.ClaimTotalAmountWrapper>
              {!user ? (
                '-.--'
              ) : !rewardsLoading ? (
                <div>{formatBNToString(userRewardBalance, 2, true, ',')}</div>
              ) : (
                <S.AmountSkeleton width={50} />
              )}{' '}
              FLURRY
            </S.ClaimTotalAmountWrapper>
          </S.ClaimWrapper>
        </S.ClaimButton>
        <S.Separator />
        <S.BalanceWrapper>
          <S.Label>You have</S.Label>
          <S.AmountWrapper>
            <S.TotalAmountWrapper>
              {!user ? (
                '-.--'
              ) : !flurryTokenLoading ? (
                <div>{formatBNToString(userFlurryBalance, 2, true, ',')}</div>
              ) : (
                <S.AmountSkeleton width={50} />
              )}
              {flurryContract && provider && provider.name === 'MetaMask' ? (
                <S.FlurryCcyLabel
                  title="Add FLURRY to Metamask"
                  isClickable={true}
                  onClick={() => dispatch(watchAsset(flurryContract.key))}
                >
                  FLURRY
                </S.FlurryCcyLabel>
              ) : (
                <S.FlurryCcyLabel>FLURRY</S.FlurryCcyLabel>
              )}
            </S.TotalAmountWrapper>
            {flurryContract && (
              <EtherscanLink contractAddress={flurryContract.address} />
            )}
            {isBridgeableNetwork && flurryContract && (
              <BridgeButton
                tokenAddress={flurryContract.address}
                tokenLabel={flurryContract.label}
                lightStyle
              />
            )}
          </S.AmountWrapper>
        </S.BalanceWrapper>
      </S.Wrapper>
    </>
  );
};

export default FlurryInfos;
