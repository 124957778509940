/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { MiddlewareAPI } from 'redux';
import { disconnect } from '../auth/thunks/disconnect';
import { redirectBasedOnNetwork } from 'utils';

const injectedProviderMiddleware = (provider: any) => {
  return (store: MiddlewareAPI<any, any>) => {
    if (provider.on) {
      provider.on('disconnect', () => {
        store.dispatch(disconnect());
      });
      provider.on('accountsChanged', async () => {
        window.location.reload();
      });
      provider.on('chainChanged', async (chain: number) => {
        redirectBasedOnNetwork(chain, undefined, true);
      });
    }

    return (next: (action: any) => void) => (action: any) => {
      return next(action);
    };
  };
};

export default injectedProviderMiddleware;
