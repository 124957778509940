import { SuccessIcon, ErrorIcon } from 'components';
import { TransactionState } from 'core/types';
import React from 'react';
import * as S from './StakingNotification.styles';

interface StakingNotificationProps {
  body: JSX.Element;
  state: TransactionState;
  onCloseBtnClicked?: () => void;
}

const StakingNotification: React.FC<StakingNotificationProps> = ({
  body,
  state,
  onCloseBtnClicked,
}) => {
  return (
    <S.Wrapper>
      {state !== 'pending' && onCloseBtnClicked && (
        <S.CloseBtn onClick={() => onCloseBtnClicked()} />
      )}
      <S.BodyWrapper>
        <S.StateWrapper>
          {state === 'pending' && <S.NotifLoadingCircle size={20} />}
          {state === 'error' && <ErrorIcon />}
          {state === 'success' && <SuccessIcon />}
        </S.StateWrapper>
        <S.TextWrapper>{body}</S.TextWrapper>
      </S.BodyWrapper>
    </S.Wrapper>
  );
};

export default StakingNotification;
