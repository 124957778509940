import { createAsyncThunk } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';
import { ContractsState } from 'core/store/contracts/contracts';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import { ethers } from 'ethers';
import { ciEquals, sendTransaction, waitReceipt } from 'utils';
import { FarmsState } from '../farms';
import { BasePayload } from 'core/types';
import { updateUserFarmApproval } from 'core/store/auth/thunks/updateUserFarmApproval';
import { AuthState } from 'core/store/auth/auth';

export const approveFarm = createAsyncThunk<void, BasePayload>(
  'farms/approveFarm',
  async (transPayload, thunkAPI) => {
    const { user } = (thunkAPI.getState() as { auth: AuthState }).auth;
    const currentState = (thunkAPI.getState() as { farms: FarmsState }).farms;
    const farms = currentState.list;
    const lpStaking = (thunkAPI.getState() as { contracts: ContractsState })
      .contracts.lpStaking;

    const transactionId = uuidv4();

    try {
      const farm = farms.find((e) =>
        ciEquals(e.address, transPayload.currency)
      );
      if (!user) {
        throw Error('No User found');
      }
      if (!farm) {
        throw Error('Farm could not be found');
      }
      if (!lpStaking) {
        throw Error('Could not find LP Staking contract');
      }

      thunkAPI.dispatch(
        addNewTransaction({
          id: transactionId,
          source: 'farm',
          type: 'approval',
          state: 'pending',
          payload: {
            amount: transPayload.amount,
            currency: farm.label,
          },
        })
      );

      const tx = await sendTransaction(
        farm.contract.approve,
        lpStaking.address,
        ethers.constants.MaxUint256
      );

      thunkAPI.dispatch(
        updateTransactionHash({
          id: transactionId,
          hash: tx.hash,
        })
      );

      await waitReceipt(tx);

      thunkAPI.dispatch(
        updateUserFarmApproval({
          farmAddress: farm.address,
          user,
          amount: transPayload.amount,
        })
      );

      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'success',
        })
      );
    } catch (error) {
      console.error(error.toString());
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'error',
        })
      );
    }
  }
);
