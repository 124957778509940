import axios from 'axios';
import BigNumber from 'bignumber.js';
import { ContractsState } from 'core/store/contracts/contracts';
import { useAppSelector } from 'core/store/hooks';
import {
  CurrencyOption,
  KyberswapRouteRes,
  KyberswapTokensRes,
  SwapRoute,
  SwapStep,
} from 'core/types';
import { translateNetworkNames } from 'packages/kyberswap/kyberswap';
import { useEffect, useMemo, useState } from 'react';
import { useInterval } from 'react-use';
import { BN, ciEquals, getContractByAddress, noExponents } from 'utils';
import DEXES from '../config/dexes.json';
import UnknownLogo from 'assets/images/unknown_logo.svg';

type TokenSwapData = {
  amountOut: BigNumber;
  exchangeRate: BigNumber;
  isDirectSwap: boolean;
  isUsingKyber: boolean;
  route: SwapRoute[];
};

type TokenSwapRes = {
  isLoading: boolean;
  error?: string;
  data: TokenSwapData;
};

const buildSwapRoute = (
  swaps: KyberswapRouteRes[][],
  tokens: KyberswapTokensRes,
  tokenList: CurrencyOption[]
): SwapRoute[] => {
  const routesGrouped: { [length: string]: KyberswapRouteRes[][] } = {};
  for (const route of swaps) {
    let key = '';
    for (const step of route) {
      key += '|' + step.tokenIn + '/' + step.tokenOut + '|';
    }
    if (!routesGrouped[key]) {
      routesGrouped[key] = [];
    }
    routesGrouped[key].push(route);
  }

  const finalRoutes: SwapRoute[] = [];
  for (const key of Object.keys(routesGrouped)) {
    const routes = routesGrouped[key];

    const currentRoute: SwapRoute = {
      swapTotalAmount: new BigNumber(0),
      steps: [],
    };
    let routeSwapAmount = new BigNumber(0);
    const finalSteps: SwapStep[] = [];

    for (const route of routes) {
      for (let i = 0; i < route.length; ++i) {
        const kyberStep = route[i];
        if (i === 0) {
          routeSwapAmount = routeSwapAmount.plus(
            new BigNumber(kyberStep.swapAmount)
          );
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const exchangeInfo = (DEXES as { [e: string]: any })[
          kyberStep.exchange
        ];

        if (typeof finalSteps[i] === 'undefined') {
          const tokenInfos = tokens[kyberStep.tokenOut];
          const tokenOption = tokenList.find((t) =>
            ciEquals(t.value, kyberStep.tokenOut)
          );
          finalSteps.push({
            token: {
              address: kyberStep.tokenOut,
              symbol: tokenInfos?.symbol ?? '',
              logo: tokenOption ? tokenOption.icon : UnknownLogo,
            },
            exchanges: [
              {
                pool: kyberStep.pool,
                name: exchangeInfo ? exchangeInfo.label : kyberStep.exchange,
                logo: exchangeInfo ? exchangeInfo.logo : UnknownLogo,
                swapAmount: new BigNumber(kyberStep.swapAmount),
              },
            ],
          });
        } else {
          const existingExchange = finalSteps[i].exchanges.find(
            (e) => e.pool === kyberStep.pool
          );
          if (existingExchange) {
            existingExchange.swapAmount.plus(
              new BigNumber(kyberStep.swapAmount)
            );
          } else {
            finalSteps[i].exchanges.push({
              pool: kyberStep.pool,
              name: exchangeInfo ? exchangeInfo.label : kyberStep.exchange,
              logo: exchangeInfo ? exchangeInfo.logo : UnknownLogo,
              swapAmount: new BigNumber(kyberStep.swapAmount),
            });
          }
        }
      }
    }
    currentRoute.steps = finalSteps;
    currentRoute.swapTotalAmount = routeSwapAmount;
    finalRoutes.push(currentRoute);
  }

  return finalRoutes;
};

const getAmountOutFromKyber = async (
  chainId: number,
  fromToken: {
    address: string;
    decimals: number;
  },
  toToken: {
    address: string;
    decimals: number;
  },
  amountIn: BigNumber,
  tokenList: CurrencyOption[],
  controller: AbortController
): Promise<{
  amountOut: BigNumber;
  amountOutUsd: number;
  exchangeRate: BigNumber;
  route: SwapRoute[];
}> => {
  const kyberswapApiBaseUrl = `https://aggregator-api.kyberswap.com/${translateNetworkNames(
    chainId
  )}/route`;

  let amountOut = new BigNumber(0);
  let amountOutUsd = 0;
  let exchangeRate = new BigNumber(0);
  let route: SwapRoute[] = [];

  try {
    const res = await axios.get(kyberswapApiBaseUrl, {
      signal: controller.signal,
      params: {
        tokenIn: fromToken.address,
        tokenOut: toToken.address,
        amountIn: noExponents(
          amountIn.times(new BigNumber(10).pow(fromToken.decimals)).toNumber()
        ),
      },
    });
    amountOut = BN(res.data.outputAmount, toToken.decimals);
    amountOutUsd = res.data.amountOutUsd;
    exchangeRate = BN(res.data.inputAmount, fromToken.decimals).div(amountOut);

    route = buildSwapRoute(
      res.data.swaps as KyberswapRouteRes[][],
      res.data.tokens as KyberswapTokensRes,
      tokenList
    );
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (e: any) {
    if (e.message !== 'canceled') console.error(e);
  }

  return { amountOut, amountOutUsd, exchangeRate, route };
};

const getBestSwap = async (payload: {
  chainId: number;
  fromToken: CurrencyOption;
  toToken: CurrencyOption;
  amountIn: BigNumber;
  contracts: ContractsState;
  controller: AbortController;
}): Promise<TokenSwapData> => {
  const { chainId, fromToken, toToken, amountIn, contracts, controller } =
    payload;

  let outAmount = {
    amountOut: new BigNumber(0),
    amountOutUsd: 0,
    exchangeRate: new BigNumber(0),
    route: [] as SwapRoute[],
  };

  // Here we get the kyberswap route direct to rho (eg DOGE to RhoUSDT)
  const directOutAmount = await getAmountOutFromKyber(
    chainId,
    {
      address: fromToken.value,
      decimals: fromToken.decimals ?? 18,
    },
    {
      address: toToken.value,
      decimals: toToken.decimals ?? 18,
    },
    amountIn,
    contracts.otherTokens,
    controller
  );

  // Here we get the kyberswap route indirect to rho (eg DOGE to USDT)
  const stablecoin = toToken.linkedToken
    ? await getContractByAddress(toToken.linkedToken, contracts)
    : undefined;
  const indirectOutAmount =
    stablecoin && stablecoin.address !== fromToken.value
      ? await getAmountOutFromKyber(
          chainId,
          {
            address: fromToken.value,
            decimals: fromToken.decimals ?? 18,
          },
          {
            address: stablecoin.address,
            decimals: stablecoin.decimals ?? 18,
          },
          amountIn,
          [...contracts.otherTokens],
          controller
        )
      : outAmount;

  let isDirectSwap = true;

  // We compare and return whichever route gives the most output
  if (directOutAmount.amountOutUsd >= indirectOutAmount.amountOutUsd) {
    outAmount = directOutAmount;
  } else {
    outAmount = indirectOutAmount;
    isDirectSwap = false;
  }

  return {
    ...outAmount,
    isDirectSwap,
    isUsingKyber: true,
  };
};

export const useTokenSwap = (payload: {
  fromToken: CurrencyOption;
  toToken: CurrencyOption;
  amountIn: string | number;
  updateIntervalMs: number;
}): TokenSwapRes => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<TokenSwapData>({
    amountOut: new BigNumber(0),
    exchangeRate: new BigNumber(0),
    isDirectSwap: false,
    isUsingKyber: false,
    route: [],
  });
  const { fromToken, toToken, amountIn, updateIntervalMs } = payload;
  const { network } = useAppSelector((state) => state.auth);
  const contracts = useAppSelector((state) => state.contracts);

  useInterval(async () => {
    const controller = new AbortController();
    if (!fromToken || !toToken || !amountIn || amountIn === '') return;
    const bnAmountIn = !new BigNumber(amountIn).isNaN()
      ? new BigNumber(amountIn)
      : new BigNumber(0);

    // TODO: Remove after kyber in polygon deployment
    if (network?.disableKyberswap) {
      if (fromToken.linkedToken === toToken.value && fromToken.exchangeRate) {
        setData({
          amountOut: bnAmountIn.times(fromToken.exchangeRate),
          exchangeRate: fromToken.exchangeRate,
          isDirectSwap: true,
          isUsingKyber: false,
          route: [],
        });
      }
      return;
    }

    const bestSwap = await getBestSwap({
      chainId: network?.chainId ?? 56,
      fromToken,
      toToken,
      amountIn: bnAmountIn,
      contracts,
      controller,
    });

    try {
      if (fromToken.linkedToken === toToken.value && fromToken.exchangeRate) {
        const protocolSwapAmount = bnAmountIn.times(fromToken.exchangeRate);

        if (protocolSwapAmount.gte(bestSwap.amountOut)) {
          setData({
            amountOut: bnAmountIn.times(fromToken.exchangeRate),
            exchangeRate: fromToken.exchangeRate,
            isDirectSwap: true,
            isUsingKyber: false,
            route: [],
          });
        } else {
          setData({
            ...bestSwap,
          });
        }
      } else {
        // Case is any token to any rho token (eg: USDT to RhoUSDC or DOGE to RhoUSDT)
        setData({
          ...bestSwap,
        });
      }
    } catch (e) {
      controller.abort();
      console.error(e);
    }
  }, updateIntervalMs);

  useEffect(() => {
    const ac = new AbortController();
    const getAmountOut = async (controller: AbortController) => {
      if (!fromToken || !toToken || !amountIn || amountIn === '') return;

      setIsLoading(true);
      const bnAmountIn = !new BigNumber(amountIn).isNaN()
        ? new BigNumber(amountIn)
        : new BigNumber(0);

      // For network without kyberswap
      if (network?.disableKyberswap) {
        if (fromToken.linkedToken === toToken.value && fromToken.exchangeRate) {
          setData({
            amountOut: bnAmountIn.times(fromToken.exchangeRate),
            exchangeRate: fromToken.exchangeRate,
            isDirectSwap: true,
            isUsingKyber: false,
            route: [],
          });
        }
        setIsLoading(false);
        return;
      }

      const bestSwap = await getBestSwap({
        chainId: network?.chainId ?? 56,
        fromToken,
        toToken,
        amountIn: bnAmountIn,
        contracts,
        controller,
      });

      try {
        if (fromToken.linkedToken === toToken.value && fromToken.exchangeRate) {
          const protocolSwapAmount = bnAmountIn.times(fromToken.exchangeRate);

          if (protocolSwapAmount.gte(bestSwap.amountOut)) {
            setData({
              amountOut: bnAmountIn.times(fromToken.exchangeRate),
              exchangeRate: fromToken.exchangeRate,
              isDirectSwap: true,
              isUsingKyber: false,
              route: [],
            });
          } else {
            setData({
              ...bestSwap,
            });
          }
        } else {
          // Case is any token to any rho token (eg: USDT to RhoUSDC or DOGE to RhoUSDT)
          setData({
            ...bestSwap,
          });
        }
      } catch (e) {
        console.error(e);
        setError('Could not retrieve exchange rate');
      }

      setIsLoading(false);
    };

    getAmountOut(ac);

    return () => ac.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amountIn, contracts?.stablecoins, fromToken, network?.chainId, toToken]);

  return useMemo(() => {
    return {
      data,
      isLoading,
      error,
    };
  }, [data, error, isLoading]);
};
