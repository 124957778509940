import styled from 'styled-components/macro';
import { IoMdClose } from 'react-icons/io';
import { down, up } from 'styled-breakpoints';

export const Wrapper = styled.div`
  ${down('lg')} {
    top: 0;
    left: 0;
    z-index: 9999;
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000050;
  }
`;
export const Modal = styled.div`
  z-index: 8000;
  position: absolute;
  top: 110px;
  right: 1rem;
  width: 370px;
  background-color: white;
  border-radius: 15px;
  border: 2px solid #e2e5ef;
  box-shadow: #4b4e5a40 0px 8px 24px;
  ${down('lg')} {
    position: relative;
    margin: 0 auto;
    right: 0;
  }
  ${down('xs')} {
    width: calc(100% - 2rem);
  }
`;
export const ModalCloseBtn = styled(IoMdClose)`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  color: #000000a4;
  background-color: #1c009708;
  padding: 0.2rem;
  border-radius: 50px;
  transition: background-color 0.2s linear;
  &:hover {
    background-color: #1c009718;
  }
`;

export const DesktopInfos = styled.div`
  display: none;
  ${up('xl')} {
    display: block;
  }
`;
