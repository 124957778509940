import { createAsyncThunk } from '@reduxjs/toolkit';

import { getContractByName } from 'utils/contracts';
import { sendTransactionWithReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';

export const claimReward = createAsyncThunk<void, string>(
  'contracts/claimReward',
  async (contractName, thunkAPI) => {
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };
    try {
      if (!contracts) {
        throw new Error('No contracts');
      }

      const contract = getContractByName(contractName, contracts).contract;

      await sendTransactionWithReceipt(contract.claimReward);
    } catch (error) {
      thunkAPI.rejectWithValue(error);
    }
  }
);
