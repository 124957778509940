import React, { useState, useCallback, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { IoMdClose } from 'react-icons/io';
import {
  VscChevronLeft as ChevronLeft,
  VscChevronRight as ChevronRight,
} from 'react-icons/vsc';
import { useNotificationMessages } from 'core/store/content/hooks';
import { down } from 'styled-breakpoints';

const Wrapper = styled.div<{ type: 'Info' | 'Warning' | 'Error' }>`
  z-index: 5;
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  padding: 0.6rem 1.2rem 1rem 1.2rem;
  font-size: 1rem;
  background-color: ${(props) => {
    switch (props.type) {
      case 'Error':
        return '#dc3545';
      case 'Warning':
        return '#f0b70e';
      case 'Info':
        return '#007bff';
      default:
        return '#007bff';
    }
  }};
  color: white;
  p {
    padding: 0;
    margin: 0;
    font-size: 0.65rem;
  }

  ${down('xs')} {
    bottom: 0;
    width: calc(100% - 2.4rem);
    height: 38px;
    right: 0;
    left: 0;
  }
`;

const CloseBtn = styled(IoMdClose)`
  cursor: pointer;
  opacity: 0.7;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  font-size: 0.8rem;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 1;
  }
`;

const NotificationText = styled.div`
  user-select: none;
`;
const PaginationArrows = styled.div`
  display: flex;
  position: absolute;
  bottom: 0.3rem;
  right: 0.3rem;
  font-size: 0.8rem;
`;

const chevronStyle = css`
  cursor: pointer;
  transition: opacity 0.3s linear;
  opacity: 0.7;
  &:hover {
    opacity: 1;
  }
`;

const ArrowRight = styled(ChevronRight)`
  ${chevronStyle}
`;
const ArrowLeft = styled(ChevronLeft)`
  ${chevronStyle}
`;

const NotificationPopup: React.FC = () => {
  const { data, isLoading, error } = useNotificationMessages();
  const [currentIdx, setCurrentIdx] = useState(0);
  const [showPopup, setShowPopup] = useState(true);

  const incrPage = useCallback(() => {
    if (currentIdx === data.length - 1) {
      setCurrentIdx(0);
    } else {
      setCurrentIdx(currentIdx + 1);
    }
  }, [currentIdx, data.length]);

  const decrPage = useCallback(() => {
    if (currentIdx === 0) {
      setCurrentIdx(data.length - 1);
    } else {
      setCurrentIdx(currentIdx - 1);
    }
  }, [currentIdx, data.length]);

  const currentNotification = useMemo(() => {
    if (currentIdx !== 0 && currentIdx > data.length - 1) {
      setCurrentIdx(currentIdx - 1);
      return data[currentIdx - 1];
    }
    return data[currentIdx];
  }, [currentIdx, data]);

  if (isLoading || error || data.length === 0 || !showPopup) {
    return <></>;
  }

  return (
    <Wrapper type={currentNotification.Type}>
      <CloseBtn onClick={() => setShowPopup(false)} />
      <NotificationText
        dangerouslySetInnerHTML={{ __html: currentNotification.Message }}
      />
      {data.length > 1 && (
        <PaginationArrows>
          <ArrowLeft onClick={() => decrPage()} />
          <ArrowRight onClick={() => incrPage()} />
        </PaginationArrows>
      )}
    </Wrapper>
  );
};

export default NotificationPopup;
