import { createAsyncThunk } from '@reduxjs/toolkit';
import { utils } from 'ethers';
import { v4 as uuidv4 } from 'uuid';

import { sendTransaction, waitReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';
import { noExponents } from 'utils';
import BigNumber from 'bignumber.js';
import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';

export const stakeFlurry = createAsyncThunk<void, BigNumber>(
  'contracts/stakeFlurry',
  async (amount, thunkAPI) => {
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };

    const transactionId = uuidv4();
    thunkAPI.dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'snowball',
        type: 'stake',
        state: 'pending',
        payload: {
          amount,
          currency: 'FLURRY',
        },
      })
    );

    try {
      const flurry = contracts.flurryToken;
      const stakingReward = contracts.flurryStaking;

      if (!stakingReward) {
        throw Error('No Flurry Staking contract found');
      }
      if (!flurry) {
        throw Error('No Flurry contract found');
      }

      const formatedAmount = utils.parseUnits(
        noExponents(amount),
        flurry.decimals
      );

      const tx = await sendTransaction(
        stakingReward.contract.stake,
        formatedAmount
      );
      thunkAPI.dispatch(
        updateTransactionHash({
          id: transactionId,
          hash: tx.hash,
        })
      );

      await waitReceipt(tx);
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'success',
        })
      );
    } catch (error) {
      console.error(error.toString());
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'error',
        })
      );
    }
  }
);
