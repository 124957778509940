import * as S from './GeneralStatistics.styles';
import { useRhoTokensTotalSupply } from 'core/store/subgraph/hooks';
import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { ChartData, DateRange } from 'core/types';
import {
  ResponsiveContainer,
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import { getFormatFromRange, KFormaterBN } from 'utils';
import BigNumber from 'bignumber.js';
import DateRangeFilter from './DateRangeFilter';
import { Spacer } from 'components';
import styled from 'styled-components';

const GraphSkeleton = styled.div`
  height: 100%;
  span {
    height: 100%;
  }
`;

const TotalSupplyChart: React.FC = () => {
  const [dateRange, setDateRange] = useState<DateRange>('7day');
  const { data, isLoading } = useRhoTokensTotalSupply(dateRange);

  const chartProps: ChartData | undefined = useMemo(() => {
    const res: ChartData = {
      data: [],
      metrics: [],
    };

    for (const ts of Object.keys(data).map(Number)) {
      const dataValues = data[ts];

      const tokenNames = Object.keys(dataValues);
      if (tokenNames.length > 0) {
        res.data.push({
          time: ts,
          ...dataValues,
        });

        if (res.metrics.length === 0) {
          for (const token of tokenNames) {
            let color = `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            if (token === 'rhoBUSD') {
              color = '#f0b90b';
            } else if (token === 'rhoUSDT') {
              color = '#2ea07b';
            } else if (token === 'rhoUSDC') {
              color = '#2775ca';
            }

            res.metrics.push({
              key: token,
              color,
            });
          }
        }
      }
    }
    return res;
  }, [data]);

  return (
    <>
      <S.GraphTitle>Rho Tokens Total Supplies</S.GraphTitle>
      <DateRangeFilter
        initialRange={dateRange}
        onDateRangeChanged={(v) => setDateRange(v)}
      />
      <Spacer axis="vertical" size={10} />
      {!isLoading && chartProps ? (
        <>
          <ResponsiveContainer height="100%" width="100%">
            <BarChart data={chartProps.data}>
              {chartProps.metrics.map(({ key, color }) => (
                <Bar
                  key={key}
                  dataKey={key}
                  fill={color}
                  stackId="totalSupply"
                />
              ))}
              <XAxis
                dataKey="time"
                tick={{ fontSize: '0.6rem' }}
                axisLine={false}
                tickMargin={8}
                tickSize={8}
                tickLine={{ stroke: '#0000003e', strokeWidth: '1px' }}
                tickFormatter={(v) => {
                  if (isNaN(v)) {
                    return '';
                  }
                  return format(
                    (v as number) * 1000,
                    getFormatFromRange(dateRange)
                  );
                }}
              />
              <YAxis
                orientation="right"
                tick={{ fontSize: '0.6rem' }}
                tickFormatter={(v) =>
                  `${KFormaterBN(new BigNumber(v), 2)}` ?? ''
                }
                axisLine={false}
                tickSize={8}
                tickLine={{ stroke: '#0000003e', strokeWidth: '1px' }}
                domain={[0, 'dataMax + 20000']}
              />
              <Tooltip
                cursor={{ fill: '#b9b9b945' }}
                labelFormatter={(timestamp) => {
                  if (isNaN(timestamp)) {
                    return '';
                  }
                  return format(
                    (timestamp as number) * 1000,
                    'yyyy-MM-dd HH:mm'
                  );
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatter={(v: any) =>
                  `${KFormaterBN(new BigNumber(v), 2)}` ?? ''
                }
                itemSorter={() => -1}
                separator=": "
                contentStyle={{
                  fontSize: '14px',
                  padding: '8px',
                  background: 'rgba(250, 250, 250, 0.8)',
                  textAlign: 'right',
                  border: 'none',
                  borderRadius: '4px',
                  color: '#000',
                }}
                wrapperStyle={{
                  top: 0,
                  left: 0,
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <GraphSkeleton>
          <Skeleton height="100%" />
        </GraphSkeleton>
      )}
    </>
  );
};

export default TotalSupplyChart;
