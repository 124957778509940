import { FaCopy, FaUser, FaCheckCircle } from 'react-icons/fa';
import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const UserInfos = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 1rem);
  padding: 0.5rem;
  border-radius: 1rem;
  background-color: #1c009708;
`;

export const UserIcon = styled(FaUser)`
  align-self: flex-start;
  font-size: 1rem;
  border-radius: 50px;
  padding: 0.5rem;
  background-color: #1c009708;
  margin-right: 0.5rem;
`;

export const AddressLabel = styled.div`
  font-size: 0.6rem;
  color: #7a7a7a;
`;

export const CopyAddress = styled.div`
  display: flex;
  flex-direction: column;
  color: black;
  ${down('lg')} {
    color: black;
  }
`;
export const AddressValue = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 0.2rem 0;
  font-size: 0.7rem;
  ${down('lg')} {
    font-size: 0.8rem;
  }
`;

export const Address = styled.div`
  font-weight: bold;
  color: black;
`;

export const CopyBtn = styled.button`
  position: relative;
  margin-left: auto;
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: black;
  background-color: white;
  border-radius: 2rem;
  transition: border 0.2s linear;
  box-sizing: border-box;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid #efedf7;
  }
`;

export const CopyIcon = styled(FaCopy)`
  width: 14px;
  height: 14px;
  color: black;
`;

export const CheckMarkIcon = styled(FaCheckCircle)`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 10px;
  height: 10px;
  color: green;
`;
