import { createAsyncThunk } from '@reduxjs/toolkit';
import { buildBridgeContract, buildRegistryContract } from 'utils/config';
import {
  InitContractPayload,
  Contract,
  Transaction,
  BridgePayload,
} from 'core/types';
import { getNetworkInfo, getStorageValue, noExponents } from 'utils';
import { EventFilter, utils } from 'ethers';
import { updateTransactionState } from 'core/store/transactions/transactions';

export const initBridge = createAsyncThunk<
  { bridge: Contract | null; registry: Contract | null },
  InitContractPayload
>('contracts/initBridge', async (payload, thunkAPI) => {
  let bridge: Contract | null = null;
  let registry: Contract | null = null;
  try {
    const { provider, contractsConfig } = payload;

    const currentNetwork = await provider.getNetwork();
    const network = getNetworkInfo(currentNetwork);

    bridge = await buildBridgeContract(contractsConfig, provider);
    registry = await buildRegistryContract(contractsConfig, provider);

    // Checking for existing bridge transaction and checking their status
    const transactions = getStorageValue<Transaction[]>('flurry-transactions');

    if (transactions && bridge) {
      for (const t of transactions) {
        if (
          t.source === 'bridge' &&
          t.state !== 'success' &&
          t.state !== 'error' &&
          t.payload &&
          (t.payload as BridgePayload).dstChain === network.chainId
        ) {
          thunkAPI.dispatch(
            updateTransactionState({
              id: t.id,
              state: 'pending',
            })
          );

          const {
            logIndex,
            tokenDecimals,
            tokenAddress,
            dstTokenAddress,
            srcChain,
            receiverAddress,
            tokenAmount,
          } = t.payload as BridgePayload;

          const formatedAmount = utils.parseUnits(
            noExponents(tokenAmount),
            tokenDecimals
          );

          const transferFilter: EventFilter =
            bridge.contract.filters.TransferAccepted(
              srcChain,
              t.hash,
              receiverAddress
            );

          let isProcessed = false;
          if (logIndex !== undefined) {
            // check if transfer was processed
            isProcessed = (await bridge.contract.isTransferProcessed(
              srcChain,
              tokenAddress,
              dstTokenAddress,
              receiverAddress,
              formatedAmount,
              t.hash,
              logIndex
            )) as boolean;
          } else {
            // No log index so we need to look amongst a certain range of block if there is a TransferAccepted
            const blockRange = Math.round(network.avgBlockTime * 3600);
            const transferEvent = await bridge.contract.queryFilter(
              transferFilter,
              -(blockRange < 5000 ? blockRange : 4500)
            );
            isProcessed =
              transferEvent && transferEvent.length > 0 ? true : false;
          }

          if (isProcessed) {
            thunkAPI.dispatch(
              updateTransactionState({
                id: t.id,
                state: 'success',
              })
            );
          } else {
            // listen to bridge transfer status
            bridge.contract.on(transferFilter, () => {
              thunkAPI.dispatch(
                updateTransactionState({
                  id: t.id,
                  state: 'success',
                })
              );
            });
          }
        }
      }
    }
  } catch (e) {
    thunkAPI.rejectWithValue('Could not setup Bridge and Registery contracts');
    console.error(e);
  } finally {
    return { bridge, registry };
  }
});
