import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

interface TabProps {
  active: boolean;
}

export const Wrapper = styled.div`
  padding-top: 0.5rem;
`;

export const TabsWrapper = styled.div`
  padding: 0.2rem 1rem;
`;

export const Tab = styled.button<TabProps>`
  border: none;
  border-bottom: ${(props) => (props.active ? '1px solid black' : 'none')};
  padding: 0.1rem 0.2rem;
  opacity: ${(props) => (props.active ? '1' : '0.7')};
  background: none;
  cursor: pointer;
  user-select: none;
  &:not(:first-child) {
    margin-left: 2rem;
  }
`;

export const ContentWrapper = styled.div`
  padding: 1.5rem 2rem 0.5rem 2rem;
  ${down('xs')} {
    padding: 1.5rem 0;
  }
`;

export const TabContent = styled.div``;
