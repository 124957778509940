import { BigNumber } from 'bignumber.js';
import { Spacer, StatTable } from 'components';
import React, { useState } from 'react';
import { formatAPR, KFormaterBN, sendGAEvent } from 'utils';
import * as S from './DetailsDrawer.styled';

export type TokenDetails = {
  key: string;
  logo: string;
  label: string;
  balance?: BigNumber;
  address: string;
  disabled?: boolean;
  loading?: boolean;
  supply?: BigNumber;
  rewardApr?: number;
};

type DetailsDrawerProps = {
  tokens: TokenDetails[];
};

const DetailsDrawer: React.FC<DetailsDrawerProps> = ({ tokens }) => {
  const [isOpened, setIsOpened] = useState(true);

  if (tokens.length <= 0) {
    return <div></div>;
  }

  return (
    <S.Wrapper>
      <S.Drawer>
        <S.DrawerContent opened={isOpened}>
          <Spacer axis="horizontal" size={20} />
          <StatTable
            id="rewardAPR"
            tooltip="Interest rate of FLURRY<br/>earned by holding rho tokens"
            header="FLURRY APY"
            values={tokens.map((t) => {
              return {
                value:
                  t.disabled || t.rewardApr === undefined
                    ? undefined
                    : `${formatAPR(t.rewardApr, 2)}%`,
                disabled: t.disabled,
              };
            })}
          />
          <Spacer axis="horizontal" size={10} />
          <StatTable
            id="tvl"
            tooltip="Тotal Value Locked (TVL) represents<br/>the number of rho tokens that are<br/>currently being staked by all users"
            header="TVL"
            values={tokens.map((t) => {
              return {
                value:
                  t.disabled || !t.supply
                    ? undefined
                    : KFormaterBN(t.supply, 1),
                disabled: t.disabled,
              };
            })}
          />
        </S.DrawerContent>
        <S.DrawerHandle
          opened={isOpened}
          onClick={() => {
            if (!isOpened) {
              sendGAEvent('Earn', 'Show RhoToken Details');
            }
            setIsOpened(!isOpened);
          }}
        >
          <S.VerticalLine opacity={0.7}>
            <S.DrawerLabel>
              {isOpened ? 'Hide details' : 'See more details'}
            </S.DrawerLabel>
          </S.VerticalLine>
          <Spacer axis="horizontal" size={4} />
          <S.VerticalLine>
            <S.ChevronBg />
            <S.ChevronRight />
          </S.VerticalLine>
        </S.DrawerHandle>
      </S.Drawer>
    </S.Wrapper>
  );
};

export default DetailsDrawer;
