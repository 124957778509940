export const getRandomBrightColor = (): string => {
  return 'hsla(' + ~~(360 * Math.random()) + ',' + '75%,' + '60%,1)';
};

export const getChartColors = (): string[] => {
  return [
    '#a6cee3',
    '#1f78b4',
    '#b2df8a',
    '#33a02c',
    '#fb9a99',
    '#e31a1c',
    '#fdbf6f',
    '#ff7f00',
    '#cab2d6',
    '#6a3d9a',
    '#ffff99',
    '#b15928',
  ];
};
