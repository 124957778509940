import { CurrencyOption } from 'core/types';
import React from 'react';
import * as S from './PopupButton.styles';

export interface PopupButtonProps {
  currency: CurrencyOption;
  onClick: () => void;
}

export const PopupButton: React.FC<PopupButtonProps> = ({
  currency,
  onClick,
}: PopupButtonProps) => {
  if (!currency) {
    return <></>;
  }
  const { symbol, label, icon } = currency;
  return (
    <S.PopupBtnWrapper>
      <S.DropdownButton onClick={onClick}>
        <S.IconWrapper>
          <S.TokenLogo src={icon} />
        </S.IconWrapper>

        <S.SelectedTokenLabel>{symbol ?? label}</S.SelectedTokenLabel>
        <S.Chevron />
      </S.DropdownButton>
    </S.PopupBtnWrapper>
  );
};
