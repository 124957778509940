import React from 'react';
import { TabLayout } from 'components';
import ConvertForm from './ConvertForm';
import RedeemForm from './RedeemForm';
import * as S from './EarnForm.styles';

const EarnForm: React.FC = () => {
  return (
    <S.Wrapper>
      <TabLayout
        tabs={[
          {
            tabLabel: 'Convert',
            tabContent: <ConvertForm />,
          },
          {
            tabLabel: 'Redeem',
            tabContent: <RedeemForm />,
          },
        ]}
      />
    </S.Wrapper>
  );
};
export default EarnForm;
