import { createAsyncThunk } from '@reduxjs/toolkit';
import { Approval, User } from 'core/types';
import BigNumber from 'bignumber.js';
import { BN, ciEquals } from 'utils';
import { FarmsState } from 'core/store/farms/farms';
import { ContractsState } from 'core/store/contracts/contracts';

export const updateUserFarmApproval = createAsyncThunk<
  Approval[],
  { farmAddress: string; amount: BigNumber; user: User | undefined }
>('auth/updateUserFarmsApproval', async (payload, thunkAPI) => {
  const approvals: Approval[] = [];
  const { user, amount } = payload;
  const farms = (thunkAPI.getState() as { farms: FarmsState }).farms.list;
  const contracts = (thunkAPI.getState() as { contracts: ContractsState })
    .contracts;

  try {
    if (!user) {
      throw Error('No User found');
    }

    const farm = farms.find((e) => ciEquals(e.address, payload.farmAddress));
    const lpStaking = contracts.lpStaking;

    if (!farm) {
      throw Error('Farm could not be found');
    }
    if (!lpStaking) {
      throw Error('Could not find LP Staking contract');
    }

    const allowance = BN(
      await farm.contract.allowance(user.address, lpStaking.address),
      farm.decimals
    );

    approvals.push({
      contractAddress: farm.address,
      isApproved: allowance.gte(amount),
    });
  } catch (e) {
    console.error(e);
  }

  return approvals;
});
