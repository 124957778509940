/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';
import { CurrencyOption } from 'core/types';
import * as S from './IntegratedPriceInput.styles';
import { ciEquals } from 'utils';

import { SelectTokenPopup } from '../SelectTokenPopup';
import { PopupButton } from './PopupButton';

export interface IntegratedPriceInputProps {
  currencies?: CurrencyOption[];
  currentCurrencyValue?: string;
  onAmountChange?: (amount: string) => void;
  onCurrencyChange?: (ccy: CurrencyOption) => void;
  onMaxBtnClicked?: () => void;
  inputValue?: string;
  maxDecimalPlace?: number;
  disableInput?: boolean;
}

const IntegratedPriceInput: React.FC<IntegratedPriceInputProps> = ({
  currencies,
  currentCurrencyValue,
  onMaxBtnClicked,
  onAmountChange,
  onCurrencyChange,
  inputValue,
  maxDecimalPlace,
  disableInput,
}) => {
  const [selectedCurrency, setSelectedCurrency] = useState(
    currencies?.find((e) => ciEquals(e.value, currentCurrencyValue))
  );
  const [openSTPopup, setOpenSTPopup] = useState(false);

  useEffect(() => {
    if (currencies && currentCurrencyValue) {
      const newCcy = currencies.find((e) =>
        ciEquals(e.value, currentCurrencyValue)
      );
      if (newCcy) {
        setSelectedCurrency(newCcy);
      }
    }
  }, [currencies, currentCurrencyValue]);

  const inputSize = useMemo(():
    | 'normal'
    | 'noMax'
    | 'noCurrencies'
    | 'full' => {
    if (!onMaxBtnClicked && !currencies) {
      return 'full';
    } else if (!onMaxBtnClicked) {
      return 'noMax';
    } else if (!currencies) {
      return 'noCurrencies';
    }
    return 'normal';
  }, [currencies, onMaxBtnClicked]);

  const checkDecimalPlace = (baseValue: string) => {
    if (!maxDecimalPlace) return baseValue;

    if (baseValue.indexOf('.') >= 0) {
      return `${baseValue.substr(0, baseValue.indexOf('.'))}.${baseValue.substr(
        baseValue.indexOf('.') + 1,
        maxDecimalPlace
      )}`;
    }
    return baseValue;
  };

  return (
    <S.Wrapper disabled={disableInput}>
      <S.Input
        min="0"
        type="number"
        placeholder={disableInput ? '0' : 'Enter Amount'}
        step="any"
        value={inputValue}
        onChange={(e) => {
          if (onAmountChange) {
            onAmountChange(checkDecimalPlace(e.target.value));
          }
        }}
        inputWidth={inputSize}
        disabled={disableInput}
      />
      {onMaxBtnClicked && (
        <S.MaxBtn
          type="button"
          onClick={() => onMaxBtnClicked()}
          disabled={disableInput}
        >
          MAX
        </S.MaxBtn>
      )}
      {currencies && (
        <PopupButton
          currency={selectedCurrency ?? currencies[0]}
          onClick={() => setOpenSTPopup(true)}
        />
      )}
      {openSTPopup && currencies && (
        <SelectTokenPopup
          isOpen
          tokens={currencies ?? []}
          currentToken={selectedCurrency?.value ?? currencies[0].value}
          mostUsedTokens={
            currencies?.filter((cur) => cur.type === 'stableCoin') ?? []
          }
          onSelect={(v) => {
            setOpenSTPopup(false);
            // TODO integrate onCurrencyChange
            if (onCurrencyChange) {
              onCurrencyChange(v);
            }
          }}
          onClose={() => setOpenSTPopup(false)}
        />
      )}
    </S.Wrapper>
  );
};

export default IntegratedPriceInput;
