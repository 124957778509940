import { down, up } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  ${up('xxl')} {
    padding-top: 1.7rem;
  }
`;

export const BalanceText = styled.p`
  margin: 0;
  padding: 0.4rem 0 0 0.5rem;
  font-size: 0.7rem;
`;

export const Stats = styled.div`
  margin: 0 auto;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  row-gap: 10px;
  column-gap: 10px;
  align-self: center;
  width: fit-content;
  justify-self: center;
  padding: 0 2rem;
  ${down('sm')} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-items: center;
    grid-template-columns: none;
    row-gap: 0;
  }
`;

export const StatWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  border: 1px solid #00000008;
  padding: 0.5rem 0.8rem;
  border-radius: 5px;
  ${down('sm')} {
    padding: 0.2rem 0.7rem;
    justify-self: center;
    margin: 0.2rem auto;
  }
  ${down('xs')} {
    padding: 0.2rem 0.7rem;
    width: 100%;
  }
`;

export const StakingImage = styled.img`
  height: 40px;
  width: auto;
  ${down('sm')} {
    height: 30px;
  }
  ${down('xs')} {
    height: 23px;
  }
`;
export const EarnedImage = styled.img`
  height: 50px;
  width: auto;
  ${down('sm')} {
    height: 38px;
  }
  ${down('xs')} {
    height: 30px;
  }
`;
export const InterestRateImage = styled.img`
  height: 50px;
  width: auto;
  ${down('sm')} {
    height: 38px;
  }
  ${down('xs')} {
    height: 30px;
  }
`;
export const StatText = styled.div`
  padding: 0.5rem 0.8rem;
  width: calc(100% - 1.6rem);
  border-radius: 5px;
  ${down('sm')} {
    padding: 0.5rem 0.5rem;
    width: calc(100% - 1rem);
  }
`;

export const StatLabel = styled.div`
  font-weight: bold;
  opacity: 0.5;
  font-size: 0.7rem;
  text-transform: uppercase;
  white-space: nowrap;
  ${down('sm')} {
    font-size: 0.65rem;
  }
  ${down('xs')} {
    font-size: 0.6rem;
  }
`;

export const StatAmount = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  white-space: nowrap;
  ${down('sm')} {
    font-size: 0.9rem;
  }
  ${down('xs')} {
    font-size: 0.8rem;
  }
`;
