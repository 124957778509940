import { AddToWalletButton, LoadingCircle } from 'components';
import { FaChevronUp } from 'react-icons/fa';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';
import { FarmColumnLayout } from '../Farms.styles';

export const Wrapper = styled.div<{ isOpened?: boolean }>`
  cursor: pointer;
  display: grid;
  align-items: center;
  ${FarmColumnLayout}
  padding: 1rem 1.5rem;
  border-top: 1px solid #0000001a;
  user-select: none;
  background-color: ${(props) => (props.isOpened ? '#f8feff' : 'inherit')};
`;

export const FarmTitle = styled.h2`
  a {
    margin-left: 5px;
    color: black;
  }
  margin: 0;
  font-size: 1rem;
  ${down('md')} {
    font-size: 0.8rem;
  }
  ${down('sm')} {
    white-space: nowrap;
  }
`;

export const ComingSoonFarmRow = styled.div`
  text-align: center;
  color: #5a5a5a;
  font-size: 0.8rem;
  grid-column: 3 / 7;
  ${down('sm')} {
    grid-column: 3 / 5;
  }
`;

export const Value = styled.div<{
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
}>`
  font-size: 0.95rem;
  ${down('md')} {
    font-size: 0.9rem;
  }
  ${down('sm')} {
    display: ${(props) => (props.hideOnTablet ? 'none' : 'flex')};
    font-size: 0.8rem;
  }
  ${down('xs')} {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  }
`;

export const StakedTokenValue = styled(Value)``;
export const StakedUsdValue = styled(Value)`
  font-weight: bold;
  color: #00000071;
  font-size: 0.7rem;
`;

export const Chevron = styled(FaChevronUp)<{ $isup: boolean }>`
  margin-left: auto;
  transform: ${(props) => (!props.$isup ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
`;

export const BodyContent = styled.div<{ show?: boolean }>`
  width: 100%;
  max-height: ${(props) => (props.show ? '700px' : '0')};
  transition: max-height 0.2s ease-in-out;
  overflow: hidden;
  background-color: ${(props) => (props.show ? '#f8feff' : 'inherit')};
`;

export const BodyWrapper = styled.div`
  display: flex;
  position: relative;
  padding: 0 0 1rem 0;
  ${down('sm')} {
    flex-direction: column;
  }
`;

export const Forms = styled.div`
  width: 100%;
`;

export const FormWrapper = styled.div`
  display: flex;
  grid-template-columns: 1fr 0.2fr;
  padding: 0 1rem 0 1rem;
  align-items: center;
`;

// ----------

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  input {
    font-size: 1rem !important;
  }
`;

export const InputLabel = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 0.7rem;
  margin-bottom: 0.5rem;
  margin-left: 0.2rem;
`;

export const UsdValue = styled.div`
  font-size: 0.7rem;
  font-style: italic;
  padding-right: 0.2rem;
`;

export const BalanceLabel = styled.div`
  font-size: 0.8rem;
  margin-left: 1.2rem;
`;

export const ErrorMsg = styled.div`
  font-size: 0.7rem;
  color: red;
  margin-top: 0.2rem;
  margin-bottom: 0.4rem;
  margin-left: 1.2rem;
`;

export const StartFarmButton = styled.button`
  align-self: flex-end;
  margin: 0 auto;
  height: 50px;
  width: 30%;
  padding: 0.75rem 1rem;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #77d37f 0%,
    #95dd9d 51%,
    #77d37f 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #77a0d349;
  border: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: #fff; text-decoration: none; }'
      : ''}
  grid-column: 5 / 6;

  ${down('sm')} {
    font-size: 0.8rem;
    box-shadow: none;
  }
`;

export const ApprovalButton = styled(StartFarmButton)`
  background-image: linear-gradient(
    to right,
    #2172e5 0%,
    #3884f0 51%,
    #2172e5 100%
  );
`;

export const ApproveLoadingCircle = styled(LoadingCircle)`
  align-self: center;
  margin: 0 auto;
`;

export const StopFarmButton = styled(StartFarmButton)`
  background-image: linear-gradient(
    to right,
    #d37777 0%,
    #dd9595 51%,
    #d37777 100%
  );
`;

export const BalanceWrapper = styled.div`
  display: flex;
  padding-left: 1rem;
  align-items: flex-end;
  ${down('xs')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const GetOnUniswap = styled.a`
  font-size: 0.7rem;
  color: blue;
  display: flex;
  align-items: center;
  ${down('sm')} {
    margin-top: 0.2rem;
    padding-left: 0.5rem;
  }
`;

export const ExternalLink = styled(HiOutlineExternalLink)`
  margin-left: 3px;
`;

export const MobileStats = styled.div`
  display: none;
  width: calc(100% - 3rem);
  padding: 0.5rem 1.5rem 1rem 1.5rem;
  ${down('sm')} {
    display: flex;
    flex-direction: column;
  }
  ${down('xs')} {
    justify-content: space-between;
    padding: 0.5rem 1rem 1rem 1rem;
  }
`;
export const Stat = styled.div<{
  showOnMobile?: boolean;
  showOnTablet?: boolean;
}>`
  display: none;
  grid-template-columns: 20% 80%;
  align-items: center;
  background-color: #00000006;
  border-radius: 5px;
  margin: 0.2rem 0;
  padding: 0.1rem 0.7rem;
  ${down('sm')} {
    padding-right: 2rem;
    display: ${(props) => (props.showOnTablet ? 'grid' : 'none')};
  }
  ${down('xs')} {
    padding-right: 0;
    display: ${(props) => (props.showOnMobile ? 'grid' : 'none')};
  }
`;
export const StatTitle = styled.h6`
  margin: 0;
  padding: 0;
  color: #6d6d6d;
`;
export const StatValue = styled.p`
  margin: 0;
  padding: 0.2rem 0.2rem;
  font-size: 0.8rem;
`;

export const StatSmallValue = styled(StatValue)`
  font-size: 0.65rem;
  font-weight: bold;
  color: #00000077;
`;

export const LoadingFormSkeleton = styled.div`
  width: 100%;
`;

export const ButtonSkeletonWrapper = styled.div`
  padding-top: 1.1rem;
  width: 30%;
`;
export const BalanceSkeletonWrapper = styled.div`
  padding-left: 1rem;
`;

export const LoadingSkeletonWrapper = styled.div<{
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
}>`
  ${down('sm')} {
    display: ${(props) => (props.hideOnTablet ? 'none' : 'block')};
  }
  ${down('xs')} {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'block')};
  }
`;

export const AddFarmToWallet = styled(AddToWalletButton)`
  margin: 0.5rem auto 0 auto;
`;
