import { createAsyncThunk } from '@reduxjs/toolkit';
import { Approval, User } from 'core/types';
import BigNumber from 'bignumber.js';
import { BN, ciEquals, getContractByAddress } from 'utils';
import { ContractsState } from 'core/store/contracts/contracts';
import { AuthState } from '../auth';

export const updateUserConvertApproval = createAsyncThunk<
  Approval[],
  {
    tokenAddress: string;
    rhoTokenAddress: string;
    amount: BigNumber;
    user: User | undefined;
  }
>('auth/updateUserConvertApproval', async (payload, thunkAPI) => {
  const approvals: Approval[] = [];
  const { user, amount, tokenAddress, rhoTokenAddress } = payload;
  const contracts = (thunkAPI.getState() as { contracts: ContractsState })
    .contracts;
  const { currentProvider } = (thunkAPI.getState() as { auth: AuthState }).auth;

  try {
    if (!user) {
      throw Error('No User found');
    }
    if (!contracts) {
      throw new Error('No contracts');
    }

    const token = await getContractByAddress(
      tokenAddress,
      contracts,
      currentProvider?.web3provider
    );

    const vault = contracts.vaults.find(
      (v) =>
        v.depositTokens.find((t) => ciEquals(t.address, tokenAddress)) ||
        ciEquals(v.underlyingAddress, tokenAddress)
    );

    let allowance: BigNumber | undefined;

    if (vault && vault.rhoTokenAddress === rhoTokenAddress) {
      allowance = BN(
        await token.contract.allowance(user.address, vault.address),
        token.decimals
      );
    } else {
      // No vault linked to token, then we use kyberswap
      if (contracts.kyberswap) {
        allowance = BN(
          await token.contract.allowance(
            user.address,
            contracts.kyberswap.address
          ),
          token.decimals
        );
      }
    }

    if (!allowance) {
      throw new Error(`Could not get allowance of ${token.label}`);
    }

    approvals.push({
      contractAddress: token.address,
      isApproved: allowance.gte(amount),
    });
  } catch (_) {}

  return approvals;
});
