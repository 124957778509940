import * as S from './GeneralStatistics.styles';
import { Spacer } from 'components';
import APRChart from './APRChart';
import TotalSupplyChart from './TotalSupplyChart';
import LiquidityChart from './LiquidityChart';
import FlurryStakedChart from './FlurryStakedChart';
import StrategiesSupplyChart from './StrategiesSupplyChart';

const GeneralStatistics: React.FC = () => {
  return (
    <S.Wrapper>
      <S.GraphRow>
        <S.GraphWrapper>
          <APRChart />
        </S.GraphWrapper>
        <S.GraphWrapper>
          <TotalSupplyChart />
        </S.GraphWrapper>
      </S.GraphRow>
      <S.GraphRow>
        <S.GraphWrapper>
          <LiquidityChart />
        </S.GraphWrapper>
        <S.GraphWrapper>
          <FlurryStakedChart />
        </S.GraphWrapper>
      </S.GraphRow>
      <S.GraphRow>
        <S.GraphWrapper>
          <StrategiesSupplyChart />
        </S.GraphWrapper>
      </S.GraphRow>
      <Spacer axis="vertical" size={50} />
    </S.Wrapper>
  );
};

export default GeneralStatistics;
