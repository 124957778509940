import React, { useCallback } from 'react';
import styled from 'styled-components/macro';
import ReactGA from 'react-ga4';

interface GAOutboundLinkProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  eventLabel: string;
  to: string;
  classname?: string;
  disabled?: boolean;
}

const GALink = styled.a``;

const NEWTAB = '_blank';
const MIDDLECLICK = 1;

const GAOutboundLink: React.FC<GAOutboundLinkProps> = ({
  eventLabel,
  to,
  disabled,
  children,
  className,
  ...props
}) => {
  const handleClick: React.MouseEventHandler<HTMLAnchorElement> = useCallback(
    (event) => {
      const { target, onClick } = props;
      const sameTarget = target !== NEWTAB;
      const normalClick = !(
        event.ctrlKey ||
        event.shiftKey ||
        event.metaKey ||
        event.button === MIDDLECLICK
      );

      if (sameTarget && normalClick) {
        event.preventDefault();
      } else {
        ReactGA.event({
          category: 'Outbound',
          action: 'click',
          label: eventLabel,
        });
      }

      if (onClick) {
        onClick(event);
      }
    },
    [eventLabel, props]
  );

  return disabled ? (
    <div className={className}>{children}</div>
  ) : (
    <GALink className={className} href={to} onClick={handleClick} {...props}>
      {children}
    </GALink>
  );
};

export default GAOutboundLink;
