import React, { useMemo, useState } from 'react';
import { BNWithThousandsSep } from '../../utils';

import * as S from './ShortenBalance.styles';
import BigNumber from 'bignumber.js';

interface ShortenBalanceProps {
  label: string;
  balanceValue?: BigNumber;
  decimalPrecision: number;
  currency: string;
  fontSize?: string;
}

const ShortenBalance: React.FC<ShortenBalanceProps> = ({
  label,
  balanceValue,
  decimalPrecision,
  currency,
  fontSize = '0.7rem',
}) => {
  const [showFullBalance, setShowFullBalance] = useState(false);

  const roundingNotNecessary = useMemo(() => {
    if (!balanceValue) {
      return false;
    }

    if (balanceValue.dp(0, BigNumber.ROUND_FLOOR).eq(balanceValue)) return true;
    if (balanceValue.dp() > decimalPrecision) {
      return false;
    }
    return true;
  }, [balanceValue, decimalPrecision]);

  return (
    <S.BalanceLabel fontSize={fontSize}>
      {label}{' '}
      <b>
        {balanceValue
          ? `${
              showFullBalance
                ? BNWithThousandsSep(balanceValue)
                : `${BNWithThousandsSep(
                    balanceValue.dp(decimalPrecision, BigNumber.ROUND_FLOOR)
                  )}${!roundingNotNecessary ? '...' : ''}`
            } ${currency}`
          : `- ${currency}`}
      </b>
      {balanceValue && !roundingNotNecessary ? (
        showFullBalance ? (
          <S.EyeSlashIcon
            title="Round decimals"
            onClick={() => setShowFullBalance(false)}
          />
        ) : (
          <S.EyeIcon
            title="Show full decimals"
            onClick={() => setShowFullBalance(true)}
          />
        )
      ) : (
        ''
      )}
    </S.BalanceLabel>
  );
};

export default ShortenBalance;
