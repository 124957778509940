import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { TokensChainData, TokensData } from 'core/types/subgraph';
import { ethers } from 'ethers';
import {
  getBlocksOfTimestamps,
  getNetworkInfoFromChainId,
  getSubgraphUrl,
} from 'utils';

type GetRhoTokenReq = {
  chains: number[];
  timestamps: number[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formatSubgraphFlurryData = (data: any): TokensChainData => {
  const res: TokensChainData = {};
  for (const d of Object.keys(data)) {
    const ts = parseInt(d.replace('t', ''));
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const value: any[] = data[d];

    for (const v of value) {
      if (v.totalStaked) {
        res[ts] = {
          ...res[ts],
          [v.flurryToken.symbol]: v.totalStaked.simple,
        };
      }
    }
  }

  return res;
};

export const getFlurryStaked = createAsyncThunk<TokensData, GetRhoTokenReq>(
  'content/getFlurryStaked',
  async (payload) => {
    const result: TokensData = {};

    for (const chain of payload.chains) {
      try {
        const network = getNetworkInfoFromChainId(chain);
        const subgraphUrl = getSubgraphUrl(chain);

        if (!subgraphUrl) {
          continue;
        }

        const provider = new ethers.providers.JsonRpcProvider(network.rpcUrl);

        const currentBlock = await provider.getBlock(
          await provider.getBlockNumber()
        );

        const blocksToQuery = getBlocksOfTimestamps(
          payload.timestamps,
          currentBlock,
          network.avgBlockTime
        );

        let currentQuery = '';

        for (let i = 0; i < payload.timestamps.length; ++i) {
          const block = blocksToQuery[i];
          const ts = payload.timestamps[i];

          currentQuery += `
            t${ts}: flurryStakings(block: { number: ${block} }) {
              id
              flurryToken {
                symbol
              }
              totalStaked {
                simple
              }
            }`;
        }

        try {
          const res = await axios({
            url: subgraphUrl,
            method: 'post',
            data: {
              query: `
                      query {
                        ${currentQuery}
                      }`,
            },
          });
          if (res?.data?.data) {
            result[chain] = formatSubgraphFlurryData(res.data.data);
          }
        } catch (e) {
          console.error(e);
        }
      } catch (e) {
        console.error(e);
      }
    }

    return result;
  }
);
