import styled from 'styled-components/macro';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

export const BalanceLabel = styled.p<{ fontSize: string }>`
  min-height: 0.7rem;
  margin: 0.5rem 0 0 0.5rem;
  color: #0000008d;
  line-height: ${(props) => props.fontSize};
  font-size: ${(props) => props.fontSize};
`;

export const EyeIcon = styled(FaEye)`
  cursor: pointer;
  margin-left: 0.4rem;
  vertical-align: bottom;
`;

export const EyeSlashIcon = styled(FaEyeSlash)`
  cursor: pointer;
  margin-left: 0.4rem;
  vertical-align: bottom;
`;
