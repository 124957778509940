import styled from 'styled-components/macro';
import { VscChevronRight } from 'react-icons/vsc';
import { down } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  ${down('xs')} {
    display: none;
  }
`;

export const Drawer = styled.div`
  position: absolute;
  display: flex;
  width: fit-content;
  top: -0.4rem;
  height: calc(100% + 1rem);
  background-color: white;
  box-shadow: rgba(33, 35, 38, 0.1) 0px 10px 10px -10px,
    rgba(33, 35, 38, 0.1) 0px -10px 10px -10px;
`;

export const DrawerContent = styled.div<{ opened: boolean }>`
  display: flex;
  width: ${(props) => (props.opened ? '210px' : '0')};
  transition: width 0.8s ease-in-out;
  height: calc(100% - 1rem);
  overflow: hidden;
  padding: 0.4rem 1rem 0.4rem 0;
`;

export const DrawerHandle = styled.div<{ opened: boolean }>`
  cursor: pointer;
  background-color: white;
  position: absolute;
  height: 100%;
  width: 8px;
  right: 0;
  display: flex;
  transform: ${(props) => (props.opened ? 'rotate(-180deg)' : '')};
  opacity: 0.7;
  transition: opacity 0.5s linear;

  &:hover {
    opacity: 1;
  }
`;

export const DrawerLabel = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% - 5px);
  display: flex;
  transform: translateX(-50%) translateY(-50%) rotate(-90deg);
  background-color: white;
  font-size: 0.5rem;
  user-select: none;
  white-space: nowrap;
  padding: 0 0.2rem;
`;

export const VerticalLine = styled.div<{ opacity?: number }>`
  background-color: rgba(0, 0, 0, ${(props) => props.opacity ?? 1});
  width: 1px;
  height: 100%;
`;

export const ChevronBg = styled.div`
  position: absolute;
  top: 50%;
  left: calc(50% + 3px);
  transform: translateX(-50%) translateY(-50%);
  background-color: white;
  width: 5px;
  height: 13px;
`;

export const ChevronRight = styled(VscChevronRight)`
  position: absolute;
  top: 50%;
  left: calc(50% + 4px);
  transform: translateX(-50%) translateY(-50%);
  width: 22px;
  height: 22px;
`;
