import styled from 'styled-components/macro';
import GAOutboundLink from 'components/GAOutboundLink/GAOutboundLink';

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 200px;
  background-color: white;
  color: black;
  font-size: 0.8rem;
`;

export const FooterContent = styled.div`
  padding: 0 0.2rem;
  display: block;
  width: 90%;
  margin: 0 auto;
`;

export const FooterLogo = styled.img`
  max-width: 150px;
  height: auto;
`;

export const Socials = styled.div`
  margin-top: 1.6rem;
  display: flex;
`;

export const SocialLogo = styled(GAOutboundLink)`
  margin-right: 0.5rem;
  img {
    max-width: 20px;
    height: auto;
  }
`;

export const ContactUs = styled.div`
  margin-top: 0.8rem;
  a {
    color: inherit;
    text-decoration: none;
  }
`;

export const Centered = styled.div`
  margin-top: 3rem;
  text-align: center;
`;
