/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AuthState } from 'core/store/auth/auth';
import { AddTransactionReq, Transaction } from 'core/types';

export const addNewTransaction = createAsyncThunk<
  { userAddress: string; transaction: Transaction },
  AddTransactionReq
>('transactions/addNewTransaction', async (req, thunkAPI) => {
  const { id, source, type, state, payload } = req;
  const { network, user } = (thunkAPI.getState() as { auth: AuthState }).auth;
  if (!user) thunkAPI.rejectWithValue('Could not find User');

  const newTransaction: Transaction = {
    id,
    isFromCache: false,
    createdAtTs: Date.now(),
    source,
    type,
    state,
    network,
    payload,
  };

  return {
    userAddress: user?.address ?? '',
    transaction: newTransaction,
  };
});
