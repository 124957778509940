import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { EarnAnnouncement } from 'core/types';
import { useEffect, useMemo, useState } from 'react';
import { usePrevious } from 'react-use';
import { CONTENT_WS, ENTITIES } from '..';
import { getEarnAnnouncements } from '../thunks/getEarnAnnouncements';

export const useEarnAnnouncements = (): {
  data: EarnAnnouncement[];
  isLoading: boolean;
  error?: string;
} => {
  const [subscribed, setSubscribed] = useState(false);
  const announcements = useAppSelector(
    (state) => state.content.earnAnnouncements
  );
  const isLoading = useAppSelector(
    (state) => state.content.loadingStates.earnAnnouncements
  );
  const prevIsLoading = usePrevious(isLoading);
  const error = useAppSelector(
    (state) => state.content.errors.earnAnnouncements
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getEarnAnnouncements());
  }, [dispatch]);

  useEffect(() => {
    if (prevIsLoading && !isLoading && !subscribed) {
      dispatch({
        type: CONTENT_WS.SUBSCRIBE,
        message: ENTITIES.EARN_ANNOUNCEMENTS,
      });
      setSubscribed(true);
    }
  }, [dispatch, isLoading, prevIsLoading, subscribed]);

  return useMemo(() => {
    return {
      data: announcements,
      isLoading,
      error,
    };
  }, [announcements, error, isLoading]);
};
