import styled from 'styled-components/macro';
import { FaChevronUp, FaChevronRight } from 'react-icons/fa';

export const DropdownWrapper = styled.div`
  grid-column: 3 / 4;
  /* position: relative; */
`;

export const DropdownBtn = styled.button`
  cursor: pointer;
  background: none;
  border: none;
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const DropdownList = styled.div<{ active: boolean }>`
  position: absolute;
  z-index: 300;
  left: 0;
  width: calc(100% - 1rem);
  margin-left: 0.5rem;
  display: ${(props) => (props.active ? 'block' : 'none')};
  border: 1px solid #a7dcff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: 1px solid white;
  background-color: #ffffff;
`;

export const DropdownItem = styled.div<{
  active?: boolean;
  disabled?: boolean;
}>`
  user-select: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: flex;
  align-items: center;
  padding: 0.7rem 0.8rem;
  font-size: 1rem;
  background-color: ${(props) => (props.active ? '#e4e4e4' : 'transparent')};
  color: ${(props) => (props.disabled ? '#00000032' : 'black')};
  font-weight: ${(props) => (props.active ? 'bold' : 'none')};
  transition: all 0.1s linear;

  img {
    opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  }

  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'transparent' : '#e4e4e4'};
  }
`;

export const DropdownGroupWrapper = styled.div``;

export const GroupItem = styled(DropdownItem)`
  background-color: ${(props) => (props.active ? '#fafafa' : 'transparent')};

  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'transparent' : '#fafafa'};
  }
`;

export const GroupItems = styled.div<{ isOpen: boolean }>`
  display: ${(props) => (props.isOpen ? 'block' : 'none')};
  background-color: #ffffff;
`;
export const DropdownItemOfGroup = styled(DropdownItem)`
  padding: 0.5rem 0.8rem;
  padding-left: 2rem;
  font-size: 0.9rem;
  border-bottom: 1px solid #f8f8f8;
  &:nth-child(odd) {
    border-top: 1px solid #f8f8f8;
  }
  &:hover {
    background-color: ${(props) =>
      props.disabled ? 'transparent' : '#f7f7f7'};
  }
`;

export const CurrencyLogo = styled.img`
  width: 20px;
  height: 20px;
  padding-right: 5px;
`;

export const CurrencyLabel = styled.div``;

export const SelectedCurrencyLabel = styled(CurrencyLabel)`
  font-weight: bold;
  font-size: 1.1rem;
  padding-right: 5px;
  white-space: nowrap;
  max-width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Chevron = styled(FaChevronUp)<{ $isup: boolean }>`
  transform: ${(props) => (!props.$isup ? 'rotate(180deg)' : 'none')};
`;

export const GroupChevron = styled(FaChevronRight)<{ $isopen: boolean }>`
  margin-left: auto;
  justify-self: flex-end;
  transform: ${(props) => (props.$isopen ? 'rotate(90deg)' : 'none')};
`;
