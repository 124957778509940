import { v4 as uuidv4 } from 'uuid';
import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  addNewTransaction,
  updateTransactionHash,
  updateTransactionState,
} from 'core/store/transactions/transactions';
import { sendTransaction, waitReceipt } from 'utils/web3';
import { ContractsState } from '../contracts';

export const claimAllRewards = createAsyncThunk<void, void>(
  'contracts/claimAllRewards',
  async (_, thunkAPI) => {
    const { contracts } = thunkAPI.getState() as { contracts: ContractsState };
    const transactionId = uuidv4();
    thunkAPI.dispatch(
      addNewTransaction({
        id: transactionId,
        source: 'reward',
        type: 'claim',
        state: 'pending',
      })
    );
    try {
      if (!contracts.flurryStaking) {
        throw new Error('No Flurry Staking contract');
      }

      const tx = await sendTransaction(
        contracts.flurryStaking.contract.claimAllRewards
      );
      thunkAPI.dispatch(
        updateTransactionHash({
          id: transactionId,
          hash: tx.hash,
        })
      );

      await waitReceipt(tx);
      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'success',
        })
      );
    } catch (error) {
      console.error(error.toString());

      thunkAPI.dispatch(
        updateTransactionState({
          id: transactionId,
          state: 'error',
        })
      );

      thunkAPI.rejectWithValue(error);
    }
  }
);
