import { createGlobalStyle } from 'styled-components/macro';
import Celias from 'assets/fonts/Celias.woff2';
import styled from 'styled-components/macro';
import { IoMdWarning } from 'react-icons/io';
import { down, up } from 'styled-breakpoints';

export const Wrapper = styled.div`
  position: relative;
  min-height: 100vh;
`;

export const ContentContainer = styled.div`
  padding-bottom: 200px;
  ${down('md')} {
    padding-bottom: 350px;
  }
`;

export const GlobalStyles = createGlobalStyle`
    @font-face {
        font-family: 'Celias';
        src: local('Celias'), local('Celias'),
            url(${Celias}) format('woff2');
        font-weight: 300;
        font-style: normal;
    }

    html {
        height:100%;
        font-size: 17px;

        ${up('xl')} {
            font-size: 18px;
        }
        ${up('xxl')} {
            font-size: 19px;
        }
    }

    body {
        height:100%;
        font-family: Celias, Helvetica, sans-serif;
    }

    #root {
        height:100%;
    }

    h5, h4 {
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
    }
`;

export const TestnetPopup = styled.div`
  font-size: 1.1rem;
  position: fixed;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  padding: 0.5rem 1rem;
  background-color: red;
  color: white;
  opacity: 0.8;
`;

export const WarningIcon = styled(IoMdWarning)`
  padding: 0 0.5rem 0 0;
`;
