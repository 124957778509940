import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContractCallContext, Multicall } from 'ethereum-multicall';
import { BN, getValueOfRef } from 'utils';
import { Contract, FarmContract, User, UserBalance } from '../../../types';

export const updateFarmsTokenBalances = createAsyncThunk<
  UserBalance[],
  {
    user: User;
    farms: FarmContract[];
    lpStaking: Contract | null;
    multicall: Multicall;
  }
>('auth/updateFarmsTokenBalances', async (payload) => {
  const balances: UserBalance[] = [];
  try {
    // Building contract multicall and farms list
    const multicallContext: ContractCallContext[] = [];
    for (const f of payload.farms) {
      multicallContext.push({
        reference: f.address,
        contractAddress: f.address,
        abi: f.abi ?? [],
        calls: [
          {
            reference: 'farmBalance',
            methodName: 'balanceOf',
            methodParameters: [payload.user.address],
          },
        ],
      });
      if (payload.lpStaking) {
        multicallContext.push({
          reference: `staking-${f.address}`,
          contractAddress: payload.lpStaking.address,
          abi: payload.lpStaking.abi ?? [],
          calls: [
            {
              reference: 'farmStakingBalance',
              methodName: 'stakeOf',
              methodParameters: [payload.user.address, f.address],
            },
          ],
        });
      }
    }

    // Executing contract multicall
    const callResults = (await payload.multicall.call(multicallContext))
      .results;

    for (const f of payload.farms) {
      const farmRes = callResults[f.address]?.callsReturnContext;
      if (farmRes) {
        balances.push({
          currency: `${f.key}`,
          amount: BN(getValueOfRef(farmRes, 'farmBalance'), f.decimals),
        });
      }

      const stakingRes =
        callResults[`staking-${f.address}`]?.callsReturnContext;
      if (stakingRes) {
        balances.push({
          currency: `Staking${f.key}`,
          amount: BN(
            getValueOfRef(stakingRes, 'farmStakingBalance'),
            f.decimals
          ),
        });
      }
    }
  } catch (e) {
    console.error(e);
  }

  return balances;
});
