/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { NetworkInfo } from 'core/types';

import { AuthState } from '../auth';

const switchProviderChain = async (
  hexChainId: string,
  authState: AuthState
): Promise<boolean> => {
  if (authState.currentProvider && authState.currentProvider.accessor) {
    if (authState.currentProvider.accessor.request) {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: hexChainId }],
      });
      return true;
    }
  }
  return false;
};

const addProviderChain = async (
  hexChainId: string,
  networkInfo: NetworkInfo,
  authState: AuthState
): Promise<boolean> => {
  if (authState.currentProvider && authState.currentProvider.accessor) {
    if (authState.currentProvider.accessor.request) {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: hexChainId,
            chainName: networkInfo.label,
            rpcUrls: [networkInfo.rpcUrl],
          },
        ],
      });
      return true;
    }
  }
  return false;
};

export const switchChain = createAsyncThunk<NetworkInfo, NetworkInfo>(
  'auth/switchChain',
  async (newNetwork, thunkAPI) => {
    const hexChain = `0x${newNetwork.chainId.toString(16)}`;
    const auth = (thunkAPI.getState() as { auth: AuthState }).auth;
    try {
      if (await switchProviderChain(hexChain, auth)) {
        return newNetwork;
      }
      return thunkAPI.rejectWithValue('Could not switch chain');
    } catch (e: any) {
      if (e.code === 4902) {
        try {
          await addProviderChain(hexChain, newNetwork, auth);
          await switchProviderChain(hexChain, auth);
          return newNetwork;
        } catch (err) {
          console.error(err);
          return thunkAPI.rejectWithValue('Could not switch chain');
        }
      } else {
        console.error(e);
        return thunkAPI.rejectWithValue('Could not switch chain');
      }
    }
  }
);
