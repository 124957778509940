/* eslint-disable @typescript-eslint/no-explicit-any */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  APRS,
  Contract,
  CurrencyOption,
  PriceOracle,
  VaultContract,
} from 'core/types';
import { updateFlurryStakingApr } from './thunks/apr/updateFlurryStakingApr';
import { updateRhotokensApr } from './thunks/apr/updateRhotokensApr';
import { claimReward } from './thunks/claimReward';
import { initVaults } from './thunks/inits/initVaults';
import { initRhoTokens } from './thunks/inits/initRhoTokens';
import { initFlurryToken } from './thunks/inits/initFlurryToken';
import { initFlurryStaking } from './thunks/inits/initFlurryStaking';
import { initLp } from './thunks/inits/initLp';
import { initBridge } from './thunks/inits/initBridge';
import { BigNumber } from 'bignumber.js';
import { initStablecoins } from './thunks/inits/initStablecoins';
import { initKyberswap } from './thunks/inits/initKyberswap';
import { initOtherTokens } from './thunks/inits/initOtherTokens';
import { initCompensater } from './thunks/inits/initCompensater';

type RewardClaimState = {
  contract: string;
  state: 'pending' | 'finished';
};

export type ContractsState = {
  flurryToken: Contract | null;
  flurryStaking: Contract | null;
  lpStaking: Contract | null;
  rhoTokenRewards: Contract | null;
  stablecoins: Contract[];
  rhoTokens: Contract[];
  vaults: VaultContract[];
  kyberswap: Contract | null;
  rewards: Contract[];
  priceOracles: PriceOracle[];
  bridge: Contract | null;
  registry: Contract | null;
  compensater: Contract | null;

  otherTokens: CurrencyOption[];

  claims: RewardClaimState[];

  flurryPrice: BigNumber | undefined;
  aprs?: APRS;

  loading: boolean;
  initLoading: {
    kyberswap: boolean;
    vaults: boolean;
    stablecoins: boolean;
    rhoTokens: boolean;
    flurryToken: boolean;
    flurryStaking: boolean;
    lp: boolean;
    bridge: boolean;
    registry: boolean;
    otherTokens: boolean;
    compensater: boolean;
  };
  error: string | null;
};

const initialState: ContractsState = {
  flurryToken: null,
  flurryStaking: null,
  lpStaking: null,
  rhoTokenRewards: null,
  stablecoins: [],
  rhoTokens: [],
  otherTokens: [],
  vaults: [],
  kyberswap: null,
  rewards: [],
  priceOracles: [],
  bridge: null,
  registry: null,
  compensater: null,
  claims: [],
  flurryPrice: undefined,
  aprs: undefined,
  loading: false,
  initLoading: {
    vaults: false,
    kyberswap: false,
    stablecoins: false,
    rhoTokens: false,
    flurryToken: false,
    flurryStaking: false,
    lp: false,
    bridge: false,
    registry: false,
    otherTokens: false,
    compensater: false,
  },
  error: null,
};

const contractsSlice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    updateFlurryPrice: (
      state,
      action: PayloadAction<BigNumber | undefined>
    ) => {
      if (action.payload) {
        state.flurryPrice = action.payload;
      }
    },
  },
  //TODO add init other token contracts reducer
  extraReducers: (builder) => {
    builder
      // Init Flurry Token
      .addCase(initFlurryToken.pending, (state) => {
        state.initLoading.flurryToken = true;
      })
      .addCase(initFlurryToken.fulfilled, (state, action) => {
        state.flurryToken = action.payload as any;
        state.initLoading.flurryToken = false;
      })
      .addCase(initFlurryToken.rejected, (state) => {
        state.initLoading.flurryToken = false;
      })
      // Init Flurry Staking
      .addCase(initFlurryStaking.pending, (state) => {
        state.initLoading.flurryStaking = true;
      })
      .addCase(initFlurryStaking.fulfilled, (state, action) => {
        state.flurryStaking = action.payload as any;
        state.initLoading.flurryStaking = false;
      })
      .addCase(initFlurryStaking.rejected, (state) => {
        state.initLoading.flurryStaking = false;
      })
      // Init Kyberswap
      .addCase(initKyberswap.pending, (state) => {
        state.initLoading.kyberswap = true;
      })
      .addCase(initKyberswap.fulfilled, (state, action) => {
        state.kyberswap = action.payload as any;
        state.initLoading.kyberswap = false;
      })
      .addCase(initKyberswap.rejected, (state) => {
        state.initLoading.kyberswap = false;
      })
      // Init Vaults
      .addCase(initVaults.pending, (state) => {
        state.initLoading.vaults = true;
      })
      .addCase(initVaults.fulfilled, (state, action) => {
        state.vaults = action.payload as [];
        state.initLoading.vaults = false;
      })
      .addCase(initVaults.rejected, (state) => {
        state.initLoading.vaults = false;
      })
      // Init Stablecoins
      .addCase(initStablecoins.pending, (state) => {
        state.initLoading.stablecoins = true;
      })
      .addCase(initStablecoins.fulfilled, (state, action) => {
        state.stablecoins = action.payload as [];
        state.initLoading.stablecoins = false;
      })
      .addCase(initStablecoins.rejected, (state) => {
        state.initLoading.stablecoins = false;
      })
      // Init Rho Tokens and Rho Token rewards
      .addCase(initRhoTokens.pending, (state) => {
        state.initLoading.rhoTokens = true;
      })
      .addCase(initRhoTokens.fulfilled, (state, action) => {
        state.rhoTokenRewards = action.payload.rhoTokenRewards as any;
        state.rhoTokens = action.payload.rhoTokens as [];
        state.initLoading.rhoTokens = false;
      })
      .addCase(initRhoTokens.rejected, (state) => {
        state.initLoading.rhoTokens = false;
      })
      // Init of other tokens
      .addCase(initOtherTokens.pending, (state) => {
        state.initLoading.otherTokens = true;
      })
      .addCase(initOtherTokens.fulfilled, (state, action) => {
        state.otherTokens = action.payload;
        state.initLoading.otherTokens = false;
      })
      .addCase(initOtherTokens.rejected, (state) => {
        state.initLoading.otherTokens = false;
      })
      // Init LP Staking and Price oracles
      .addCase(initLp.pending, (state) => {
        state.initLoading.lp = true;
      })
      .addCase(initLp.fulfilled, (state, action) => {
        state.lpStaking = action.payload.lpStaking as any;
        state.priceOracles = action.payload.priceOracles as [];
        state.initLoading.lp = false;
      })
      .addCase(initLp.rejected, (state) => {
        state.initLoading.lp = false;
      })
      // Init Bridge
      .addCase(initBridge.pending, (state) => {
        state.initLoading.bridge = true;
        state.initLoading.registry = true;
      })
      .addCase(initBridge.fulfilled, (state, action) => {
        state.bridge = action.payload.bridge as any;
        state.registry = action.payload.registry as any;
        state.initLoading.bridge = false;
        state.initLoading.registry = false;
      })
      .addCase(initBridge.rejected, (state) => {
        state.initLoading.bridge = false;
        state.initLoading.registry = false;
      })
      // Init Compensater
      .addCase(initCompensater.pending, (state) => {
        state.initLoading.compensater = true;
      })
      .addCase(initCompensater.fulfilled, (state, action) => {
        state.compensater = action.payload as any;
        state.initLoading.compensater = false;
      })
      .addCase(initCompensater.rejected, (state) => {
        state.initLoading.compensater = false;
      })
      .addCase(claimReward.pending, (state, action) => {
        const idx = state.claims.findIndex(
          (e) => e.contract === action.meta.arg
        );
        if (idx >= 0) {
          state.claims[idx].state = 'pending';
        } else {
          state.claims.push({
            contract: action.meta.arg,
            state: 'pending',
          });
        }
      })
      .addCase(claimReward.fulfilled, (state, action) => {
        const idx = state.claims.findIndex(
          (e) => e.contract === action.meta.arg
        );
        if (idx >= 0) {
          state.claims[idx].state = 'finished';
        } else {
          state.claims.push({
            contract: action.meta.arg,
            state: 'finished',
          });
        }
      })
      .addCase(claimReward.rejected, (state, action) => {
        const idx = state.claims.findIndex(
          (e) => e.contract === action.meta.arg
        );
        if (idx >= 0) {
          state.claims[idx].state = 'finished';
        } else {
          state.claims.push({
            contract: action.meta.arg,
            state: 'finished',
          });
        }
      })
      .addCase(updateRhotokensApr.fulfilled, (state, action) => {
        if (state.aprs?.rhoTokens !== action.payload) {
          state.aprs = {
            ...state.aprs,
            rhoTokens: action.payload,
          };
        }
      })
      .addCase(updateFlurryStakingApr.fulfilled, (state, action) => {
        if (state.aprs?.flurryStaking?.apr !== action.payload?.apr) {
          state.aprs = {
            ...state.aprs,
            flurryStaking: action.payload,
          };
        }
      });
  },
});

export const { updateFlurryPrice } = contractsSlice.actions;

export default contractsSlice.reducer;
