import * as S from './GeneralStatistics.styles';
import { useFarmsLiquidity } from 'core/store/subgraph/hooks';
import { useMemo, useState } from 'react';
import { format } from 'date-fns';
import Skeleton from 'react-loading-skeleton';
import { ChartData, DateRange } from 'core/types';
import {
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
} from 'recharts';
import {
  getChartColors,
  getFormatFromRange,
  getRandomBrightColor,
  KFormaterBN,
} from 'utils';
import DateRangeFilter from './DateRangeFilter';
import NetworkFilter from './NetworkFilter';
import { Spacer } from 'components';
import styled from 'styled-components';
import BigNumber from 'bignumber.js';

const FiltersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GraphSkeleton = styled.div`
  height: 100%;
  span {
    height: 100%;
  }
`;

const LiquidityChart: React.FC = () => {
  const [dateRange, setDateRange] = useState<DateRange>('7day');
  const { data, isLoading } = useFarmsLiquidity(dateRange);

  const [chain, setChain] = useState(
    !data ? Object.keys(data).map(Number)[0] : undefined
  );

  const chartProps: ChartData | undefined = useMemo(() => {
    const chainToUse = chain ?? Object.keys(data).map(Number)[0];

    if (!data[chainToUse]) return undefined;

    const res: ChartData = {
      data: [],
      metrics: [],
    };

    const dataOfChain = data[chainToUse];

    for (const ts of Object.keys(dataOfChain).map(Number)) {
      const sortedValues = Object.entries(dataOfChain[ts]).sort(
        ([, a], [, b]) => (a as number) - (b as number)
      );

      let dataValues = {};
      for (let j = 0; j < sortedValues.length; ++j) {
        // hide all $0 famrs
        if (new BigNumber(sortedValues[j][1]).gt(0)) {
          dataValues = {
            ...dataValues,
            [sortedValues[j][0]]: sortedValues[j][1],
          };
        }
      }

      const tokenNames = Object.keys(dataValues);
      if (tokenNames.length > 0) {
        res.data.push({
          time: ts,
          ...dataValues,
        });

        if (res.metrics.length === 0) {
          const colors = getChartColors();
          for (let i = 0; i < tokenNames.length; i++) {
            const token = tokenNames[i];
            res.metrics.push({
              key: token,
              color: colors.length > i ? colors[i] : getRandomBrightColor(),
            });
          }
        }
      }
    }
    return res;
  }, [chain, data]);

  return (
    <>
      <S.GraphTitle>Farms Liquidity</S.GraphTitle>
      <FiltersWrapper>
        <DateRangeFilter
          initialRange={dateRange}
          onDateRangeChanged={(v) => setDateRange(v)}
        />

        <Spacer axis="vertical" size={5} />
        {data ? (
          <NetworkFilter
            initialChains={[Object.keys(data).map(Number)[0]]}
            chains={Object.keys(data).map(Number)}
            onChainsChanged={(c) => setChain(c[0])}
          />
        ) : (
          <Skeleton height={35} />
        )}
      </FiltersWrapper>
      <Spacer axis="vertical" size={10} />
      {!isLoading && chartProps ? (
        <>
          <ResponsiveContainer height="100%" width="100%">
            <BarChart data={chartProps.data}>
              {chartProps.metrics.map(({ key, color }) => (
                <Bar key={key} dataKey={key} fill={color} stackId="liquidity" />
              ))}
              <XAxis
                dataKey="time"
                tick={{ fontSize: '0.6rem' }}
                axisLine={false}
                tickMargin={8}
                tickSize={8}
                tickLine={{ stroke: '#0000003e', strokeWidth: '1px' }}
                tickFormatter={(v) => {
                  if (isNaN(v)) {
                    return '';
                  }
                  return format(
                    (v as number) * 1000,
                    getFormatFromRange(dateRange)
                  );
                }}
              />
              <YAxis
                orientation="right"
                tick={{ fontSize: '0.6rem' }}
                tickFormatter={(v) =>
                  `$${KFormaterBN(new BigNumber(v), 2)}` ?? ''
                }
                axisLine={false}
                tickSize={8}
                tickLine={{ stroke: '#0000003e', strokeWidth: '1px' }}
                domain={[0, 'dataMax + 0.05']}
              />
              <Tooltip
                cursor={{ fill: '#b9b9b945' }}
                labelFormatter={(timestamp) => {
                  if (isNaN(timestamp)) {
                    return '';
                  }
                  return format(
                    (timestamp as number) * 1000,
                    'yyyy-MM-dd HH:mm'
                  );
                }}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                formatter={(v: any) =>
                  `$${KFormaterBN(new BigNumber(v), 2)}` ?? ''
                }
                separator=": "
                contentStyle={{
                  fontSize: '14px',
                  padding: '8px',
                  background: 'rgba(255, 255, 255, 0.8)',
                  textAlign: 'right',
                  border: 'none',
                  borderRadius: '4px',
                  color: '#000',
                }}
                wrapperStyle={{
                  top: 0,
                  left: 0,
                }}
              />
            </BarChart>
          </ResponsiveContainer>
        </>
      ) : (
        <GraphSkeleton>
          <Skeleton height="100%" />
        </GraphSkeleton>
      )}
    </>
  );
};

export default LiquidityChart;
