import React from 'react';
import styled from 'styled-components/macro';
import { HiOutlineExternalLink } from 'react-icons/hi';
import { useAppSelector } from 'core/store/hooks';
import { useMemo } from 'react';
import { getEtherscanLink } from 'utils';
import GAOutboundLink from 'components/GAOutboundLink/GAOutboundLink';

interface EtherscanLinkProps {
  showWithLabel?: boolean;
  contractAddress?: string;
  transactionHash?: string;
  disabled?: boolean;
}

const ExternalLinkIcon = styled(HiOutlineExternalLink)`
  width: 11px;
  height: 11px;
`;

const LinkToEtherscan = styled(GAOutboundLink)<{ disabled?: boolean }>`
  color: white;
  font-size: 1.1rem;
  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`;

const EtherscanLink: React.FC<EtherscanLinkProps> = ({
  showWithLabel,
  contractAddress,
  transactionHash,
  disabled,
}) => {
  const network = useAppSelector((state) => state.auth.network);

  const url = useMemo(
    () => getEtherscanLink(network, transactionHash, contractAddress),
    [network, contractAddress, transactionHash]
  );

  if (!url) return <></>;

  return (
    <LinkToEtherscan
      eventLabel={url}
      disabled={disabled}
      to={url}
      title="Show on etherscan"
      target="_blank"
      rel="noopener noreferrer"
    >
      {showWithLabel ? 'Show on etherscan' : ''}
      <ExternalLinkIcon />
    </LinkToEtherscan>
  );
};

export default EtherscanLink;
