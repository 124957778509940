import { createAsyncThunk } from '@reduxjs/toolkit';
import { Contract, UserBalance } from 'core/types';
import { BigNumber, EventFilter } from 'ethers';
import { updateBalances } from './updateBalances';
import { BN } from 'utils';

export const initFlurryStakingBalance = createAsyncThunk<
  UserBalance | null,
  {
    flurryStaking: Contract | null;
    decimals: number | undefined;
    userAddress: string;
  }
>('balances/initFlurryStakingBalance', async (payload, thunkAPI) => {
  let stakingBalance: UserBalance | null = null;
  try {
    const { flurryStaking, decimals, userAddress } = payload;

    if (!flurryStaking) {
      thunkAPI.rejectWithValue('No Flurry Staking Contract');
      throw new Error('No Flurry Staking Contract');
    } else if (!flurryStaking.contract.filters.StakeBalanceUpdate) {
      thunkAPI.rejectWithValue('Event not found on contract');
      throw new Error('Event not found on contract');
    }

    if (!userAddress) {
      thunkAPI.rejectWithValue('No user address');
    }

    const currency = 'FlurryStakingBalance';

    // event listener
    const eventFilter: EventFilter =
      flurryStaking.contract.filters.StakeBalanceUpdate(userAddress);
    flurryStaking.contract.on(
      eventFilter,
      (addr: string, blockNbr: BigNumber, amnt: BigNumber) => {
        thunkAPI.dispatch(
          updateBalances({
            currency: currency,
            amount: BN(amnt, decimals),
          })
        );
      }
    );

    // init value
    stakingBalance = {
      currency: currency,
      amount: BN(await flurryStaking.contract.stakeOf(userAddress), decimals),
    };
  } catch (e) {
    thunkAPI.rejectWithValue(
      'Could not initialize Flurry Staking user balance'
    );
    console.error(e);
  } finally {
    return stakingBalance;
  }
});
