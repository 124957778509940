import { createAsyncThunk } from '@reduxjs/toolkit';
import { ContractsState } from 'core/store/contracts/contracts';
import { FarmApr, FarmContract, NetworkInfo } from 'core/types';
import { Multicall } from 'ethereum-multicall';
import { BigNumber } from 'bignumber.js';
import { calculateFarmsApr } from 'utils';

export const updateFarmAprs = createAsyncThunk<
  FarmApr[],
  {
    farms: FarmContract[];
    contracts: ContractsState;
    network: NetworkInfo | undefined;
    multicall: Multicall;
    flurryUsdPrice: BigNumber | undefined;
  }
>('farms/updateFarmAprs', async (payload) => {
  let result: FarmApr[] = [];
  const { farms, contracts, network, multicall, flurryUsdPrice } = payload;
  const { lpStaking, flurryToken, priceOracles } = contracts;
  if (!farms || farms.length === 0) {
    return [];
  }

  try {
    result = await calculateFarmsApr({
      farms,
      lpStaking,
      flurryToken,
      priceOracles,
      multicall,
      network,
      flurryUsdPrice,
    });
  } catch (e) {
    console.error(e);
  } finally {
    return result;
  }
});
