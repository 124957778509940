/* eslint-disable @typescript-eslint/no-explicit-any */
import * as io from 'socket.io-client';

const EVENTS = {
  CONNECT: 'connected',
  DISCONNECT: 'disconnect',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  SUBSCRIBE: 'subscribe',
  SUBSCRIBED: 'subscribed',
};

export class Socket {
  private onCreate: (message: any) => void;

  private onUpdate: (message: any) => void;

  private onDelete: (message: any) => void;

  private onSubscribed: (message: any) => void;

  private socket: io.Socket | undefined;

  constructor(
    onCreate: (message: any) => void,
    onUpdate: (message: any) => void,
    onDelete: (message: any) => void,
    onSubscribed: (message: any) => void
  ) {
    this.onCreate = onCreate;
    this.onUpdate = onUpdate;
    this.onDelete = onDelete;
    this.onSubscribed = onSubscribed;
    this.socket = undefined;
  }

  public connect = (url: string): void => {
    this.socket = io.connect(url);

    this.socket.on(EVENTS.CONNECT, this.onConnected);
  };

  public onConnected = (): void => {
    if (this.socket) {
      this.socket.on(EVENTS.CREATE, this.onCreate);
      this.socket.on(EVENTS.UPDATE, this.onUpdate);
      this.socket.on(EVENTS.DELETE, this.onDelete);
      this.socket.on(EVENTS.SUBSCRIBED, this.onSubscribed);
    }
  };

  public subscribe = (category: string): void => {
    if (this.socket && typeof this.socket.emit === 'function') {
      this.socket.emit(EVENTS.SUBSCRIBE, category);
    } else {
      console.error('Cannot emit socket messages. Socket.io not connected.');
    }
  };

  public disconnect = (): void => {
    if (this.socket) this.socket.close();
  };
}
