import styled, { keyframes } from 'styled-components/macro';

const dotStretching = keyframes`
    0% {
      transform: scale(1.25, 1.25);
    }
    50%,
    60% {
      transform: scale(0.8, 0.8);
    }
    100% {
      transform: scale(1.25, 1.25);
    }
`;

const dotStretchingBefore = keyframes`
    0% {
      transform: translate(0) scale(0.7, 0.7);
    }
    50%,
    60% {
      transform: translate(-20px) scale(1, 1);
    }
    100% {
      transform: translate(0) scale(0.7, 0.7);
    }
`;

const dotStretchingAfter = keyframes`
    0% {
      transform: translate(0) scale(0.7, 0.7);
    }
    50%,
    60% {
      transform: translate(20px) scale(1, 1);
    }
    100% {
      transform: translate(0) scale(0.7, 0.7);
    }
`;

const LoadingDots = styled.div`
  position: relative;
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: #000000;
  color: #000000;
  transform: scale(1.25, 1.25);
  animation: ${dotStretching} 2s infinite ease-in;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }

  &::before {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
    animation: ${dotStretchingBefore} 2s infinite ease-in;
  }

  &::after {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: #000000;
    color: #000000;
    animation: ${dotStretchingAfter} 2s infinite ease-in;
  }
`;

export default LoadingDots;
