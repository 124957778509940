import React, { useMemo, useRef, useState } from 'react';
import { Spacer, useOnClickOutside } from 'components';
import { useAppSelector } from 'core/store/hooks';

import * as S from './APRDetailsPopup.styles';
import { APR, RhoTokenAprs, StrategyAPR } from 'core/types';
import { formatAPR, formatBNToString } from 'utils';
import { useCallback } from 'react';
import { InfoIcon } from 'components/Navbar/Navbar.styles';

interface APRDetailsPopupProps {
  tokenName: string;
  stablecoinName: string;
  tokenAprs: RhoTokenAprs;
  show: boolean;
  onClose: () => void;
}

const APRDetailsPopup: React.FC<APRDetailsPopupProps> = ({
  tokenName,
  stablecoinName,
  tokenAprs,
  show,
  onClose,
}) => {
  const [normalizedSupplyRateAPR, setNormalizedSupplyRateAPR] = useState<
    string | undefined
  >(undefined);
  const [normalizedBonusAPR, setNormalizedBonusAPR] = useState<
    string | undefined
  >(undefined);
  const [normalizedStrategy, setNormalizedStrategy] = useState<
    string | undefined
  >(undefined);
  const [detailsShowing, setDetailsShowing] = useState<
    'none' | 'apr' | 'strategy'
  >('none');

  const ref = useRef(null);
  useOnClickOutside(ref, () => onClose());
  const contracts = useAppSelector((state) => state.contracts);

  const strategiesAPR: StrategyAPR[] = useMemo(() => {
    return tokenAprs.strategies ?? [];
  }, [tokenAprs.strategies]);

  const indicativeAPR: APR | undefined = useMemo(() => {
    return tokenAprs.vaultIndicativeApr;
  }, [tokenAprs.vaultIndicativeApr]);

  const instantApr: APR | undefined = useMemo(() => {
    return tokenAprs.vaultInstantApr;
  }, [tokenAprs.vaultInstantApr]);

  const rebasePercentage: APR | undefined = useMemo(() => {
    return tokenAprs.rebasePercentage;
  }, [tokenAprs.rebasePercentage]);

  const switchDetailsOfNormalizedStrategy = useCallback(
    (apr: StrategyAPR, isNormalizedStrategy: boolean) => {
      if (isNormalizedStrategy && (apr.supplyRateApr || apr.bonusApr)) {
        if (
          normalizedStrategy &&
          apr.key.toLowerCase().includes(normalizedStrategy.toLowerCase())
        ) {
          setDetailsShowing('none');
          setNormalizedStrategy(undefined);
          setNormalizedSupplyRateAPR(undefined);
          setNormalizedBonusAPR(undefined);
          return;
        }
        if (apr.supplyRateApr) {
          setNormalizedSupplyRateAPR(
            apr.normalizedSupplyRateApr !== undefined
              ? `${formatAPR(apr.normalizedSupplyRateApr, 2)}%`
              : undefined
          );
        } else {
          setNormalizedSupplyRateAPR(undefined);
        }

        if (apr.bonusApr) {
          setNormalizedBonusAPR(
            apr.normalizedBonusApr !== undefined
              ? `${formatAPR(apr.normalizedBonusApr, 2)}%`
              : undefined
          );
        } else {
          setNormalizedBonusAPR(undefined);
        }

        if (apr.key.toLowerCase().includes('compound')) {
          setNormalizedStrategy('Compound');
          setDetailsShowing('strategy');
        } else if (apr.key.toLowerCase().includes('cream')) {
          setNormalizedStrategy('Cream');
          setDetailsShowing('strategy');
        } else if (apr.key.toLowerCase().includes('venus')) {
          setNormalizedStrategy('Venus');
          setDetailsShowing('strategy');
        }
      }
    },
    [normalizedStrategy]
  );

  const details = useMemo(() => {
    switch (detailsShowing) {
      case 'strategy':
        return (
          <>
            <Spacer axis="vertical" size={30} />
            <S.APRDetailsTitle>
              <S.InfoIcon></S.InfoIcon>
              <Spacer axis="horizontal" size={5} />
              {normalizedStrategy} Strategy details:
            </S.APRDetailsTitle>
            <S.APRDetailsContent>
              {normalizedSupplyRateAPR !== undefined && (
                <S.NormalizedAPR>
                  Normalized Supply Rate APY: {normalizedSupplyRateAPR}
                </S.NormalizedAPR>
              )}
              <Spacer axis="vertical" size={5} />
              {normalizedBonusAPR !== undefined && (
                <S.NormalizedAPR>
                  Normalized Bonus APY: {normalizedBonusAPR}
                </S.NormalizedAPR>
              )}
              <Spacer axis="vertical" size={15} />
              The {normalizedStrategy} Strategy Supply Rate and Bonus APY quoted
              are different from the rate you see on {normalizedStrategy}. This
              is due to the difference in how annualized rate is calculated from
              the supply rate per block. (An assumption of average block time is
              used, and also daily compounding is assumed in{' '}
              {normalizedStrategy}'s calculation method).
              {normalizedStrategy === 'Compound' && (
                <>
                  <br />
                  <br />
                  For details see{' '}
                  <a
                    href="https://compound.finance/docs#protocol-math"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://compound.finance/docs#protocol-math
                  </a>
                  .
                </>
              )}
              {normalizedStrategy === 'Venus' && (
                <>
                  <br />
                  <br />
                  For details see{' '}
                  <a
                    href="https://docs.venus.io/docs/getstarted#protocol-math"
                    target="_blank"
                    rel="noreferrer"
                  >
                    https://docs.venus.io/docs/getstarted#protocol-math
                  </a>
                  .
                </>
              )}
            </S.APRDetailsContent>
          </>
        );
      case 'apr':
        return (
          <>
            <Spacer axis="vertical" size={30} />
            <S.APRDetailsTitle>
              <S.InfoIcon></S.InfoIcon>
              <Spacer axis="horizontal" size={5} />
              Indicative APY - APY after rebasing today
            </S.APRDetailsTitle>
            <S.TitleInstantApr>
              Instant APY - APY as per deposit before rebasing today
            </S.TitleInstantApr>
            <S.APRDetailsContent>
              Deposit is not deployed on DeFi protocol instantly but is put on
              vault contract. Flurry Protocol will call the vault contract to
              deploy the fund everyday when rebasing occurs. <br /> Hence the
              Instant APY is different from the Indicative APY, which is the APY
              after rebasing occurs.
            </S.APRDetailsContent>
          </>
        );
      default:
        return <></>;
    }
  }, [
    detailsShowing,
    normalizedBonusAPR,
    normalizedStrategy,
    normalizedSupplyRateAPR,
  ]);

  if (!show || !contracts) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.Popup ref={ref}>
        <S.Header>
          <S.Title>{tokenName} APY details</S.Title>
          <S.CloseBtn onClick={() => onClose()} />
        </S.Header>
        <Spacer axis="vertical" size={15} />
        <S.Body>
          <S.APRRow>
            <S.RowHeader></S.RowHeader>
            <S.RowHeader>Amount</S.RowHeader>
            <S.RowHeader isDetail={true}>Supply Rate APY</S.RowHeader>
            <S.RowHeader isDetail={true}>Bonus APY</S.RowHeader>
            <S.RowHeader>APY</S.RowHeader>
          </S.APRRow>
          {tokenAprs.totalCash && (
            <S.APRRow>
              <S.RowLabel>{`${stablecoinName} Cash`}</S.RowLabel>
              <S.RowAmount>
                {formatBNToString(tokenAprs.totalCash, 0, true, ',')}
              </S.RowAmount>
              <S.RowPercentage></S.RowPercentage>
              <S.RowPercentage></S.RowPercentage>
              <S.RowPercentageTotal></S.RowPercentageTotal>
            </S.APRRow>
          )}
          {strategiesAPR.map((e) => {
            const isNormalizedStrategy =
              e.key.toLowerCase().includes('compound') ||
              e.key.toLowerCase().includes('cream') ||
              e.key.toLowerCase().includes('venus');
            return (
              <S.APRRow key={e.key}>
                <S.RowLabel>{e.label}</S.RowLabel>
                <S.RowAmount>
                  {e.amount !== undefined
                    ? formatBNToString(e.amount, 0, true, ',')
                    : '0'}
                </S.RowAmount>
                <S.RowPercentage
                  isDetail={true}
                  isClickable={
                    isNormalizedStrategy &&
                    e.normalizedSupplyRateApr !== undefined &&
                    e.normalizedSupplyRateApr > 0
                  }
                  onClick={() => {
                    if (e.normalizedSupplyRateApr) {
                      switchDetailsOfNormalizedStrategy(
                        e,
                        isNormalizedStrategy
                      );
                    }
                  }}
                >
                  {e.supplyRateApr ? `${formatAPR(e.supplyRateApr, 2)}%` : '-'}
                  {isNormalizedStrategy && e.normalizedSupplyRateApr ? (
                    <S.InfoIcon></S.InfoIcon>
                  ) : (
                    <></>
                  )}
                </S.RowPercentage>
                <S.RowPercentage
                  isDetail={true}
                  isClickable={
                    isNormalizedStrategy &&
                    e.normalizedBonusApr !== undefined &&
                    e.normalizedBonusApr > 0
                  }
                  onClick={() => {
                    if (e.normalizedBonusApr) {
                      switchDetailsOfNormalizedStrategy(
                        e,
                        isNormalizedStrategy
                      );
                    }
                  }}
                >
                  {e.bonusApr ? `${formatAPR(e.bonusApr, 2)}%` : '-'}
                  {isNormalizedStrategy && e.normalizedBonusApr ? (
                    <S.InfoIcon></S.InfoIcon>
                  ) : (
                    <></>
                  )}
                </S.RowPercentage>
                <S.RowPercentageTotal>
                  {e.apr ? `${formatAPR(e.apr, 2)}%` : '-'}
                </S.RowPercentageTotal>
              </S.APRRow>
            );
          })}
          <Spacer axis="vertical" size={5} />
          {indicativeAPR && instantApr && (
            <S.APRRowTotal
              onClick={() => {
                if (detailsShowing === 'apr') {
                  setDetailsShowing('none');
                } else {
                  setDetailsShowing('apr');
                }
              }}
            >
              <S.APRDetailedTotalSubRow>
                <S.TotalLabel>Instant APY</S.TotalLabel>
                <S.RowLabel></S.RowLabel>
                <S.RowLabel isDetail={true}></S.RowLabel>
                <S.RowLabel isDetail={true}></S.RowLabel>
                <S.TotalAmount>
                  {instantApr.apr !== undefined
                    ? `${formatAPR(instantApr.apr, 2)}%`
                    : ''}
                </S.TotalAmount>
              </S.APRDetailedTotalSubRow>
              <Spacer axis="vertical" size={5} />
              <S.APRTotalSubRow isClickable>
                <S.TotalLabel>
                  Indicative APY
                  <InfoIcon />
                </S.TotalLabel>
                <S.RebasingPercentage isClickable>
                  {rebasePercentage?.apr !== undefined
                    ? `Rebasing %: ${formatAPR(rebasePercentage.apr, 2)}%`
                    : ''}
                </S.RebasingPercentage>
                <S.RowLabel isDetail={true}></S.RowLabel>
                <S.RowLabel isDetail={true}></S.RowLabel>
                <S.TotalAmount>
                  {indicativeAPR.apr !== undefined
                    ? `${formatAPR(indicativeAPR.apr, 2)}%`
                    : ''}
                </S.TotalAmount>
              </S.APRTotalSubRow>
            </S.APRRowTotal>
          )}
          {detailsShowing && details}
        </S.Body>
      </S.Popup>
    </S.Wrapper>
  );
};

export default APRDetailsPopup;
