import BigNumber from 'bignumber.js';
import { useAppSelector } from 'core/store/hooks';
import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import { formatAPR, formatBNToString } from 'utils';
import * as S from './SnowballStats.styles';

import StakingImg from 'assets/images/snowball/StakingIcon.png';
import EarnedImg from 'assets/images/snowball/EarnIcon.png';
import InterestRateImg from 'assets/images/snowball/InterestRateIcon.png';
import TotalFlurryStakedImg from 'assets/images/snowball/TotalFlurryStakedIcon.png';

const SnowballStats: React.FC = () => {
  const flurryTokenLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryToken
  );
  const flurryStakingBalanceLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryStakingBalance
  );
  const flurryStakingSupplyLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryStakingSupply
  );
  const user = useAppSelector((state) => state.auth.user);
  const balancesState = useAppSelector((state) => state.balances);
  const flurryStaking = useAppSelector(
    (state) => state.contracts.flurryStaking
  );
  const aprs = useAppSelector((state) => state.contracts.aprs);

  const flurryStakingBalance = useMemo(() => {
    if (user && flurryStaking) {
      const balance = balancesState.balances.find(
        (b) => b.currency === 'FlurryStakingBalance'
      );
      if (balance) {
        return balance.amount;
      }
    }
    return undefined;
  }, [user, flurryStaking, balancesState.balances]);

  const flurryStakingSupply = useMemo(() => {
    if (user && flurryStaking) {
      const balance = balancesState.balances.find(
        (b) => b.currency === 'FlurryStakingSupply'
      );
      if (balance) {
        return balance.amount;
      }
    }
    return undefined;
  }, [user, flurryStaking, balancesState.balances]);

  const userFlurryStakingRewardBalance = useMemo(() => {
    if (user && flurryStaking) {
      const balance = user.balances.find(
        (e) => e.currency === `${flurryStaking.key}Rewards`
      );
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [flurryStaking, user]);

  const flurryStakingApr = useMemo(() => {
    if (flurryStaking && aprs) {
      return aprs.flurryStaking;
    }
  }, [aprs, flurryStaking]);

  return (
    <S.Wrapper>
      <S.Stats>
        <S.StatWrapper>
          <S.InterestRateImage
            src={InterestRateImg}
            alt="Interest Rate image"
          />
          <S.StatText>
            <S.StatLabel>APY</S.StatLabel>
            {!flurryTokenLoading &&
            !flurryStakingBalanceLoading &&
            flurryStakingApr &&
            flurryStakingApr.apr !== undefined ? (
              <S.StatAmount>{`${formatAPR(
                flurryStakingApr.apr,
                2
              )}%`}</S.StatAmount>
            ) : (
              <Skeleton height={20} width={100} />
            )}
          </S.StatText>
        </S.StatWrapper>
        <S.StatWrapper>
          <S.InterestRateImage
            src={TotalFlurryStakedImg}
            alt="Total FLURRY Staked image"
          />
          <S.StatText>
            <S.StatLabel>Total Staked</S.StatLabel>
            {!flurryStakingSupplyLoading && flurryStakingSupply ? (
              <S.StatAmount>
                {formatBNToString(flurryStakingSupply, 0, false, ',')} FLURRY
              </S.StatAmount>
            ) : (
              <Skeleton height={20} width={100} />
            )}
          </S.StatText>
        </S.StatWrapper>
        <S.StatWrapper>
          <S.StakingImage src={StakingImg} alt="Staking Flurry image" />
          <S.StatText>
            <S.StatLabel>Currently staking</S.StatLabel>
            {!flurryStakingBalanceLoading && flurryStakingBalance ? (
              <S.StatAmount>
                {formatBNToString(flurryStakingBalance, 2, false, ',')} FLURRY
              </S.StatAmount>
            ) : (
              <Skeleton height={20} width={100} />
            )}
          </S.StatText>
        </S.StatWrapper>
        <S.StatWrapper>
          <S.EarnedImage src={EarnedImg} alt="Earned Flurry image" />
          <S.StatText>
            <S.StatLabel>Earned</S.StatLabel>
            {!flurryTokenLoading ? (
              <S.StatAmount>
                {formatBNToString(
                  userFlurryStakingRewardBalance,
                  2,
                  false,
                  ','
                )}{' '}
                FLURRY
              </S.StatAmount>
            ) : (
              <Skeleton height={20} width={100} />
            )}
          </S.StatText>
        </S.StatWrapper>
      </S.Stats>
    </S.Wrapper>
  );
};
export default SnowballStats;
