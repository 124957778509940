import React from 'react';
import * as S from './ErrorIcon.styles';

const ErrorIcon: React.FC = () => {
  return (
    <S.CheckMarkCircle>
      <S.Background />
      <S.CrossDraw />
    </S.CheckMarkCircle>
  );
};

export default ErrorIcon;
