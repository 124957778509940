import React, { useMemo } from 'react';
import styled from 'styled-components/macro';

type ProgressCircleProps = {
  sizePx: number;
  radius: number;
  strokeWidth: number;
  percentage: number;
};

const Wrapper = styled.div`
  .circle-background,
  .circle-progress {
    fill: none;
  }

  .circle-background {
    stroke: #ddd;
  }

  .circle-progress {
    stroke: #0dc8ff;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
`;

const ProgressCircle: React.FC<ProgressCircleProps> = ({
  sizePx,
  radius,
  strokeWidth,
  percentage,
}) => {
  const viewBox = useMemo(() => `0 0 ${sizePx} ${sizePx}`, [sizePx]);
  const dashArray = useMemo(() => radius * Math.PI * 2, [radius]);
  const dashOffset = useMemo(
    () => dashArray - (dashArray * percentage) / 100,
    [dashArray, percentage]
  );

  return (
    <Wrapper>
      <svg width={sizePx} height={sizePx} viewBox={viewBox}>
        <circle
          className="circle-background"
          cx={sizePx / 2}
          cy={sizePx / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
        />
        <circle
          className="circle-progress"
          cx={sizePx / 2}
          cy={sizePx / 2}
          r={radius}
          strokeWidth={`${strokeWidth}px`}
          transform={`rotate(-90 ${sizePx / 2} ${sizePx / 2})`}
          style={{
            strokeDasharray: dashArray,
            strokeDashoffset: dashOffset,
          }}
        />
      </svg>
    </Wrapper>
  );
};

export default ProgressCircle;
