import BigNumber from 'bignumber.js';

export const noExponents = (num: number | BigNumber): string => {
  let number = num.toString();
  const numberHasSign = number.startsWith('-') || number.startsWith('+');
  const sign = numberHasSign ? number[0] : '';
  number = numberHasSign ? number.replace(sign, '') : number;

  // if the number is in scientific notation remove it
  if (/\d+\.?\d*e[+-]*\d+/i.test(number)) {
    const zero = '0';
    const parts = number.toString().toLowerCase().split('e'); // split into coeff and exponent
    const e = parseFloat(parts.pop() ?? ''); // store the exponential part
    let l = Math.abs(e); // get the number of zeros
    const s = e / l;
    const coeffArray = parts[0].split('.');

    if (s === -1) {
      coeffArray[0] = Math.abs(parseFloat(coeffArray[0])).toString();
      number = `${zero}.${new Array(l).join(zero)}${coeffArray.join('')}`;
    } else {
      const dec = coeffArray[1];
      if (dec) l -= dec.length;
      number = coeffArray.join('') + new Array(l + 1).join(zero);
    }
  }

  return `${sign}${number}`;
};

export const formatToString = (
  num: number,
  precision: number,
  showEndZeros: boolean,
  thousandsSep?: string
): string => {
  let k = 10 ** precision;
  k = Math.round(num * k) / k;

  const s = (precision ? noExponents(k) : noExponents(Math.round(num)))
    .toString()
    .split('.');

  if (thousandsSep && s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
  }

  if ((s[1] || '').length < precision) {
    s[1] = s[1] || '';
    if (showEndZeros) {
      s[1] += new Array(precision - s[1].length + 1).join('0');
    }
  }
  return s[1] ? s.join('.') : s[0];
};

export const formatBNToString = (
  num: BigNumber,
  precision: number,
  showEndZeros: boolean,
  thousandsSep?: string
): string => {
  const str = (
    showEndZeros
      ? num.toFixed(precision)
      : num.dp(precision, BigNumber.ROUND_FLOOR)
  ).toString();

  if (thousandsSep) {
    const nb = str.split('.');
    if (nb[0].length > 3) {
      nb[0] = nb[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, thousandsSep);
      return nb[1] ? nb.join('.') : nb[0];
    }
  }

  return str;
};
export const BNWithThousandsSep = (
  num: BigNumber,
  thousandsSep?: string
): string => {
  const s = noExponents(num).split('.');
  let sep = ',';
  if (thousandsSep) {
    sep = thousandsSep;
  }

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  return s[1] ? s.join('.') : s[0];
};

export const withThousandsSep = (
  num: number,
  thousandsSep?: string
): string => {
  const s = noExponents(num).split('.');
  let sep = ',';
  if (thousandsSep) {
    sep = thousandsSep;
  }

  if (s[0].length > 3) {
    s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
  }

  return s[1] ? s.join('.') : s[0];
};

export const countDecimals = (num: number): number => {
  if (Math.floor(num.valueOf()) === num.valueOf()) return 0;
  return noExponents(num).split('.')[1].length || 0;
};

export const KFormaterBN = (num: BigNumber, digits: number): string => {
  const lookup = [
    { value: new BigNumber(1), symbol: '' },
    { value: new BigNumber(1e3), symbol: 'k' },
    { value: new BigNumber(1e6), symbol: 'M' },
    { value: new BigNumber(1e9), symbol: 'G' },
    { value: new BigNumber(1e12), symbol: 'T' },
    { value: new BigNumber(1e15), symbol: 'P' },
    { value: new BigNumber(1e18), symbol: 'E' },
  ];
  const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  const item = lookup
    .slice()
    .reverse()
    .find(function (e) {
      return num.gte(e.value);
    });
  return item
    ? num.div(item.value).toFixed(digits, 1).replace(rx, '$1') + item.symbol
    : '0';
};

export const roundingRateNumber = (num: number): string => {
  const MAX_DIGIS = 7,
    SIZE = 3;
  num = +num;
  const numberStr = num.toString();
  if (isNaN(num) || num <= 0) num = 0;
  if (num < 1e-18) num = 0;
  if (('' + Math.floor(num)).length >= MAX_DIGIS) {
    return Math.floor(num).toLocaleString();
  }

  let count0 = 0;
  for (const j of numberStr) {
    if (j === '.') continue;
    if (j === '0') count0++;
    else break;
  }

  const minDisplay = MAX_DIGIS - count0 < 4 ? 4 : MAX_DIGIS - count0;

  let precision;

  const numBig = new BigNumber(num);

  if (numBig.comparedTo(0) > 0 && numBig.comparedTo(0.0001) < 0) {
    precision = new BigNumber(num).toPrecision(4);
  } else {
    precision = num.toPrecision(num < 1 && num > 0 ? minDisplay : MAX_DIGIS);
    precision = (parseFloat(precision) * 1).toString();
  }

  const arr = precision.split('.'),
    intPart = arr[0];
  let i = intPart.length % SIZE || SIZE,
    result = intPart.substr(0, i);

  for (; i < intPart.length; i += SIZE) {
    result += intPart.substr(i, SIZE);
  }
  if (arr[1]) {
    result += '.' + arr[1];
  }
  return result;
};
