import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Notification } from 'components';
import * as S from './SinglePopupNotification.styles';
import { showUserModal } from 'core/store/ui/ui';

type SinglePopupNotificationProps = {
  hideNotif: () => void;
  notifLifetimeS: number;
  transactionId?: string;
};

const SinglePopupNotification: React.FC<SinglePopupNotificationProps> = ({
  hideNotif,
  notifLifetimeS,
  transactionId,
}) => {
  const [initialTime, setInitialTime] = useState(notifLifetimeS * 1000);
  const network = useAppSelector((state) => state.auth.network);
  const transactions = useAppSelector((state) => state.transactions.list);

  const dispatch = useAppDispatch();

  const onNotificationClick = useCallback(() => {
    dispatch(showUserModal(true));
    hideNotif();
  }, [dispatch, hideNotif]);

  const transaction = useMemo(
    () => transactions.find((t) => t.id === transactionId),
    [transactionId, transactions]
  );

  useEffect(() => {
    setInitialTime(notifLifetimeS * 1000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transactionId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setInitialTime((t) => t - 100);
    }, 100);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (initialTime === 0) {
      hideNotif();
    }
  }, [hideNotif, initialTime, notifLifetimeS]);

  if (!transaction) {
    return <></>;
  }

  return (
    <S.Wrapper>
      <S.TitleWrapper>
        <S.SingleNotifTitle>New transaction!</S.SingleNotifTitle>
        <S.ButtonsWrapper>
          <S.ShowNotifsBtn onClick={() => onNotificationClick()}>
            Show all
            <S.EyeIcon />
          </S.ShowNotifsBtn>
          <S.CloseBtn onClick={() => hideNotif()} />
        </S.ButtonsWrapper>
      </S.TitleWrapper>
      <Notification transaction={transaction} network={network} />
      {/* <S.BottomTimerLineWrapper>
        <S.BottomTimerLine
          percent={(initialTime / (notifLifetimeS * 1000)) * 100}
        />
      </S.BottomTimerLineWrapper> */}
    </S.Wrapper>
  );
};

export default SinglePopupNotification;
