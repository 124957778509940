import { useOnClickOutside } from 'components';
import { DropdownGroup, CurrencyOption } from 'core/types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import * as S from './CurrenciesDropdown.styles';

interface CurrenciesDropdownProps {
  currencies?: CurrencyOption[];
  selectedCurrency?: CurrencyOption;
  onCurrencyChange?: (ccy: CurrencyOption) => void;
}

type DropdownItem = {
  group?: DropdownGroup;
  items?: CurrencyOption[];
  aloneItem?: CurrencyOption;
};

const CurrenciesDropdown: React.FC<CurrenciesDropdownProps> = ({
  currencies,
  selectedCurrency,
  onCurrencyChange,
}) => {
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [openedGroup, setOpenedGroup] = useState<DropdownGroup | undefined>(
    undefined
  );

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsDropdownActive(false));

  const onDropdownChange = (ccy: CurrencyOption) => {
    setIsDropdownActive(false);
    if (onCurrencyChange) {
      onCurrencyChange(ccy);
    }
  };

  const dropdownItems = useMemo((): DropdownItem[] => {
    const result: DropdownItem[] = [];
    if (!currencies) return result;

    for (const currency of currencies) {
      if (!currency.group) {
        result.push({ aloneItem: currency });
        continue;
      }
      let currentGroupIdx = result.findIndex(
        (r) => r.group?.label === currency.group?.label
      );

      // Create new group
      if (currentGroupIdx === -1) {
        currentGroupIdx =
          result.push({
            group: currency.group,
            items: [],
          }) - 1;
      }

      result[currentGroupIdx].items?.push(currency);
    }
    return result;
  }, [currencies]);

  useEffect(() => {
    if (selectedCurrency) {
      const currentItem = dropdownItems.find(
        (g) => g.items && g.items.indexOf(selectedCurrency) !== -1
      );
      if (currentItem) {
        setOpenedGroup(currentItem.group);
      }
    }
  }, [currencies, dropdownItems, selectedCurrency]);

  if (!currencies) return <></>;

  return (
    <S.DropdownWrapper ref={ref}>
      {selectedCurrency && (
        <S.DropdownBtn
          type="button"
          onClick={() => setIsDropdownActive(!isDropdownActive)}
        >
          {selectedCurrency.icon && (
            <S.CurrencyLogo
              src={selectedCurrency.icon}
              alt={`${selectedCurrency.label} logo`}
            />
          )}
          <S.SelectedCurrencyLabel>
            {selectedCurrency.label}
          </S.SelectedCurrencyLabel>
          <S.Chevron $isup={isDropdownActive} />
        </S.DropdownBtn>
      )}
      <S.DropdownList active={isDropdownActive}>
        {dropdownItems.map((c) => {
          if (c.aloneItem) {
            const item = c.aloneItem;
            return (
              <S.DropdownItem
                active={item === selectedCurrency}
                disabled={item.disabled}
                onClick={() => {
                  if (!item.disabled && onDropdownChange)
                    onDropdownChange(item);
                }}
                key={item.value}
              >
                {item.icon && (
                  <S.CurrencyLogo src={item.icon} alt={`${item.label} logo`} />
                )}
                <S.CurrencyLabel>{item.label}</S.CurrencyLabel>
              </S.DropdownItem>
            );
          }
          if (c.group && c.items && c.items.length > 0) {
            const { group, items } = c;

            return (
              <S.DropdownGroupWrapper key={`group-${group.label}`}>
                <S.GroupItem
                  active={
                    selectedCurrency && items.indexOf(selectedCurrency) !== -1
                  }
                  onClick={() => {
                    if (openedGroup === group) {
                      setOpenedGroup(undefined);
                    } else {
                      setOpenedGroup(group);
                    }
                  }}
                >
                  {group.icon && (
                    <S.CurrencyLogo
                      src={group.icon}
                      alt={`${group.label} logo`}
                    />
                  )}
                  <S.CurrencyLabel>{group.label}</S.CurrencyLabel>
                  <S.GroupChevron $isopen={group === openedGroup} />
                </S.GroupItem>
                <S.GroupItems isOpen={group === openedGroup}>
                  {items.map((item) => {
                    return (
                      <S.DropdownItemOfGroup
                        active={item === selectedCurrency}
                        disabled={item.disabled}
                        onClick={() => {
                          if (!item.disabled && onDropdownChange)
                            onDropdownChange(item);
                        }}
                        key={item.value}
                      >
                        {item.icon && (
                          <S.CurrencyLogo
                            src={item.icon}
                            alt={`${item.label} logo`}
                          />
                        )}
                        <S.CurrencyLabel>{item.label}</S.CurrencyLabel>
                      </S.DropdownItemOfGroup>
                    );
                  })}
                </S.GroupItems>
              </S.DropdownGroupWrapper>
            );
          }
          return <></>;
        })}
      </S.DropdownList>
    </S.DropdownWrapper>
  );
};

export default CurrenciesDropdown;
