import React, { useState, useEffect, useMemo } from 'react';
import * as S from './EarnHeader.styles';
import InterestRate from './InterestRate';
import { useAppSelector } from 'core/store/hooks';
// import APRChart from './APRChart';
// import Skeleton from 'react-loading-skeleton';

import { BridgeButton, EtherscanLink, StatTable } from 'components';
import { ciEquals } from 'utils';

import DetailsDrawer, { TokenDetails } from './DetailsDrawer/DetailsDrawer';
import { useRhoTokens } from 'core/hooks';

const EarnHeader: React.FC = () => {
  const aprs = useAppSelector((state) => state.contracts.aprs);
  const user = useAppSelector((state) => state.auth.user);
  const balancesState = useAppSelector((state) => state.balances);
  // const network = useAppSelector((state) => state.auth.network);
  const isBridgeableNetwork = useMemo(() => {
    return false;
    // if (!network) return false;
    // const bridgeableNetworks = getBridgeableNetworks();
    // return bridgeableNetworks.indexOf(network) !== -1;
  }, []);

  const rhoTokenContracts = useRhoTokens();
  const rhotokensLoading = useAppSelector(
    (state) => state.balances.loadingState.rhoTokenBalance
  );
  const loadingContracts = useAppSelector((state) => state.contracts.loading);
  const loading = useMemo(
    () => rhotokensLoading || loadingContracts,
    [loadingContracts, rhotokensLoading]
  );
  const [rhoTokensList, setRhoTokensList] = useState<TokenDetails[]>([]);

  useEffect(() => {
    const res: TokenDetails[] = [];
    for (const c of rhoTokenContracts) {
      const s = balancesState.balances.find(
        (el) => el.currency === `${c.label}Supply`
      );
      const b =
        user && balancesState.balances.find((el) => el.currency === c.value);
      const rewardApr = aprs?.rhoTokens?.find((el) =>
        ciEquals(el.tokenAddress, c.value)
      )?.reward;

      res.push({
        key: c.value,
        logo: c.icon ?? '',
        label: c.label,
        balance: b ? b.amount : undefined,
        address: c.value,
        rewardApr: rewardApr?.apr,
        supply: s?.amount,
      });
    }

    setRhoTokensList(res);
  }, [aprs, rhoTokenContracts, balancesState, user]);

  return (
    <S.EarnHeaderWrapper>
      <S.BalanceWrapper>
        <StatTable
          id="APR"
          header={<S.BalanceLabel>Your token balances</S.BalanceLabel>}
          values={rhoTokensList.map((token) => {
            if (loading) {
              return { value: <S.LoadingRow /> };
            }
            return {
              value: (
                <S.BalanceRow
                  key={`balance_${token.key}`}
                  disabled={token.disabled}
                  withBridgeBtn={isBridgeableNetwork}
                >
                  <S.RhoLogo src={token.logo} />
                  <S.AmountBalance
                    value={token.balance}
                    currency={token.label}
                    currencyKey={token.key}
                  />
                  <S.TokenEtherscanLink>
                    <EtherscanLink
                      contractAddress={token.address}
                      disabled={token.disabled}
                    />
                  </S.TokenEtherscanLink>
                  {isBridgeableNetwork && (
                    <BridgeButton
                      tokenAddress={token.address}
                      tokenLabel={token.label}
                      disabled={token.disabled}
                    />
                  )}
                </S.BalanceRow>
              ),
            };
          })}
        />
        <StatTable
          id="APRToken"
          header="APY"
          tooltip="To see a detailed view<br/>of each APY, click on it"
          values={rhoTokensList.map((token) => {
            return {
              disabled: token.disabled,
              value: (
                <InterestRate
                  tokenName={token.label}
                  tokenAddress={token.address}
                  disabled={token.disabled}
                />
              ),
            };
          })}
        />
      </S.BalanceWrapper>
      <DetailsDrawer tokens={rhoTokensList} />
      {/* <S.ChartWrapper>
                <S.ComingSoon>Coming Soon</S.ComingSoon>
                <S.ChartTitle>Daily APY</S.ChartTitle>
                <S.ChartContainer>
                {!loadingContracts ? <APRChart /> : <Skeleton height={120} />}
                </S.ChartContainer>
            </S.ChartWrapper> */}
    </S.EarnHeaderWrapper>
  );
};

export default EarnHeader;
