export const shortenStringInMiddle = (str: string): string => {
  if (str.length > 10) {
    return `${str.substr(0, 6)}...${str.substr(str.length - 4, str.length)}`;
  }
  return str;
};
export const longShortenStringInMiddle = (str: string): string => {
  if (str.length > 18) {
    return `${str.substr(0, 10)}...${str.substr(str.length - 8, str.length)}`;
  }
  return str;
};

export const nthIndex = (str: string, pat: string, n: number): number => {
  const L = str.length;
  let i = -1;
  while (n-- && i++ < L) {
    i = str.indexOf(pat, i);
    if (i < 0) break;
  }
  return i;
};

export const getArrayNumberFromStr = (
  arrayStr: string | undefined
): number[] | undefined => {
  if (!arrayStr) return undefined;
  if (arrayStr === '[]') return [];
  return arrayStr
    .substr(1, arrayStr.length - 2)
    .split(', ')
    .map((x) => +x);
};

export const ciEquals = (
  a: string | undefined | null,
  b: string | undefined | null
): boolean => {
  if (!a || !b) {
    return false;
  }
  return typeof a === 'string' && typeof b === 'string'
    ? a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0
    : a === b;
};
