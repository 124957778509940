import ReactGA from 'react-ga4';

type Categories =
  | 'Earn'
  | 'Snowball'
  | 'Farms'
  | 'Bridge'
  | 'Rewards'
  | 'Navbar'
  | 'User'
  | 'Compensation';

type Actions =
  | 'Connect'
  | 'Disconnect'
  | 'Claim All'
  | 'Show RhoToken Details'
  | 'Show RhoToken APR Details'
  | 'Open Farm Row'
  | 'Close Farm Row'
  | 'Approve'
  | 'Stake'
  | 'Unstake'
  | 'Convert'
  | 'Redeem'
  | 'Swap'
  | 'Show User Modal'
  | 'Hide User Modal'
  | 'Show Claim Rewards Popup'
  | 'Switch Network'
  | 'Compensation Link'
  | 'Compensate';

export const sendGAEvent = (
  category: Categories,
  action: Actions,
  label?: string
): void => {
  if (window._env_.GOOGLE_ANALYTICS_TRACKING_ID) {
    ReactGA.event({
      category,
      action,
      label,
    });
  }
};
