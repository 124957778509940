import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { DateRange } from 'core/types';
import { TokensChainData } from 'core/types/subgraph';
import { getTime, startOfHour } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { getPeriodTimestamps } from 'utils';
import { getFlurryStaked } from '../thunks/getFlurryStaked';

export const useFlurryStaked = (
  period: DateRange
): {
  data: TokensChainData;
  isLoading: boolean;
  error?: string;
} => {
  const flurryData = useAppSelector((state) => state.subgraph.flurry.staked);
  const isLoading = useAppSelector(
    (state) => state.subgraph.loadingStates.flurry.staked
  );
  const error = useAppSelector((state) => state.subgraph.errors.flurry.staked);

  const dispatch = useAppDispatch();

  useEffect(() => {
    let timestamps: number[] = [];
    let currentTs = getTime(startOfHour(Date.now()));
    if (Date.now() - currentTs <= 300000) {
      // If latest hour is less than 5min ago, then we take the previous hour
      // This is in case the graph has not synchronized with latest data
      currentTs = currentTs - 3.6e6;
    }

    switch (period) {
      case '24h':
        timestamps = getPeriodTimestamps(currentTs, 'hour', 24, true);
        break;
      case '7day':
        timestamps = getPeriodTimestamps(currentTs, 'day', 7, true);
        break;
      case '30day':
        timestamps = getPeriodTimestamps(currentTs, 'day', 30, true);
        break;
      case '90day':
        timestamps = getPeriodTimestamps(currentTs, 'day', 90, true);
        break;
    }

    dispatch(getFlurryStaked({ chains: [1, 56, 137], timestamps }));
  }, [dispatch, period]);

  return useMemo(() => {
    const result: TokensChainData = {};
    for (const chain of Object.keys(flurryData).map(Number)) {
      const values = flurryData[chain];

      for (const ts of Object.keys(values).map(Number)) {
        const tokenValues = values[ts];
        for (const token of Object.keys(tokenValues)) {
          if (result[ts]) {
            const newValue = +result[ts][token] + +tokenValues[token];
            result[ts] = { ...result[ts], [token]: newValue };
          } else {
            result[ts] = tokenValues;
          }
        }
      }
    }

    return {
      data: result,
      isLoading,
      error,
    };
  }, [error, isLoading, flurryData]);
};
