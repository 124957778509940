import { FaChevronUp } from 'react-icons/fa';
import styled from 'styled-components/macro';

export const NetworkDropdownWrapper = styled.div`
  position: relative;
  width: calc(100% - 1rem);
  padding: 0.5rem;
  border-top: 1px solid #00000021;
`;

export const NoNetwork = styled.div`
  width: calc(100% - 1rem);
  padding: 1.5rem 0.5rem;
  color: #707070;
  text-align: center;
  font-size: 0.6rem;
`;

export const Option = styled.button`
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem 0.5rem;
`;

export const BodyOption = styled(Option)`
  background-color: white;
  transition: background-color 0.2s linear;
  border: none;
  outline: none;
  border-radius: 0.5rem;
  &:hover {
    background-color: #f7f6fb;
  }
`;

export const SelectedOption = styled(Option)<{ opened: boolean }>`
  border-radius: 0.5rem;
  background-color: #f7f6fb;
  transition: border 0.2s linear;
  box-sizing: border-box;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid #efedf7;
  }
`;
export const Chevron = styled(FaChevronUp)<{ $isup: boolean }>`
  margin-left: auto;
  transform: ${(props) => (!props.$isup ? 'rotate(180deg)' : 'none')};
  transition: all 0.2s ease-in-out;
  justify-self: flex-end;
`;

export const Options = styled.div`
  position: absolute;
  background-color: white;
  width: calc(100% - 2rem);
  bottom: calc(3.5rem);
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
  border-radius: 0.5rem;
  max-height: 10rem;
  overflow: auto;
`;

export const Label = styled.div`
  font-size: 0.6rem;
  font-weight: bold;
  padding: 0.2rem 0.2rem 0.3rem 0.2rem;
  color: #575757;
`;

export const NetworkImg = styled.img`
  width: 25px;
  height: auto;
`;
export const NetworkLabel = styled.div``;
