import styled, { css } from 'styled-components/macro';
import { Image, Card } from 'components';
import { down, up } from 'styled-breakpoints';
import { Link } from 'react-router-dom';
import { HiInformationCircle } from 'react-icons/hi';

export const FarmsWrapper = styled.div`
  padding-top: 150px;
  ${down('xl')} {
    padding-top: 100px;
  }
  ${down('md')} {
    padding-top: 70px;
  }
`;

export const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  width: 100%;
  height: auto;
  max-height: 250px;
  ${up('xl')} {
    max-height: 350px;
  }
`;

export const FarmsCard = styled(Card)`
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: calc(85% - 4rem);
  padding: 1rem 2rem 2rem 2rem;
  max-height: 650px;
  ${up('xl')} {
    width: calc(1100px - 4rem);
  }
  ${up('xxl')} {
    width: calc(1500px - 4rem);
    max-height: 800px;
  }
  ${up('xxxl')} {
    width: calc(2000px - 4rem);
  }
  ${down('md')} {
    padding: 1rem 0.5rem 2rem 0.5rem;
    width: calc(100% - 1rem);
    border-radius: 0;
  }
`;

export const Title = styled.h1`
  font-size: 1.2rem;
  font-weight: normal;
  margin-bottom: 0;
`;
export const SubTitle = styled.h2`
  opacity: 0.5;
  font-size: 0.8rem;
  font-weight: normal;
`;
export const CompensationLinkWrapper = styled.div`
  padding: 0.8rem 1rem;
  border-radius: 15px;
  background-color: #5959ff;
  display: flex;
  align-items: center;
  width: fit-content;
`;
export const CompensationLink = styled(Link)`
  opacity: 1;
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  margin-left: 0.7rem;
  text-decoration: none;
`;

export const InfoIcon = styled(HiInformationCircle)`
  color: white;
  font-size: 1rem;
`;

export const FarmColumnLayout = css`
  grid-template-columns: 1fr 3.5fr 2fr 2fr 2fr 2fr 0.5fr;
  ${down('sm')} {
    grid-template-columns: 0.6fr 2fr 2fr 2fr 0.5fr;
  }
  ${down('xs')} {
    grid-template-columns: 0.6fr 1fr 0.5fr;
  }
`;

export const ComingSoonWrapper = styled.div`
  grid-column: 1 / 4;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
