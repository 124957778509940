import React, { useCallback, useMemo, useState } from 'react';
import { Spacer, PriceInput, ShortenBalance } from 'components';
import * as S from './EarnForm.styles';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { useEffect } from 'react';
import BigNumber from 'bignumber.js';
import Skeleton from 'react-loading-skeleton';

import { connectToProvider } from 'core/store/auth/thunks/connectToProvider';
import { noExponents, sendGAEvent } from 'utils';
import { useRhoTokens, useStablecoins } from 'core/hooks';
import { CurrencyOption, User } from 'core/types';
import { redeem } from 'core/store/contracts/thunks/redeem';

type SubmitButtonProps = {
  onFormSubmit: () => void;
  hasValidAmount: boolean;
  user: User | undefined;
};

const DECIMAL_PRECISION = 6;

const formatAmountToPrecision = (amount: BigNumber) => {
  return amount.dp(DECIMAL_PRECISION, BigNumber.ROUND_FLOOR);
};

const SubmitButton: React.FC<SubmitButtonProps> = ({
  onFormSubmit,
  hasValidAmount,
  user,
}) => {
  const dispatch = useAppDispatch();

  if (user) {
    if (!hasValidAmount) {
      return (
        <S.SubmitButton type="button" disabled>
          Enter an amount
        </S.SubmitButton>
      );
    }

    return (
      <S.SubmitButton type="button" onClick={() => onFormSubmit()}>
        Redeem Now
      </S.SubmitButton>
    );
  }

  return (
    <S.ConnectButton
      onClick={() => {
        sendGAEvent('Earn', 'Connect');
        dispatch(connectToProvider());
      }}
    >
      Connect Wallet
    </S.ConnectButton>
  );
};

const RedeemForm: React.FC = () => {
  const dispatch = useAppDispatch();

  const [loaded, setLoaded] = useState(false);

  const stablecoinsLoading = useAppSelector(
    (state) => state.auth.balancesStates.stablecoins
  );
  const rhotokensLoading = useAppSelector(
    (state) => state.balances.loadingState.rhoTokenBalance
  );

  const user = useAppSelector((state) => state.auth.user);

  const balances = useAppSelector((state) => state.balances.balances);
  const rhoTokens = useRhoTokens();
  const stablecoins = useStablecoins();

  // Form state
  const [tokenState, setTokenState] = useState({
    stablecoinCurrency: stablecoins[0],
    rhoTokenCurrency: rhoTokens[0],
  });
  const [amountState, setAmountState] = useState({
    stablecoinAmount: '',
    rhoTokenAmount: '',
  });
  const [formInfoState, setFormInfoState] = useState<{
    hasValidAmount: boolean;
    error: string | undefined;
  }>({
    hasValidAmount: false,
    error: undefined,
  });

  // User's rhotoken balance
  const rhoTokenBalance = useMemo(() => {
    if (user && tokenState.rhoTokenCurrency) {
      const balance =
        user &&
        balances.find((b) => b.currency === tokenState.rhoTokenCurrency.value);
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [balances, tokenState.rhoTokenCurrency, user]);

  const isLoadingData = useMemo(
    () => stablecoinsLoading || rhotokensLoading || !rhoTokenBalance,
    [rhotokensLoading, rhoTokenBalance, stablecoinsLoading]
  );

  // Tell if amount submitted is valid, if not will set error and return false
  const isAmountValid = useCallback(
    (amt: BigNumber): boolean => {
      if (amt.gt(rhoTokenBalance ?? new BigNumber(-1))) {
        setFormInfoState({
          ...formInfoState,
          error: `You do not have enough ${tokenState.rhoTokenCurrency?.label} to convert that much.`,
        });
        return false;
      } else if (amt.isZero() || amt.isNegative()) {
        setFormInfoState({
          ...formInfoState,
          error: `${tokenState.rhoTokenCurrency?.label} amount should be greater than zero.`,
        });
        return false;
      }
      return true;
    },
    [formInfoState, rhoTokenBalance, tokenState.rhoTokenCurrency]
  );

  // Function ran when the form is submitted
  const onSubmit = useCallback(() => {
    if (!user) return;

    const rhotokenAmt = new BigNumber(amountState.stablecoinAmount);
    if (isAmountValid(rhotokenAmt)) {
      setFormInfoState({ ...formInfoState, error: undefined });
      sendGAEvent('Earn', 'Redeem', tokenState.rhoTokenCurrency.label);
      dispatch(
        redeem({
          token: tokenState.stablecoinCurrency.value,
          tokenAmount: new BigNumber(amountState.stablecoinAmount),
          rhoToken: tokenState.rhoTokenCurrency.value,
          rhotokenAmount: rhotokenAmt,
        })
      );
    }
  }, [amountState, dispatch, formInfoState, isAmountValid, tokenState, user]);

  // Handles the changes in the input value
  const handleInputChange = useCallback(
    (amountStr: string | BigNumber) => {
      if (!amountStr || amountStr === '') {
        setAmountState({
          stablecoinAmount: '',
          rhoTokenAmount: '',
        });
        setFormInfoState({
          ...formInfoState,
          hasValidAmount: false,
        });
      } else {
        const currentAmount = new BigNumber(amountStr);
        setAmountState({
          stablecoinAmount: noExponents(currentAmount),
          rhoTokenAmount: noExponents(currentAmount),
        });
        setFormInfoState({
          ...formInfoState,
          hasValidAmount: !currentAmount.isZero(),
        });
      }
    },
    [formInfoState]
  );

  // Handles changes of the stablecoin dropdown
  const stablecoinTokenChanged = useCallback(
    (stablecoin: CurrencyOption) => {
      if (!stablecoin) return;
      const rhoToken =
        rhoTokens.find((t) => t.value === stablecoin.linkedToken) ??
        rhoTokens[0];
      if (rhoToken) {
        setTokenState({
          stablecoinCurrency: stablecoin,
          rhoTokenCurrency: rhoToken,
        });
        setFormInfoState({ ...formInfoState, error: undefined });

        const newAmount =
          user && balances.find((b) => b.currency === rhoToken.value);
        if (newAmount) {
          handleInputChange(
            formatAmountToPrecision(newAmount.amount).toString()
          );
        }
      }
    },
    [balances, formInfoState, handleInputChange, rhoTokens, user]
  );

  // Handles changes of the rhoToken dropdown
  const rhoTokenChanged = useCallback(
    (rhoToken: CurrencyOption) => {
      if (!rhoToken) return;
      const stablecoin = stablecoins.find(
        (t) => t.linkedToken === rhoToken.value
      );
      if (stablecoin) {
        setTokenState({
          stablecoinCurrency: stablecoin,
          rhoTokenCurrency: rhoToken,
        });
        setFormInfoState({ ...formInfoState, error: undefined });

        const newAmount =
          user && balances.find((b) => b.currency === rhoToken.value);
        if (newAmount) {
          handleInputChange(
            formatAmountToPrecision(newAmount.amount).toString()
          );
          if (!loaded) setLoaded(true);
        }
      }
    },
    [balances, formInfoState, handleInputChange, loaded, stablecoins, user]
  );

  // First update to set default values
  useEffect(() => {
    if (!loaded && !stablecoinsLoading && !rhotokensLoading) {
      rhoTokenChanged(rhoTokens[0]);
      if (tokenState.stablecoinCurrency && tokenState.rhoTokenCurrency) {
        setLoaded(true);
      }
    }
  }, [
    loaded,
    rhoTokenChanged,
    rhoTokens,
    rhotokensLoading,
    stablecoinsLoading,
    tokenState.rhoTokenCurrency,
    tokenState.stablecoinCurrency,
  ]);

  return (
    <S.FormWrapper>
      <S.InputLabel>Convert</S.InputLabel>
      {!isLoadingData ? (
        <>
          <PriceInput
            currencies={rhoTokens}
            currentCurrencyValue={tokenState.rhoTokenCurrency?.value}
            inputValue={amountState.rhoTokenAmount}
            onAmountChange={(v) => handleInputChange(v)}
            onCurrencyChange={(token: CurrencyOption) => {
              rhoTokenChanged(token);
            }}
            onMaxBtnClicked={() => {
              handleInputChange(noExponents(rhoTokenBalance));
            }}
            maxDecimalPlace={tokenState.rhoTokenCurrency?.decimals}
          />
          <ShortenBalance
            label="Available balance:"
            balanceValue={rhoTokenBalance}
            decimalPrecision={DECIMAL_PRECISION}
            currency={tokenState.rhoTokenCurrency?.label}
          />
          {formInfoState.error && (
            <S.InputError>{formInfoState.error ?? ''}</S.InputError>
          )}
        </>
      ) : (
        <Skeleton height={55} />
      )}
      <Spacer axis="vertical" size={15} />
      <S.InputLabel>Into</S.InputLabel>
      {!isLoadingData ? (
        <PriceInput
          currencies={stablecoins}
          currentCurrencyValue={tokenState.stablecoinCurrency?.value}
          inputValue={amountState.stablecoinAmount}
          onAmountChange={(v) => handleInputChange(v)}
          onCurrencyChange={(token: CurrencyOption) => {
            stablecoinTokenChanged(token);
          }}
          maxDecimalPlace={tokenState.rhoTokenCurrency?.decimals}
        />
      ) : (
        <Skeleton height={55} />
      )}
      <Spacer axis="vertical" size={20} />
      <SubmitButton
        onFormSubmit={() => onSubmit()}
        hasValidAmount={formInfoState.hasValidAmount}
        user={user}
      />
    </S.FormWrapper>
  );
};

export default RedeemForm;
