import { useAppSelector } from 'core/store/hooks';
import { CurrencyOption } from 'core/types';
import { useState, useEffect } from 'react';
// import { TokenContractsLogos } from 'core/config/contracts';
import FlurryTokenIcon from 'assets/images/logos/flurry_icon_only.png';

/// This hook will give an up to date list of bridgeable tokens
export const useBridgeTokens = (): CurrencyOption[] => {
  const [tokenOptions, setTokenOptions] = useState<CurrencyOption[]>([]);

  // const rhoTokens = useAppSelector((state) => state.contracts.rhoTokens);
  const flurryToken = useAppSelector((state) => state.contracts.flurryToken);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    // Building the list of bridgeable tokens that can be shown to the user
    const res: CurrencyOption[] = [];

    if (!flurryToken) {
      res.push({
        value: 'Flurry',
        label: 'FLURRY',
        decimals: 18,
        icon: FlurryTokenIcon,
      });
    } else {
      res.push({
        value: flurryToken.address,
        label: flurryToken.label,
        decimals: flurryToken.decimals,
        icon: FlurryTokenIcon,
      });
    }

    // for (const l of TokenContractsLogos) {
    //   if (!user) {
    //     res.push({
    //       value: l.key,
    //       label: l.key,
    //       icon: l.logo,
    //     });
    //   } else {
    //     const currentToken = rhoTokens.find((t) => t.key === l.key);
    //     if (currentToken) {
    //       res.push({
    //         value: currentToken.address,
    //         label: currentToken.label,
    //         decimals: currentToken.decimals,
    //         icon: l.logo,
    //       });
    //     }
    //   }
    // }

    setTokenOptions(res);
  }, [flurryToken, user]);

  return tokenOptions;
};
