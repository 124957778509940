import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { UserBalance } from '../../types';
import { initFlurryStakingBalance } from './thunks/initFlurryStakingBalance';
import { initFlurryStakingSupply } from './thunks/initFlurryStakingSupply';
import { updateRhoBalances } from './thunks/updateRhoBalances';
import { updateStablecoinBalances } from './thunks/updateStablecoinBalances';
import { initRhoSupply } from './thunks/initRhoSupply';
import { updateOtherTokenBalances } from './thunks/updateOtherTokenBalances';
import { resetBalances } from './thunks/resetBalances';
import { updateBalances } from './thunks/updateBalances';
import { updateFlurryTokenBalance } from './thunks/updateFlurryTokenBalance';

export type BalancesState = {
  balances: UserBalance[];
  loadingState: {
    flurryStakingBalance: boolean;
    flurryStakingSupply: boolean;
    stablecoinBalance: boolean;
    rhoTokenBalance: boolean;
    rhoTokenSupply: boolean;
    flurryToken: boolean;
    tokenBalance: boolean;
  };
};

export const initialBalancesState: BalancesState = {
  balances: [],
  loadingState: {
    flurryStakingBalance: false,
    flurryStakingSupply: false,
    stablecoinBalance: false,
    rhoTokenBalance: false,
    rhoTokenSupply: false,
    flurryToken: true,
    tokenBalance: false,
  },
};

const balancesSlice = createSlice({
  name: 'balances',
  initialState: initialBalancesState,
  reducers: {
    onFlurryStakingBalanceEvent: (
      state,
      action: PayloadAction<UserBalance>
    ) => {
      const newBalance = action.payload;
      if (newBalance && !state.loadingState.flurryStakingBalance) {
        const idx = state.balances.findIndex(
          (v) => v.currency === newBalance.currency
        );
        if (idx !== -1 && state.balances[idx].amount.eq(newBalance.amount)) {
          state.balances[idx] = newBalance;
        } else if (idx === -1) {
          state.balances.push(newBalance);
        }
      }
    },
    setStablecoinBalanceLoading: (state) => {
      state.loadingState.stablecoinBalance = true;
    },
    setRhoTokensBalanceLoading: (state) => {
      state.loadingState.rhoTokenBalance = true;
    },
    setTokensBalanceLoading: (state) => {
      state.loadingState.tokenBalance = true;
    },
  },
  extraReducers: (builder) => {
    builder
      // init Flurry Staking Balance
      .addCase(initFlurryStakingBalance.pending, (state) => {
        state.loadingState.flurryStakingBalance = true;
      })
      .addCase(initFlurryStakingBalance.fulfilled, (state, action) => {
        const newBalance = action.payload;
        if (newBalance) {
          const idx = state.balances.findIndex(
            (v) => v.currency === newBalance.currency
          );
          if (idx !== -1 && !state.balances[idx].amount.eq(newBalance.amount)) {
            state.balances[idx] = newBalance;
          } else if (idx === -1) {
            state.balances.push(newBalance);
          }
        }
        if (state.loadingState.flurryStakingBalance) {
          state.loadingState.flurryStakingBalance = false;
        }
      })
      .addCase(initFlurryStakingBalance.rejected, (state) => {
        state.loadingState.flurryStakingBalance = false;
      })
      // init Flurry Staking Supply
      .addCase(initFlurryStakingSupply.pending, (state) => {
        state.loadingState.flurryStakingSupply = true;
      })
      .addCase(initFlurryStakingSupply.fulfilled, (state, action) => {
        const newBalance = action.payload;
        if (newBalance) {
          const idx = state.balances.findIndex(
            (v) => v.currency === newBalance.currency
          );
          if (idx !== -1 && !state.balances[idx].amount.eq(newBalance.amount)) {
            state.balances[idx] = newBalance;
          } else if (idx === -1) {
            state.balances.push(newBalance);
          }
        }
        if (state.loadingState.flurryStakingSupply) {
          state.loadingState.flurryStakingSupply = false;
        }
      })
      .addCase(initFlurryStakingSupply.rejected, (state) => {
        state.loadingState.flurryStakingSupply = false;
      })
      // init Rho Total Supply
      .addCase(initRhoSupply.pending, (state) => {
        state.loadingState.rhoTokenSupply = true;
      })
      .addCase(initRhoSupply.fulfilled, (state, action) => {
        const newBalances = action.payload;
        if (newBalances.length > 0) {
          for (const newBalance of newBalances) {
            const idx = state.balances.findIndex(
              (v) => v.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.balances.push(newBalance);
            }
          }
        }
        if (state.loadingState.rhoTokenSupply) {
          state.loadingState.rhoTokenSupply = false;
        }
      })
      .addCase(initRhoSupply.rejected, (state) => {
        state.loadingState.rhoTokenSupply = false;
      })
      .addCase(updateRhoBalances.fulfilled, (state, action) => {
        const newBalances = action.payload;
        if (newBalances.length > 0) {
          for (const newBalance of newBalances) {
            const idx = state.balances.findIndex(
              (v) => v.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.balances.push(newBalance);
            }
          }
        }
        if (state.loadingState.rhoTokenBalance) {
          state.loadingState.rhoTokenBalance = false;
        }
      })
      .addCase(updateRhoBalances.rejected, (state) => {
        state.loadingState.rhoTokenBalance = false;
      })
      // update tokens Balance
      .addCase(updateOtherTokenBalances.fulfilled, (state, action) => {
        const newBalances = action.payload;
        if (newBalances.length > 0) {
          for (const newBalance of newBalances) {
            const idx = state.balances.findIndex(
              (v) => v.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.balances.push(newBalance);
            }
          }
        }

        if (state.loadingState.tokenBalance) {
          state.loadingState.tokenBalance = false;
        }
      })
      .addCase(updateOtherTokenBalances.rejected, (state) => {
        state.loadingState.tokenBalance = false;
      })
      // stabelcoins
      .addCase(updateStablecoinBalances.fulfilled, (state, action) => {
        const newBalances = action.payload;
        if (newBalances.length > 0) {
          for (const newBalance of newBalances) {
            const idx = state.balances.findIndex(
              (v) => v.currency === newBalance.currency
            );
            if (
              idx !== -1 &&
              !state.balances[idx].amount.eq(newBalance.amount)
            ) {
              state.balances[idx] = newBalance;
            } else if (idx === -1) {
              state.balances.push(newBalance);
            }
          }
        }

        if (state.loadingState.stablecoinBalance) {
          state.loadingState.stablecoinBalance = false;
        }
      })
      .addCase(updateStablecoinBalances.rejected, (state) => {
        state.loadingState.stablecoinBalance = false;
        if (state.loadingState.stablecoinBalance) {
          state.loadingState.stablecoinBalance = false;
        }
      })
      // update balances
      .addCase(updateBalances.fulfilled, (state, action) => {
        const newBalance = action.payload;
        if (newBalance) {
          const idx = state.balances.findIndex(
            (v) => v.currency === newBalance.currency
          );
          if (idx !== -1 && !state.balances[idx].amount.eq(newBalance.amount)) {
            state.balances[idx] = newBalance;
          } else if (idx === -1) {
            state.balances.push(newBalance);
          }
        }
      })
      // reset balances
      .addCase(resetBalances.fulfilled, (state) => {
        state.balances = [];
      })
      .addCase(updateFlurryTokenBalance.fulfilled, (state, action) => {
        const newBalance = action.payload;
        if (newBalance) {
          const idx = state.balances.findIndex(
            (v) => v.currency === newBalance.currency
          );
          if (idx !== -1 && !state.balances[idx].amount.eq(newBalance.amount)) {
            state.balances[idx] = newBalance;
          } else if (idx === -1) {
            state.balances.push(newBalance);
          }
        }
        if (state.loadingState.flurryToken) {
          state.loadingState.flurryToken = false;
        }
      });
  },
});

export const {
  onFlurryStakingBalanceEvent,
  setRhoTokensBalanceLoading,
  setStablecoinBalanceLoading,
  setTokensBalanceLoading,
} = balancesSlice.actions;

export default balancesSlice.reducer;
