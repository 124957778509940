import React from 'react';
import * as S from './DisconnectButton.styles';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import { disconnect } from 'core/store/auth/thunks/disconnect';
import { showUserModal } from 'core/store/ui/ui';
import { sendGAEvent } from 'utils';

const DisconnectButton: React.FC = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.auth.user);

  if (!user) {
    return <></>;
  }

  return (
    <S.DisconnectWrapper>
      <S.DisconnectBtn
        onClick={() => {
          sendGAEvent('User', 'Disconnect');
          dispatch(showUserModal(false));
          dispatch(disconnect());
        }}
      >
        <S.DisconnectIcon />
        Disconnect
      </S.DisconnectBtn>
    </S.DisconnectWrapper>
  );
};

export default DisconnectButton;
