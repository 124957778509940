import styled from 'styled-components';
import { FarmColumnLayout } from '../Farms.styles';
import { FiArrowUp, FiArrowDown } from 'react-icons/fi';
import { down } from 'styled-breakpoints';

export const ConnectWalletWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  background-color: #ffffffbe;
  width: 100%;
  height: 100%;
  border-radius: 7px;
`;
export const ConnectButton = styled.button`
  width: calc(100% - 16rem);
  margin: 0 auto;
  padding: 1rem 0;
  text-align: center;
  color: black;
  border: 1px solid black;
  background-color: white;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-size: 0.9rem;

  opacity: ${(props) => (!props.disabled ? '1' : '0.5')};
`;

export const FarmTableWrapper = styled.div`
  position: relative;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0.04em 0.1em,
    rgba(0, 0, 0, 0.1) 0px 0.1em 0.2em,
    rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
  border-radius: 7px;
`;

export const Header = styled.div`
  display: grid;
  ${FarmColumnLayout}
  padding: 1.5rem 1.5rem;
  box-shadow: rgba(33, 35, 38, 0.384) 0px 10px 10px -10px;
  ${down('md')} {
    padding: 0.8rem 1.5rem;
  }
`;

export const HeaderItem = styled.div<{
  isSorted?: boolean;
  hideOnTablet?: boolean;
  hideOnMobile?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 0.8rem;
  font-weight: bold;
  color: ${(props) => (props.isSorted ? '#0000FF' : 'inherit')};
  ${down('md')} {
    font-size: 0.7rem;
  }
  ${down('sm')} {
    display: ${(props) => (props.hideOnTablet ? 'none' : 'flex')};
  }
  ${down('xs')} {
    display: ${(props) => (props.hideOnMobile ? 'none' : 'flex')};
  }
`;

export const ArrowUp = styled(FiArrowUp)`
  padding-left: 0.2rem;
`;
export const ArrowDown = styled(FiArrowDown)`
  padding-left: 0.2rem;
`;

export const BodyItem = styled.div``;

export const EmptyFarms = styled.div`
  width: 100%;
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #707070;
  font-size: 0.8rem;
`;
