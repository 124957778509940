/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Socket } from 'core/types';
import { MiddlewareAPI } from '@reduxjs/toolkit';
import {
  addEarnAnnouncement,
  addNotificationMessage,
  CONTENT_WS,
  deleteEarnAnnouncement,
  deleteNotificationMessage,
  ENTITIES,
  updateEarnAnnouncement,
  updateNotificationMessage,
} from '../content';

const flurryWebsocketMiddleware = (store: MiddlewareAPI<any, any>) => {
  const onCreate = (message: any) => {
    if (message.entity) {
      switch (message.entity) {
        case ENTITIES.EARN_ANNOUNCEMENTS:
          store.dispatch(addEarnAnnouncement(message));
          break;
        case ENTITIES.NOTIFICATION_MESSAGES:
          store.dispatch(addNotificationMessage(message));
          break;
      }
    }
  };

  const onUpdate = (message: any) => {
    if (message.entity) {
      switch (message.entity) {
        case ENTITIES.EARN_ANNOUNCEMENTS:
          store.dispatch(updateEarnAnnouncement(message));
          break;
        case ENTITIES.NOTIFICATION_MESSAGES:
          store.dispatch(updateNotificationMessage(message));
          break;
      }
    }
  };

  const onDelete = (message: any) => {
    if (message.entity && message.id) {
      switch (message.entity) {
        case ENTITIES.EARN_ANNOUNCEMENTS:
          store.dispatch(deleteEarnAnnouncement(message.id));
          break;
        case ENTITIES.NOTIFICATION_MESSAGES:
          store.dispatch(deleteNotificationMessage(message.id));
          break;
      }
    }
  };

  const onSubscribed = () => {};

  const socket = new Socket(onCreate, onUpdate, onDelete, onSubscribed);
  socket.connect(window._env_.FLURRY_API_URL);

  return (next: any) => (action: any) => {
    switch (action.type) {
      case CONTENT_WS.CONNECT_SOCKET:
        socket.connect(window._env_.FLURRY_API_URL);
        break;

      case CONTENT_WS.SUBSCRIBE:
        socket.subscribe(action.message);
        break;

      default:
        break;
    }

    return next(action);
  };
};

export default flurryWebsocketMiddleware;
