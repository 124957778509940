import BigNumber from 'bignumber.js';
import { Spacer, Toggle } from 'components';
import { stakeFlurry } from 'core/store/contracts/thunks/stakeFlurry';
import { unstakeFlurry } from 'core/store/contracts/thunks/unstakeFlurry';
import { approveFlurryStaking } from 'core/store/contracts/thunks/approveFlurryStaking';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import React, { useMemo } from 'react';
import { useState } from 'react';
import * as S from './SnowballForm.styles';
import StakeForm from './StakeForm';
import UnstakeForm from './UnstakeForm';
import { selectLatestSnowballTransaction } from 'core/store/transactions/selectors';
import { ciEquals, sendGAEvent } from 'utils';

const SnowballForm: React.FC = () => {
  const flurryTokenLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryToken
  );
  const flurryStakingLoading = useAppSelector(
    (state) => state.balances.loadingState.flurryStakingBalance
  );
  const approvalLoading = useAppSelector(
    (state) => state.auth.approvalsStates.flurryToken
  );
  const user = useAppSelector((state) => state.auth.user);
  const balancesState = useAppSelector((state) => state.balances);
  const flurryToken = useAppSelector((state) => state.contracts.flurryToken);
  const flurryStaking = useAppSelector(
    (state) => state.contracts.flurryStaking
  );
  const stakeTransaction = useAppSelector((state) =>
    selectLatestSnowballTransaction(state)
  );
  const dispatch = useAppDispatch();

  const [currentToggleOption, setCurrentToggleOption] = useState('stake');

  const needApproval = useMemo((): boolean | undefined => {
    if (!user) {
      return undefined;
    }

    const approval = user.approvals.find((e) =>
      ciEquals(e.contractAddress, flurryStaking?.address)
    );
    if (!approval) {
      return undefined;
    }

    return !approval.isApproved;
  }, [flurryStaking, user]);

  const flurryBalance = useMemo(() => {
    if (user && flurryToken) {
      const balance = balancesState.balances.find(
        (b) => b.currency === flurryToken.address
      );
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [balancesState.balances, flurryToken, user]);

  const flurryStakingBalance = useMemo(() => {
    if (user && flurryStaking) {
      const balance = balancesState.balances.find(
        (b) => b.currency === 'FlurryStakingBalance'
      );
      if (balance) {
        return balance.amount;
      }
    }
    return new BigNumber(0);
  }, [user, flurryStaking, balancesState.balances]);

  return (
    <S.Wrapper>
      <S.Body>
        <S.FormWrapper>
          <S.ToggleWrapper>
            <Toggle
              onToggleChange={(v) => setCurrentToggleOption(v.value)}
              leftOption={{
                label: 'Stake',
                value: 'stake',
              }}
              rightOption={{
                label: 'Unstake',
                value: 'unstake',
              }}
            />
          </S.ToggleWrapper>
          <Spacer axis="vertical" size={25} />
          {currentToggleOption === 'stake' && (
            <StakeForm
              loading={flurryTokenLoading || flurryStakingLoading}
              onStakeSubmit={(amount) => {
                sendGAEvent('Snowball', 'Stake');
                dispatch(stakeFlurry(amount));
              }}
              onApproveSubmit={(amount) => {
                sendGAEvent('Snowball', 'Approve');
                dispatch(approveFlurryStaking(amount));
              }}
              needApproval={needApproval}
              approvalLoading={approvalLoading}
              flurryBalance={flurryBalance}
              disabled={
                (stakeTransaction && stakeTransaction.state === 'pending') ||
                !user
              }
              maxDecimalPlace={flurryToken?.decimals}
            />
          )}
          {currentToggleOption === 'unstake' && (
            <UnstakeForm
              loading={flurryTokenLoading || flurryStakingLoading}
              onFormSubmit={(amount) => {
                sendGAEvent('Snowball', 'Unstake');
                dispatch(unstakeFlurry(amount));
              }}
              stakingBalance={flurryStakingBalance}
              disabled={
                (stakeTransaction && stakeTransaction.state === 'pending') ||
                !user
              }
              maxDecimalPlace={flurryToken?.decimals}
            />
          )}
          <Spacer axis="vertical" size={25} />
        </S.FormWrapper>
      </S.Body>
    </S.Wrapper>
  );
};
export default SnowballForm;
