import React from 'react';
import * as S from './BridgeInfos.styles';
import BridgeIllustration from 'assets/images/bridge/bridge_illustration.png';

const BridgeInfos: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Title>Flurry Bridge</S.Title>
      <S.SubTitle>Change your tokens from one network to another</S.SubTitle>
      <S.Links>
        <S.Link
          href="https://docs.flurry.finance/user-guide/bridge"
          target="_blank"
          rel="noreferrer"
        >
          How does it work
          <S.ExternalLinkIcon />
        </S.Link>
      </S.Links>
      <S.BottomImg src={BridgeIllustration} alt="bridge illustration" />
    </S.Wrapper>
  );
};

export default BridgeInfos;
