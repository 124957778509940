import React, { useState } from 'react';
import { useAppSelector } from 'core/store/hooks';
import * as S from './UserAddressButton.styles';
import { longShortenStringInMiddle } from 'utils';

const UserAddressButton: React.FC = () => {
  const user = useAppSelector((state) => state.auth.user);
  const [hasCopied, setHasCopied] = useState(false);

  if (!user) {
    return <></>;
  }

  return (
    <S.UserInfos>
      <S.UserIcon />
      <S.CopyAddress>
        <S.AddressLabel>Your address</S.AddressLabel>
        <S.AddressValue>
          <S.Address title={user.address}>
            {longShortenStringInMiddle(user.address)}
          </S.Address>
        </S.AddressValue>
      </S.CopyAddress>
      <S.CopyBtn
        onClick={() => {
          setHasCopied(false);
          navigator.clipboard.writeText(user.address).then(() => {
            setHasCopied(true);
          });
        }}
      >
        <S.CopyIcon />
        {hasCopied && <S.CheckMarkIcon />}
      </S.CopyBtn>
    </S.UserInfos>
  );
};

export default UserAddressButton;
