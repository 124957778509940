import styled from 'styled-components/macro';
import { AiOutlineLoading } from 'react-icons/ai';
import { IoMdClose } from 'react-icons/io';
import { down } from 'styled-breakpoints';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  z-index: 9999;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: #00000050;
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3rem auto;
  background-color: white;
  border-radius: 15px;
  width: 550px;
  padding: 1.2rem 1rem;
  max-height: 85vh;
  overflow: auto;
  ${down('xs')} {
    margin: 1rem auto;
    width: calc(100% - 2.5rem);
    padding: 0.8rem 0.6rem;
  }
`;

export const CloseBtnWrapper = styled.div`
  height: 2rem;
  width: 2rem;
  margin-left: auto;
`;

export const CloseBtn = styled(IoMdClose)`
  align-self: flex-end;
  opacity: 0.3;
  margin-right: 0.5rem;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  ${down('xs')} {
    margin-top: 1rem;
  }
`;

export const Logo = styled.img`
  max-width: 70px;
  height: auto;
  margin-right: 1.5rem;
  ${down('xs')} {
    margin-right: 0.5rem;
    max-width: 45px;
  }
`;

export const TotalRewardLabel = styled.h6`
  margin: 0;
  font-weight: normal;
  font-size: 1rem;
  ${down('xs')} {
    font-size: 0.6rem;
  }
`;

export const TotalRewardAmount = styled.p`
  margin: 0;
  margin-top: 0.2rem;
  font-weight: bold;
  font-size: 1.7rem;
  ${down('xs')} {
    font-size: 1rem;
  }
`;

export const Title = styled.h5`
  font-weight: normal;
  font-size: 1rem;
  text-align: center;
  margin-top: 2rem;
  ${down('xs')} {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
`;

export const CurrentClaimableRewards = styled.h5<{ hasReward: boolean }>`
  opacity: ${(props) => (props.hasReward ? '1' : '0.5')};
  color: #1e9c27;
  font-weight: normal;
  b {
    font-weight: bold;
  }
`;

export const RewardsContainer = styled.div`
  width: calc(100% - 2rem);
  padding: 0 1rem;
  ${down('xs')} {
    width: calc(100%);
    padding: 0;
  }
`;

export const DetailedReward = styled.table`
  border-spacing: 0;
  border-radius: 5px;
`;

export const DetailedRewardRow = styled.tr`
  &:nth-child(even) {
    background-color: #a8d2fc14;
  }

  td {
    font-size: 0.9rem;
    padding: 0.5rem 0;
    ${down('xs')} {
      padding: 0.4rem 0;
      font-size: 0.7rem;
    }
  }

  td:first-child {
    text-align: center;
  }
`;

export const FarmIconWrapper = styled.td`
  padding-left: 0.5rem !important;
`;

export const DetailedRewardHead = styled.tr`
  border-radius: 5px;
  th {
    padding: 0.5rem 0;
    background: none;
    border: none;
    text-align: left;
    font-size: 0.75rem;
    color: black;
    ${down('xs')} {
      font-size: 0.6rem;
    }
  }

  th:first-child {
    border-top-left-radius: 5px;
  }
  th:last-child {
    border-top-right-radius: 5px;
  }
`;

export const RewardRowWrapper = styled.div`
  height: auto;
  padding: 1rem 1rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px;
`;

export const RewardRowBody = styled.div`
  padding: 1rem 0.5rem 0 0.5rem;
  display: flex;
  flex-direction: column;
  ${down('xs')} {
    padding: 1rem 0 0 0;
    font-size: 0.7rem;
  }
`;

export const RewardRowHeader = styled.div`
  display: flex;
  align-items: center;
  text-align: left;
`;

export const ContractLogo = styled.img<{ small?: boolean }>`
  max-width: ${(props) => (props.small ? '25px' : '35px')};
  height: auto;
  ${down('xs')} {
    max-width: 20px;
    padding-right: 0.2rem;
    padding-left: 0.2rem;
  }
`;

export const TextWrapper = styled.div``;

export const AmountLabel = styled.h6`
  margin: 0;
  font-weight: normal;
  font-size: 0.7rem;
`;

export const Amount = styled.p`
  margin: 0;
  margin-top: 0.2rem;
  font-weight: normal;
  font-size: 1.2rem;
  ${down('xs')} {
    font-size: 1rem;
  }
`;

export const LockWrapper = styled.div`
  margin-left: auto;
`;

export const ClaimButton = styled.button`
  margin: 0 auto;
  padding: 0.75rem 1rem;
  text-align: center;
  background-image: linear-gradient(
    to right,
    #77d37f 0%,
    #95dd9d 51%,
    #77d37f 100%
  );
  transition: 0.5s;
  background-size: 200% auto;
  color: white;
  box-shadow: 0 0 20px #77a0d349;
  border: none;
  border-radius: 10px;
  display: block;
  cursor: pointer;
  font-weight: bold;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};

  ${(props) =>
    !props.disabled
      ? '&:hover { background-position: right center; color: #fff; text-decoration: none; }'
      : ''}
`;

export const ClaimAllButton = styled(ClaimButton)`
  width: calc(100% - 4rem);
`;

export const SnowballRewardsButtonLink = styled(Link)`
  width: calc(100% - 4rem);
  text-decoration: none;
`;

export const SnowballRewardsButton = styled(ClaimButton)`
  width: 100%;

  background-image: linear-gradient(
    to right,
    #2349ff 0%,
    #1484ec 51%,
    #2349ff 100%
  );
`;

export const LockedRewardsWrapper = styled.div`
  text-align: center;
  background-color: #1f65ff;
  padding: 1rem 1.5rem;
  border-radius: 15px;
  color: white;
  ${down('xs')} {
    padding: 0.8rem 0.8rem;
  }
`;
export const LockedCountdown = styled.p`
  font-weight: bold;
  font-size: 0.9rem;
  margin: 0;
  padding: 0;
  ${down('xs')} {
    font-size: 0.7rem;
  }
`;
export const LockedBlock = styled.p`
  font-size: 0.75rem;
  line-height: 1.5rem;
  margin: 0;
  padding: 0;
  ${down('xs')} {
    font-size: 0.6rem;
    line-height: 1rem;
  }
`;

export const LoadingIcon = styled(AiOutlineLoading)``;

export const ComingSoon = styled.div`
  opacity: 0.5;
  padding: 1rem 0;
  font-weight: bold;
  text-align: center;
`;
