import { ethers } from 'ethers';

export const getBlocksOfTimestamps = (
  targetTimestamps: number[],
  currentBlock: ethers.providers.Block,
  averageBlockTime: number
): number[] => {
  const blocks: number[] = [];
  for (const timestamp of targetTimestamps) {
    const decreaseBlocks = Math.floor(
      (currentBlock.timestamp - timestamp) / averageBlockTime
    );
    blocks.push(currentBlock.number - decreaseBlocks);
  }

  return blocks;
};
