import React from 'react';
import FooterLogo from 'assets/images/logos/footer_logo.png';
import TwitterLogo from 'assets/images/logos/socialsLogos/twitter.png';
import TelegramLogo from 'assets/images/logos/socialsLogos/telegram.png';
import MediumLogo from 'assets/images/logos/socialsLogos/medium.png';
import RedditLogo from 'assets/images/logos/socialsLogos/reddit.png';
import DiscordLogo from 'assets/images/logos/socialsLogos/discord.png';

import * as S from './Footer.styles';

const Footer: React.FC = () => {
  return (
    <S.FooterWrapper>
      <S.FooterContent>
        <div>
          <S.FooterLogo src={FooterLogo} alt="Flurry logo" />
          <S.Socials>
            <S.SocialLogo
              eventLabel="Flurry Twitter"
              to="https://twitter.com/FlurryFi"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TwitterLogo} alt="Twitter logo" />
            </S.SocialLogo>
            <S.SocialLogo
              eventLabel="Flurry Telegram"
              to="https://t.me/FlurryFinance_Official"
              target="_blank"
              rel="noreferrer"
            >
              <img src={TelegramLogo} alt="Telegram logo" />
            </S.SocialLogo>
            <S.SocialLogo
              eventLabel="Flurry Medium"
              to="https://medium.com/flurry-finance"
              target="_blank"
              rel="noreferrer"
            >
              <img src={MediumLogo} alt="Medium logo" />
            </S.SocialLogo>
            <S.SocialLogo
              eventLabel="Flurry Reddit"
              to="https://www.reddit.com/r/FlurryFinance"
              target="_blank"
              rel="noreferrer"
            >
              <img src={RedditLogo} alt="Reddit logo" />
            </S.SocialLogo>
            <S.SocialLogo
              eventLabel="Flurry Discord"
              to="https://discord.com/invite/vKtW89Z3j7"
              target="_blank"
              rel="noreferrer"
            >
              <img src={DiscordLogo} alt="Discord logo" />
            </S.SocialLogo>
          </S.Socials>
          <S.ContactUs>
            <a
              href="mailto:info@flurry.finance"
              target="_blank"
              rel="noreferrer"
            >
              Contact Us
            </a>
          </S.ContactUs>
        </div>
        <S.Centered>©️ 2021 flurry.finance. All rights reserved</S.Centered>
      </S.FooterContent>
    </S.FooterWrapper>
  );
};

export default Footer;
