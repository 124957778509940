import React, { useMemo } from 'react';
import EarnBackgroundWebp from 'assets/images/earn_bg.webp';
import EarnBackgroundPng from 'assets/images/earn_bg.png';
import * as S from './Farms.styles';
import { Spacer } from 'components';
import FarmsTable from './FarmsTable';

import { useAppSelector } from 'core/store/hooks';
import { BasePayload, FarmRow, UserBalance } from 'core/types';
import { findLogoOfContract, getLiquidityLink, sendGAEvent } from 'utils';
import { selectFarmsTransactions } from 'core/store/transactions/selectors';

const Farms: React.FC = () => {
  const farms = useAppSelector((state) => state.farms.list);
  const transactions = useAppSelector((state) =>
    selectFarmsTransactions(state)
  );
  const user = useAppSelector((state) => state.auth.user);
  const network = useAppSelector((state) => state.auth.network);
  const farmsLoading = useAppSelector(
    (state) => state.auth.balancesStates.farmsToken
  );
  const rewardsLoading = useAppSelector(
    (state) => state.auth.balancesStates.rewards
  );

  const farmRows = useMemo(() => {
    const rows: FarmRow[] = [];

    farms.forEach((e) => {
      let rewardBalance: UserBalance | undefined;
      let availableBalance: UserBalance | undefined;
      let stakedBalance: UserBalance | undefined;

      if (user) {
        rewardBalance = user.balances.find(
          (b) => b.currency === `FarmRewards${e.key}`
        );
        availableBalance = user.balances.find((b) => b.currency === e.key);
        stakedBalance = user.balances.find(
          (b) => b.currency === `Staking${e.key}`
        );
      }

      const foundContracts = e.key.split('/');
      const logo1 = findLogoOfContract(foundContracts[0]);
      const logo2 = findLogoOfContract(foundContracts[1]);

      const transaction = transactions.find(
        (t) => t.payload && (t.payload as BasePayload).currency === e.key
      );
      const liquidityUrl = network
        ? getLiquidityLink(network, e.token0.address, e.token1.address)
        : undefined;

      rows.push({
        unreleased: e.unreleased,
        loading: farmsLoading || rewardsLoading,
        farm: e,
        logo1,
        logo2,
        liquidityUrl,
        liquidity: e.liquidity,
        rewardAPR: e.apr,
        earned: rewardBalance?.amount,
        staked: stakedBalance?.amount,
        availableBalance: availableBalance?.amount,
        transaction,
      });
    });

    return rows;
  }, [farms, farmsLoading, network, rewardsLoading, transactions, user]);

  return (
    <S.FarmsWrapper>
      <S.BackgroundImage
        src={EarnBackgroundWebp}
        fallback={EarnBackgroundPng}
        alt="earn background image"
      />
      <S.FarmsCard>
        <S.Title>Farms</S.Title>
        <S.SubTitle>Farm and earn Flurry token</S.SubTitle>
        <Spacer axis="vertical" size={5} />
        <S.CompensationLinkWrapper>
          <S.InfoIcon />
          <S.CompensationLink
            to="/compensation"
            onClick={() => sendGAEvent('Farms', 'Compensation Link')}
          >
            Click here to claim your LP token compensation for the 22 Feb 2022
            incident on BNB Chain.
          </S.CompensationLink>
        </S.CompensationLinkWrapper>
        <Spacer axis="vertical" size={15} />
        <FarmsTable
          farms={farmRows}
          user={user}
          loading={farmsLoading || rewardsLoading || farms.length === 0}
        />
      </S.FarmsCard>
      <Spacer axis="vertical" size={50} />
    </S.FarmsWrapper>
  );
};

export default Farms;
