import { useAppSelector } from 'core/store/hooks';
import { CurrencyOption } from 'core/types';
import { useState, useEffect } from 'react';
import { TokenContractsLogos } from 'core/config/contracts';
import { ciEquals } from 'utils';

/// This hook will give an up to date list of the RHO Tokens
export const useRhoTokens = (): CurrencyOption[] => {
  const [rhoTokensOptions, setRhoTokensOptions] = useState<CurrencyOption[]>(
    []
  );

  const rhoTokens = useAppSelector((state) => state.contracts.rhoTokens);
  const vaults = useAppSelector((state) => state.contracts.vaults);
  const user = useAppSelector((state) => state.auth.user);

  useEffect(() => {
    // Building the list of RHO tokens that can be shown to the user
    const res: CurrencyOption[] = [];
    const nonPresentTokens = [];

    for (const l of TokenContractsLogos) {
      const currentToken = rhoTokens.find((t) => t.key === l.key);
      if ((!user || rhoTokens.length === 0) && l.showDisconnected) {
        nonPresentTokens.push(l);
        continue;
      }

      if (!currentToken) continue;

      const linkedToken = vaults.find((v) =>
        ciEquals(v.rhoTokenAddress, currentToken.address)
      )?.underlyingAddress;

      res.push({
        value: currentToken.address,
        label: currentToken.label,
        decimals: currentToken.decimals,
        icon: l.logo,
        linkedToken,
      });
    }

    // Some token that we still want to show offline
    for (const l of nonPresentTokens) {
      res.push({
        value: l.key,
        label: l.key,
        icon: l.logo,
        disabled: true,
      });
    }

    setRhoTokensOptions(res);
  }, [rhoTokens, user, vaults]);

  return rhoTokensOptions;
};
