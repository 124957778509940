import React from 'react';
import { CurrencyOption } from 'core/types';
import * as S from './CustomOption.styles';

type CustomOptionProps = {
  style: React.CSSProperties;
  option: CurrencyOption;
  onClick: () => void;
  isActive?: boolean;
};

export const CustomOption: React.FC<CustomOptionProps> = ({
  style,
  option,
  onClick,
  isActive,
}) => {
  const { label, symbol, icon } = option;

  return (
    <S.OptionWrapper style={style} isActive={isActive} onClick={onClick}>
      <S.IconWrapper>
        <img src={icon} width="20" height="20" />
      </S.IconWrapper>
      <S.LabelWrapper>
        <S.Symbol>{symbol ?? label}</S.Symbol>
        <S.Label>{label}</S.Label>
      </S.LabelWrapper>
    </S.OptionWrapper>
  );
};
