import { BaseContractsLogos, TokenContractsLogos } from 'core/config/contracts';
import { ContractsState } from 'core/store/contracts/contracts';
import { Contract } from 'core/types';

import FlurryIcon from 'assets/images/logos/flurry_icon_only.png';
import RhoTokenIcon from 'assets/images/logos/rhoToken_logo.png';
import EthLogo from 'assets/images/logos/eth_logo.png';
import BnbLogo from 'assets/images/logos/bnb_logo.svg';
import { ciEquals } from 'utils';
import { ethers } from 'ethers';

const ERC20_ABI = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Approval',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'from',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'to',
        type: 'address',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'value',
        type: 'uint256',
      },
    ],
    name: 'Transfer',
    type: 'event',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'owner',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
    ],
    name: 'allowance',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'spender',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'approve',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'decimals',
    outputs: [
      {
        internalType: 'uint8',
        name: '',
        type: 'uint8',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'name',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'symbol',
    outputs: [
      {
        internalType: 'string',
        name: '',
        type: 'string',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'totalSupply',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'transfer',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'sender',
        type: 'address',
      },
      {
        internalType: 'address',
        name: 'recipient',
        type: 'address',
      },
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'transferFrom',
    outputs: [
      {
        internalType: 'bool',
        name: '',
        type: 'bool',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

export const getContractByName = (
  contractName: string,
  contracts: ContractsState
): Contract => {
  if (contracts.flurryToken?.key === contractName) {
    return contracts.flurryToken;
  }
  if (contracts.flurryStaking?.key === contractName) {
    return contracts.flurryStaking;
  }
  if (contracts.rhoTokenRewards?.key === contractName) {
    return contracts.rhoTokenRewards;
  }
  if (contracts.lpStaking?.key === contractName) {
    return contracts.lpStaking;
  }

  const allContracts = contracts.stablecoins.concat(
    contracts.rhoTokens,
    contracts.vaults,
    contracts.vaults
      .map((v) => v.depositTokens)
      .reduce(function (prev, next) {
        return prev.concat(next);
      }),
    contracts.vaults
      .map((v) => v.strategies)
      .reduce(function (prev, next) {
        return prev.concat(next);
      })
  );

  const contract = allContracts.find((e) => e.key === contractName);

  if (!contract) {
    throw new Error(`Could not find ${contractName} contract`);
  }

  return contract;
};

export const getContractByAddress = async (
  contractAddress: string,
  contracts: ContractsState,
  provider?: ethers.providers.Web3Provider
): Promise<Contract> => {
  if (
    contracts.flurryToken &&
    ciEquals(contracts.flurryToken.address, contractAddress)
  ) {
    return contracts.flurryToken;
  }

  if (
    contracts.lpStaking &&
    ciEquals(contracts.lpStaking.address, contractAddress)
  ) {
    return contracts.lpStaking;
  }

  if (
    contracts.rhoTokenRewards &&
    ciEquals(contracts.rhoTokenRewards.address, contractAddress)
  ) {
    return contracts.rhoTokenRewards;
  }

  if (
    contracts.flurryStaking &&
    ciEquals(contracts.flurryStaking.address, contractAddress)
  ) {
    return contracts.flurryStaking;
  }

  const depositTokens = contracts.vaults.map((v) => v.depositTokens);
  const strategies = contracts.vaults.map((v) => v.strategies);

  const allContracts = contracts.stablecoins.concat(
    contracts.rhoTokens,
    contracts.vaults,
    depositTokens.length > 0
      ? depositTokens.reduce(function (prev, next) {
          return prev.concat(next);
        })
      : [],
    strategies.length > 0
      ? strategies.reduce(function (prev, next) {
          return prev.concat(next);
        })
      : []
  );

  const contract = allContracts.find((e) =>
    ciEquals(e.address, contractAddress)
  );

  // The contract is not a Flurry Protocol contract, so we assume it's an ERC20
  if (!contract) {
    if (!provider) {
      throw Error(`Could not find contract: ${contractAddress}`);
    }

    const ethersContract = new ethers.Contract(
      contractAddress,
      ERC20_ABI,
      provider
    );

    const decimals = await ethersContract.decimals();
    const symbol = await ethersContract.symbol();
    const name = await ethersContract.name();

    return {
      key: contractAddress,
      label: `${name} (${symbol})`,
      address: contractAddress,
      abi: ERC20_ABI,
      contract: ethersContract,
      decimals,
    };
  }

  return contract;
};

export const findLogoOfContract = (contractName: string): string => {
  const allContractLogos = TokenContractsLogos.concat(BaseContractsLogos);

  const logo = allContractLogos.find((e) => e.key === contractName);
  if (!logo) {
    if (contractName === 'FLURRY') {
      return FlurryIcon;
    }
    if (contractName === 'ETH') {
      return EthLogo;
    }
    if (contractName === 'BNB') {
      return BnbLogo;
    }
    return RhoTokenIcon;
  }

  return logo.logo;
};
