import React, { useMemo } from 'react';
import { Transaction } from 'core/types';
import * as S from './UserNotifications.styles';
import { Spacer, Notification } from 'components';
import { useAppDispatch, useAppSelector } from 'core/store/hooks';
import {
  showConfirmedNotifications,
  showErrorsNotifications,
  showPendingNotifications,
} from 'core/store/ui/ui';

const UserNotifications: React.FC = () => {
  const dispatch = useAppDispatch();
  const network = useAppSelector((state) => state.auth.network);
  const transactions = useAppSelector((state) => state.transactions.list);
  const notificationsModalUI = useAppSelector((state) => state.ui.userModal);

  const filteredTransaction = useMemo(() => {
    let newTransactionList: Transaction[] = [...transactions];
    if (!notificationsModalUI.showPendings) {
      newTransactionList = newTransactionList.filter(
        (e) => e.state !== 'pending'
      );
    }
    if (!notificationsModalUI.showConfirmed) {
      newTransactionList = newTransactionList.filter(
        (e) => e.state !== 'success'
      );
    }
    if (!notificationsModalUI.showErrors) {
      newTransactionList = newTransactionList.filter(
        (e) => e.state !== 'error'
      );
    }
    return newTransactionList;
  }, [
    notificationsModalUI.showConfirmed,
    notificationsModalUI.showErrors,
    notificationsModalUI.showPendings,
    transactions,
  ]);

  return (
    <>
      <S.ModalHeader>
        <S.ModalTitleWrapper>
          <S.ModalTitle>Recent transactions</S.ModalTitle>
        </S.ModalTitleWrapper>
        <S.ModalSettings>
          <S.Setting
            active={notificationsModalUI.showPendings}
            onClick={() =>
              dispatch(
                showPendingNotifications(!notificationsModalUI.showPendings)
              )
            }
          >
            Pending
          </S.Setting>
          <Spacer axis="horizontal" size={5} />
          <S.ConfirmedSetting
            active={notificationsModalUI.showConfirmed}
            onClick={() =>
              dispatch(
                showConfirmedNotifications(!notificationsModalUI.showConfirmed)
              )
            }
          >
            Confirmed
          </S.ConfirmedSetting>
          <Spacer axis="horizontal" size={5} />
          <S.ErrorSetting
            active={notificationsModalUI.showErrors}
            onClick={() =>
              dispatch(
                showErrorsNotifications(!notificationsModalUI.showErrors)
              )
            }
          >
            Error
          </S.ErrorSetting>
        </S.ModalSettings>
      </S.ModalHeader>
      {filteredTransaction.length > 0 ? (
        <S.Notifications>
          {filteredTransaction.map((t) => (
            <>
              <Notification
                transaction={t}
                network={network}
                key={`notif_${t.id}`}
              />
              <Spacer axis="vertical" size={9} key={`notif_spacer_${t.id}`} />
            </>
          ))}
        </S.Notifications>
      ) : (
        <S.NoTransaction>No recent transaction found.</S.NoTransaction>
      )}
    </>
  );
};

export default UserNotifications;
