import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';

export const useGaTracker = (): void => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (window._env_.GOOGLE_ANALYTICS_TRACKING_ID) {
      ReactGA.initialize(window._env_.GOOGLE_ANALYTICS_TRACKING_ID, {
        testMode: window._env_.ENVIRONMENT === 'develop',
      });

      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.send({
        hitType: 'pageview',
        page: `dapp${location.pathname}${location.search}`,
      });
    }
  }, [initialized, location]);
};

export default useGaTracker;
