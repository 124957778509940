import React, { useCallback, useState } from 'react';
import * as S from './FarmRow.styles';
import { PriceInput, Spacer, ShortenBalance } from 'components';
import { useAppDispatch } from 'core/store/hooks';
import { unstakeFarm } from 'core/store/farms/thunks/unstakeFarm';
import { formatBNToString, noExponents, sendGAEvent } from 'utils';
import BigNumber from 'bignumber.js';

interface FarmUnstakingFormProps {
  contractKey: string;
  contractAddress: string;
  stakedBalance?: BigNumber;
  disabled?: boolean;
  maxDecimalPlace?: number;
  liquidity?: BigNumber;
  totalSupply?: BigNumber;
  showUSDPrice?: boolean;
}

const FarmUnstakingForm: React.FC<FarmUnstakingFormProps> = ({
  contractKey,
  contractAddress,
  stakedBalance,
  disabled,
  maxDecimalPlace,
  liquidity,
  totalSupply,
  showUSDPrice = true,
}) => {
  const [unstakeAmount, setUnstakeAmount] = useState('');
  const [unstakeError, setUnstakeError] = useState('');
  const dispatch = useAppDispatch();

  const onStopFarmingClicked = useCallback(
    (contract: string, amount: BigNumber) => {
      if (stakedBalance) {
        setUnstakeError('');
        if (amount.lte(new BigNumber(0))) {
          setUnstakeError('Amount should be greater than zero');
        } else if (amount.gt(stakedBalance)) {
          setUnstakeError('You do not have enough farming balance');
        } else {
          dispatch(unstakeFarm({ contract, amount }));
        }
      }
    },
    [dispatch, stakedBalance]
  );

  return (
    <>
      {stakedBalance !== undefined && !stakedBalance.isZero() && (
        <>
          <Spacer axis="vertical" size={20} />
          <S.FormWrapper>
            <S.InputWrapper>
              <S.InputLabel>
                Liquidity to remove
                {showUSDPrice && (
                  <S.UsdValue>
                    In USD:
                    {unstakeAmount &&
                    unstakeAmount !== '' &&
                    totalSupply &&
                    liquidity
                      ? ` $${formatBNToString(
                          !totalSupply.isZero()
                            ? new BigNumber(unstakeAmount)
                                .div(totalSupply)
                                .times(liquidity)
                            : new BigNumber(0),
                          0,
                          false,
                          ','
                        )}`
                      : ' -'}
                  </S.UsdValue>
                )}
              </S.InputLabel>
              <PriceInput
                inputValue={unstakeAmount.toString()}
                onAmountChange={(v) => setUnstakeAmount(v)}
                onMaxBtnClicked={() =>
                  setUnstakeAmount(noExponents(stakedBalance))
                }
                maxDecimalPlace={maxDecimalPlace}
              />
            </S.InputWrapper>
            <Spacer axis="horizontal" size={20} />
            <S.StopFarmButton
              type="button"
              disabled={
                disabled === true ||
                unstakeAmount === '' ||
                new BigNumber(unstakeAmount).isZero()
              }
              onClick={() => {
                sendGAEvent('Farms', 'Unstake', contractKey);
                onStopFarmingClicked(
                  contractAddress,
                  new BigNumber(unstakeAmount)
                );
              }}
            >
              Stop Farming
            </S.StopFarmButton>
          </S.FormWrapper>
          {unstakeError && <S.ErrorMsg>{unstakeError}</S.ErrorMsg>}
          <S.BalanceWrapper>
            <ShortenBalance
              fontSize="0.75rem"
              label="Currently providing:"
              balanceValue={stakedBalance}
              decimalPrecision={5}
              currency=""
            />
          </S.BalanceWrapper>
          <Spacer axis="vertical" size={10} />
        </>
      )}
    </>
  );
};

export default FarmUnstakingForm;
