import { createAsyncThunk } from '@reduxjs/toolkit';
import { Approval, User } from 'core/types';
import BigNumber from 'bignumber.js';
import { BN, getContractByAddress } from 'utils';
import { ContractsState } from 'core/store/contracts/contracts';

export const updateUserBridgeApproval = createAsyncThunk<
  Approval[],
  { amount: BigNumber; tokenAddress: string; user: User | undefined }
>('auth/updateUserBridgeApproval', async (payload, thunkAPI) => {
  const approvals: Approval[] = [];
  const { user, amount, tokenAddress } = payload;
  const contracts = (thunkAPI.getState() as { contracts: ContractsState })
    .contracts;

  try {
    if (!user) {
      throw Error('No User found');
    }

    const token = await getContractByAddress(tokenAddress, contracts);

    const bridge = contracts.bridge;
    if (!bridge) {
      throw Error('No Bridge contract found');
    }

    const allowance = BN(
      await token.contract.allowance(user.address, bridge.address),
      token.decimals
    );

    approvals.push({
      contractAddress: `${token.address}-bridge`,
      isApproved: allowance.gte(amount),
    });
  } catch (e) {
    console.error(e);
  }

  return approvals;
});
