import React from 'react';
import * as S from './SuccessIcon.styles';

const SuccessIcon: React.FC = () => {
  return (
    <S.CheckMarkCircle>
      <S.Background />
      <S.CheckmarkDraw />
    </S.CheckMarkCircle>
  );
};

export default SuccessIcon;
