/* eslint-disable @typescript-eslint/no-explicit-any */
import { Spacer } from 'components';
import React, { useState } from 'react';
import { useCallback } from 'react';
import * as S from './Toggle.styles';

type ToggleOption = {
  label?: string;
  value: any;
  icon?: string;
};

type Props = {
  onToggleChange?: (selectedOption: ToggleOption) => void;
  leftOption: ToggleOption;
  rightOption: ToggleOption;
};

const Toggle: React.FC<Props> = ({
  onToggleChange,
  leftOption,
  rightOption,
}: Props) => {
  const [isToggled, setIsToggled] = useState(false);

  const selectOption = useCallback(
    (option: ToggleOption) => {
      if (onToggleChange) {
        onToggleChange(option);
      }
      setIsToggled(option === rightOption);
    },
    [onToggleChange, rightOption]
  );

  const toggle = useCallback(() => {
    if (!isToggled) {
      selectOption(rightOption);
    } else {
      selectOption(leftOption);
    }
  }, [isToggled, leftOption, rightOption, selectOption]);

  return (
    <S.Wrapper>
      {leftOption.label && (
        <S.Label onClick={() => selectOption(leftOption)} active={!isToggled}>
          {leftOption.label}
        </S.Label>
      )}
      <Spacer axis="horizontal" size={10} />
      <S.SliderWrapper htmlFor="slider">
        <S.Btn
          type="checkbox"
          onChange={() => toggle()}
          id="slider"
          checked={isToggled}
        />
        <S.Slider />
      </S.SliderWrapper>
      <Spacer axis="horizontal" size={10} />
      {rightOption.label && (
        <S.Label onClick={() => selectOption(rightOption)} active={isToggled}>
          {rightOption.label}
        </S.Label>
      )}
    </S.Wrapper>
  );
};

export default Toggle;
