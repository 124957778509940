import styled, { css, keyframes } from 'styled-components/macro';
import { FaBars, FaInfoCircle, FaChevronRight } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { NavLink as Link } from 'react-router-dom';
import { down, up } from 'styled-breakpoints';

export const Nav = styled.nav`
  position: absolute;
  top: 0;
  left: 1rem;
  width: calc(100% - 2rem);
  height: 90px;
  display: flex;
  justify-content: space-between;
  z-index: 11;
  ${down('md')} {
    height: 70px;
    left: 0;
    width: 100%;
  }
  ${up('lg')} {
    height: 90px;
  }
`;

export const RightNav = styled.div`
  display: flex;
  ${down('lg')} {
    display: none;
  }
  @media screen and (max-width: 1200px) {
    margin-left: auto;
  }
`;

const linkStyle = css`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  margin: 0 2rem;
  ${down('lg')} {
    margin: 0 1rem;
  }
  padding: 0 0.2rem;
  cursor: pointer;
`;

export const NavLink = styled(Link)`
  ${linkStyle}
  height: auto;
  padding: 0.1rem 0.4rem;
  margin: 0 1rem;
  &.active {
    font-weight: bold;
    border-bottom: 2px solid white;
  }
`;

export const A = styled.a`
  ${linkStyle}
`;

export const NavbarSpacer = styled.div`
  display: block;
  width: 10px;
  ${down('xs')} {
    display: none;
  }
`;

export const NavMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${down('lg')} {
    display: none;
  }
`;

export const NavBtn = styled.nav`
  position: relative;
  display: flex;
  align-items: center;
`;

export const Btn = styled.button`
  border: 1px solid #ffffff;
  border-radius: 8px;
  padding: 7px 15px;
  color: #fff;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  text-decoration: none;
  background-color: transparent;
  font-size: 0.8rem;
`;

export const InfoIcon = styled(FaInfoCircle)`
  padding-left: 0.3rem;
  width: 11px;
  height: 11px;
`;

export const ChevronRight = styled(FaChevronRight)`
  opacity: 0.7;
  cursor: pointer;
  position: absolute;
  top: 0.5rem;
  right: 0.3rem;
`;

export const UserInfo = styled.div`
  position: absolute;
  bottom: 2rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FlurryBalance = styled.div`
  display: none;
  font-size: 0.8rem;
  color: black;
  padding: 1rem 0.2rem;
  b {
    margin-left: 0.5rem;
  }
  ${down('xs')} {
    display: flex;
  }
`;

export const Logo = styled.img`
  max-width: 130px;
  height: auto;
  ${up('xxl')} {
    max-width: 200px;
  }
`;

export const Bars = styled(FaBars)`
  display: none;
  color: #fff;
  ${down('lg')} {
    display: block;
    transform: translate(-100%, 75%);
    font-size: 1.8rem;
    cursor: pointer;
  }
  ${down('xs')} {
    transform: translate(-50%, 75%);
  }
`;

const slide = keyframes`
  100% { right: 0; }
`;

export const MobileMenu = styled.div<{ show?: boolean }>`
  z-index: 8000;
  display: ${(props) => (props.show ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  right: -100px;
  width: 280px;
  height: 100%;
  background: #ffffff;
  animation: ${slide} 0.5s forwards;
  box-shadow: rgba(0, 0, 0, 0.1) -5px 0px 15px;
`;

export const TopMenu = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1rem;

  ${Btn} {
    border-color: black;
    color: black;
  }
`;

export const BottomMenu = styled.div`
  margin-top: auto;
`;

export const UserBtnWrapper = styled.div`
  padding: 0.5rem 0.5rem 0rem 0.5rem;
`;

export const MobileFlurryInfoWrapper = styled.div`
  display: none;
  height: 100%;

  ${down('lg')} {
    display: flex;
    align-items: center;
  }
`;

export const MenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const MobileMenuItems = styled.div`
  display: flex;
  flex-direction: column;
  ${NavLink} {
    color: black;
    padding: 0.5rem 0.5rem;
    margin-bottom: 1rem;
    border-bottom: 2px solid #3f515c11;
    &.active {
      font-weight: bold;
      border-bottom: 2px solid #000000;
    }
  }
`;

export const CloseMenu = styled(IoMdClose)`
  color: black;
  display: block;
  font-size: 2.2rem;
  cursor: pointer;
  margin-left: auto;
`;

export const BtnUserAddress = styled.div`
  display: flex;
  align-items: center;
`;
