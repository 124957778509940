import React from 'react';
import * as S from './FarmIcon.styles';

interface FarmIconProps {
  backgroundIcon: string;
  foregroundIcon: string;
  small?: boolean;
}

export const FarmIcon: React.FC<FarmIconProps> = ({
  backgroundIcon,
  foregroundIcon,
  small,
}) => {
  return (
    <S.Wrapper small={small}>
      <S.BackgroundIcon
        small={small}
        src={backgroundIcon}
        alt="Farm icon background"
      />
      <S.ForegroundIcon
        small={small}
        src={foregroundIcon}
        alt="Farm icon foreground"
      />
    </S.Wrapper>
  );
};

export default FarmIcon;
