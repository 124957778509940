import {
  BasePayload,
  BridgePayload,
  CompensationPayload,
  EarnPayload,
  Transaction,
} from 'core/types';
import FlurryIconOnly from 'assets/images/logos/flurry_icon_only.png';
import SnowballIcon from 'assets/images/flurry_snowball_illustration.png';
import RhoTokenLogo from 'assets/images/logos/rhoToken_logo.png';
import FarmLogo from 'assets/images/logos/farm_icon.png';
import { noExponents } from 'utils';

export const getNotificationDescription = (
  transaction: Transaction
): string => {
  if (transaction.type === 'approval') {
    if (transaction.source === 'earn') {
      const payload = transaction.payload as EarnPayload;
      return `Approve ${payload.token}`;
    }
    if (transaction.source === 'snowball') {
      const payload = transaction.payload as BasePayload;
      return `Approve ${payload.currency}`;
    }
    if (transaction.source === 'farm') {
      const payload = transaction.payload as BasePayload;
      return `Approve for farm: ${payload.currency}`;
    }
    if (transaction.source === 'bridge') {
      const payload = transaction.payload as BasePayload;
      return `Approve ${payload.currency}`;
    }
    if (transaction.source === 'compensation') {
      const payload = transaction.payload as CompensationPayload;
      return `Approve for farm: ${payload.label}`;
    }
  }

  if (transaction.type === 'stake' || transaction.type === 'unstake') {
    if (transaction.source === 'farm') {
      const payload = transaction.payload as BasePayload;
      return `${
        transaction.type === 'stake' ? 'Staking' : 'Unstaking'
      } ${noExponents(payload.amount)} tokens in ${payload.currency} farm`;
    }
    if (transaction.source === 'snowball') {
      const payload = transaction.payload as BasePayload;
      return `${
        transaction.type === 'stake' ? 'Staking' : 'Unstaking'
      }  ${noExponents(payload.amount)} ${payload.currency}`;
    }
  }

  if (transaction.source === 'earn') {
    if (transaction.type === 'mint') {
      const payload = transaction.payload as EarnPayload;
      return `Convert ${noExponents(payload.tokenAmount)} ${
        payload.token
      } into ${payload.rhoToken}`;
    }
    if (transaction.type === 'redeem') {
      const payload = transaction.payload as EarnPayload;
      return `Redeem ${noExponents(payload.rhotokenAmount)} ${
        payload.rhoToken
      } into ${payload.token}`;
    }
  }
  if (transaction.source === 'reward') {
    if (transaction.type === 'claim') {
      return `Claim all your rewards of FLURRY`;
    }
  }
  if (transaction.source === 'compensation') {
    const payload = transaction.payload as CompensationPayload;
    if (transaction.type === 'claim') {
      return `Claim your compensation for ${payload.label}`;
    }
  }
  if (transaction.source === 'bridge') {
    if (transaction.type === 'swap') {
      const payload = transaction.payload as BridgePayload;
      return `Bridge ${noExponents(payload.tokenAmount)} ${payload.token} `;
    }
  }
  return '';
};

export const getNotificationTitle = (transaction: Transaction): string => {
  let titleRes = '';
  switch (transaction.source) {
    case 'earn':
      titleRes += 'Earn';
      break;
    case 'farm':
      titleRes += 'Farm';
      break;
    case 'snowball':
      titleRes += 'Snowball';
      break;
    case 'reward':
      titleRes += 'Rewards';
      break;
    case 'bridge':
      titleRes += 'Bridge';
      break;
    case 'compensation':
      titleRes += 'Compensation';
      break;
  }

  switch (transaction.type) {
    case 'approval':
      titleRes += ' - Approval';
      break;
    case 'mint':
      titleRes += ' - Mint';
      break;
    case 'redeem':
      titleRes += ' - Redeem';
      break;
    case 'stake':
      titleRes += ' - Stake';
      break;
    case 'unstake':
      titleRes += ' - Unstake';
      break;
    case 'claim':
      titleRes += ' - Claim All';
      break;
    case 'swap':
      titleRes += ' - Swap';
      break;
  }
  return titleRes;
};

export const getSourceIcon = (transaction: Transaction): string => {
  switch (transaction.source) {
    case 'earn':
      return RhoTokenLogo;
    case 'snowball':
      return SnowballIcon;
    case 'compensation':
    case 'farm':
      return FarmLogo;
    case 'reward':
      return FlurryIconOnly;
    case 'bridge':
      return FlurryIconOnly;
  }
};
