import { down, up } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const Wrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  max-height: inherit;
  padding: 1rem 0.2rem 1rem 1rem;
  overflow-y: scroll;
  gap: 1rem;

  &::-webkit-scrollbar {
    margin-top: 15px;
    width: 13px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #00000021;
    border: 4px solid transparent;
    border-radius: 8px;
    background-clip: padding-box;
  }
`;

export const NotificationWrapper = styled.div`
  border: 1px solid #00000073;
  border-radius: 5px;
  padding: 0.2rem 0.8rem;
  word-break: break-word;
  img {
    max-width: 100%;
    height: auto;
  }
  iframe {
    max-width: 100%;
    display: block;
    margin: 0 auto;
    ${up('xl')} {
      height: auto;
    }
    ${down('xs')} {
      height: auto;
    }
  }
`;

export const NotificationTitle = styled.h5`
  font-size: 0.8rem;
`;

export const NotificationText = styled.div`
  font-size: 0.7rem;
`;

export const AddTokenToWallet = styled.button`
  cursor: pointer;
  opacity: 0.7;
  border: none;
  border-radius: 8px;
  font-size: 0.7rem;
  outline: none;
  background-color: #3ab4d37d;
  color: white;
  padding: 0.2rem 0.5rem;
  margin: 0;
  margin: 0.5rem auto 0 auto;
  display: block;
  transition: opacity 0.2s linear;

  &:hover {
    opacity: 1;
  }
`;

export const TransactionDescription = styled.p`
  font-size: 0.7rem;
  margin: 0;
  text-align: center;
`;

export const TransactionStatus = styled.div`
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TransactionPhrase = styled.p`
  font-size: 0.55rem;
  opacity: 0.7;
  margin: 0;
  text-align: center;
`;

export const TxEtherscanLink = styled.div`
  a {
    color: #4343fdb7;
    font-size: 0.55rem;
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 7px;
  }
`;

export const TestnetNotifWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding: 0.8rem 0;
`;

export const TestnetTitle = styled.h3`
  margin: 0;
  font-size: 0.98rem;
`;

export const TestnetText = styled.p`
  margin: 0;
  font-size: 0.7rem;
`;

export const TestnetGetStablecoins = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  text-align: center;
`;

export const CoinsLabel = styled.h5`
  margin: 0;
  font-size: 0.8rem;
`;

export const CoinsWrapper = styled.div`
  /* display:grid;
    grid-template-columns: .5fr .5fr;
    column-gap: 5px;
    row-gap: 5px; */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

export const GetUSDTLink = styled.a`
  background-color: #26a17b;
  padding: 0.5rem 0.8rem;
  margin: 0.2rem 0.2rem;
  border-radius: 5px;
  color: white;
  text-decoration: none;
  font-size: 0.8rem;
`;

export const GetUSDCLink = styled(GetUSDTLink)`
  background-color: #2775c9;
`;

export const GetBUSDLink = styled(GetUSDTLink)`
  background-color: #f0b90b;
`;
