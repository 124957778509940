import styled, { keyframes } from 'styled-components/macro';

const rotate = keyframes`
    from {
        transform: rotate(0deg);
    }
    to { 
        transform: rotate(360deg);
    }
`;

const LoadingCircle = styled.div<{ size?: number; color?: string }>`
  width: ${(props) => props.size ?? '25'}px;
  height: ${(props) => props.size ?? '25'}px;
  border: solid ${(props) => (props.size ? `${props.size / 5}px` : '5px')}
    ${(props) => props.color ?? '#8822aa'};
  border-radius: 50%;
  border-right-color: transparent;
  border-bottom-color: transparent;

  transition: all 0.5s ease-in;
  animation-name: ${rotate};
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`;

export default LoadingCircle;
