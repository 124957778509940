import { down } from 'styled-breakpoints';
import styled from 'styled-components/macro';

export const Wrapper = styled.div<{ small?: boolean }>`
  position: relative;
  display: flex;
  width: ${(props) => (props.small ? '25px' : '40px')};
  height: ${(props) => (props.small ? '25px' : '40px')};
  ${down('md')} {
    width: ${(props) => (props.small ? '25px' : '30px')};
    height: ${(props) => (props.small ? '25px' : '30px')};
  }
`;

export const BackgroundIcon = styled.img<{ small?: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  max-width: ${(props) => (props.small ? '15px' : '25px')};
  height: auto;
  ${down('md')} {
    max-width: ${(props) => (props.small ? '15px' : '20px')};
  }
`;

export const ForegroundIcon = styled.img<{ small?: boolean }>`
  position: absolute;
  z-index: 3;
  top: ${(props) => (props.small ? '6px' : '10px')};
  left: ${(props) => (props.small ? '6px' : '10px')};
  max-width: ${(props) => (props.small ? '20px' : '30px')};
  height: auto;
  ${down('md')} {
    top: ${(props) => (props.small ? '6px' : '8px')};
    left: ${(props) => (props.small ? '6px' : '8px')};
    max-width: ${(props) => (props.small ? '20px' : '25px')};
  }
`;
